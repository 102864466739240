import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import PowerBox from '../Dashboard/components/PowerBox'
import MonthlyBarChartBox from '../../components/MonthlyBarChartBox/MonthlyBarChartBox'
import '../../components/PrintPageBreak/PrintPageBreak.css'
import PrintPageBreak from '../../components/PrintPageBreak/PrintPageBreak'
import TextFieldBox from '../../components/TextFieldBox/TextFieldBox'
import Paper from '@mui/material/Paper'
import { LoadingBackdrop } from '../../components'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAggregation } from '../../store/metrics/metricsSlice'
import { DateTime } from 'luxon'

const chartData = [
  { 'day': '1', 'amount': 9, 'menge': 5 },
  { 'day': '2', 'amount': 10, 'menge': 5 },
  { 'day': '3', 'amount': 7, 'menge': 5 },
  { 'day': '4', 'amount': 2, 'menge': 5 },
  { 'day': '5', 'amount': 2, 'menge': 5 },
  { 'day': '6', 'amount': 1, 'menge': 5 },
  { 'day': '7', 'amount': 6, 'menge': 5 },
  { 'day': '8', 'amount': 7, 'menge': 5 },
  { 'day': '9', 'amount': 5, 'menge': 5 },
  { 'day': '10', 'amount': 4, 'menge': 5 },
  { 'day': '11', 'amount': 9, 'menge': 5 },
  { 'day': '12', 'amount': 8, 'menge': 5 },
  { 'day': '13', 'amount': 8, 'menge': 5 },
  { 'day': '14', 'amount': 3, 'menge': 5 },
  { 'day': '15', 'amount': 1, 'menge': 5 },
  { 'day': '16', 'amount': 0, 'menge': 5 },
  { 'day': '17', 'amount': 10, 'menge': 5 },
  { 'day': '18', 'amount': 9, 'menge': 5 },
  { 'day': '19', 'amount': 8, 'menge': 5 },
  { 'day': '20', 'amount': 4, 'menge': 5 },
  { 'day': '21', 'amount': 5, 'menge': 5 },
  { 'day': '22', 'amount': 6, 'menge': 5 },
  { 'day': '23', 'amount': 1, 'menge': 5 },
  { 'day': '24', 'amount': 4, 'menge': 5 },
  { 'day': '25', 'amount': 10, 'menge': 5 },
  { 'day': '26', 'amount': 9, 'menge': 5 },
  { 'day': '27', 'amount': 8, 'menge': 5 },
  { 'day': '28', 'amount': 3, 'menge': 5 },
  { 'day': '29', 'amount': 1, 'menge': 5 },
  { 'day': '30', 'amount': 4, 'menge': 5 }]

const chartKeys = ['amount']

const ReportContainer = () => {
  const { clientId } = useParams()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const reportData = useSelector(state => prepareReportData(state))
  const factors = reportData?.factors

  const selectedMonth = DateTime.now()
    .minus({ months: 1 })
  const intFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 })
  const dezOneFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 1 })

  useEffect(() => {
    setLoading(true)
    fetchData(selectedMonth.toUTC()
      .toFormat('yyyy-MM'))
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = (month) => {
    dispatch(fetchAggregation({ clientId: clientId, payload: { periodPrefix: month, aggregation: 'month' } }))
  }

  if (!factors) {
    return <React.Fragment>Keine Daten für diesen Berichtszeitraum </React.Fragment>
  }

  function int (kpi) {
    return intFormat.format(kpi?.v) + ' ' + kpi?.uom
  }

  function dezOne (kpi) {
    return dezOneFormat.format(kpi?.v) + ' ' + kpi?.uom
  }

  const kpiList = []

  function addKpi (kpi, label) {
    if (kpi) {
      kpiList.push({ v: kpi.v, uom: kpi.uom, label: label })
    }
  }

  addKpi(reportData.chargedWork, 'Stromverbrauch')
  addKpi(reportData.chargedWork_l1, 'L1')
  addKpi(reportData.chargedWork_l2, 'L2')
  addKpi(reportData.chargedWork_l3, 'L3')

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Paper
        elevation={-1}
        sx={{
          padding: 2,
          margin: 'auto',
        }}>
        <Grid container spacing={2} style={{ display: 'block' }}>
          <Grid item xs={12}>
            <TextFieldBox
              text={'Flotteladen Monatsbericht - ' + this.getName()?.name + ' ' + selectedMonth.format('MMMM YYYY')}/>
            <TextFieldBox
              text={'Hier sind die Ladeinformationen für den Monat ' + selectedMonth.format('MMMM YYYY')}/>
          </Grid>
          <Grid container style={{ display: 'flex' }}>
            <Grid item xs={3} md={3} style={{ display: 'flex', padding: '8px' }}>
              <PowerBox
                label={'Geladene Strecke'}
                total={reportData?.km?.v}
                uom={reportData?.km?.uom}
              />
            </Grid>
            <Grid item xs={3} md={3} style={{ display: 'flex', padding: '8px' }}>
              <PowerBox
                label={'Strecke pro Tag'}
                total={reportData?.kmPerDay?.v}
                uom={reportData?.kmPerDay?.uom}
              />
            </Grid>
            <Grid item xs={3} md={3} style={{ display: 'flex', padding: '8px' }}>
              <PowerBox
                label={'Gespartes CO2'}
                total={reportData?.savedCO2?.v}
                uom={reportData?.savedCO2?.uom}
              />
            </Grid>
            <Grid item xs={3} md={3} style={{ display: 'flex', padding: '8px' }}>
              <PowerBox
                label={'Stromkosten'}
                total={reportData?.costs?.v}
                uom={reportData?.costs?.uom}
              />
            </Grid>
          </Grid>


          <Grid container style={{ display: 'flex' }}>

            {kpiList.map((kpi) => {
              return (
                <Grid item xs={3} md={3} style={{ display: 'flex', padding: '8px' }}>
                  <PowerBox
                    label={kpi.label}
                    total={kpi.v}
                    uom={kpi.uom}
                  />
                </Grid>
              )
            })}

          </Grid>


          <Grid item xs={12}>
            <TextFieldBox
              text={`Ihre Flotte ist diesen Monat etwa ${int(reportData?.km)} gefahren, im Durchschnitt ${int(reportData?.kmPerDay)} am Tag*.`}/>
            <TextFieldBox
              text={`Durch die Elektroauto-Flotte und Flotteladen haben Sie diesen Monat ${dezOne(
                reportData?.savedCO2)} ** CO2 eingespart und leisten einen aktiven Beitrag zum Klimaschutz - Danke!`}/>
            <TextFieldBox
              text={`Sie haben ${int(reportData?.chargedWork)} geladen. Bei einem Strompreis von ${dezOne(factors.eurPerKwh)} sind dies ${int(reportData?.costs)}.`}/>
            <TextFieldBox text={'Die Grafik zeigt die Ladungen im Monatsverlauf.'}/>
          </Grid>
          {chartData &&
            <Grid item xs={12}>
              <MonthlyBarChartBox data={chartData} keys={chartKeys}/>
            </Grid>
          }
          <PrintPageBreak/>

          <Grid item xs={12}>
            <TextFieldBox
              text={'Der Wochentag an dem am meisten geladen wurde war Montag, 5. Oktober mit 500km.'}/>
            <TextFieldBox text={'Monatsverbrauch im Vergleich'}/>
            <TextFieldBox text={'Hier sehen Sie den Verbrauch der jeweiligen Monate dieses Jahres.'}/>
          </Grid>
          {chartData &&
            <Grid item xs={12}>
              <MonthlyBarChartBox data={chartData} keys={chartKeys}/>
            </Grid>
          }
          <Grid item xs={12}>
            <TextFieldBox text={'Ihr Flotteladen LMS00 hat insgesamt schon 23232 km geladen.'}/>
            <TextFieldBox text={'Ihr Flotteladen LMS00 lädt Ihre Fahrzeuge seit 3122 Tagen.'}/>
            <TextFieldBox text={'Flotteladen wünscht Ihnen weiterhin Gute Fahrt.'}/>
            <TextFieldBox text={''}/>
            <TextFieldBox text={'Ihr Flotteladen-Team'}/>
            <TextFieldBox noNewLineTop={true}
                          text={`* bei angenommenem Durchschnittsverbrauch von ${int(factors.kwhPer100Km)}`}/>
            <TextFieldBox noNewLineBottom={true}
                          text={`** Bei 100g CO2/km im Vergleich zu einem Benzin-Kleinwagen und bei Verwendung von Ökostrom.`}/>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

function prepareReportData (state) {
  if (!state?.aggregationMetrics?.aggregationMetrics?.length > 0) {
    return {}
  }

  let factors = {
    kwhPer100Km: { v: 15.0, uom: 'kWh/100 km' },
    kgCo2PerKm: { v: 0.1, uom: 'kg/km' },
    eurPerKwh: { v: 0.3, uom: '€/kWh' },
  }

  let kwhPerKm = factors.kwhPer100Km.v / 100.0

  let kpis = state.aggregationMetrics.aggregationMetrics[0]
  return {
    kpis: kpis,
    chargedWork: kpis.chargedWork,
    chargedWork_l1: kpis.chargedWork_l1,
    chargedWork_l2: kpis.chargedWork_l2,
    chargedWork_l3: kpis.chargedWork_l3,
    lengthOfMonth: kpis.lengthOfMonth,
    km: { uom: 'km', v: kpis.chargedWork?.v / kwhPerKm },
    kmPerDay: { uom: 'km', v: kpis.chargedWork?.v / kwhPerKm / kpis.lengthOfMonth?.v },
    savedCO2: { uom: 'kg', v: kpis.chargedWork?.v / kwhPerKm * factors.kgCo2PerKm.v },
    costs: { uom: '€', v: kpis.chargedWork?.v * factors.eurPerKwh.v },
    factors: factors,
  }

}

export default ReportContainer