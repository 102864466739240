import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import UserTabContainer from './UserTabContainer'
import userSettingsMailNotificationHelpText from '../../../help-texts/FL_User_Settings_Mail_Benachrichtigung.md'

const UserSettingsPage = () => {
  const SETTINGS = 'Einstellungen'
  const HELP_TITLE = 'E-Mail-Benachrichtigungen'

  const [helpText, setHelpText] = useState('')

  useEffect(() => {
    fetch(userSettingsMailNotificationHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={SETTINGS} pageTitle={SETTINGS} helpText={helpText} helpTitle={HELP_TITLE}>
      <UserTabContainer/>
    </Page>
  )
}

export default UserSettingsPage