import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Tooltip, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop, LoadMoreButton, NavigateFab } from '../../../components'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import UsersTable from '../Users/UsersTable'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import UsersList from '../Users/UsersList'
import BlockIcon from '@mui/icons-material/Block'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import Page from '../../../components/Page/Page'
import ChargingPointsUsersHelpText from '../../../help-texts/FL_Authorization_ChargingPoints_Users.md'
import AddIcon from '@mui/icons-material/Add'

const ContentDiv = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}))

const ButtonDiv = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '18px',
})

const CustomDeleteIcon = () => (
  <Tooltip title={'Nutzer*in entfernen'}>
    <BlockIcon/>
  </Tooltip>
)

const ChargingPointUsersPage = () => {

  const theme = useTheme()
  const { clientId, pointId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const userRoles = useSelector(state => getRoles(state))

  const [data, setData] = useState({})
  const [content, setContent] = useState([])
  const [name, setName] = useState(location.state?.name)
  const [userToDeleteCPFrom, setUserToDeleteCPFrom] = useState()

  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const CP_URL = `/api/client/${clientId}/configuration/chargingpoints/${pointId}}`
  const CP_USERS_URL = `/api/client/${clientId}/chargingpoints/${pointId}/users`
  const CP_USER_URL = `/api/client/${clientId}/chargingpoint/users/{userId}`

  const TITLE = `Aktive Nutzer*innen für ${name}`
  const CP_USER_TITLE = 'Ladepunktnutzer*innen'
  const Users_HELP_TITLE = 'Nutzer*innen'

  const [helpText, setHelpText] = useState()

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    fetchHelpText()
    if (!name) {
      fetchChargingPoint()
    }
    fetchChargingPointUsers()
  }

  const fetchHelpText = () => {
    fetch(ChargingPointsUsersHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchChargingPoint = () => {
    backendApiClient.get(CP_URL)
      .then((response) => {
        setName(response.name)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const fetchChargingPointUsers = (cursor) => {
    setLoading(true)
    let queryParams = cursor ? { cursor: cursor } : {}
    backendApiClient.get(CP_USERS_URL, queryParams)
      .then((response) => {
        setData(response)
        if (cursor) {
          setContent(content.concat(response.content))
        } else {
          setContent(response.content)
        }
      })
      .catch((e) => {
        throw new Error(e)
      })
      .finally(() => setLoading(false))
  }

  const updateUser = () => {
    setDeleteOpen(false)
    setLoading(true)
    const validForChargingPoints = userToDeleteCPFrom.chargingPointIds.filter(function (item) {
      return item !== parseInt(pointId)
    })
    const updatedUser = { ...userToDeleteCPFrom, chargingPointIds: validForChargingPoints }
    saveUser(updatedUser)
      .finally(() => setLoading(false))
  }

  const saveUser = (data) => {
    const url = CP_USER_URL
      .replace(/{userId}/g, userToDeleteCPFrom.userId)
    return backendApiClient.put(url, data)
      .then(() => {
        fetchChargingPointUsers()
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const handleDialogOpen = (entry) => {
    try {
      setUserToDeleteCPFrom(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  function handleEdit (user) {
    navigate(`../../users/${user.userId}/edit`, { state: { user: user } })
  }

  function handleDelete (user) {
    handleDialogOpen(user)
  }

  return (
    <Page viewName={CP_USER_TITLE} pageTitle={CP_USER_TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={Users_HELP_TITLE}>
      <LoadingBackdrop open={loading}/>
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={updateUser}
        dialogText={'Ladepunktnutzer*in für diesen Ladepunkt entfernen?'}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h4'}>
            {TITLE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ContentDiv>
            {smallScreen ?
              <UsersList content={content} handleEdit={handleEdit} handleDelete={handleDelete} allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)} CustomDeleteIcon={CustomDeleteIcon}/>
              :
              <UsersTable content={content} handleEdit={handleEdit} handleDelete={handleDelete} allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)} CustomDeleteIcon={CustomDeleteIcon}/>
            }
          </ContentDiv>
        </Grid>
      </Grid>
      <ButtonDiv>
        {data?.last ? <></> :
          <LoadMoreButton
            onClick={() => {fetchChargingPointUsers(data?.pageable?.cursor)}}
            label={'Weitere Nutzer*innen anzeigen'}
          />
        }
      </ButtonDiv>
      <NavigateFab
        path={`add`}
        state={data?.last ? { users: content, name: location.state?.name } : { name: location.state?.name }}
        text={'Bestehende Nutzer zuordnen'}
      >
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default ChargingPointUsersPage
