import { Grid, Paper } from '@mui/material'
import CustomTextField from './CustomTextField'
import ConfigurationTextField from './ConfigurationTextField'
import CustomSelect from './CustomSelect'
import IpAddressWidget from './IpAddressWidget'
import React, { useEffect, useState } from 'react'
import CustomChangeableSelect from './CustomChangeableSelect'
import SwitchCheckbox from './SwitchCheckBox'
import { useRouteLoaderData } from 'react-router'

const AreaWidget = (props) => {
  const [data, setData] = useState(props?.formData)
  const schema = props?.schema.properties
  const [errorSchema, setErrorSchema] = useState(props?.errorSchema)
  const [isChanged, setIsChanged] = useState(false)

  const { lmsVersion } = useRouteLoaderData('clientRoot')

  useEffect(() => {
    try {
      setErrorSchema(props.errorSchema)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onChange = (name, value) => {
    try {
      setData(
        {
          ...data,
          [name]: value,
        },
      )
      setIsChanged(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onChangeEnergyMeter = (name, value) => {
    try {
      setData(
        {
          ...data,
          energyMeter: {
            ...data.energyMeter,
            [name]: value,
          },
        },
      )
      setIsChanged(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onBlur = () => {
    if (isChanged) {
      props.setAreaChanged(true)
      props.setAreaData(data)
    }
  }

  const disableOption = (item) => {
    let disable = false
    const itemArea = props.areas?.find((area) => area.id === item)
    if (lmsVersion > 1 && !itemArea?.parentAreaId && data.meterConfiguration === false) {
      return true
    }
    if (lmsVersion > 1 && itemArea?.meterConfiguration === false) {
      disable = true
    }
    return disable
  }

  const allowVirtualMeter = (data) => {
    //first 2 levels of areas cant have
    return data.id !== props.firstArea && data.parentAreaId !== props.firstArea && lmsVersion > 1 && !data.hasChild
  }

  return (
    <Paper sx={{
      flexGrow: 1,
      borderRadius: '7px',
      padding: '10px',
    }}
           elevation={2}>
      <Grid
        sx={{ marginTop: 2 }}
        container
        spacing={2}
      >
        {allowVirtualMeter(data) &&
          <Grid item xs={12} sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: '4px',
          }}>
            <SwitchCheckbox
              value={data.meterConfiguration !== null ? data.meterConfiguration : true}
              onChange={(value) => onChange('meterConfiguration', value)}
              id={'meterConfigurationSwitch'}
              label={data.meterConfiguration ? 'Leistungsmessung mit Zähler' : 'Leistungsmessungen über die angeschlossenen Ladepunkte'}
              labelPlacement={'start'}
              onBlur={() => onBlur()}
            />
          </Grid>
        }
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            id={'areaName'}
            disabled={!props.isServicePerson}
            props={schema?.areas?.items?.properties?.name}
            value={data.name ? data.name : ''}
            error={errorSchema.name ? errorSchema?.name : undefined}
            onChange={(value) => onChange('name', value)}
            onBlur={() => onBlur()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ConfigurationTextField
            id={'areaFuseCurrentLimit'}
            readonly={!props.isServicePerson}
            props={schema?.areas?.items?.properties?.fuseCurrentLimit}
            value={data.fuseCurrentLimit ? data.fuseCurrentLimit : 0}
            error={errorSchema.fuseCurrentLimit ? errorSchema?.fuseCurrentLimit : undefined}
            onChange={(value) => onChange('fuseCurrentLimit', value)}
            onBlur={() => onBlur()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ConfigurationTextField
            id={'areaPowerLimit'}
            readonly={!props.isServicePerson}
            props={schema?.areas?.items?.properties?.powerLimit}
            value={data.powerLimit ? data.powerLimit : 0}
            error={errorSchema.powerLimit ? errorSchema?.powerLimit : undefined}
            onChange={(value) => onChange('powerLimit', value)}
            onBlur={() => onBlur()}
          />
        </Grid>
        {(data.meterConfiguration || lmsVersion < 2) &&
          <Grid
            item container
            spacing={2}
            xs={12}
          >
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                id={'energyMeterName'}
                disabled={lmsVersion < 2 || !props.isServicePerson}
                props={schema?.areas?.items?.properties?.energyMeter?.name}
                value={data.energyMeter?.name ? data.energyMeter?.name : ''}
                error={errorSchema.energyMeter?.name ? errorSchema?.energyMeter?.name : undefined}
                onChange={(value) => onChangeEnergyMeter('name', value)}
                onBlur={() => onBlur()}
              />
            </Grid>
            {lmsVersion < 2 &&
              <Grid
                item
                xs={12}
                sm={6}
              >
                <CustomSelect
                  id={'energyMeterComType'}
                  disabled={lmsVersion < 2 || !props.isServicePerson}
                  props={schema?.areas?.items?.properties?.energyMeter?.comType}
                  value={data.energyMeter?.comType ? data.energyMeter?.comType : 'tcp'}
                  error={errorSchema.energyMeter?.comType ? errorSchema?.energyMeter?.comType : undefined}
                  onChange={(value) => onChangeEnergyMeter('comType', value)}
                  onBlur={() => onBlur()}
                />
              </Grid>
            }
            <Grid
              item
              xs={12}
              sm={lmsVersion < 2 ? 6 : 12}
            >
              <CustomChangeableSelect
                id={'energyMeterManufacturer'}
                disabled={!props.isServicePerson}
                setOne={['schneider']}
                setOneNames={['Schneider']}
                setTwo={['celsa', 'gossen', 'janitza']}
                setTwoNames={['Celsa', 'Gossen', 'Janitza']}
                useFirst={data.energyMeter?.comType === 'serial'}
                props={schema?.areas?.items?.properties?.energyMeter?.manufacturer}
                value={data.energyMeter?.manufacturer ? data.energyMeter?.manufacturer : (data.energyMeter?.comType ? (data.energyMeter?.comType === 'tcp' ? 'celsa' : 'schneider') : 'celsa')}
                error={errorSchema.energyMeter?.manufacturer ? errorSchema?.energyMeter?.manufacturer : undefined}
                onChange={(value) => onChangeEnergyMeter('manufacturer', value)}
                onBlur={() => onBlur()}
              />
            </Grid>
            {data.energyMeter?.comType === 'tcp' && (
              <Grid item container xs={12} sm={6}>
                <Grid item>
                  <IpAddressWidget
                    id={'energyMeterIpAddress'}
                    disabled={lmsVersion < 2 || !props.isServicePerson}
                    props={schema?.areas?.items?.properties?.energyMeter?.ipAddress}
                    value={data?.energyMeter?.ipAddress}
                    error={errorSchema.energyMeter?.ipAddress ? errorSchema?.energyMeter?.ipAddress : undefined}
                    onChange={(value) => onChangeEnergyMeter('ipAddress', value)}
                    onBlur={() => onBlur()}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={lmsVersion < 2 ? 12 : 6}
            >
              <CustomSelect
                id={'energyMeterDeviceId'}
                disabled={true}
                props={schema?.areas?.items?.properties?.energyMeter?.deviceId}
                value={data.energyMeter?.deviceId ? data.energyMeter?.deviceId : 1}
                error={errorSchema.energyMeter?.deviceId ? errorSchema?.energyMeter?.deviceId : undefined}
                onChange={(value) => onChangeEnergyMeter('deviceId', value)}
                onBlur={() => onBlur()}
              />
            </Grid>
          </Grid>
        }

        {!(data.parentAreaId === null || data.parentAreaId === 0) && (
          <Grid item xs={12}>
            <CustomSelect
              id={'areaConfigurationParent'}
              disabled={lmsVersion < 2 || !props.isServicePerson}
              props={schema?.areas?.items?.properties?.parentAreaId}
              value={data.parentAreaId}
              error={errorSchema.parentAreaId ? errorSchema?.parentAreaId : undefined}
              onChange={(value) => onChange('parentAreaId', value)}
              onBlur={() => onBlur()}
              disableOption={disableOption}
            />
          </Grid>
        )
        }
      </Grid>
    </Paper>
  )

}

export default AreaWidget