import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { Footer, Sidebar, Topbar } from './components'
import Container from '@mui/material/Container'
import { Outlet } from 'react-router'

const RootDiv = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  paddingTop: 56,
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  },
}))

const Main = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  return (
    <RootDiv sx={[
      isDesktop && { paddingLeft: 30 },
    ]}
    >
      <Topbar onSidebarOpen={handleSidebarOpen}/>
      <Sidebar onClose={handleSidebarClose}
               open={shouldOpenSidebar}
               variant={isDesktop ? 'persistent' : 'temporary'}/>
      <main
        style={{
          minHeight: '100%',
          paddingLeft: 23,
          paddingRight: 23,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container
          sx={{
            minHeight: '100%',
            padding: 3,
          }}>
          <Outlet/>
          <Footer/>
        </Container>
      </main>
    </RootDiv>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
