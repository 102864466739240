import PropTypes from 'prop-types'
import { Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useParams } from 'react-router'
import { useFormik } from 'formik'
import { SubmitFab } from '../../../components'
import { digitalOutputValidationSchema as validationSchema } from '../EnergieManagementValidations'
import DigitalOutputRule from './DigitalOutputRule'
import { styled } from '@mui/material/styles'

const TypographyLeftSide = styled(Typography)({
  paddingTop: '4px',
})

const InputTextField = styled(TextField)(({ theme }) => ({
  width: '60px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  align: 'right',
}))

const EditDigitalOutput = ({ onAfterSubmit }) => {
  const location = useLocation()
  const { clientId } = useParams()
  const URL = '/api/client/' + clientId + '/ems/configuration/digitaloutputs'
  const [data, setData] = useState({})

  useEffect(() => {
    if (location.state.name === null) {
      location.state.name = ''
    }
    setData(location.state)
  }, [location.state])

  const formik = useFormik({
    initialValues: {
      'name': '',
      'enabledByDefault': false,
      'activated': false,
      'rules': [
        {
          'type': 'powerSpotPrice',
          'activated': false,
          'values': [
            {
              'threshold': 'greaterThanThreshold',
              'thresholdValue': 2,
              'unit': 'euroPerMWh',
            },
          ],
        },
        {
          'type': 'powerSpotPrice',
          'activated': false,
          'values': [
            {
              'threshold': 'lessThanThreshold',
              'thresholdValue': 2,
              'unit': 'euroPerMWh',
            },
          ],
        },
        {
          'type': 'powerSurplus',
          'activated': false,
          'values': [],
        },
        {
          'type': 'powerLimitSwitchOn',
          'activated': false,
          'values': [
            {
              'threshold': 'switchOnThreshold',
              'thresholdValue': 0,
              'unit': 'percentage',
            },
          ],
        },
      ],
      'minRunningTimeInMinutes': 0,
      'waitingTimeBeforeRestartInMinutes': 0,
      'loadLimit': {
        'type': 'powerLimitSwitchOff',
        'activated': false,
        'values': [
          {
            'threshold': 'switchOffThreshold',
            'thresholdValue': 0,
            'unit': 'percentage',
          },
        ],
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      backendApiClient.put(URL, values)
        .then(() => {
          onAfterSubmit()
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
  })

  useEffect(() => {
    formik.setValues(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item sm={8} xs={12}>
            <TextField
              fullWidth
              variant={'standard'}
              id={'name'}
              name={'name'}
              label={'Name'}
              value={formik.values.name ? formik.values.name : ''}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item container sm={4} xs={12} justifyContent={'flex-end'} sx={{ paddingTop: '11px' }}>
            <Grid container item xs={6} sm={7} justifyContent={'center'} direction={'column'}>
              <Typography align={'right'}>
                Standardmäßig
              </Typography>
            </Grid>
            <Grid item xs={6} sm={5} container justifyContent={'flex-end'}>
              <Stack direction="row" spacing={1} alignItems="center" height={'100%'}>
                <Typography>Aus</Typography>
                <Switch id={'enabledByDefault'} name={'enabledByDefault'}
                        checked={formik.values.enabledByDefault ? formik.values.enabledByDefault : false}
                        onChange={formik.handleChange}/>
                <Typography>Ein</Typography>
              </Stack>
            </Grid>
          </Grid>


          <Grid item xs={12} sx={{ paddingTop: 3 }}>
            <Typography variant={'h6'}>
              Allgemeine Einstellung:
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ paddingTop: 2 }}>
            <Grid item sm={3} xs={6}>
              <TypographyLeftSide>
                Minimale Laufzeit
              </TypographyLeftSide>
            </Grid>
            <Grid item sm={2} xs={6} container>
              <InputTextField
                type={'number'}
                inputProps={{ style: { textAlign: 'right' } }}
                variant={'standard'}
                id={'minRunningTimeInMinutes'}
                name={'minRunningTimeInMinutes'}
                value={formik.values.minRunningTimeInMinutes ? formik.values.minRunningTimeInMinutes : 0}
                error={!!formik.errors?.minRunningTimeInMinutes}
                onChange={formik.handleChange}>
              </InputTextField>
              <TypographyLeftSide>
                Minuten
              </TypographyLeftSide>
            </Grid>
            <TypographyLeftSide color={'error'}>
              {formik.errors.minRunningTimeInMinutes}
            </TypographyLeftSide>
          </Grid>
          <Grid item container xs={12} sx={{ paddingTop: '5px' }}>
            <Grid item sm={3} xs={6}>
              <TypographyLeftSide>
                Wartezeit vor Neustart
              </TypographyLeftSide>
            </Grid>
            <Grid item sm={2} xs={6} container>
              <InputTextField
                type={'number'}
                inputProps={{ style: { textAlign: 'right' } }}
                variant={'standard'}
                id={'waitingTimeBeforeRestartInMinutes'}
                name={'waitingTimeBeforeRestartInMinutes'}
                value={formik.values.waitingTimeBeforeRestartInMinutes ? formik.values.waitingTimeBeforeRestartInMinutes : 0}
                error={!!formik.errors?.waitingTimeBeforeRestartInMinutes}
                onChange={formik.handleChange}>
              </InputTextField>
              <TypographyLeftSide>
                Minuten
              </TypographyLeftSide>
            </Grid>
            <TypographyLeftSide color={'error'}>
              {formik.errors.waitingTimeBeforeRestartInMinutes}
            </TypographyLeftSide>
          </Grid>
          <Grid item container xs={12} sx={{ paddingTop: 2 }}>
            <TypographyLeftSide>
              {'Bei Auslastung am Hausanschluss von >'}
            </TypographyLeftSide>
            <InputTextField
              type={'number'}
              inputProps={{ style: { textAlign: 'right' } }}
              variant={'standard'}
              id={'loadLimit.values[0].thresholdValue'}
              name={'loadLimit.values[0].thresholdValue'}
              value={formik.values.loadLimit?.values?.length > 0 && formik.values.loadLimit?.values[0]?.thresholdValue ? formik.values.loadLimit?.values[0]?.thresholdValue : 0}
              error={!!formik.errors?.loadLimit?.values?.length}
              onChange={formik.handleChange}>
            </InputTextField>
            <TypographyLeftSide>
              % immer abschalten
            </TypographyLeftSide>
            <Switch
              sx={{ marginTop: '-4px' }}
              id={'loadLimit.activated'}
              name={'loadLimit.activated'}
              checked={formik.values.loadLimit?.activated ? formik.values.loadLimit.activated : false}
              onChange={formik.handleChange}
            />
          </Grid>
          <TypographyLeftSide color={'error'}>
            {formik.errors.loadLimit?.values[0]?.thresholdValue}
          </TypographyLeftSide>
        </Grid>
        {formik.values.rules && formik.values.rules.length > 0 &&
          <div style={{ width: '100%' }}>
            <Grid item xs={12} sx={{ paddingTop: 4 }}>
              <Typography variant={'h6'}>
                Digitaler Ausgang ist aktiv, wenn:
              </Typography>
            </Grid>
            {formik.values.rules.map((rule, index) =>
              <DigitalOutputRule formik={formik} rule={rule} index={index} key={index}/>,
            )}
          </div>
        }
        <SubmitFab show={true}/>
      </form>
    </div>
  )
}

EditDigitalOutput.propTypes = {
  onAfterSubmit: PropTypes.func,
}

export default EditDigitalOutput