import React, { useEffect, useState } from 'react'
import backendApiClient from '../../../apiClient/apiClient'
import schema from './NewConfigSchemaBuilder.js'
import EditForm from '../../../components/crud/EditForm'
import { getRoles, isServicePerson } from '../../../security/roles'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { LoadingBackdrop } from '../../../components'
import ConfigurationWidgetWithZones from '../../../components/crud/widgets/ConfigurationWidgetWithZones'
import AreaEditForm from './AreaEditForm'
import { useParams, useRouteLoaderData } from 'react-router'

const ConfigurationEditForm = ({ settings }) => {
  const { clientId } = useParams()
  const URL_UPDATE = '/api/client/{client}/configuration'

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const userRoles = useSelector(state => getRoles(state))

  useEffect(() => {
    setSettingsData(settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  const uiSchema = {
    'ui:field': 'ConfigurationWidget',
  }

  const fields = {
    ConfigurationWidget: (props) => <ConfigurationWidgetWithZones {...props} canSave={isServicePerson(userRoles)}
                                                                  onSave={(formData) => save(formData)}/>,
  }

  const fetchData = () => {
    setLoading(true)
    const url = URL_UPDATE.replace(/{client}/g, clientId)
    backendApiClient.get(url)
      .then((response) => {
        setSettingsData(response)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const setSettingsData = (settings) => {
    setData({
      ...settings,
      gridPowerLimit: Math.round(settings.gridPowerLimit / 0.69),
      chargingPowerLimit: Math.round(settings.chargingPowerLimit / 0.69),
    })
  }

  const save = (formData) => {
    if (isServicePerson(userRoles)) {
      let data = formData
      delete formData.areas
      setLoading(true)
      const url = URL_UPDATE.replace(/{client}/g, clientId)
      data.gridPowerLimit = Math.round(data.gridPowerLimit * 0.69)
      data.chargingPowerLimit = Math.round(data.chargingPowerLimit * 0.69)
      backendApiClient.put(url, data)
        .then(() => {
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
            variant: 'success',
          })
          setLoading(false)
          fetchData()
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
            variant: 'error',
          })
          setLoading(false)
        })
    } else {
      enqueueSnackbar('Sie haben keine Berechtigung zum ändern!', {
        variant: 'warning',
      })
    }
  }

  return (

    <div style={{
      display: 'flex',
      flexDirection: 'column-reverse',
    }}>
      <LoadingBackdrop open={loading}/>
      <AreaEditForm version={lmsVersion} canSave={isServicePerson(userRoles)}/>
      <EditForm
        showSubmit={false}
        schema={schema(data.areas)}
        uiSchema={uiSchema}
        formData={data}
        fields={fields}
        onSubdmit={save}
        disabledSubmit={isServicePerson(userRoles)}
      />
    </div>
  )
}

export default ConfigurationEditForm