import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Page } from '../../components'
import UserManagementWidget from '../../components/crud/widgets/UserManagementWidget'
import { backendApiClient } from '../../apiClient'
import { useSnackbar } from 'notistack'

const AddUserPage = ({ roles }) => {

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const NEW_USER_TITLE = 'Neuen Nutzer anlegen'
  const USER_MANAGEMENT = 'Nutzerverwaltung'

  const profileApi = '/api/user/profiles'

  const save = (data) => {
    setLoading(true)
    backendApiClient.post(profileApi, data)
      .then(() => {
        enqueueSnackbar('Nutzer erfolgreich gespeichert!', {
          variant: 'success',
        })
        navigate('..')
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={NEW_USER_TITLE} onBack={() => navigate(-1)} pageTitle={USER_MANAGEMENT} roles={roles}>
      <UserManagementWidget save={save} loading={loading}/>
    </Page>
  )
}

export default AddUserPage