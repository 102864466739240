import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import ChargingPointAuthorizationTabContainer from './ChargingPointAuthorizationTabContainer'
import ChargingPointsHelpText from '../../help-texts/FL_Authorization_ChargingPoints.md'
import CardsHelpText from '../../help-texts/FL_Authorization_Cards.md'
import UsersHelpText from '../../help-texts/FL_Authorization_Users.md'
import { useLocation } from 'react-router-dom'

const ChargingPointAuthorizationPage = () => {
  const PAGE_TITLE = 'Freigabe'

  const CP_HELP_TITLE = 'Freigabe Ladepunkte'
  const Cards_HELP_TITLE = 'Ladekarten'
  const Users_HELP_TITLE = 'Nutzer*innen'

  const [helpText, setHelpText] = useState()
  const [helpTitle, setHelpTitle] = useState()

  const url = useLocation().pathname

  const getHelpText = (url) => {
    if (url.includes('tags')) {
      return { title: Cards_HELP_TITLE, text: CardsHelpText }
    } else if (url.includes('users')) {
      return { title: Users_HELP_TITLE, text: UsersHelpText }
    } else {
      return { title: CP_HELP_TITLE, text: ChargingPointsHelpText }
    }
  }

  useEffect(() => {
    const helpText = getHelpText(url)
    setHelpTitle(helpText.title)
    fetch(helpText.text)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <Page viewName={'Freigabe'} helpText={helpText} helpTitle={helpTitle} pageTitle={PAGE_TITLE}>
      <ChargingPointAuthorizationTabContainer/>
    </Page>
  )
}

export default ChargingPointAuthorizationPage