import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import NewContact from './NewContact'
import { useNavigate } from 'react-router'
import baseDataNewContactHelpText from '../../help-texts/FL_Ansprechperson_anlegen.md'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const NewBaseDataPage = () => {

  const navigate = useNavigate()
  const [helpText, setHelpText] = useState()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const CONTACT_HELP_TITLE = 'Legen Sie eine neue Ansprechperson an'

  useEffect(() => {
    fetch(baseDataNewContactHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={smallScreen ? 'Kontakt' : 'Ansprechperson anlegen'} onBack={() => navigate(-1)}
          pageTitle={'Stammdaten'} helpTitle={CONTACT_HELP_TITLE} helpText={helpText}>
      <NewContact onAfterSubmit={() => navigate(-1)}/>
    </Page>
  )
}

export default NewBaseDataPage