import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { backendApiClient } from '../../../apiClient'
import { useParams } from 'react-router'
import { Checkbox, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { useSnackbar } from 'notistack'
import { chargingSuggestionValidationSchema as validationSchema } from '../EnergieManagementValidations'

const EditChargingSuggestion = ({}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { clientId } = useParams()
  const [loading, setLoading] = useState(false)
  const URL = '/api/client/' + clientId + '/ems/configuration/charge-advice'

  const SWITCH_ON_HELP = 'Die Ladeempfehlung wird ab dieser Leistung aktiviert'
  const SWITCH_ON_LABEL = 'Einschaltschwelle'
  const SWITCH_OFF_HELP = 'Die Ladeempfehlung wird unterhalb dieser Leistung deaktiviert'
  const SWITCH_OFF_LABEL = 'Ausschaltschwelle'

  const formik = useFormik({
    initialValues: {
      enabled: false,
      powerSurplus: {
        activated: false,
        type: 'powerSurplus',
        values: [
          {
            type: 'switchOffThreshold',
            unit: 'kW',
            value: 0,
          },
          {
            type: 'switchOnThreshold',
            unit: 'kW',
            value: 0,
          },
        ],
      },
      powerSpotPrice: {
        activated: false,
        type: 'powerSpotPrice',
        values: [
          {
            type: 'lessThanThreshold',
            unit: 'euroPerMWh',
            value: 0,
          },
        ],
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      backendApiClient.put(URL, values)
        .then(() => {
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen.', { variant: 'success' })
          fetchData()
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
            variant: 'error',
          })
        })
    },
  })

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(URL)
      .then((response) => {
        formik.setValues(response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item container xs={12}>
            <Typography variant={'h6'} sx={{ marginRight: '16px', paddingTop: '9px' }}>
              Ladeempfehlung
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" height={'100%'}>
              <Typography>Aus</Typography>
              <Switch id={'enabled'} name={'enabled'}
                      checked={formik.values.enabled ? formik.values.enabled : false}
                      onChange={formik.handleChange}/>
              <Typography>Ein</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: '16px' }}>
            <Typography variant={'h6'}>
              Nachhaltiges Laden definiert sich über:
            </Typography>
          </Grid>

          <Grid item container xs={12} sx={{ paddingTop: 2 }}>
            <Grid item container xs={11}>
              <Typography sx={{ paddingTop: '4px' }}>
                {'Spotmarktpreis <'}
              </Typography>
              <TextField
                disabled={!formik.values.powerSpotPrice?.activated}
                sx={{
                  width: '80px',
                  paddingLeft: 1,
                  paddingRight: 1,
                  align: 'right',
                }}
                type={'number'}
                inputProps={{ style: { textAlign: 'right' } }}
                variant={'standard'}
                id={'powerSpotPrice.values[0].thresholdValue'}
                name={'powerSpotPrice.values[0].thresholdValue'}
                value={formik.values.powerSpotPrice?.values && formik.values.powerSpotPrice?.values[0].thresholdValue ? formik.values.powerSpotPrice?.values[0].thresholdValue : 0}
                onChange={formik.handleChange}
              >
              </TextField>
              <Typography sx={{ paddingTop: '4px' }}>
                EUR / MWh
              </Typography>
            </Grid>
            <Grid item container justifyContent={'flex-end'} xs={1}>
              <Checkbox
                sx={{ paddingTop: '3px' }}
                id={'powerSpotPrice.activated'}
                name={'powerSpotPrice.activated'}
                checked={formik.values.powerSpotPrice?.activated}
                onChange={formik.handleChange}
              />
            </Grid>
            {
              formik.errors.powerSpotPrice?.values[0]?.value &&
              <Typography color={'error'} variant={'body2'}>
                {formik.errors.powerSpotPrice.values[0].value}
              </Typography>
            }
          </Grid>

          <Grid item container xs={12} sx={{ paddingTop: 2 }}>
            <Grid item xs={11}>
              <Typography variant={'h6'}>
                Überschuss am Hausanschluss
              </Typography>
            </Grid>
            <Grid item container justifyContent={'flex-end'} xs={1}>
              <Checkbox
                sx={{ paddingTop: '3px' }}
                id={'powerSurplus.activated'}
                name={'powerSurplus.activated'}
                checked={formik.values.powerSurplus.activated}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item sm={3} xs={12}>
              <TextField
                type={'number'}
                variant={'standard'}
                id={'powerSurplus.values[1].thresholdValue'}
                name={'powerSurplus.values[1].thresholdValue'}
                disabled={!formik.values.powerSurplus.activated}
                value={formik.values.powerSurplus.values[1].thresholdValue ? formik.values.powerSurplus.values[1].thresholdValue : 0}
                onChange={formik.handleChange}
                label={SWITCH_ON_LABEL}
                helperText={formik.errors?.powerSurplus?.values[1]?.thresholdValue ? formik.errors.powerSurplus.values[1].thresholdValue : SWITCH_ON_HELP}
                error={!!formik.errors?.powerSurplus?.values[1]?.thresholdValue}
                inputProps={{ step: 0.01 }}
                InputProps={
                  {
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <PlayArrowIcon/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <Typography
                          sx={[
                            !formik.values.powerSurplus.activated && { color: 'secondary.light' },
                          ]}
                        >
                          kW
                        </Typography>
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Grid>
            <Grid item sm={3} xs={12} sx={{ paddingLeft: '8px' }}>
              <TextField
                type={'number'}
                variant={'standard'}
                id={'powerSurplus.values[0].thresholdValue'}
                name={'powerSurplus.values[0].thresholdValue'}
                disabled={!formik.values.powerSurplus.activated}
                onChange={formik.handleChange}
                value={formik.values.powerSurplus.values[0].thresholdValue ? formik.values.powerSurplus.values[0].thresholdValue : 0}
                label={SWITCH_OFF_LABEL}
                helperText={formik.errors?.powerSurplus?.values[0]?.thresholdValue ? formik.errors.powerSurplus.values[0].thresholdValue : SWITCH_OFF_HELP}
                error={!!formik.errors?.powerSurplus?.values[0]?.thresholdValue}
                inputProps={{ step: 0.01 }}
                InputProps={
                  {
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <StopIcon/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <Typography
                          sx={[
                            !formik.values.powerSurplus.activated && { color: 'secondary.light' },
                          ]}
                        >
                          kW
                        </Typography>
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <SubmitFab show={true}/>
      </form>
    </div>
  )
}

export default EditChargingSuggestion