import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import LmsStatusBox from '../../components/LmsStatusBox/LmsStatusBox'
import { SearchBarHeaderFilter } from '../../components'
import { Navigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import Card from '@mui/material/Card'
import { CardActionArea } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import { getRoles, isCPO, isSupportPerson } from '../../security/roles'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router'

const LmsListContainer = ({lmsList}) => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  const [filter, setFilter] = useState([])
  const filterOptions = [
    'SEPARATOR_SEVERITY',
    'INFO',
    'WARNING',
    'ERROR',
    'SEPARATOR_CONNECTION',
    'ONLINE',
    'OFFLINE',
    'UNKNOWN',
    'SEPARATOR_VERSION',
    '0',
    '1',
    '2',
  ]
  const severityOptions = ['INFO', 'WARNING', 'ERROR']
  const versionOptions = ['0', '1', '2']
  const statusOptions = ['ONLINE', 'OFFLINE', 'UNKNOWN']

  const userRoles = useSelector(state => getRoles(state))

  const [displayList, setDisplayList] = useState(lmsList)

  const getFilterLabel = (value) => {
    switch (value) {
      case 'SEPARATOR_SEVERITY':
        return 'Warnstufe:'
      case 'ERROR':
        return 'Fehler'
      case 'WARNING':
        return 'Warnung'
      case 'INFO':
        return 'Info'
      case 'SEPARATOR_CONNECTION':
        return 'Systemstatus:'
      case 'OFFLINE':
        return 'Offline'
      case 'ONLINE':
        return 'Online'
      case 'UNKNOWN':
        return 'Unbekannt'
      case 'SEPARATOR_VERSION':
        return 'LMS-Version:'
      case '0':
        return '0'
      case '1':
        return '1'
      case '2':
        return '2'
      default:
        return 'Fehler'
    }
  }

  const handleClear = () => {
    setSearchText('')
  }

  const changeFilter = (value) => {
    if (value === 'CLEAR') {
      setFilter([])
    } else {
      const valueIndex = filter.indexOf(value)
      let tempFilter = [...filter]
      if (valueIndex >= 0) {
        tempFilter.splice(valueIndex, 1)
      } else {
        tempFilter.push(value)
      }
      setFilter(tempFilter)
    }
  }

  const adjustLMSList = (newList) => {
    if (filter.length > 0 || searchText.length > 0) {
      let tempList = newList
      if (searchText.length > 0) {
        tempList = newList.filter((system) => {
          if (system.name) {
            return system.name?.toUpperCase()
                .includes(searchText.toUpperCase()) ||
              system.version?.toUpperCase()
                .includes(searchText.toUpperCase()) ||
              system.clientId?.toUpperCase()
                .includes(searchText.toUpperCase())
          } else {
            return system.version?.toUpperCase()
                .includes(searchText.toUpperCase()) ||
              system.clientId.toUpperCase()
                .includes(searchText.toUpperCase())
          }
        })
      }
      if (filter.length > 0) {
        const severityFilter = severityOptions.filter(value => filter.includes(value))
        const versionFilter = versionOptions.filter(value => filter.includes(value))
        const statusFilter = statusOptions.filter(value => filter.includes(value))
        if (statusFilter.length > 0) {
          tempList = tempList.filter((system) => statusFilter.includes(system.connectionStatus))
        }
        if (severityFilter.length > 0) {
          tempList = tempList.filter((system) => severityFilter.includes(system.severity))
        }
        if (versionFilter.length > 0) {
          tempList = tempList.filter((system) => versionFilter.includes(system.lmsVersion))
        }
      }
      setDisplayList(tempList)
    } else {
      setDisplayList(lmsList)
    }
  }

  const cardClickNavigate = (item) => {
    if (item.name && item.name.length > 0) {
      navigate('/client/' + item.clientId + '/dashboard')
    } else {
      navigate('/stations/new', { state: { clientId: item.clientId } })
    }
  }

  const editIconClickNavigate = (item) => {
    if (item.name && item.name.length > 0) {
      navigate('/stations/' + item.clientId + '/edit')
    } else {
      navigate('/stations/new', { state: { clientId: item.clientId } })
    }
  }

  useEffect(() => {
    adjustLMSList(lmsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lmsList, filter, searchText])

  return (
    <div>
      {lmsList.length === 1 && !(isSupportPerson(userRoles) || isCPO(userRoles)) && (
        <Navigate to={'../client/' + lmsList[0].clientId + '/dashboard/'}/>
      )}
      <Grid container spacing={4}>
        <Grid item container xs={12} sx={{ marginLeft: 0 }}>
          <SearchBarHeaderFilter
            setValue={setSearchText}
            handleClear={handleClear}
            searchValue={searchText}
            handleSelectChange={changeFilter}
            selectOptions={filterOptions}
            filter={filter}
            getFilterLabel={getFilterLabel}

          />
        </Grid>
        {displayList.map((item, index) => (
          <Grid item sm={4} xs={12} key={index}>
            <Card sx={{ borderRadius: 4 }}>
              <CardActionArea onClick={() => cardClickNavigate(item)}>
                <LmsStatusBox status={item}/>
              </CardActionArea>
              {(isSupportPerson(userRoles) || isCPO(userRoles)) &&
                <CardActions sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '-64px',
                }}>
                  <IconButton
                    id={'edit-station-' + item.clientId}
                    onClick={() => editIconClickNavigate(item)}
                    size="large">
                    <EditIcon/>
                  </IconButton>
                </CardActions>}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default LmsListContainer