import React, { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'

const CustomSelect = (props) => {

  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    setValue(event.target.value)
    props.onChange(event.target.value)
  }

  useEffect(() => {
    try {
      setValue(props.value)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <TextField
      id={props.id}
      onBlur={props.onBlur}
      disabled={props?.disabled}
      sx={{
        color: 'text.primary',
        width: '100%',
      }}
      defaultValue={props.value ? props.value : props?.props.default}
      select
      label={props?.props.title}
      value={value}
      variant={'standard'}
      onChange={handleChange}
      helperText={props?.error?.__errors.length > 0 ? props.error.__errors[0] : ' '}
      error={!!props?.error && props.error.__errors.length > 0}
    >
      {props?.props.enum?.map((item) => (
        <MenuItem key={props?.props.enum.indexOf(item)} value={item}
                  disabled={props.disableOption ? props.disableOption(item) : false}>
          {props?.props.enumNames ? props.props.enumNames[props.props.enum.indexOf(item)] : item}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default CustomSelect