import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, Grid } from '@mui/material'
import DOMPurify from 'isomorphic-dompurify'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateTime } from 'luxon'

const MessageDialog = ({ dialogOpen, item, onClose }) => {
  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
        }}>
        <DialogTitle>{item?.id && DateTime.fromISO(item.id)
          .toFormat('dd.MM.yyyy HH:mm:ss')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ wordWrap: 'break-word' }}>
                {item?.message}
              </Typography>
            </Grid>
            {item?.parameters?.content &&
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.parameters?.content) }}/>
              </Grid>
            }

            <Grid item xs={12}>
              <Accordion slotProps={{ transition: { unmountOnExit: true } }} elevation={1}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon/>}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item?.parameters &&
                    (Object.entries(item.parameters)
                      .filter(param => param[0] !== 'content')
                      .map(param =>
                        <Grid container key={param}>
                          <Grid item xs={3}>
                            <Typography>
                              {param[0]}:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <pre style={{
                              display: 'block',
                              padding: '10px 0px',
                              overflow: 'scroll',
                            }}>{JSON.stringify(param[1], null, 4)}</pre>
                          </Grid>
                        </Grid>,
                      ))
                  }
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {onClose()}} color={'primary'}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default MessageDialog
