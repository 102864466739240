import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import { useNavigate, useParams } from 'react-router'
import editProcess from '../../help-texts/FL_Vorgang hinzufügen.md'
import { backendApiClient } from '../../apiClient'
import { enqueueSnackbar } from 'notistack'
import ProcessCustomWidget from '../../components/crud/widgets/ProcessCustomWidget'
import { useLocation } from 'react-router-dom'

const EditProcessPage = () => {
  const { clientId, processId } = useParams()
  const navigate = useNavigate()
  const [helpText, setHelpText] = useState()
  const location = useLocation()
  const PROCESS_URL = `/api/client/${clientId}/events/${processId}`
  const EDIT_HELP_TITLE = 'Halten Sie Vorgänge fest'
  const [process, setProcess] = useState(location.state?.process)

  const save = (data) => {
    backendApiClient.put(PROCESS_URL, data)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
      })
  }

  const getHelpText = () => {
    fetch(editProcess)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchData = () => {
    if (process && Object.values(process).length > 0) {
      return
    }
    backendApiClient.get(PROCESS_URL)
      .then((response) => {
        setProcess(response)
      })
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    getHelpText()
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Vorgang'} onBack={() => navigate(-1)} pageTitle={'Vorgänge'} helpText={helpText}
          helpTitle={EDIT_HELP_TITLE}>
      <ProcessCustomWidget save={save} process={process}/>
    </Page>
  )
}

export default EditProcessPage