import React from 'react'
import { Divider, IconButton, List, ListItemSecondaryAction, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { DateTime } from 'luxon'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'

const DocumentsList = ({ data, isSupportPerson, showFile, downloadFile, handleDialogOpen }) => {
  return (
    <List>
      {data && data.length > 0 && data.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItem sx={{ marginBottom: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h6'} color={'inherit'}>
                  {entry.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {entry.creationDate ? DateTime.fromISO(entry.creationDate)
                    .toFormat('dd.MM.yyyy - HH:mm') : '--.--.---- --:--'}
                </Typography>
              </Grid>
            </Grid>
            {isSupportPerson &&
              <ListItemSecondaryAction style={{ top: '100%' }}>
                <IconButton size={'small'} onClick={() => showFile(entry.id)} sx={{ paddingRight: 1 }}>
                  <VisibilityIcon/>
                </IconButton>
                <IconButton size={'small'} onClick={() => downloadFile(entry.id, entry.url)}>
                  <CloudDownloadIcon/>
                </IconButton>
                {isSupportPerson && (
                  <IconButton size={'small'} onClick={() => handleDialogOpen({ id: entry.id, name: entry.url })}>
                    <DeleteIcon/>
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            }
          </ListItem>
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default DocumentsList
