import React from 'react'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const ButtonText = styled('span')(({ theme }) => ({
  transform: theme.skewText,
}))

const ErrorContainer = () => {
  const { clientId } = useParams()
  const HEADLINE = 'Entschuldigung'
  const SUB_HEADLINE = 'Hier ist etwas schief gelaufen'
  const BUTTON_TEXT = 'Neu laden'
  const SUPPORT_TEXT = 'Oder kontaktiere unseren Support unter:'
  const SUPPORT_NUMBER_FL = 'Tel: 07533 8030460'
  const SUPPORT_MAIL_FL = 'service@flotteladen.de'
  const SUPPORT_NUMBER_AMPERFIED = 'Tel: +49 6222 82-2266'
  const SUPPORT_MAIL_AMPERFIED = 'support@amperfied.com'

  return (
    <Box sx={{ paddingTop: '5%' }} pt={5}>
      <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="center"
      >
        <Grid>
          <WarningIcon sx={{
            color: 'primary.errorPageColor',
            fontSize: '220px',
          }}/>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{ color: 'primary.errorPageColor' }}
            fontWeight={600}
            fontSize={48}>
            {HEADLINE}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{ color: 'primary.errorPageColor' }}
            fontWeight={600}
            fontSize={15}
            pt={5}
            pb={2}>
            {SUB_HEADLINE}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            color={'primary'}
            variant={'contained'}
            component={Link}
            sx={{ margin: 1 }}
            to={`/client/${clientId}/dashboard/`}
          >
            <ButtonText>
              {BUTTON_TEXT}
            </ButtonText>
          </Button>
        </Grid>
        <Grid item xs={12}><Box pt={5} fontSize={16} textAlign={'center'}>{SUPPORT_TEXT}</Box></Grid>
        <Grid item xs={12} sx={(theme) => ({ display: theme.displayFlotteladen })}><Box pt={1} fontSize={16}>{SUPPORT_NUMBER_FL}</Box></Grid>
        <Grid item xs={12} sx={(theme) => ({ display: theme.displayAmperfied })}><Box pt={1} fontSize={16}>{SUPPORT_NUMBER_AMPERFIED}</Box></Grid>
        <Grid item xs={12} sx={(theme) => ({ display: theme.displayFlotteladen })}>
          <Box fontSize={16}>
            <Link
              sx={{ color: 'text.link' }}
              component="a"
              to="_blank"
              onClick={(e) => {
                window.location.href = `mailto:${SUPPORT_MAIL_FL}`
                e.preventDefault()
              }}
            >
              {SUPPORT_MAIL_FL}
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sx={(theme) => ({ display: theme.displayAmperfied })}>
          <Box fontSize={16}>
            <Link
              sx={{ color: 'text.link' }}
              component="a"
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:${SUPPORT_MAIL_AMPERFIED}`
                e.preventDefault()
              }}
            >
              {SUPPORT_MAIL_AMPERFIED}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ErrorContainer