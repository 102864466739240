import React from 'react'
import PropTypes from 'prop-types'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material/styles'

const MessageIcon = ({ severity }) => {
  const theme = useTheme()
  switch (severity) {
    case 'WARNING':
      return <WarningIcon fontSize={'small'} style={{ fill: theme.palette.warning.light }}/>

    case 'ERROR' :
      return <ErrorIcon fontSize={'small'} color={'error'}/>

    case 'INFO': // Fall through
    default:
      return <InfoIcon fontSize={'small'} color={'success'}/>
  }
}

MessageIcon.propTypes = {
  severity: PropTypes.string,
}

export default MessageIcon
