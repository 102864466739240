import React from 'react'
import validate from 'validate.js'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import App from './App'
import flTheme from './theme/flotteladen'
import amperfiedTheme from './theme/amperfied'
import * as serviceWorker from './serviceWorker'
import './index.css'
import { groupBy, mapValues } from 'lodash'
import { createRoot } from 'react-dom/client'
import { Settings } from 'luxon'

Settings.defaultLocale = 'de'

validate.formatters.first = function (errors) {
  return mapValues(groupBy(errors, 'attribute'),
    attributes => attributes[0].error)
}

const root = createRoot(document.getElementById('root'))

root.render(
  <ThemeProvider theme={process.env.REACT_APP_USED_THEME === 'amperfied' ? amperfiedTheme : flTheme}>
    <StyledEngineProvider injectFirst>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline/>
      <App/>
    </StyledEngineProvider>
  </ThemeProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
