import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import CustomTextField from './CustomTextField'
import SwitchCheckbox from './SwitchCheckBox'

const PvOverchargingWidget = (props) => {

  const { canEdit } = props

  const [data, setData] = useState(props?.formData)
  const schema = props?.schema.properties
  const [errorSchema, setErrorSchema] = useState(props?.errorSchema)

  useEffect(() => {
    try {
      props.onChange(data)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    try {
      setData(props.formData)
      setErrorSchema(props.errorSchema)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onChange = (name, value) => {
    try {
      setData(
        {
          ...data,
          [name]: value,
        },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <Grid
      container
      alignItems={'center'}
      sx={{ flexGrow: 1 }}
      spacing={3}
    >
      <Grid
        item
        sm={3}
        xs={12}>
        <Typography
          variant={'h6'}
        >
          Photovoltaik Überschussladen
        </Typography>
      </Grid>
      <Grid
        item
        sm={9}
        xs={12}>
        <SwitchCheckbox
          props={schema?.enabled}
          disabled={!canEdit}
          value={data?.enabled ? data?.enabled : false}
          error={errorSchema?.enabled}
          label={schema.enabled?.title}
          onChange={(value) => onChange('enabled', value)}
        />
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}>
        <CustomTextField
          props={schema?.activationTime}
          disabled={!data?.enabled || !canEdit}
          value={data?.activationTime}
          error={errorSchema?.activationTime}
          onChange={(value) => onChange('activationTime', value)}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <WbSunnyIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!data?.enabled && { color: 'secondary.light' }]}>Uhr</Typography>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}>
        <CustomTextField
          props={schema?.deactivationTime}
          value={data?.deactivationTime}
          disabled={!data?.enabled || !canEdit}
          error={errorSchema?.deactivationTime}
          onChange={(value) => onChange('deactivationTime', value)}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <NightsStayIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!data?.enabled && { color: 'secondary.light' }]}>Uhr</Typography>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}>
        <CustomTextField
          type={'number'}
          props={schema?.powerInfeedToStartCharging}
          value={data?.powerInfeedToStartCharging}
          disabled={!data?.enabled || !canEdit}
          error={errorSchema?.powerInfeedToStartCharging}
          onChange={(value) => onChange('powerInfeedToStartCharging', value)}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <PlayArrowIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!data?.enabled && { color: 'secondary.light' }]}>kW</Typography>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}>
        <CustomTextField
          type={'number'}
          props={schema?.powerInfeedToStopCharging}
          value={data?.powerInfeedToStopCharging}
          disabled={!data?.enabled || !canEdit}
          error={errorSchema?.powerInfeedToStopCharging}
          onChange={(value) => onChange('powerInfeedToStopCharging', value)}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <StopIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!data?.enabled && { color: 'secondary.light' }]}>kW</Typography>
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
    </Grid>

  )
}

export default PvOverchargingWidget