import React from 'react'
import PropTypes from 'prop-types'
import SaveIcon from '@mui/icons-material/Save'
import Fab from '@mui/material/Fab'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const IconBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transform: theme.skewText,
}))

// Floating Action Button for submitting forms
const SubmitFab = ({ show, children, disabled, text, ...rest }) => {
  return (
    <div>
      {
        show ?
          <Tooltip title={text ? text : 'speichern'} arrow>
            <Fab color={'primary'}
                 {...rest}
                 sx={{
                   position: 'fixed',
                   bottom: 40,
                   right: 40,
                 }}
                 type={'submit'}
                 disabled={disabled}
                 id={'submitFab'}
            >
              {children ?
                <IconBox>
                  {children}
                </IconBox>
                :
                <IconBox>
                  <SaveIcon variant={'buttonIcon'}/>
                </IconBox>
              }
            </Fab>
          </Tooltip>
          : ''}
    </div>
  )
}

SubmitFab.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default SubmitFab
