import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import backendApiClient from '../../apiClient/apiClient'
import { useSnackbar } from 'notistack'
import LmsWidget from '../../components/crud/widgets/LmsWidget'
import { useLocation } from 'react-router-dom'
import { LoadingBackdrop } from '../../components'

const NewLms = (props) => {
  const url = '/api/customers'

  const { onAfterSubmit, groups, fetchGroupData } = props
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const [clientId, setClientId] = useState(location.state?.clientId)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchGroupData(clientId, setLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const onBlur = (name, lmsVersion) => {
    try {
      if (name?.length > 0) {
        getId(name, lmsVersion)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const getId = (name, version) => {
    if (!location.state?.clientId?.length > 0) {
      try {
        if (name?.length > 0) {
          const url = '/api/customers/client-id?systemName=' + name + '&version=' + version
          backendApiClient.post(url)
            .then((r) => {
              setClientId(r.clientId)
            })
            .catch(() => {
              setClientId(null)
              enqueueSnackbar('Name bereits vergeben, bitte wählen Sie einen anderen Namen', {
                variant: 'error',
              })
            })
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <LmsWidget
        url={url}
        groups={groups}
        onAfterSubmit={onAfterSubmit}
        onBlur={onBlur}
        clientId={clientId}
        isNew
      />

    </div>
  )
}

NewLms.propTypes = {
  onAfterSubmit: PropTypes.func,
}

export default NewLms
