import React from 'react'
import PropTypes from 'prop-types'

import { Topbar } from './components'
import { Outlet } from 'react-router'

const Minimal = () => {
  return (
    <div style={{
      paddingTop: 64,
      height: '100vh',
    }}>
      <Topbar/>
      <main style={{ height: '100%' }}>
        <Outlet/>
      </main>
    </div>
  )
}

Minimal.propTypes = {
  className: PropTypes.string,
}

export default Minimal
