import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import TabPanel, { tabIdProps } from '../../../components/TabPanel/TabPanel'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router'
import MailNotificationScreen from './MailNotificationScreen'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CustomTab, CustomTabs } from '../../../components/TabMenu/TabMenu'

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const UserTabContainer = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const user = useSelector(state => state.auth.user)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleSelectChange = (newIndex) => {
    setCurrentIndex(newIndex)
    if (newIndex === 1) {
      navigate('settings', { relative: 'path' })
    }
  }

  return (
    <RootDiv>
      {smallScreen ?
        <FormControl sx={{ width: '100%' }}>
          <Select
            labelId="user-tab-label"
            id="user-tab-select"
            value={currentIndex}
            onChange={event => handleSelectChange(event.target.value)}
          >
            <MenuItem value={0}>E-Mail-Benachrichtigungen</MenuItem>
          </Select>
        </FormControl>
        :
        <CustomTabs
          value={currentIndex}
          onChange={(event, idx) => handleSelectChange(idx)}
          indicatorColor="primary"
          textColor="primary"
          aria-label="configuration-tabs"
        >
          <CustomTab label="E-Mail-Benachrichtigungen" {...tabIdProps(0)} value={0}/>
        </CustomTabs>

      }
      <TabPanel value={currentIndex} index={currentIndex}>
        <MailNotificationScreen user={user}/>
      </TabPanel>

    </RootDiv>
  )
}

export default UserTabContainer