import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import { DateTime } from 'luxon'

const parseAsJson = (message) => {
  try {
    return JSON.stringify(JSON.parse(message), null, 2)
  } catch (e) {
    return message
  }
}

const LogMessageDialog = ({ dialogOpen, item, onClose }) => {
  return (
    <div>
      <Dialog
        open={dialogOpen}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
        }}>
        <DialogTitle>{item?.messageType}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600 }}>
                {item?.id && DateTime.fromISO(item.id)
                  .toFormat('dd.MM.yyyy HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ wordWrap: 'break-word' }}>
                {item?.message && <pre>{parseAsJson(item.message)}</pre>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {item?.parameters && <div>
                <Typography sx={{ fontWeight: 600 }}>Parameter</Typography>
                {Object.entries(item.parameters)
                  .map(param =>
                    <Grid container key={param}>
                      <Grid item xs={4}>
                        <Typography>
                          {param[0]}:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <pre>{JSON.stringify(param[1], null, 2)}</pre>
                      </Grid>
                    </Grid>,
                  )}
              </div>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {onClose()}} color={'primary'}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LogMessageDialog
