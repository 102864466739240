import React, { useEffect, useState } from 'react'
import validate from 'validate.js'
import { Button, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { loginUser, logoutUser } from '../../store'
import { ERROR_CP_USER_LOGIN, ERROR_NOT_AUTHORIZED_EXCEPTION, ERROR_USER_NOT_FOUND_EXCEPTION } from '../../store/auth/authSlice'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'

const schema = {
  username: {
    presence: { allowEmpty: false, message: '^Nutzername wird benötigt' },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: '^Passwort wird benötigt' },
    length: {
      maximum: 128,
    },
  },
}

const CustomTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const TextFieldLabelBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const authError = useSelector(state => state.auth.error)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  const hasSignInAuthError = () => {
    return authError?.name === ERROR_NOT_AUTHORIZED_EXCEPTION || authError?.name === ERROR_USER_NOT_FOUND_EXCEPTION
  }

  const hasCPUserLoginError = () => {
    return authError === ERROR_CP_USER_LOGIN
  }

  useEffect(() => {
    const validationErrors = validate(formState.values, schema)
    setFormState(formState => ({
      ...formState,
      isValid: !validationErrors,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values])

  useEffect(() => {
    if (hasSignInAuthError()) {
      setFormState(formState => ({
        ...formState,
        errors: { username: [getGermanError()], password: [getGermanError()] },
      }))
    } else if (hasCPUserLoginError()) {
      logoutUser()
      enqueueSnackbar('Fehlende Berechtigung für diesen Nutzer', {
        variant: 'warning',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const getGermanError = () => {
    return 'Falscher Nutzername oder Passwort.'
  }

  const handleSignIn = async (event) => {
    event.preventDefault()
    const { values: { username, password } } = formState
    loginUser(username, password)
    setFormState(formState => ({
      ...formState,
      touched: {},
    }))
  }

  const hasError = field => !formState.touched['username'] && !formState.touched['password'] && formState.errors[field]

  return (
    <form onSubmit={handleSignIn}>
      <Typography
        sx={{ marginTop: 3 }}
        variant="h2">
        Anmelden
      </Typography>
      <CustomTypography
        color="textSecondary"
        variant="body1">
        Melden Sie sich mit Ihrem Nutzernamen an
      </CustomTypography>
      <TextFieldLabelBox>
        <Typography variant={'caption'}>
          Nutzername
        </Typography>
      </TextFieldLabelBox>
      <TextField
        error={hasError('username')}
        autoComplete={'username'}
        fullWidth
        helperText={
          hasError('username') ? formState.errors.username?.[0] : null
        }
        name="username"
        id="username"
        onChange={handleChange}
        type="text"
        value={formState.values.username || ''}
        variant="outlined"
      />
      <TextFieldLabelBox>
        <Typography variant={'caption'}>
          Passwort
        </Typography>
      </TextFieldLabelBox>
      <TextField
        error={hasError('password')}
        autoComplete={'current-password'}
        fullWidth
        helperText={
          hasError('password') ? formState.errors.password?.[0] : null
        }
        name="password"
        id="password"
        onChange={handleChange}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility/> : <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={formState.values.password || ''}
        variant="outlined"/>
      <CustomTypography
        color="textSecondary"
        variant="body1">
        <Link component={RouterLink}
              to="/reset-password"
              sx={(theme) => ({
                color: theme.palette.primary.alternateContrastText,
              })}
        >
          Passwort vergessen?
        </Link>
      </CustomTypography>
      <Button
        sx={(theme) => ({ margin: theme.spacing(2, 0) })}
        id={'submit'}
        color="primary"
        disabled={!formState.isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        <Typography
          sx={(theme) => ({
            transform: theme.skewText,
            color: theme.palette.white,
          })}
        >
          Anmelden
        </Typography>
      </Button>
      <Typography
        sx={(theme) => ({
          display: theme.displayFlotteladen === 'block' ? 'inline' : 'none',
        })}
        variant="body1">
        &copy;{' '}
        <Link
          sx={{
            color: 'text.link',
          }}
          component="a"
          href="https://www.flotteladen.de/"
          target="_blank">
          Flotteladen
        </Link>
        . {new Date().getFullYear()}{' '}
      </Typography>
      <Link
        sx={{ color: 'text.link' }}
        component="a"
        href="/imprint">
        Impressum
      </Link>
    </form>
  )
}

export default SignIn