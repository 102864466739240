import { List, ListItemButton, Popover, Tooltip } from '@mui/material'
import { Delete, Edit, SystemUpdateAlt } from '@mui/icons-material'
import React from 'react'

const FirmwareMoreSelect = ({ firmware, anchor, setAnchor, handleEdit, handleDelete, handleInstall, handleDialogClose }) => {
  const openPopover = Boolean(anchor)

  const handleClose = () => {
    setAnchor(null)
    handleDialogClose()
  }

  return (
    <React.Fragment>
      <Popover
        open={openPopover}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItemButton id={`popover-install-${firmware.name}`} onClick={() => handleInstall(firmware)}>
            <Tooltip title={'Firmware installieren'}>
              <SystemUpdateAlt/>
            </Tooltip>
          </ListItemButton>
          <ListItemButton id={`popover-edit-${firmware.name}`} onClick={() => handleEdit(firmware)}>
            <Tooltip title={'Firmware Version bearbeiten'}>
              <Edit/>
            </Tooltip>
          </ListItemButton>
          <ListItemButton id={`popover-delete-${firmware.name}`} onClick={() => handleDelete(firmware)}>
            <Tooltip title={'Firmware Version löschen'}>
              <Delete/>
            </Tooltip>
          </ListItemButton>
        </List>
      </Popover>
    </React.Fragment>
  )
}

export default FirmwareMoreSelect