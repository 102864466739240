import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Grid, IconButton, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { DatePicker } from '@mui/x-date-pickers'
import { styled } from '@mui/material/styles'

const GridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const YearMonthPicker = ({ selectedDate, onChange, label, views = ['year'], pickable = true, disableFuture = false }) => {
  const onDateIncrement = () => {
    let newDate
    if (views.find(view => view === 'month')) {
      newDate = selectedDate.plus({ months: 1 })
    } else {
      newDate = selectedDate.plus({ years: 1 })
    }
    onChange(newDate)
  }

  const onDateDecrement = () => {
    let newDate
    if (views.find(view => view === 'month')) {
      newDate = selectedDate.minus({ months: 1 })
    } else {
      newDate = selectedDate.minus({ years: 1 })
    }
    onChange(newDate)
  }

  const onDatePick = (jsDate) => {
    let newDate = DateTime.now()
    if (views.find(view => view === 'month')) {
      newDate = newDate.set({ month: jsDate.getMonth() })
    }
    newDate = newDate.set({ year: jsDate.getFullYear() })
    onChange(newDate)
  }

  return (
    <Grid container
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              marginTop: theme.spacing(1),
            },
          })}
          spacing={2}
          justifyContent={'flex-end'}
          display={'flex'}
          alignItems={'center'}>
      <GridItem item>
        {pickable ? (
          <React.Fragment>
            <DatePicker
              views={['year']}
              label={label}
              maxDate={new Date()}
              value={selectedDate.toJSDate()}
              onChange={onDatePick}
              sx={{ width: '120px' }}
              yearsPerRow={3}
              slotProps={{
                actionBar: { actions: ['cancel', 'accept'] },
                textField: { variant: 'standard', size: 'small' },
              }}
            />
          </React.Fragment>
        ) : (
          <Typography sx={{ fontSize: 'x-large', fontWeight: '500' }}>{selectedDate.toFormat(views.length > 1 ? 'MMMM yyyy' : 'yyyy')}</Typography>
        )}
      </GridItem>
      <GridItem item>
        <IconButton sx={{ marginLeft: 1 }}
                    onClick={() => onDateDecrement()} size={'small'} id={'timeBackArrow'}
        >
          <ArrowBackIosIcon/>
        </IconButton>
        <IconButton onClick={() => onDateIncrement()} size={'small'}
                    id={'timeForwardArrow'}
                    disabled={disableFuture && (views.find(view => view === 'month') ?
                      (selectedDate.year === DateTime.now().year && selectedDate.month === DateTime.now().month) : selectedDate.year === DateTime.now().year)}>
          <ArrowForwardIosIcon/>
        </IconButton>
      </GridItem>
    </Grid>
  )
}

YearMonthPicker.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  views: PropTypes.array,
}

export default YearMonthPicker
