import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import PropTypes from 'prop-types'

/**
 * showing switch for check box form fields
 */
const CustomFormikSwitch = ({ value, id, onChange, label, disabled }) => {

  return (
    <FormControlLabel
      control={(
        <Switch
          disabled={disabled}
          checked={value}
          onChange={onChange}
          name={id}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
        />
      )}
      label={String(label)}
    />
  )
}

CustomFormikSwitch.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export default CustomFormikSwitch
