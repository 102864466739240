import React from 'react'
import { Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const NotificationPopperWithoutMessage = ({ error }) => {

  const NO_MESSAGE_AVAILABLE = 'Es sind keine Meldungen vorhanden'
  const ERROR_MESSAGE = 'Es gab ein Problem bei den Meldungen'

  return (
    <Paper elevation={12}>
      <Box paddingTop={2} paddingBottom={2} paddingLeft={4} paddingRight={4}>
        <Grid
          container
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant={'h5'}
            >
              {error ? ERROR_MESSAGE : NO_MESSAGE_AVAILABLE}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default NotificationPopperWithoutMessage