import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import UserWidget from './UserWidget'
import Grid from '@mui/material/Grid'
import CustomSearchBar from '../../../components/crud/widgets/CustomSearchBar'
import UsersNewHelpText from '../../../help-texts/FL_Authorization_Users_New.md'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import Page from '../../../components/Page/Page'

const NewUserPage = () => {
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const [chargingPoints, setChargingPoints] = useState([])
  const [userList, setUserList] = useState()
  const [searchedUser, setSearchedUser] = useState()
  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState()

  const CP_USERS_URL = `/api/client/${clientId}/chargingpoint/users`
  const CP_URL = `/api/client/${clientId}/chargingpoints`
  const USER_LIST_URL = `/api/client/${clientId}/users`

  const USER_SEARCH_LABEL = 'Nach bestehenden Nutzer*innen suchen'
  const TITLE = 'Ladepunktnutzer*in hinzufügen'

  useEffect(() => {
    fetchChargingPoints()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(UsersNewHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchChargingPoints = () => {
    setLoading(true)
    backendApiClient.get(CP_URL)
      .then((response) => {
        setChargingPoints(response)
      })
      .catch((e) => throw new Error(e))
      .finally(() => setLoading(false))

  }

  const save = (data, actions) => {
    backendApiClient.post(CP_USERS_URL, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, { variant: 'success' })
        navigate(-1)
      })
      .catch((error) => {
        if (error._response.statusCode === 409) {
          actions.setErrors({ email: 'E-Mail bereits vergeben' })
        } else {
          enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
        }
      })
  }

  const handleSearch = (event) => {
    if (event?.target?.value?.length > 2) {
      fetchUserListData({ searchText: event.target.value })
    }
  }

  const fetchUserListData = (params) => {
    backendApiClient.get(USER_LIST_URL, params)
      .then((res) => {
        setUserList(res)
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  const handleSearchedUserChange = (event, value) => {
    setSearchedUser({
      ...value,
    })
  }

  return (
    <Page viewName={TITLE} pageTitle={TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={TITLE}>
      <Grid container>
        <Grid
          item
          xs={12}
          paddingBottom={3}
        >
          <CustomSearchBar
            data={userList?.content}
            handleChange={handleSearchedUserChange}
            label={USER_SEARCH_LABEL}
            handleSearch={handleSearch}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <UserWidget chargingPoints={chargingPoints} save={save} preSelectedId={location.state?.selectedCPId} loading={loading} user={searchedUser}/>
      </Grid>
    </Page>
  )
}

export default NewUserPage
