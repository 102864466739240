import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Section from './Section'
import { getRoles, hasRequiredRole, isCPO, isSupportPerson } from '../../../../../../security/roles'
import { sidebarElements } from '../../SidebarRoutes'

const SidebarNav = ({ clientId, hasEms, lmsNumber }) => {
  const userRoles = useSelector(state => getRoles(state))
  const pages = clientId ? sidebarElements.general : sidebarElements.global
  const allowedPages = pages.filter(page => hasRequiredRole(page.roles, userRoles))

  return (
    <React.Fragment>
      {(lmsNumber > 1 || isSupportPerson(userRoles) || isCPO(userRoles)) &&
        <Section pages={[sidebarElements.stations]} noDivider/>
      }
      <Section pages={allowedPages.filter(page => 'global' === page.section)} noDivider/>
      <Section pages={allowedPages} noDivider={true} hasEms={hasEms}/>
    </React.Fragment>
  )
}

SidebarNav.propTypes = {
  pages: PropTypes.object,
}

export default SidebarNav
