import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { confirmResetPassword, confirmSignIn, resetPassword, signIn, signOut } from 'aws-amplify/auth'
import { currentAccessToken } from '../../apiClient/apiClient'

const initialState = {
  status: 'uninitialized',
  challenge: null,
  signedIn: false,
  authenticating: true,
  error: null,
  user: {},
}

export const CONFIRM_RESET_PASSWORD_FULFILLED = 'ConfirmResetPasswordFulfilled'

export const ERROR_NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException'
export const ERROR_USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException'
export const ERROR_CP_USER_LOGIN = 'CPUserLoginException'

export const fetchCurrentSession = createAsyncThunk('auth/fetchCurrentSession', async () => {
  return currentAccessToken()
})

export const logout = createAsyncThunk('auth/logout', async () => {
  return await signOut()
    .catch(e => Promise.reject(e))
})

export const login = createAsyncThunk('auth/login', async (data, thunkAPI) => {
  return await signIn(data)
    .catch(e => Promise.reject(e))
})
export const doPasswordReset = createAsyncThunk('auth/doPasswordReset', async (data, thunkAPI) => {
  return await resetPassword(data)
    .catch(e => Promise.reject(e))
})
export const doConfirmResetPassword = createAsyncThunk('auth/doConfirmResetPassword', async (data, thunkAPI) => {
  return await confirmResetPassword(data)
    .catch(e => Promise.reject(e))
})
export const doConfirmSignIn = createAsyncThunk('auth/doConfirmSignIn', async (data, thunkAPI) => {
  return await confirmSignIn(data)
    .catch(e => Promise.reject(e))
})

const isCPUser = (groups) => {
  return groups.length === 1 && groups[0] === 'FL_cp_user'
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(doPasswordReset.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(doConfirmResetPassword.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(doConfirmResetPassword.fulfilled, (state, action) => {
        state.challenge = CONFIRM_RESET_PASSWORD_FULFILLED
      })
      .addCase(doConfirmSignIn.rejected, (state, action) => {
        state.error = action.error
        state.challenge = null
      })
      .addCase(doConfirmSignIn.fulfilled, (state, action) => {
        state.challenge = action.payload.nextStep.signInStep
      })
      .addCase(login.fulfilled, (state, action) => {
        state.challenge = action.payload.nextStep.signInStep
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error
        state.challenge = null
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.authenticating = false
        state.signedIn = false
      })
      .addCase(fetchCurrentSession.pending, (state, action) => {
        state.authenticating = true
        state.signedIn = false
      })
      .addCase(fetchCurrentSession.fulfilled, (state, action) => {
        const payload = action.payload.payload
        const groups = payload['cognito:groups']
        if (isCPUser(groups)) {
          state.error = ERROR_CP_USER_LOGIN
          state.signedIn = false
          state.authenticating = false
        } else {
          state.authenticating = false
          state.signedIn = true
          state.error = null
          state.roles = groups
          state.user = payload
        }
      })
      .addCase(fetchCurrentSession.rejected, (state, action) => {
        state.error = action.error
        state.authenticating = false
        state.signedIn = false
      })
  },
})

export default authSlice.reducer