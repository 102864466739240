import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import { Tabs } from '@mui/material'

export const CustomTab = styled(Tab)({
  fontSize: '12pt',
  flexGrow: 1,
})

export const CustomTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
})