import React from 'react'
import { PowerHistoryChart } from '../../../components'
import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router'

const PowerHistoryChartCard = ({ gridPowerLimit = 50, chargingPowerLimit = 30, data, hasData }) => {
  const navigate = useNavigate()

  const options = {
    gridLimit: gridPowerLimit,
    chargeLimit: chargingPowerLimit,
  }

  return (
    <Card
      onClick={() => navigate('../evaluation')}
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 2,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Typography
        align={'left'}
        variant={'h5'}
        sx={{
          height: '100%',
          fontWeight: '600',
          marginBottom: 2,
        }}>
        Leistungsverlauf
      </Typography>
      {hasData === true && data.length > 0 && data[0].data.length > 0 ? (
        <div style={{
          height: '291px',
          width: '100%',
        }}>
          {data && <PowerHistoryChart data={data} options={options}/>}
        </div>
      ) : (
        <Typography align={'center'}>
          {'Keine aktuellen Leistungsdaten vorhanden'}
        </Typography>
      )}
    </Card>

  )
}

export default PowerHistoryChartCard
