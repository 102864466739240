import React from 'react'
import ErrorContainer from './ErrorContainer'
import { useParams, useRouteError } from 'react-router'
import { useSnackbar } from 'notistack'
import { backendApiClient } from '../../apiClient'

const ErrorPage = () => {
  const error = useRouteError()
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const errorHandler = (error) => {
    if (clientId) {
      const API = `/api/cloudwatch/error?client=${clientId}`

      backendApiClient.post(API, error.toString())
        .then(() => {
          enqueueSnackbar('Die Fehlermeldung wurde erfolgreich aufgezeichnet', {
            variant: 'warning',
          })
        })
        .catch(() => {
          enqueueSnackbar('Die Fehlermeldung konnte nicht aufgezeichnet werden', {
            variant: 'error',
          })
        })
    }
  }

  errorHandler(error)

  return (
    <ErrorContainer/>
  )
}

export default ErrorPage