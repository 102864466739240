import { createTheme } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import 'typeface-work-sans'
import 'typeface-noto-sans'
import { deDE } from '@mui/material/locale'

const theme = createTheme({
    palette,
    typography,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
            width: '100%',
          },
          body: {
            height: '100%',
            width: '100%',
            backgroundColor: '#233447 !important',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },
          '.App': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    skewButton: 'skew(20deg)',
    skewText: 'skew(-20deg)',
    roundingDisplay: 'none',
    displayAmperfied: 'block',
    displayFlotteladen: 'none',
    zIndex: {
      appBar: 1400,
      drawer: 1100,
    },
  },
  deDE,
)

export default theme
