import React from 'react'
import Grid from '@mui/material/Grid'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { FilterPopover } from '../index'

const SearchBarHeaderFilter = ({ handleSelectChange, selectOptions, filter, setValue, searchValue, handleClear, disableSelect = false, getFilterLabel }) => {

  const handleSelectBarChange = (event) => {
    handleSelectChange(event)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        xs={true}
      >
        <TextField
          autoFocus
          value={searchValue}
          fullWidth
          variant={'standard'}
          label={'Suche'}
          onChange={(event) => {
            setValue(event.target.value)
          }}
          style={disableSelect ? {} : { marginTop: -16 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue.length > 0 &&
                  <IconButton onClick={handleClear} size="large">
                    <ClearIcon/>
                  </IconButton>
                }
                <Search/>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid
        item
        xs={'auto'}
        sx={{
          marginRight: '7px',
        }}
      >
        <FilterPopover filter={filter} getFilterLabel={getFilterLabel} selectOptions={selectOptions} handleToggle={handleSelectBarChange}/>
      </Grid>
    </Grid>
  )
}

export default SearchBarHeaderFilter