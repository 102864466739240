import React from 'react'
import { FormControl, MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const CustomFormikSelectField = ({ id, value, values, label, helperText, error, onChange, onBlur, variant, disabled }) => {
  return (
    <FormControl fullWidth>
      <TextField
        select
        id={id}
        helperText={helperText}
        name={id}
        value={value}
        label={label}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        variant={variant ? variant : 'standard'}
        disabled={disabled}
        sx={{
          color: 'text.primary',
          width: '100%',
        }}
      >
        {
          values?.map((value, index) => (
            <MenuItem key={index} value={value.value ? value.value : value}>{value.label ? value.label : value}</MenuItem>
          ))
        }
      </TextField>
    </FormControl>
  )
}

CustomFormikSelectField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  type: PropTypes.string,
}

export default CustomFormikSelectField