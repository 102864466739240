import { FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UnlockingTable from './UnlockingTable'
import { useTheme } from '@mui/material/styles'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop } from '../../../components'
import UnlockingList from './UnlockingList'
import { useSnackbar } from 'notistack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import { useSelector } from 'react-redux'

const UnlockingTabContainer = () => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const userRoles = useSelector(state => getRoles(state))
  const [loading, setLoading] = useState(false)
  const [chargingPoints, setChargingPoints] = useState([])
  const [authModeAll, setAuthModeAll] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [toChangePoint, setToChangePoint] = useState({})
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const canEdit = isServicePerson(userRoles) || isLMSOwner(userRoles)

  const POINTS_URL = '/api/client/{client}/chargingpoints'
  const AUTHMODE_POINT_URL = '/api/client/{client}/chargingpoints/{pointId}/policy/authmode'
  const AUTHMODE_ALL_URL = '/api/client/{client}/chargingpoints/authmode'
  const INTERFACE_TYPE_MODBUS = 'modbus'
  const INTERFACE_TYPE_UDP = 'udp'

  const fetchData = () => {
    const url = POINTS_URL.replace(/{client}/g, clientId)
    setLoading(true)

    backendApiClient.get(url)
      .then((response) => {
        const sortedPoints = response.sort((a, b) => {
          if (a.ordinalNumber > b.ordinalNumber) {
            return 1
          } else if (a.ordinalNumber < b.ordinalNumber) {
            return -1
          }
          return 0
        })
        setChargingPoints(sortedPoints)
        setLoading(false)
      })
      .catch(() => {
        enqueueSnackbar('Fehler beim Laden der Daten, bitte laden Sie die Seite neu!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const hasChargingPointsForAuthModeAll = () => {
    return chargingPoints.length !== 0 && chargingPoints.find((point) => point.interfaceType !== 'ocpp')
  }

  const onPointAuthModeChange = (point) => {
    if (canEdit) {
      handleDialogClose()
      setLoading(true)
      const authMode = point.currentPolicy?.authMode !== 'DEFAULT_UNAUTHORIZED' ? 'DEFAULT_UNAUTHORIZED' : 'DEFAULT_AUTHORIZED'
      const url = AUTHMODE_POINT_URL.replace(/{client}/g, clientId)
        .replace(/{pointId}/g, point.id)
      backendApiClient.put(url, { authMode: authMode })
        .then(() => {
          fetchData()
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
            variant: 'success',
          })
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
            variant: 'error',
          })
          handleDialogClose()
        })
    } else {
      enqueueSnackbar('Sie haben nicht die nötigen Berechtigungen das zu bearbeiten', {
        variant: 'warning',
      })
      handleDialogClose()
    }
  }

  const onAllAuthModeChange = () => {
    if (canEdit) {
      handleDialogClose()
      setLoading(true)
      setAuthModeAll(!authModeAll)
      const authMode = authModeAll ? 'DEFAULT_UNAUTHORIZED' : 'DEFAULT_AUTHORIZED'
      const url = AUTHMODE_ALL_URL.replace(/{client}/g, clientId)
      backendApiClient.put(url, { authMode: authMode })
        .then(() => {
          fetchData()
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
            variant: 'success',
          })
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
            variant: 'error',
          })
          handleDialogClose()
        })
    } else {
      enqueueSnackbar('Sie haben nicht die nötigen Berechtigungen das zu bearbeiten', {
        variant: 'warning',
      })
    }
  }

  useEffect(() => {
    if (chargingPoints.length === 0 ||
      chargingPoints.find((point) => 'ocpp' !== point.interfaceType &&
        'DISCONNECTED' !== point.controllerConnectionStatus &&
        'DEFAULT_UNAUTHORIZED' === point.currentPolicy?.authMode)) {
      setAuthModeAll(false)
    } else {
      setAuthModeAll(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoints])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDialogOpen = (point) => {
    try {
      if (canEdit) {
        if (point === 'all') {
          if (authModeAll) {
            onAllAuthModeChange()
          } else {
            setOpen(true)
            setToChangePoint(point)
          }
        } else {
          if (point.interfaceType === INTERFACE_TYPE_MODBUS || point.interfaceType === INTERFACE_TYPE_UDP || point.interfaceType === null) {
            if (point.currentPolicy.authMode === 'DEFAULT_AUTHORIZED') {
              onPointAuthModeChange(point)
            } else {
              setOpen(true)
              setToChangePoint(point)
            }
          }
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setOpen(false)
      setToChangePoint(null)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleTagClick = (chargingPoint) => {
    navigate(`${chargingPoint.id}/tags`, { state: { name: chargingPoint.name } })
  }

  const handleUserClick = (chargingPoint) => {
    navigate(`${chargingPoint.id}/userList`, { state: { name: chargingPoint.name } })
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Bei ungesicherten Ladevorgängen ist derzeit keine individuelle Abrechnung auf
            Ladepunktnutzer:innen möglich.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose} color="primary">
            {'Abbrechen'}
          </Button>
          <Button
            onClick={() => {
              if (toChangePoint === 'all') {
                onAllAuthModeChange()
              } else {
                onPointAuthModeChange(toChangePoint)
              }
            }}
            color="primary"
            autoFocus>
            {'Bestätigen'}
          </Button>
        </DialogActions>
      </Dialog>
      {hasChargingPointsForAuthModeAll() &&
        <Grid container>
          <Grid item
                xs={12}
                display={'flex'}
                flexDirection={'row-reverse'}
                alignItems={'flex-end'}
                marginBottom={2}
          >
            <FormControlLabel
              labelPlacement="start"
              label={'automatische Ladefreigabe für alle Ladepunkte'}
              control={(
                <Switch
                  disabled={!canEdit}
                  checked={authModeAll ? authModeAll : false}
                  onChange={() => {
                    handleDialogOpen('all')
                  }}
                  id={'unlockAllSwitch'}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  color="primary"
                />
              )}
            />
          </Grid>
        </Grid>
      }
      {fullScreen ?
        <UnlockingList chargingPoints={chargingPoints} handleTagClick={handleTagClick} handleUserClick={handleUserClick} onPointAuthModeChange={handleDialogOpen}
                       version={lmsVersion} canEdit={canEdit}/>
        :
        <UnlockingTable chargingPoints={chargingPoints} handleTagClick={handleTagClick} handleUserClick={handleUserClick} onPointAuthModeChange={handleDialogOpen}
                        version={lmsVersion} canEdit={canEdit}/>
      }
    </React.Fragment>
  )
}

export default UnlockingTabContainer