import { alpha, Button, ListItem } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const DivIcon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.unselectedIcon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}))

const TextBox = styled('span')(({ theme }) => ({
  transform: theme.skewText,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}))

const CustomButton = styled(Button)(({ theme }) => ({
  transform: theme.skewButton,
  color: alpha(theme.palette.primary.contrastText, 0.5),
  padding: '10px 10px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: 0,
  '&:hover': {
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
}))

const CustomNavLink = styled(NavLink)(({ theme }) => ({
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.selectedSidebarItem, 0.4),
    width: '100%',
    borderRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
    '& button': {
      color: theme.palette.primary.contrastText,
    },
  },
}))

function NavItem ({ page }) {
  const { clientId } = useParams()
  return (
    <ListItem
      sx={{
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
      }}
      disableGutters
    >
      <CustomNavLink to={page.path.replace(':clientId', clientId)}>
        <CustomButton>
          <TextBox>
            <DivIcon>
              {React.createElement(page.icon)}
            </DivIcon>
            {page.title}
          </TextBox>
        </CustomButton>
      </CustomNavLink>
    </ListItem>
  )
}

NavItem.propTypes = {
  page: PropTypes.any,
}
export default NavItem