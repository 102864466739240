import * as yup from 'yup'

export const tagValidationSchema = yup.object({
  name: yup.string()
    .min(3, 'Der Kartenname darf nicht weniger als 3 Zeichen enthalten')
    .required('Der Kartenname darf nicht leer sein'),
  value: yup.string()
    .min(8, 'Die ID darf nicht weniger als 8 Zeichen enthalten')
    .max(14, 'Die ID darf nicht mehr als 14 Zeichen enthalten')
    .test('isValidTag', 'Entspricht nicht dem UID Format (Erlaubte Zahlen: 0-9, Erlaubte Buchstaben: a-f)',
      function (value) {
        return isHex(value)
      })
    .required('Die ID darf nicht leer sein'),
  accountId: yup.string()
    .required('Die Kostenstelle darf nicht leer sein'),
})

const isHex = (value) => {
  const re = new RegExp(/^[0-9A-Fa-f]{8,14}$/g)
  return value ? re.test(value) : true
}