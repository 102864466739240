import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&$checked': {
    color: theme.palette.primary.alternateContrastText,
  },
}))

const CustomSelect = (props) => {
  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    setValue(event.target.value)
    props.onChange(event.target.value)
  }

  useEffect(() => {
    setValue(props.value)
  }, [props])

  return (
    <div className={props?.className}>
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        <FormLabel component="legend" sx={{ fontSize: '11px' }}>{props.title ? props.title : ''}</FormLabel>
        <RadioGroup aria-label={props.title ? props.title : ''} name={props.title ? props.title : ''} value={value} onChange={handleChange} row={props.row} color={'primary'} sx={{ width: '100%' }}>
          {props.grid ? (
            <Grid container sx={{ width: '100%' }}>
              {props.input.map((entry) => (
                <Grid key={entry.name} item xs={12 / props.itemsCount}>
                  <CustomFormControlLabel value={entry.name} control={<Radio disabled={props?.disabled}/>} label={entry.label}/>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div>
              {props.input.map((entry) => (
                <CustomFormControlLabel key={entry.name} value={entry.name} control={<Radio disabled={props?.disabled}/>} label={entry.label}/>
              ))}
            </div>
          )}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default CustomSelect