import React from 'react'
import PropTypes from 'prop-types'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import MessageIcon from '../../components/MessageIcon/MessageIcon'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import { DateTime } from 'luxon'

const MessagesTable = ({ messages, onOpen }) => {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={'checkbox'}/>
            <TableCell>Zeitpunkt</TableCell>
            <TableCell>Nachrichten-Typ</TableCell>
            <TableCell>Nachricht</TableCell>
            <TableCell padding={'checkbox'}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages && messages.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <MessageIcon severity={item.severity}/>
              </TableCell>
              <TableCell>
                {item?.id && DateTime.fromISO(item.id)
                  .toFormat('dd.MM.yyyy HH:mm:ss')}
              </TableCell>
              <TableCell sx={{ minWidth: 200, maxWidth: 200 }}>
                {item.messageType}
              </TableCell>
              <TableCell sx={{
                maxWidth: 200,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
                {item.message}
              </TableCell>
              <TableCell>
                <Button onClick={() => {onOpen(item)}}>
                  <VisibilityIcon/>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MessagesTable.propTypes = {
  items: PropTypes.array,
}

export default MessagesTable
