import React from 'react'
import MessageIcon from '../../components/MessageIcon/MessageIcon'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { isServicePerson } from '../../security/roles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ListItemButton } from '@mui/material'
import { DateTime } from 'luxon'

const MessagesList = ({ messages, translate, userRoles, onOpen }) => {
  return (
    <List>
      {messages && messages.map((item) => (
        <React.Fragment key={item.id}>
          <ListItemButton button={isServicePerson(userRoles)}
                          onClick={() => onOpen(item)}
          >
            <Grid
              container
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Box paddingTop={2} paddingRight={2}>
                <Grid
                  item
                  xs={2}
                >
                  <MessageIcon severity={item.severity}/>
                </Grid>
              </Box>
              <Grid
                item
                xs={10}
              >
                <Typography>
                  {item?.id ? DateTime.fromISO(item.id)
                    .toFormat('dd.MM.yyyy HH:mm:ss') : '--'}
                </Typography>
                <Typography sx={{
                  maxWidth: 250,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                  {translate(item)}
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
          <Divider variant={'middle'} component="li"/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default MessagesList
