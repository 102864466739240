import React from 'react'
import Typography from '@mui/material/Typography'
import { Divider, IconButton, List, ListItemSecondaryAction, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'

const TagsList = ({ entry, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <List>
      {entry && entry.length > 0 && entry.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItem onClick={() => handleEdit(entry)} sx={{ marginBottom: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h6'} color={'inherit'}>
                  {entry.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="inherit" display={'inline'} style={{ whiteSpace: 'pre-line' }}>
                  ID: {entry.value}{'\n'}
                  Kostenstelle: {entry.accountId}{'\n'}
                </Typography>
              </Grid>
            </Grid>
            {allowEdit &&
              <ListItemSecondaryAction style={{ top: '100%' }}>
                <IconButton
                  style={{ padding: 0 }}
                  size={'small'}
                  onClick={() => handleDelete(entry)}
                >
                  {CustomDeleteIcon ? <CustomDeleteIcon/> :
                    <Tooltip title={'Karte löschen'}>
                      <DeleteIcon/>
                    </Tooltip>
                  }
                </IconButton>
              </ListItemSecondaryAction>
            }
          </ListItem>
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default TagsList