import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { StatusTranslation } from '../../../components'
import { canUseApp, canUseCard } from '../../../utils'
import { styled } from '@mui/material/styles'

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1pt solid ${theme.palette.border.light}`,
  paddingLeft: 0,
  paddingRight: 0,
}))

const CustomTableCellNoBorder = styled(TableCell)({
  borderBottom: 'none',
  padding: 0,
})

const UnlockingTableRow = ({ point, index, onPointAuthModeChange, handleTagClick, handleUserClick, version, canEdit, isDisconnected }) => {
  return (
    <TableRow key={index}>
      <Tooltip title={isDisconnected ? 'Verbindung zum LMS ist getrennt.' : ''}>
        <CustomTableCell
          sx={[
            { width: '25%' },
            isDisconnected && { color: 'text.disabled ' },
          ]}
        >
          {point.name}
        </CustomTableCell>
      </Tooltip>
      <CustomTableCell
        sx={[
          { width: '40%' },
          isDisconnected && { color: 'text.disabled ' },
        ]}>
        <StatusTranslation status={point.currentStatus?.status}/>
      </CustomTableCell>
      <CustomTableCell align={'center'}>
        {point.interfaceType === 'ocpp' ? <Tooltip title={'Automatische Freigabe nicht möglich'}>
                <span>
                  <IconButton disabled={true} size={'small'} id={`lockButton${point.id}`}>
                    <LockIcon/>
                  </IconButton>
                </span>
          </Tooltip> :
          (point.currentPolicy?.authMode !== 'DEFAULT_UNAUTHORIZED' ?
            <IconButton disabled={isDisconnected || !canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'} id={`lockButton${point.id}`}>
              <LockOpenIcon/>
            </IconButton> :
            <IconButton disabled={isDisconnected || !canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'} id={`unlockButton${point.id}`}>
              <LockIcon/>
            </IconButton>)
        }
      </CustomTableCell>
      <CustomTableCell align={'right'}>
        <Tooltip title={'Aktive Ladekarten'}>
                <span>
                <IconButton disabled={!canUseCard(point, version)} onClick={() => handleTagClick(point)}>
                  <CreditCardIcon/>
                </IconButton>
                </span>
        </Tooltip>
        <Tooltip title={'Aktive Nutzer*innen'}>
                <span>
                <IconButton disabled={!canUseApp(point, version)} onClick={() => handleUserClick(point)}>
                  <PhoneIphoneIcon/>
                </IconButton>
                </span>
        </Tooltip>
      </CustomTableCell>
    </TableRow>
  )
}

const UnlockingTable = ({ chargingPoints, mapState, onPointAuthModeChange, handleTagClick, handleUserClick, version, canEdit }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCellNoBorder>
            Ladepunkt
          </CustomTableCellNoBorder>
          <CustomTableCellNoBorder>
            Status
          </CustomTableCellNoBorder>
          <CustomTableCellNoBorder align={'center'}>
            Freigabe
          </CustomTableCellNoBorder>
          <CustomTableCellNoBorder>
          </CustomTableCellNoBorder>
        </TableRow>
      </TableHead>
      <TableBody>
        {chargingPoints && chargingPoints.length > 0 && chargingPoints.map((entry, index) => {
          let isDisconnected = entry.controllerConnectionStatus === 'DISCONNECTED'
          return (<UnlockingTableRow point={entry}
                                     key={index}
                                     mapState={mapState}
                                     onPointAuthModeChange={onPointAuthModeChange}
                                     version={version}
                                     canEdit={canEdit}
                                     isDisconnected={isDisconnected}
                                     handleTagClick={handleTagClick}
                                     handleUserClick={handleUserClick}/>)
        })}
      </TableBody>
    </Table>
  )
}

export default UnlockingTable