import React from 'react'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

const CustomFormikTextField = ({ id, value, label, helperText, error, onChange, onBlur, variant, multiline, rows, disabled, InputProps, InputLabelProps, type }) => {
  return (
    <TextField
      id={id}
      value={value}
      label={label}
      helperText={helperText}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      variant={variant ? variant : 'standard'}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      type={type}
      sx={{
        color: 'text.primary',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': {
          marginLeft: 0
        }
      }}
    />
  )
}

CustomFormikTextField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  type: PropTypes.string,
}

export default CustomFormikTextField