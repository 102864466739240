import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const ConfirmDialog = ({ handleConfirm, handleClose, open, dialogText, closeText, submitText }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {closeText ? closeText : 'Abbrechen'}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus>
          {submitText ? submitText : 'Bestätigen'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  dialogText: PropTypes.string,
}

export default ConfirmDialog
