import { Autocomplete, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import React from 'react'

const CustomSearchBar = ({ data, handleChange, label, handleSearch }) => {
  return (
    <div>
      <Autocomplete
        id={'searchBar'}
        sx={{
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        }}
        autoComplete
        filterSelectedOptions={false}
        options={data ? data : []}
        clearOnBlur={false}
        filterOptions={(options) => options}
        getOptionLabel={(options) => options.lastName + ', ' + options.firstName}
        noOptionsText={'Kein übereinstimmender Nutzer vorhanden'}
        renderInput={(params) => (<TextField
          {...params}
          label={label ? label : 'Suche'}
          variant={'standard'}
        />)}
        renderOption={(props) => {
          return (
            <li {...props} key={props.id}>
              {props.key}
            </li>
          )
        }}
        popupIcon={<Search/>}
        onChange={handleChange}
        onInputChange={handleSearch}
        variant={'standard'}
      />
    </div>
  )
}

export default CustomSearchBar