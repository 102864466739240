import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import chargingPointEditHelpText from '../../../help-texts/FL_Konfiguration_Ladepunkt_bearbeiten.md'
import { useParams, useRouteLoaderData } from 'react-router'
import ChargingPointVersionTwoWidget from './lmsVersionTwo/ChargingPointVersionTwoWidget'
import { backendApiClient } from '../../../apiClient'
import { enqueueSnackbar } from 'notistack'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from './lmsVersionTwo/ChargingPointVersionTwoConstants'
import ChargingPointVersionOneWidget from './lmsVersionOne/ChargingPointVersionOneWidget'

const EditChargingPointPage = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const [helpText, setHelpText] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const [chargingPoint, setChargingPoint] = useState(location.state?.chargingPoint || {})
  const { settings } = useRouteLoaderData('settingsRoot')
  const { clientId, pointId } = useParams()
  const [loading, setLoading] = useState(false)

  const CONFIG = 'Konfiguration'
  const CP_EDIT_HELP_TITLE = 'Ladepunkt bearbeiten'
  const URL_CONFIG_CHARGINGPOINT = `/api/client/${clientId}/configuration/chargingpoints/${pointId}`

  const fetchHelpText = () => {
    fetch(chargingPointEditHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const hasTwin = (point) => {
    return (point.manufacturer === 'abl' || point.manufacturer === 'alfen') && point.extenders && point.extenders.length === 1
  }

  const fetchChargingPoint = () => {
    if (chargingPoint && Object.values(chargingPoint).length > 0) {
      return
    }
    setLoading(true)

    backendApiClient.get(URL_CONFIG_CHARGINGPOINT)
      .then((r) => {
        if (!r.areaId) {
          let ids = []
          if (r.areaIds) {
            ids = r.areaIds
          }
          ids.push(settings.areas[0].id)
          r.areaIds = ids
          r.areaId = settings.areas[0].id
        }
        if (!r.manufacturer) {
          r = {
            ...r,
            manufacturer: 'flotteladen',
          }
        } else if (hasTwin(r)) {
          r = {
            ...r,
            chargingBoxType: 'twin',
            twinName: r.extenders[0].name,
          }
        }
        setChargingPoint(r)
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const save = (data) => {
    setLoading(true)
    if (hasTwin(data)) {
      data.extenders[0].name = data.twinName
    }
    delete data.chargingBoxType
    delete data.twinName

    if (data.type === 'TYP2_THREE_PHASE') {
      data.phase = null
    }

    backendApiClient.put(URL_CONFIG_CHARGINGPOINT, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchHelpText()
    fetchChargingPoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Ladepunkt bearbeiten'} pageTitle={CONFIG} onBack={() => navigate(-1)} helpText={helpText}
          helpTitle={CP_EDIT_HELP_TITLE}>
      {lmsVersion > 1 ?
        <ChargingPointVersionTwoWidget save={save} chargingPoint={chargingPoint} loading={loading} disabledOnEdit={true}/>
        :
        <ChargingPointVersionOneWidget save={save} lmsVersion={lmsVersion} chargingPoint={chargingPoint} loading={loading}/>
      }
    </Page>
  )

}

export default EditChargingPointPage