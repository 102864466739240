import React, { useEffect, useState } from 'react'
import AuthorizationWidget from '../../components/crud/widgets/AuthorizationWidget'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { backendApiClient } from '../../apiClient'
import { LoadingBackdrop } from '../../components'
import Grid from '@mui/material/Grid'
import CustomSearchBar from '../../components/crud/widgets/CustomSearchBar'

const AddUser = () => {

  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()

  const API = '/api/user/profiles'
  const USER_LIST_URL = `/api/user/profiles/assigned-users`

  useEffect(() => {
    try {
      fetchUserListData()
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchUserListData = (params) => {
    backendApiClient.get(USER_LIST_URL, params)
      .then((res) => {
        setUserList(res)
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  const save = (data) => {
    setLoading(true)
    if (user) {
      data.clientIds.push(clientId)
      backendApiClient.put(API, data, { userProfileId: data.userId })
        .then(() => {
          enqueueSnackbar('Nutzer erfolgreich gespeichert!', {
            variant: 'success',
          })
          navigate('..')
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
            variant: 'error',
          })
        })
        .finally(() => setLoading(false))
    } else {
      backendApiClient.post(API, data)
        .then(() => {
          enqueueSnackbar('Nutzer erfolgreich gespeichert!', {
            variant: 'success',
          })
          navigate('..')
        })
        .catch(() => {
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
            variant: 'error',
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const handleUserChange = (event, value) => {
    setUser(value)
  }

  const handleSearch = (event) => {
    if (event?.target?.value?.length > 2) {
      fetchUserListData({ searchText: event.target.value })
    }
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        flexGrow={1}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sm={10}
        >
          <CustomSearchBar
            data={userList.content}
            handleChange={handleUserChange}
            handleSearch={handleSearch}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <AuthorizationWidget save={save} searchedUserInUse={user} user={user} loading={loading}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddUser