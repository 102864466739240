import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import CustomSelect from './CustomSelect'
import { useRouteLoaderData } from 'react-router'

const ConfigurationWidgetWithZones = (props) => {
  const schema = props?.schema.properties
  const [data, setData] = useState(props?.formData)
  const { lmsVersion } = useRouteLoaderData('clientRoot')

  const onChange = (name, value) => {
    const change = {
      ...data,
      [name]: value,
    }
    setData(change)
    props.onSave(change)
  }

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <CustomSelect
          id={'lmsMode'}
          disabled={!props?.canSave}
          props={schema?.lmsMode}
          value={data?.lmsMode ? data?.lmsMode : schema?.lmsMode.default}
          onChange={(value) => onChange('lmsMode', value)}
        />
      </Grid>
      {lmsVersion === 1 &&
        <Grid item xs={12}>
          <CustomSelect
            disabled={!props?.canSave}
            props={schema?.numberOfControllers}
            value={data?.numberOfControllers}
            onChange={(value) => onChange('numberOfControllers', value)}
          />
        </Grid>
      }
    </Grid>
  )
}

export default ConfigurationWidgetWithZones