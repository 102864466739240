import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { backendApiClient } from '../../apiClient'
import { LoadingBackdrop, LoadMoreButton } from '../../components'
import DialogDelete from '../../components/ConfirmDialog/ConfirmDialog'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ListItemSecondaryAction, ListItemText, TableCell, Typography } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLocation, useNavigate, useParams } from 'react-router'
import Table from '@mui/material/Table'
import SearchBarHeader from '../../components/User/SearchBarHeader'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'

const AuthorizationUserTable = () => {

  const { clientId } = useParams()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState()
  const [searchText, setSearchText] = useState('')
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const SEARCHBAR_LABEL = 'Suche'
  const currentUserMail = useSelector(state => state.auth.user.email)

  const [user, setUser] = useState()

  const USER_API = '/api/user/profiles/managing-users?clientId=' + clientId
  const API_EDIT_ENABLED = '/api/user/profiles/enable'
  const DELETE_API = `/api/client/${clientId}/users`

  const url = useLocation().pathname

  const { enqueueSnackbar } = useSnackbar()
  const ACTIVE = 'Aktiviert'
  const DEACTIVATE = 'Deaktiviert'

  const DELETE_TEXT = `Soll der Nutzer: ${user?.firstName} ${user?.lastName} wirklich gelöscht werden?`
  const SHOW_MORE_USER = 'Weitere Nutzer Anzeigen'

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try {
      if (searchText.trim().length > 2 || searchText.trim().length === 0) {
        fetchData()
      }
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const fetchData = (forceEmpty = false) => {
    try {

      let queryParams = {}

      if (!forceEmpty && searchText.length > 2) {
        queryParams.searchText = searchText
      }
      setLoading(true)
      backendApiClient.get(USER_API, queryParams)
        .then((response) => {
          setData(response)
        })
        .finally(() => setLoading(false))
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchMoreData = (page) => {
    try {

      let queryParams = { page: page }

      if (searchText.trim().length > 2) {
        queryParams.searchText = searchText.trim()
      }
      setLoading(true)
      backendApiClient.get(USER_API, queryParams)
        .then((response) => {
          setData(
            {
              content: [...data.content.concat(response.content)],
              empty: response.empty,
              last: response.last,
              pageable: response.pageable,
            },
          )
          setLoading(false)
        })
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDelete = () => {
    const api = DELETE_API.concat('/', user?.userId)
    setLoading(true)
    backendApiClient.delete(api)
      .then(() => {
        enqueueSnackbar('Der Nutzer wurde erfolgreich gelöscht!', {
          variant: 'success',
        })
        handleDialogClose()
        fetchData()
      })
      .catch((e) => {
        enqueueSnackbar('Der Nutzer konnte nicht gelöscht werden!', {
          variant: 'error',
        })
        setLoading(false)
        throw new Error(e)
      })
  }

  const handleCheckBox = (user) => {
    user.enabled = !user.enabled
    setLoading(true)
    backendApiClient.put(API_EDIT_ENABLED, user)
      .then(() => {
        enqueueSnackbar(`Der Nutzer ${user.lastName} wurde erfolgreich ${activationChecker(user.enabled)}!`, {
          variant: 'success',
        })
        setLoading(false)
      })
      .catch((e) => {
        enqueueSnackbar('Der Nutzer konnte nicht editiert werden!', {
          variant: 'error',
        })
        setLoading(false)
        throw new Error(e)
      })
  }

  const activationChecker = (enable) => {
    switch (enable) {
      case true :
        return ACTIVE.toLowerCase()
      default:
        return DEACTIVATE.toLowerCase()
    }
  }

  const handleDialogOpen = (user) => {
    try {
      setOpen(true)
      setUser(user)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setOpen(false)
      setUser(null)
    } catch (e) {
      throw new Error(e)
    }
  }

  const keyUpHandler = (event) => {
    setSearchText(event.target.value)
  }

  const handleClear = () => {
    setSearchText('')
    fetchData(true)
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <DialogDelete
        open={open}
        handleClose={handleDialogClose}
        handleConfirm={handleDelete}
        dialogText={DELETE_TEXT}
      />
      <SearchBarHeader
        label={SEARCHBAR_LABEL}
        keyUpHandler={keyUpHandler}
        searchValue={searchText}
        handleClear={handleClear}
        disableSelect
      />
      {smallScreen ?
        <ContentList
          data={data}
          url={url}
          handleCheckBox={handleCheckBox}
          handleDelete={handleDialogOpen}
          currentUserMail={currentUserMail}
        />
        :
        <ContentTable
          data={data}
          url={url}
          handleCheckBox={handleCheckBox}
          handleDelete={handleDialogOpen}
          currentUserMail={currentUserMail}
        />
      }
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '18px',
      }}>
        {data?.last ? <></> :
          <LoadMoreButton
            onClick={() => {
              fetchMoreData(data?.pageable?.pageNumber + 1)
            }}
            label={SHOW_MORE_USER}
          />
        }
      </div>
    </div>
  )
}

const ContentList = props => {

  const { data, url, handleCheckBox, handleDelete, currentUserMail } = props

  return (
    <List>
      {data?.content?.map((user, index) => {
        return (
          <UserListItem key={user.userId}
                        user={user}
                        url={url}
                        handleCheckBox={handleCheckBox}
                        handleDelete={handleDelete}
                        currentUserMail={currentUserMail}
                        index={index}
          />
        )
      })}
    </List>
  )
}

const UserListItem = ({ user, handleDelete, currentUserMail, index }) => {
  const navigate = useNavigate()

  return (
    <React.Fragment key={index}>
      <ListItem onClick={() => navigate('edit', { replace: false, state: { user: user } })} sx={{ marginBottom: 2 }}>
        <ListItemText
          disableTypography
          primary={user.firstName === ' ' ?
            <Typography variant={'h6'}>{user.lastName}</Typography>
            :
            <Typography variant={'h6'}>{user.lastName}, {user.firstName}</Typography>}
          secondary={RoleTranslator(user.groupName)}
        >
        </ListItemText>
        <ListItemSecondaryAction style={{ top: '100%' }}>
          <IconButton disabled={currentUserMail === user.email} onClick={() => {handleDelete(user)}}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Divider component="li"/>
    </React.Fragment>

  )
}

const ContentTable = props => {

  const { data, url, handleCheckBox, handleDelete, currentUserMail } = props

  const NAME = 'Name'
  const ROLE = 'Rolle'

  return (
    <TableContainer>
      <Table sx={{ minWidth: 500 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderBottom: 'none', width: '40%' }} align={'left'}>{NAME}</TableCell>
            <TableCell style={{ borderBottom: 'none' }} align={'left'}>{ROLE}</TableCell>
            <TableCell style={{ borderBottom: 'none' }}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.content?.map((user) => (
            <UserTableRow key={user.userId}
                          user={user}
                          url={url}
                          handleCheckBox={handleCheckBox}
                          handleDelete={handleDelete}
                          currentUserMail={currentUserMail}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const UserTableRow = props => {

  const navigate = useNavigate()
  const { user, handleDelete, currentUserMail } = props

  return (
    <TableRow
      key={user.userId}
    >
      <TableCell align={'left'}>
        {user.firstName === ' ' ?
          <Typography>{user.lastName}</Typography>
          :
          <Typography>{user.lastName}, {user.firstName}</Typography>}
      </TableCell>
      <TableCell>
        {RoleTranslator(user.groupName)}
      </TableCell>
      <TableCell align={'right'}>
        <IconButton onClick={() => {
          navigate('edit', {
              replace: false,
              state: { user: user },
            },
          )
        }}>
          <EditIcon/>
        </IconButton>
        <IconButton disabled={currentUserMail === user.email} onClick={() => {
          handleDelete(user)
        }}>
          <DeleteIcon/>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const RoleTranslator = (groupName) => {

  const FL_CPO = 'Betreiber*in'
  const FL_SERVICE = 'Servicemitarbeiter*in'
  const FL_LMS_OWNER = 'Verwalter*in'
  const FL_USER = 'Nutzer'

  switch (groupName) {
    case 'FL_lms_owner' :
      return FL_LMS_OWNER
    case 'FL_cpo' :
      return FL_CPO
    case 'FL_service' :
      return FL_SERVICE
    default :
      return FL_USER
  }

}

export default AuthorizationUserTable