import React, { useState } from 'react'
import { getRoles } from '../../../../security/roles'
import List from '@mui/material/List'
import { useSelector } from 'react-redux'
import { ChargingPointAccordionMobile } from '../../../../components'
import RestartDialog from '../../../../components/RestartDialog/RestartDialog'

const CpList = ({ chargingPoints, onEdit, onRestart, pvSettings, hasPv, version, onActivate }) => {
  const userRoles = useSelector(state => getRoles(state))
  const [openRestartDialog, setOpenRestartDialog] = useState(false)
  const [selectedChargingPoint, setSelectedChargingPoint] = useState({})

  const handleCloseRestartDialog = () => {
    setOpenRestartDialog(false)
  }

  const handleOpenRestartDialog = (cp) => {
    setSelectedChargingPoint(cp)
    setOpenRestartDialog(true)
  }

  const handleRestart = () => {
    onRestart(selectedChargingPoint)
  }

  return (
    <React.Fragment>
      <List>
        {chargingPoints && chargingPoints.length > 0 && chargingPoints
          .filter(cp => isCpLMS01(cp) || isCpLMS02Main(cp))
          .map((item, index) => {
            return (
              <ChargingPointAccordionMobile item={item}
                                            key={index}
                                            index={index}
                                            pvSettings={pvSettings}
                                            hasPv={hasPv}
                                            userRoles={userRoles}
                                            onEdit={onEdit}
                                            onRestart={handleOpenRestartDialog}
                                            onActivate={onActivate}
                                            version={version}
              />
            )
          })}
      </List>
      <RestartDialog openDialog={openRestartDialog} handleClose={handleCloseRestartDialog} onConfirm={handleRestart}/>
    </React.Fragment>
  )
}

function isCpLMS01 (chargingPoint) {
  return chargingPoint.interfaceType === 'na' || chargingPoint.interfaceType == null
}

function isCpLMS02Main (chargingPoint) {
  return chargingPoint.connectorId == null || chargingPoint.connectorId === 1
}

export default CpList