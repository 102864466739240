import React, { useEffect, useState } from 'react'
import { NavigateFab, Page } from '../../components'
import BaseDataContainer from './BaseDataContainer'
import AddIcon from '@mui/icons-material/Add'
import { useLocation } from 'react-router'
import baseDataHelpText from '../../help-texts/FL_Stammdaten.md'

const BaseDataPage = () => {

  const url = useLocation().pathname
  const [helpText, setHelpText] = useState()
  const HELP_TITLE = 'In den Stammdaten werden die wichtigsten Informationen der Ladestation festgehalten'

  useEffect(() => {
    fetch(baseDataHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Stammdaten'} pageTitle={'Stammdaten'} helpTitle={HELP_TITLE} helpText={helpText}>
      <BaseDataContainer/>
      <NavigateFab
        path={`${url}/new`}
        text={'neu'}>
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default BaseDataPage