import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { NavLink as RouterLink } from 'react-router-dom'
import backendApiClient from '../../apiClient/apiClient'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { LoadingBackdrop } from '../../components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useLocation, useParams } from 'react-router'
import { styled } from '@mui/material/styles'

const TextFieldTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))

const BaseDataContainer = () => {
  const { clientId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const url = useLocation().pathname

  const apiUrl = '/api/client/' + clientId + '/customer'

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(apiUrl)
      .then((response) => {
        setData(response)
        setLoading(false)
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  const translateRole = (role) => {
    try {
      switch (role) {
        case 'service':
          return 'Service'
        case 'manager':
          return 'Verwalter'
        default:
          return 'nicht angegeben'
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Grid container>
        <Grid item xs={2}>
          <TextFieldTypography>
            {`Name:`}
          </TextFieldTypography>
        </Grid>
        <Grid item xs={10}>
          <TextFieldTypography>
            {`${data.name}`}
          </TextFieldTypography>
        </Grid>
        <Grid item xs={2}>
          <TextFieldTypography>
            {`LMS ID:`}
          </TextFieldTypography>
        </Grid>
        <Grid item xs={10}>
          <TextFieldTypography>
            {`${data.clientId}`}
          </TextFieldTypography>
        </Grid>
        <Grid item xs={2}>
          <TextFieldTypography>
            {`LMS Version:`}
          </TextFieldTypography>
        </Grid>
        <Grid item xs={10}>
          <Typography>
            {`${data.lmsVersion}`}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        sx={{
          marginTop: 4,
          marginBottom: 1,
          paper: {
            padding: 1,
          },
        }}
        variant={'h4'}>
        Ansprechpartner
      </Typography>

      {/* same as material ui accordion but not renamed yet due to older version of M-UI*/}
      {data.contacts?.length > 0 && data.contacts.map((entry) => (
        <Accordion key={entry.id}
                   sx={{
                     marginTop: '1px',
                     boxShadow: 'none',
                     '&:before': {
                       display: 'none',
                     },
                     '&.Mui-expanded': {
                       marginTop: '0px !important',
                       marginBottom: '0px !important',
                     },
                   }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            sx={{
              height: '64px',
              paddingLeft: 0,
              borderBottom: `1px solid #B9B9B9`,
              flexDirection: 'row-reverse',
              '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
                transform: 'rotate(-90deg)',
              },
              '&.Mui-expanded & > .expandIconWrapper': {
                marginLeft: -2,
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginLeft: 10,
              }}>
              <Typography variant={'h6'}>
                {entry.lastName + ', ' + entry.firstName + ' - ' + translateRole(entry.role)}
              </Typography>
              <IconButton
                size={'small'}
                component={RouterLink}
                to={`${url}/edit/${entry.id}`}>
                <EditIcon/>
              </IconButton>
            </div>

          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={{
                marginLeft: '36px',
                paddingTop: 2,
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography gutterBottom component="div">
                  {(entry.locationPostcode ? entry.locationPostcode : '') + ' ' + (entry.locationCity ? entry.locationCity : '')}
                </Typography>
                <Typography gutterBottom component="div">
                  {'Tel. ' + entry.phone}
                </Typography>
                <Typography gutterBottom component="div">
                  {entry.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  {entry.notes}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>

  )
}

export default BaseDataContainer