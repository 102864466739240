import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import chargingPointHelpText from '../../../help-texts/FL_Konfiguration_Ladepunkte.md'
import { useParams, useRouteLoaderData } from 'react-router'
import ChargingPointVersionTwoWidget from './lmsVersionTwo/ChargingPointVersionTwoWidget'
import { backendApiClient } from '../../../apiClient'
import { enqueueSnackbar } from 'notistack'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from './lmsVersionTwo/ChargingPointVersionTwoConstants'
import ChargingPointVersionOneWidget from './lmsVersionOne/ChargingPointVersionOneWidget'

const AddChargingPointPage = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const { clientId } = useParams()
  const [helpText, setHelpText] = useState()
  const navigate = useNavigate()

  const CONFIG = 'Konfiguration'
  const CP_HELP_TITLE = 'Ladepunkte'

  const URL_ADD_CP = `/api/client/${clientId}/configuration/chargingpoints`
  const URL_ADD_EXTENDER = `/api/client/${clientId}/configuration/chargingpoints/{chargingPointId}/extender`

  useEffect(() => {
    fetch(chargingPointHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data) => {
    backendApiClient.post(URL_ADD_CP, data)
      .then(function (result) {
        if (data.chargingBoxType === 'twin') {
          const addExtenderUrl = URL_ADD_EXTENDER.replace(/{chargingPointId}/g, result.id)
          let extender = {
            name: data.twinName,
            serialNumber: data.serialNumber + '*2',
            manufacturer: data.manufacturer,
          }
          postUrl(addExtenderUrl, extender)
        } else {
          enqueueSnackbar(SNACKBAR_SUCCESS, {
            variant: 'success',
          })
          navigate(-1)
        }
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const postUrl = (url, data) => {
    backendApiClient.post(url, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  return (
    <Page viewName={'Ladepunkt anlegen'} pageTitle={CONFIG} onBack={() => navigate(-1)} helpText={helpText}
          helpTitle={CP_HELP_TITLE}>
      {lmsVersion > 1 ?
        <ChargingPointVersionTwoWidget save={save}/>
        :
        <ChargingPointVersionOneWidget save={save} lmsVersion={lmsVersion}/>
      }
    </Page>
  )

}

export default AddChargingPointPage