import React from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

const ResetPasswordCompleted = () => {

  const navigate = useNavigate()

  return (
    <div>
      <Typography
        sx={{ marginBottom: 2 }}
        variant="h2">
        Geschafft!
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1"
        sx={{ marginBottom: 2 }}
      >
        Ihr Passwort wurde geändert. Sie können sich jetzt mit ihrem neuen Passwort anmelden.
      </Typography>
      <Button
        variant={'contained'}
        color={'primary'}
        fullWidth
        onClick={() => {
          navigate('/')
        }}
      >
        Zurück zur Anmeldung
      </Button>
    </div>
  )
}

export default ResetPasswordCompleted