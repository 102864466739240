import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import ChargingPointIcon from './ChargingPointIcon'
import TodayIcon from '@mui/icons-material/Today'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Box, Card, TextField, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import { isLMSOwner, isServicePerson } from '../../../security/roles'
import SustainableIcon from './SustainableIcon'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'

const InfoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  marginRight: theme.spacing(1),
}))

const TextBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const GridText = styled(Grid)({
  width: '100%',
})

const RootDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  paddingBottom: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
}))

const ChargingPointLabel = ({ chargingPoint, fetchData, clientId, hasEms, userRoles, versionTwoOrNewer, connectionStatus, systemStatus }) => {
  const [switchEdit, setSwitchEdit] = useState(true)
  const [name, setName] = useState(chargingPoint.name)
  const { enqueueSnackbar } = useSnackbar()

  const save = () => {
    const URL_UPDATE = '/api/client/{client}/configuration/chargingpoints/{chargingpointId}/name'
    let url = URL_UPDATE.replace(/{client}/g, clientId)
    url = url.replace(/{chargingpointId}/g, chargingPoint.id)
    let currentCp = { ...chargingPoint }
    currentCp.name = name

    backendApiClient.put(url, currentCp)
      .then(() => {
        setSwitchEdit(true)
        fetchData()
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
      })
  }

  const handleAbort = () => {
    setName(chargingPoint.name)
    setSwitchEdit(true)
  }

  const getPowerMeasure = (chargingPoint) => {
    let value
    if (chargingPoint.currentStatus?.status === 'CHARGING' && chargingPoint.currentStatus?.powerMeasurement?.value) {
      value = (Math.round((chargingPoint.currentStatus?.powerMeasurement?.value / 1000) * 10) / 10).toString()
        .replace('.', ',')
    } else {
      value = '0'
    }
    value += ' kW / '
    return value
  }

  useEffect(() => {
    setName(chargingPoint.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoint])

  return (
    <Card id={'chargingPoint-' + chargingPoint.id}
          sx={{
            marginBottom: 2,
            borderRadius: 1,
          }}
          elevation={2}>
      {chargingPoint &&
        <div style={{
          float: 'right',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box sx={{
            display: 'block',
            float: 'left',
            flexGrow: 1,
          }}>
            <RootDiv>
              <div style={{ marginRight: 1 }}>
                <ChargingPointIcon status={chargingPoint.currentStatus?.status} connectionStatus={connectionStatus} systemStatus={systemStatus} activated={chargingPoint.activated} width={60}/>
              </div>
              <InfoBox>
                {switchEdit ?
                  <Typography variant={'h5'} onClick={() => {
                    if (isLMSOwner(userRoles) || isServicePerson(userRoles)) {
                      setSwitchEdit(false)
                    }
                  }}>
                    {chargingPoint.name ? name : chargingPoint.id}
                  </Typography>
                  :
                  <TextField
                    fullWidth
                    placeholder="Name..."
                    variant={'outlined'}
                    autoFocus
                    defaultValue={name}
                    onBlur={() => save()}
                    onKeyUp={(ev) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault()
                        save()
                      } else if (ev.key === 'Escape') {
                        ev.preventDefault()
                        handleAbort()
                      }
                    }}
                    onChange={(event) => setName(event.target.value)}
                    inputProps={{
                      style: {
                        padding: 2,
                      },
                    }}
                  />
                }
                <Grid
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  <GridText item lg={3} md={6} sm={6} xs={6}>
                    <Typography>
                      {versionTwoOrNewer ? getPowerMeasure(chargingPoint) : ''}
                      {chargingPoint.maxPower?.toString()
                        .replace('.', ',')} kW
                    </Typography>
                  </GridText>
                  <GridText item lg={3} md={6} sm={6} xs={6}>
                    <Tooltip title={'Ladung gestartet am'}>
                      <TextBox>
                        <TodayIcon
                          fontSize={'small'}
                          sx={{ marginRight: 1 }}
                        />
                        <Typography>
                          {chargingPoint.currentStatus?.lastSessionStarted ? (
                            DateTime.fromISO(chargingPoint.currentStatus.lastSessionStarted)
                              .toFormat('dd.MM.yy')
                          ) : (
                            '--.--.--'
                          )}
                        </Typography>
                      </TextBox>
                    </Tooltip>
                  </GridText>
                  <GridText item lg={3} md={6} sm={6} xs={6}>
                    <TextBox>
                      <AccessTimeIcon
                        fontSize={'small'}
                        sx={{ marginRight: 1 }}/>
                      <div style={{ width: '100%' }}>
                        {!chargingPoint.currentStatus?.lastSessionStarted ?
                          <Tooltip title={'Keine Ladung erfasst'}>
                            <Typography>
                              {'--:-----:--'}
                            </Typography>
                          </Tooltip>
                          :
                          <div>
                            <Tooltip title={'Ladung gestartet um'}>
                              <Typography display={'inline'}>
                                {DateTime.fromISO(chargingPoint.currentStatus.lastSessionStarted)
                                  .toFormat('HH:mm')}
                              </Typography>
                            </Tooltip>
                            <Typography display={'inline'}>
                              {' - '}
                            </Typography>
                            {chargingPoint.currentStatus?.lastSessionCompleted &&
                            (DateTime.fromISO(chargingPoint.currentStatus?.lastSessionCompleted) > DateTime.fromISO(chargingPoint.currentStatus?.lastSessionStarted)) ?
                              <Tooltip title={'Ladung beendet um'}>
                                <Typography display={'inline'}>
                                  {DateTime.fromISO(chargingPoint.currentStatus.lastSessionCompleted)
                                    .toFormat('HH:mm')}
                                </Typography>
                              </Tooltip>
                              :
                              <Tooltip title={'wird aktuell geladen'}>
                                <Typography display={'inline'}>
                                  {DateTime.now()
                                    .toFormat('HH:mm')}
                                </Typography>
                              </Tooltip>
                            }
                          </div>
                        }
                      </div>
                    </TextBox>
                  </GridText>
                  <GridText item lg={3} md={6} sm={6} xs={6}>
                    <Tooltip title={chargingPoint.currentStatus?.lastSessionStarted ? 'Ladedauer' : 'Keine Ladedauer erfasst'}>
                      <TextBox>
                        <HourglassEmptyIcon
                          fontSize={'small'}
                          sx={{ marginRight: 1 }}
                        />
                        <Typography sx={(theme) => ({ width: theme.spacing(7) })}>
                          {!chargingPoint.currentStatus?.lastSessionStarted ? '--:--:--'
                            :
                              chargingPoint.currentStatus?.lastSessionCompleted &&
                                (DateTime.fromISO(chargingPoint.currentStatus?.lastSessionCompleted) > DateTime.fromISO(chargingPoint.currentStatus?.lastSessionStarted)) ?
                              DateTime.fromISO(chargingPoint.currentStatus?.lastSessionCompleted)
                                .diff(DateTime.fromISO(chargingPoint.currentStatus?.lastSessionStarted))
                                .toFormat('hh:mm:ss')
                              :
                              DateTime.now()
                                .diff(DateTime.fromISO(chargingPoint.currentStatus?.lastSessionStarted))
                                .toFormat('hh:mm:ss')
                          }
                        </Typography>
                      </TextBox>
                    </Tooltip>
                  </GridText>
                </Grid>
              </InfoBox>
            </RootDiv>
          </Box>
          {hasEms && chargingPoint.currentPolicy?.selectedChargeMode === 'GREEN' &&
            <div id={'sustainableIcon-CP' + chargingPoint.id} style={{ marginLeft: '-30px' }}>
              <SustainableIcon></SustainableIcon>
            </div>
          }
        </div>
      }
    </Card>
  )
}

ChargingPointLabel.propTypes = {
  total: PropTypes.number,
  CurrentLimit: PropTypes.number,
  PowerLimit: PropTypes.number,
}

export default ChargingPointLabel
