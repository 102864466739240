import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const RestartDialog = ({ onConfirm, openDialog, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleConfirm = () => {
    handleClose()
    onConfirm()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          Soll der Ladepunkt neu gestartet werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()} color="primary">
          {'Abbrechen'}
        </Button>
        <Button
          onClick={() => handleConfirm()}
          color="primary"
          autoFocus>
          {'Bestätigen'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RestartDialog
