import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { backendApiClient } from '../../apiClient'

const CUSTOMERS_API = '/api/user/customers'

let initialState = {
  customerList: [],
}

export const fetchCustomers = createAsyncThunk('customer/fetchCustomers', async () => {
  return backendApiClient.get(CUSTOMERS_API)
})

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customerList = action.payload.items
      })
  },
})

export default customerSlice.reducer