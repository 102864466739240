import * as yup from 'yup'

export const firmwareValidationSchema = yup.object({
  manufacturer: yup.string()
    .required('Der Hersteller darf nicht leer sein'),
  version: yup.string()
    .required('Die Firmware Version darf nicht leer sein'),
  model: yup.string()
    .required('Das Wallbox Modell darf nicht leer sein'),
})
