import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const HeaderGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: theme.spacing(5),
  marginTop: theme.spacing(2),
}))

const CustomTableSummary = ({ totalTime, totalAmount, children }) => {

  return (

    <Grid
      container
      direction={'row'}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.alternateTableHeader}`,
        width: '100%',
      })}>
      <Grid item xs={4}/>
      <Grid item container sm={8} xs={12} direction={'row'} justifycontent={'flex-end'}>
        <HeaderGridItem item sm={children ? 5 : 6} xs={12}>
          <Typography sx={{
            marginRight: '12px',
            fontWeight: 800,
            fontSize: '15px',
          }}>
            Ladezeit
          </Typography>
          <Typography sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              marginRight: '14px',
            },
            marginRight: '2px',
            fontWeight: 800,
            fontSize: '15px',
            color: theme.palette.primary.alternateContrastText,
          })}
          >
            {' ' + totalTime}
          </Typography>
        </HeaderGridItem>
        <HeaderGridItem
          item
          sm={children ? 5 : 6}
          xs={12}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              marginTop: '0px',
            },
          })}>
          <Typography sx={{
            marginRight: '15px',
            fontWeight: 800,
            fontSize: '15px',
          }}>
            Lademenge
          </Typography>
          <Typography sx={{
            marginRight: '15px',
            fontWeight: 800,
            fontSize: '15px',
            color: 'primary.alternateContrastText',
          }}>
            {(Math.round((totalAmount / 1000) * 100) / 100).toFixed(2)
              .toString()
              .replace('.', ',')} kWh
          </Typography>
        </HeaderGridItem>
        {
          children &&
          <HeaderGridItem
            item
            sm={2}
            xs={12}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                marginTop: '0px',
              },
            })}>
            {children}
          </HeaderGridItem>
        }
      </Grid>
    </Grid>
  )
}

CustomTableSummary.propTypes = {
  totalTime: PropTypes.string,
  totalAmount: PropTypes.number,
}

export default CustomTableSummary