import * as yup from 'yup'

export const validationSchema = yup.object({
  firstName: yup.string()
    .required('Der Vorname darf nicht leer sein'),
  lastName: yup.string()
    .required('Der Nachname darf nicht leer sein'),
  email: yup.string()
    .required('E-Mail darf nicht leer sein')
    .email('Ungültige E-Mail-Adresse'),
  groupName: yup.string()
    .required('Es muss eine Gruppe ausgewählt werden'),
  clientIds: yup.string()
    .when(['groupName'], {
      is: (groupName) => {
        return groupName !== 'FL_support'
      },
      then: () => yup.array()
        .min(1, 'Es muss eine Ladestation ausgewählt werden'),
    }),
})