import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop, LoadMoreButton, NavigateFab } from '../../../components'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import UsersTable from './UsersTable'
import UsersList from './UsersList'
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonRoot = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '18px',
})

const UsersTabPage = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const userRoles = useSelector(state => getRoles(state))
  const theme = useTheme()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState()
  const [data, setData] = useState({})
  const [content, setContent] = useState([])

  const CP_USERS_URL = `/api/client/${clientId}/chargingpoint/users`
  const CP_USER_URL = `/api/client/${clientId}/chargingpoint/users/{userId}`

  const fetchData = (cursor) => {
    setLoading(true)
    let queryParams = cursor ? { cursor: cursor } : {}
    backendApiClient.get(CP_USERS_URL, queryParams)
      .then((response) => {
        setData(response)
        if (cursor) {
          setContent(content.concat(response.content))
        } else {
          setContent(response.content)
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => setLoading(false))
  }

  const deleteUser = () => {
    setLoading(true)
    setDeleteOpen(false)
    const url = CP_USER_URL
      .replace(/{userId}/g, userToDelete.userId)
    backendApiClient.delete(url)
      .then(() => {
        fetchData()
      })
      .catch(() => {
        enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten, bitte versuchen Sie es erneut', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  const handleDialogOpen = (entry) => {
    try {
      setUserToDelete(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleEdit (user) {
    navigate(`${user.userId}/edit`, { state: { user: user } })
  }

  function handleDelete (user) {
    handleDialogOpen(user)
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      {lmsVersion < 1 && <Navigate to={-1}/>}
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={deleteUser}
        dialogText={'Soll dieser Eintrag wirklich gelöscht werden?'}
      />
      {smallScreen ?
        <UsersList content={content} handleEdit={handleEdit} handleDelete={handleDelete} allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>
        :
        <UsersTable content={content} handleEdit={handleEdit} handleDelete={handleDelete} allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>
      }
      <ButtonRoot>
        {data?.last === false ?
          <LoadMoreButton
            onClick={() => {fetchData(data.pageable.cursor)}}
            label={'Weitere Nutzer*innen anzeigen'}
          />
          :
          <></>
        }
      </ButtonRoot>
      <NavigateFab
        path={`new`}
        text={'neu'}
      >
        <AddIcon/>
      </NavigateFab>

    </React.Fragment>
  )
}
export default UsersTabPage