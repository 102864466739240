import Grid from '@mui/material/Grid'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InstallFirmwareTable from './InstallFirmwareTable'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { GeneralFab, LoadingBackdrop } from '../../../components'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import { addChargingPointForStatusUpdates } from '../../../store/firmwarestatus/firmwareStatusSlice'
import { isEmpty } from '../../../utils'

const InstallFirmwareContainer = ({ firmware }) => {
  const dispatch = useDispatch()
  const { manufacturer, version } = useParams()
  const navigate = useNavigate()
  const rootData = useRouteLoaderData('root')
  const [searchValue, setSearchValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [displayList, setDisplayList] = useState([])
  const [loading, setLoading] = useState(false)
  const lmsList = rootData?.systemStatusList
  let selectedChargingPointsMap = {}

  const FIRMWARE_INSTALL_URL = '/api/administration/charging-point-firmwares/install'
  const TITLE = `Firmwareversion ${version} für ${manufacturer} Ladepunkte`

  function addChargingPoint (key, cp) {
    selectedChargingPointsMap[key] = selectedChargingPointsMap[key] || []
    isEmpty(selectedChargingPointsMap[key].filter(item => item.id === cp.id)) && selectedChargingPointsMap[key].push(cp)
  }

  function removeChargingPoint (key, cp) {
    if (!selectedChargingPointsMap[key]) {
      return
    }
    selectedChargingPointsMap[key] = selectedChargingPointsMap[key].filter(item => item.id !== cp.id)
    if (selectedChargingPointsMap[key].length === 0) {
      delete selectedChargingPointsMap[key]
    }
  }

  const handleInstall = () => {
    setLoading(true)

    const targets = {}
    Object.keys(selectedChargingPointsMap)
      .forEach(function (key, index) {
        targets[key] = selectedChargingPointsMap[key].map(cp => cp.id)
      })

    const data = {
      firmware,
      targets: targets,
    }

    backendApiClient.post(FIRMWARE_INSTALL_URL, data)
      .then(() => {
        enqueueSnackbar('Der Vorgang wurde erfolgreich gestartet', { variant: 'success' })
        dispatch(addChargingPointForStatusUpdates({ manufacturer: manufacturer, version: version, targets: selectedChargingPointsMap }))
        navigate('status')
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          enqueueSnackbar('Firmware nicht vorhanden', { variant: 'error' })
        } else {
          enqueueSnackbar('Der Vorgang konnte nicht gestartet werden', { variant: 'error' })
        }
      })
      .finally(() => setLoading(false))
  }

  const adjustLMSList = (newList) => {
    const filteredList = newList
      .filter((system) => system.lmsVersion === '2')
    if (searchValue.length > 0) {
      const searchList = filteredList
        .filter((system) => (system.name?.toUpperCase()
          .includes(searchValue.toUpperCase()) || system.clientId?.toUpperCase()
          .includes(searchValue.toUpperCase())))

      setDisplayList(searchList)
    } else {
      setDisplayList(filteredList)
    }
  }

  const changeSelectedState = (clientId, cp, checked) => {
    if (checked) {
      addChargingPoint(clientId, cp)
    } else {
      removeChargingPoint(clientId, cp)
    }
  }

  useEffect(() => {
    adjustLMSList(lmsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lmsList, searchValue])

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}>
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h4'}>
            {TITLE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            autoFocus
            value={searchValue}
            fullWidth
            variant={'standard'}
            label={'Suche'}
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue.length > 0 &&
                    <IconButton onClick={() => setSearchValue('')} size="large">
                      <ClearIcon/>
                    </IconButton>
                  }
                  <Search/>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={true}
        >
          <InstallFirmwareTable lmsList={displayList} changeSelectedState={changeSelectedState} manufacturer={manufacturer}/>
        </Grid>
      </Grid>
      <GeneralFab text={'Starte Vorgang'} onClick={handleInstall} id={'installFab'}>
        <SaveAltIcon/>
      </GeneralFab>
    </React.Fragment>
  )
}

export default InstallFirmwareContainer