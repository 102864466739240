import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import { useNavigate } from 'react-router'
import AddUser from './AddUser'
import newPage from '../../help-texts/FL_Zugriffsberechtigung_hinzufuegen.md'

const AddUserAuthorizationPage = () => {

  const navigate = useNavigate()

  const AUTHORIZATION = 'Zugriffsberechtigung'
  const NEW_AUTHORIZATION = 'Neue Berechtigung anlegen'

  const [newHelpText, setNewHelpText] = useState('')

  useEffect(() => {
    fetch(newPage)
      .then(res => res.text())
      .then(text => setNewHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={NEW_AUTHORIZATION} helpText={newHelpText} helpTitle={NEW_AUTHORIZATION}
          onBack={() => navigate(-1)} pageTitle={AUTHORIZATION}>
      <AddUser onAfterSubmit={() => navigate('..')}/>
    </Page>
  )
}

export default AddUserAuthorizationPage