import Grid from '@mui/material/Grid'
import { Link, Typography } from '@mui/material'
import React from 'react'

const AmperfiedImprint = () => {

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Typography variant={'h5'} sx={{ marginBottom: 2, fontWeight: 700 }}>
          Informationen gemäß Telemediengesetz
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Dienstanbieter
        </Typography>
        <Typography>
          Amperfied GmbH
        </Typography>
        <Typography>
          Gutenbergring 20
        </Typography>
        <Typography>
          69190 Walldorf
        </Typography>
        <Typography>
          Deutschland
        </Typography>
        <Typography>
          Tel.: +49 6222 82-2266
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          {'E-Mail: '}
          <Link
            sx={{ color: 'primary.alternateContrastText' }}
            component="a"
            to="_blank"
            onClick={(e) => {
              window.location.href = `mailto:support@amperfied.com`
              e.preventDefault()
            }}
          >
            support@amperfied.com
          </Link>
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Geschäftsführung
        </Typography>
        <Typography>
          Ulrich Grimm
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Robin Karpp
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Handelsregister
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Amtsgericht Mannheim HRB 740405
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Umsatzsteueridentifikationsnummer
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          DE 346419339
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          EAR Nummer/ WEEE-Reg.-Nr.
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          DE 69879498
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Haftungsausschluss
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Die Amperfied GmbH bemüht sich, auf dieser Website richtige und vollständige Informationen zur Verfügung zu stellen. Sie übernimmt jedoch keine Haftung oder Garantie für die Aktualität,
          Richtigkeit und Vollständigkeit der bereitgestellten Informationen, da trotz aller Sorgfalt ein Auftreten von Fehlern nicht grundsätzlich ausgeschlossen werden kann. Die gesetzliche Haftung
          für Vorsatz und/oder grobe Fahrlässigkeit bleibt davon unberührt. Die Amperfied GmbH behält sich das Recht vor, ohne vorherige Ankündigung Änderungen, Ergänzungen oder Löschungen der
          bereitgestellten Informationen vorzunehmen.
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Externe Links und Verweise
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Die Amperfied GmbH übernimmt keine Haftung oder Garantie für die Gestaltung oder den Inhalt von Internetseiten, auf die unsere Website direkt oder indirekt verweist. Auf Aktualität oder
          Inhalte fremder Websites hat die Amperfied GmbH keinerlei Einfluss. Besucher folgen Verbindungen zu anderen Websites und Homepages auf eigene Gefahr und benutzen sie gemäß den jeweils
          geltenden Nutzungsbedingungen der entsprechenden Websites. Für Schäden, die aus der Nutzung dieser Websites resultieren wird keine Haftung übernommen. Die Amperfied GmbH distanziert sich von
          den Inhalten anderer Websites und macht sich diese auch nicht zu Eigen, selbst wenn die Links durch uns gesetzt wurden.
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Urheberrechte
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Der Inhalt und das Layout dieser Website sind urheberrechtlich geschützt. Aus Gründen des Urheberrechts ist die Speicherung und Vervielfältigung von Texten, Bildmaterial oder Grafiken aus
          dieser Website grundsätzlich nicht gestattet, es sei denn dies wurde ausdrücklich zuvor schriftlich genehmigt. Lediglich ausdrücklich autorisierte und durch Download zugänglich gemachte
          Informationen mit Quellenangabe sind hiervon ausgenommen. Allein zu privaten und nicht kommerziellen Zwecken ist eine Vervielfältigung oder Speicherung der Website gestattet. Die Einbettung
          der Website in andere Onlineauftritte oder in Teilfenster ist nur nach vorheriger ausdrücklicher und schriftlicher Genehmigung durch die Amperfied GmbH erlaubt.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AmperfiedImprint