import React from 'react'
import Routes from './routes/Routes'
import { store } from './store'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import { SnackbarProvider } from 'notistack'
import { de } from 'date-fns/locale/de'
import { deDE } from '@mui/x-date-pickers/locales'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { PersistGate } from 'redux-persist/integration/react';
import { persistentStore } from './store/store'

Amplify.configure(awsconfig)

function App () {
  return (
    <SnackbarProvider
      maxSnack={3}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistentStore}>
            <Routes/>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </SnackbarProvider>
  )
}

export default App
