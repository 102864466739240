const newConfigSchemaBuilder = (areas) => {
  let baseDeviceId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let parentIdArray = []
  let parentIdNameArray = []
  if (areas) {
    areas.forEach((area) => {
      parentIdArray.push(area.id)
      parentIdNameArray.push(area.name)
    })
  }

  return ({
      'type': 'object',
      'required': [],
      'properties': {
        'areas': {
          'type': 'array',
          'items': {
            'type': 'object',
            'properties': {
              'id': {
                'type': 'number',
              },
              'name': {
                'type': 'string',
                'title': 'Zonenname',
              },
              'parentAreaId': {
                'type': ['number', 'null'],
                'title': 'Übergeordnete Zone',
                'enum': parentIdArray,
                'enumNames': parentIdNameArray,
              },
              'meterConfiguration': {
                'type': 'boolean',
                'default': true,
                'title': 'Leistungsmesser mit Zähler',
              },
              'powerLimit': {
                'type': 'number',
                'title': 'Leistungsbegrenzung',
              },
              'fuseCurrentLimit': {
                'type': 'number',
                'title': 'Absicherung der Zone',
              },
              'energyMeter': {
                'type': ['object', 'null'],
                'name': {
                  'type': 'string',
                  'title': 'Zählername',
                },
                'comType': {
                  'type': 'string',
                  'title': 'Modbus Typ',
                  'enum': [
                    'serial',
                    'tcp',
                  ],
                  'enumNames': [
                    'Modbus RTU (serial)',
                    'Modbus TCP',
                  ],
                  'default': 'tcp',
                },
                'ipAddress': {
                  'type': ['string', 'null'],
                  'title': 'IP-Adresse',
                },
                'deviceId': {
                  'type': ['number', 'null'],
                  'title': 'Zählerkennung',
                  'enum': baseDeviceId,
                  'default': areas?.length ? areas.length - 1 : 1,
                },
                'manufacturer': {
                  'type': ['string', 'null'],
                  'title': 'Zählerhersteller',
                  'enum': ['schneider', 'celsa', 'gossen', 'janitza'],
                  'enumNames': ['Schneider', 'Celsa', 'Gossen', 'Janitza'],
                  'default': 'schneider',
                },
              },
            },
          },
        },
      },
    }

  )
}

export default newConfigSchemaBuilder