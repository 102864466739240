import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import CustomFormikTextField from './components/CustomFormikTextField'
import { SubmitFab } from '../../index'
import { validationSchema } from '../../../pages/Process/ProcessValidation'

const ProcessCustomWidget = ({ save, process }) => {

  const formik = useFormik({
    initialValues: {
      reason: '',
      responsible: '',
      comments: '',
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  useEffect(() => {
    if (process && Object.values(process).length > 0) {
      formik.setValues(process)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.reason}
            onChange={formik.handleChange}
            id={'reason'}
            label={'Grund'}
            error={Boolean(formik.errors?.reason)}
            helperText={formik.errors?.reason || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.responsible}
            onChange={formik.handleChange}
            id={'responsible'}
            label={'Erstellt von'}
            error={Boolean(formik.errors?.responsible)}
            helperText={formik.errors?.responsible || ''}
          />
        </Grid>
        <Grid item xs={12}/>
        <Grid
          item
          xs={12}>
          <Box paddingBottom={0}>
            <Typography>
              Bemerkung
            </Typography>
          </Box>
          <CustomFormikTextField
            value={formik.values.comments}
            onChange={formik.handleChange}
            multiline
            rows={5}
            variant={'outlined'}
            id={'comments'}
            error={Boolean(formik.errors?.comments)}
            helperText={formik.errors?.comments || ''}
          />
        </Grid>
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default ProcessCustomWidget