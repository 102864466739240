import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import CustomRadioButtons from './CustomRadioButtons'
import Box from '@mui/material/Box'
import MultipleSelect from './MultipleSelect'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import CustomFormikTextField from './components/CustomFormikTextField'
import CustomFormikSelectField from './components/CustomFormikSelectField'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop, SubmitFab } from '../../index'
import { validationSchema } from '../../../pages/UserManagement/UserManagementValidation'

const TypographyLeftSide = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const UserManagementWidget = ({ disabled, searchedUserInUse, loading, user, save }) => {
  const [groups, setGroups] = useState([])

  const groupsApi = '/api/user/profiles/groups'
  const customerApi = '/api/user/customers'

  const [clients, setClients] = useState({
    content: [
      {
        clientId: 'unknown',
      },
    ],

  })

  const salutation = [
    {
      name: 'Frau',
      label: 'Frau',
    },
    {
      name: 'Herr',
      label: 'Herr',
    },
    {
      name: 'Divers',
      label: 'Neutrale Anrede',
    },
  ]

  const formik = useFormik({
    initialValues: {
      salutation: 'Divers',
      firstName: '',
      lastName: '',
      email: '',
      groupName: '',
      clientIds: [],
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  const fetchUserGroups = () => {
    backendApiClient.get(groupsApi)
      .then((res) => {
        setGroups(res)
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  const fetchClients = () => {
    backendApiClient.get(customerApi)
      .then((res) => {
        let x
        for (let index in formik.values.clientIds) {
          let temp = res.content.find((client) => client.clientId === formik.values.clientIds[index])
          if (temp) {
            x = temp
            break
          }
        }
        if (!x) {
          formik.setFieldValue('clientIds', [])
        }

        setClients(res.content.map((client) => client.clientId))
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  useEffect(() => {
    if (user && Object.values(user).length > 0) {
      formik.setValues(user)
      formik.setErrors({})
    } else {
      formik.setValues(formik.initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (groups && Object.values(groups).length > 0) {
      fetchClients()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  useEffect(() => {
    fetchUserGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        sx={{ flexGrow: 1 }}
        spacing={4}
      >
        <Grid
          item
          container
          xs={12}
          spacing={2}
          paddingTop={0}
        >
          <Grid
            paddingTop={0}
            item
            xs={12}
            sm={3}
            md={2}
          >
            <Typography variant={'h5'} sx={{ marginTop: 1 }}>
              Daten
            </Typography>
          </Grid>
          <Grid
            paddingTop={0}
            item
            xs={12}
            sm={6.5}
          >
            <CustomRadioButtons
              disabled={searchedUserInUse}
              input={salutation}
              row={true}
              onChange={(value) => formik.setFieldValue('salutation', value)}
              value={formik.values.salutation}
            />
          </Grid>
          <Grid item>
            <Typography sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              color: 'error.main',
            }} align={'center'}>
              {formik.errors?.salutation || ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={false} sm={3} md={2}/>
        <Grid
          item
          xs={12}
          sm={3.5}
        >
          <CustomFormikTextField
            disabled={searchedUserInUse}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            id={'firstName'}
            label={'Vorname'}
            error={Boolean(formik.errors?.firstName)}
            helperText={formik.errors?.firstName || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.5}
        >
          <CustomFormikTextField
            disabled={searchedUserInUse}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            id={'lastName'}
            label={'Nachname'}
            error={Boolean(formik.errors?.lastName)}
            helperText={formik.errors?.lastName || ''}
          />
        </Grid>
        <Grid item xs={false} sm={2}/>
        <Grid item xs={false} sm={3} md={2}/>
        <Grid
          item
          xs={12}
          sm={7}
        >
          <CustomFormikTextField
            disabled={!!disabled || !!searchedUserInUse}
            value={formik.values.email}
            onChange={formik.handleChange}
            id={'email'}
            label={'E-Mail'}
            error={Boolean(formik.errors?.email)}
            helperText={formik.errors?.email || ''}
          />
        </Grid>
        <Grid item xs={false} sm={2}/>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
        >
          <Typography variant={'h5'} sx={{ marginTop: 3 }}>
            Gruppe
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
        >
          <CustomFormikSelectField
            value={formik.values.groupName}
            values={groups}
            onChange={formik.handleChange}
            id={'groupName'}
            label={'Gruppe'}
            error={Boolean(formik.errors?.groupName)}
            helperText={formik.errors?.groupName}
          />
        </Grid>
        <Grid item xs={false} sm={2}/>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
        >
          <Box paddingTop={2}>
            <TypographyLeftSide variant={'h5'}>
              Ladestationen
            </TypographyLeftSide>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
        >
          <MultipleSelect
            value={formik.values.clientIds}
            onChange={(clientIds) => formik.setFieldValue('clientIds', clientIds)}
            options={clients}
            title={'Ladestationen'}
            error={Boolean(formik.errors?.clientIds)}
            helperText={formik.errors?.clientIds}
          />
        </Grid>
        <Grid item xs={false} sm={2}/>
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default UserManagementWidget