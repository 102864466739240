import React from 'react'
import PropTypes from 'prop-types'
import { StyledEngineProvider } from '@mui/material/styles'
import { Form } from '@rjsf/mui'
import localize_de from 'ajv-i18n/localize/de/index'
import SwitchCheckbox from './widgets/SwitchCheckBox'
import SubmitFab from '../SubmitFab/SubmitFab'
import validator from '@rjsf/validator-ajv8'

/**
 * localize the validations errors. Using ajv-i18n here. In a later release when
 * schema-forms supports localization this code might be removed
 */
const transformErrors = (errors) => {
  const translatedErrors = errors.map((e) => {
    // add keyword field which is used in localize function
    e.keyword = e.name
    return e
  })
  localize_de(translatedErrors)
  return translatedErrors

}

/**
 * re-usable from to edit entities, The form structure and layout are defined using json-schema
 */
const EditForm = ({
                    schema,
                    uiSchema,
                    formData,
                    onSubmit,
                    customWidgets,
                    customValidate,
                    fields,
                    liveValidate = false,
                    disabledSubmit,
                    showSubmit = true,
                  }) => {
  // text field outlines variant https://github.com/rjsf-team/react-jsonschema-form/issues/1987

  const widgets = {
    CheckboxWidget: SwitchCheckbox,
    ...customWidgets,
  }

  const log = (type) => console.log.bind(console, type)
  const submit = (data) => {
    onSubmit(data.formData, data.errors)
  }

  return (
    <StyledEngineProvider injectFirst>
      {showSubmit ?
        <Form
          schema={schema}
          uiSchema={uiSchema}
          customValidate={customValidate}
          formData={formData}
          onSubmit={submit}
          onError={log('errors')}
          noHtml5Validate
          liveValidate={liveValidate}
          widgets={widgets}
          transformErrors={transformErrors}
          showErrorList={false}
          fields={fields}
          validator={validator}>
          <SubmitFab show={disabledSubmit !== undefined ? disabledSubmit : true}/>
        </Form>
        : (
          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            customValidate={customValidate}
            onError={log('errors')}
            noHtml5Validate
            liveValidate={liveValidate}
            widgets={widgets}
            transformErrors={transformErrors}
            showErrorList={false}
            fields={fields}
            validator={validator}>
            <SubmitFab show={false}/>
          </Form>)}
    </StyledEngineProvider>
  )
}

EditForm.propTypes = {
  schema: PropTypes.object.isRequired,
  formData: PropTypes.object,
  uiSchema: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default EditForm
