import { createTheme } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import 'typeface-work-sans'
import 'typeface-noto-sans'

import { deDE } from '@mui/material/locale'
import overrides from './overrides'

const theme = createTheme({
    palette,
    typography,
    components: {
      ...overrides,
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
            width: '100%',
          },
          body: {
            height: '100%',
            width: '100%',
            backgroundColor: '#FFFFFF !important',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },
          '.App': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    skewButton: 'none',
    skewText: 'none',
    roundingDisplay: 'block',
    displayAmperfied: 'none',
    displayFlotteladen: 'block',
    zIndex: {
      appBar: 1099,
      drawer: 1100,
    },
  },
  deDE,
)

export default theme
