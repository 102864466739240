import React from 'react'
import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { isEmpty } from '../../../utils'
import { DateTime, DateTime as Datetime } from 'luxon'

const StatusInstallFirmwareTable = ({ chargingPoints }) => {

  return (
    <div style={{ paddingTop: 5 }}>
      <Grid container sx={{ paddingBottom: 1 }}>
        <Grid item xs={4}>
          <Typography variant={'h6'}>
            Ladepunkte
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>
            Aktuelle Version
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={'h6'}>
            Update Status
          </Typography>
        </Grid>
      </Grid>
      {!isEmpty(chargingPoints) && Object.entries(chargingPoints)
        .map(([client, value]) => (
          <Accordion key={client}
                     expanded={true}
                     sx={{
                       borderTop: `1px solid #B9B9B9`,
                       paddingTop: '0px !important',
                       boxShadow: 'none',
                       '&:before': {
                         display: 'none',
                       },
                       '&.Mui-expanded': {
                         marginTop: '0px !important',
                         marginBottom: '0px !important',
                       },

                     }}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon sx={{ color: 'text.disabled' }}/>}
              sx={{
                flexDirection: 'row-reverse',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Typography>
                {client}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: 0,
                padding: 0,
              }}
            >
              {!isEmpty(value) && Object.entries(value)
                .map(([k, v]) =>
                  <Grid
                    key={`${client}-${value}`}
                    container
                    paddingBottom={2}
                  >
                    <Grid
                      item
                      xs={4}
                      paddingLeft={3}
                    >
                      <Typography fontWeight={500}>
                        {v.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                    >
                      <Typography>
                        {v.currentVersion || 'Unbekannt'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                    >
                      {v.timestamp && Datetime.fromISO(v.timestamp) > Datetime.fromISO(v.startTimestamp) ?
                        <Typography>
                          {DateTime.fromISO(v.timestamp).toFormat('dd.MM.yyyy HH:mm:ss') + ' - ' + v.status}
                        </Typography>
                        : '-'
                      }
                    </Grid>
                  </Grid>,
                )}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}

export default StatusInstallFirmwareTable