import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import { Update } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const CustomTableCell = styled(TableCell)({
  paddingLeft: 0,
})

const FirmwareTableRow = ({ entry, index, selectMore, handleUpdateStatus, showFirmwareUpdateIcon, allowEdit }) => {
  return (
    <TableRow key={index}>
      <CustomTableCell>
        {entry.manufacturer}
      </CustomTableCell>
      <CustomTableCell>
        {entry.version}
      </CustomTableCell>
      <CustomTableCell>
        {entry.model}
      </CustomTableCell>
      <CustomTableCell>
        {entry.comment}
      </CustomTableCell>
      <CustomTableCell style={{ width: '15%' }} align={'right'}>
        {allowEdit && (
          <div>
            {showFirmwareUpdateIcon(entry) &&
              <IconButton size={'small'} onClick={() => handleUpdateStatus(entry)}>
                <Tooltip title={'Status zur Installation'}>
                  <Update/>
                </Tooltip>
              </IconButton>
            }
            <IconButton id={`more-firmware-${index}`}
                        onClick={(event) => selectMore(entry, event.currentTarget)}>
              <MoreVertIcon/>
            </IconButton>
          </div>
        )}
      </CustomTableCell>
    </TableRow>
  )
}

const FirmwareTable = ({ content, selectMore, allowEdit, handleUpdateStatus, showFirmwareUpdateIcon }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell style={{ width: '10%', borderBottom: 'none' }}>
            Hersteller
          </CustomTableCell>
          <CustomTableCell style={{ width: '10%', borderBottom: 'none' }}>
            Version
          </CustomTableCell>
          <CustomTableCell style={{ width: '10%', borderBottom: 'none' }}>
            Modell
          </CustomTableCell>
          <CustomTableCell style={{ borderBottom: 'none' }}>
            Kommentar
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {content && content.length > 0 && content.map((row, index) => (
          <FirmwareTableRow entry={row} key={index} index={index}
                            selectMore={selectMore}
                            allowEdit={allowEdit}
                            handleUpdateStatus={handleUpdateStatus}
                            showFirmwareUpdateIcon={showFirmwareUpdateIcon}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default FirmwareTable