import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import AddIcon from '@mui/icons-material/Add'
import { useLocation } from 'react-router'
import landingPage from '../../help-texts/FL_Zugriffsberechtigung.md'
import { NavigateFab } from '../../components'
import AuthorizationUserTable from './AuthorizationUserTable'

const UserAuthorizationPage = ({ roles }) => {

  const url = useLocation().pathname

  const NEW_PATH = '/new'
  const AUTHORIZATION = 'Zugriffsberechtigung'

  const [helpText, setHelpText] = useState('')

  useEffect(() => {
    fetch(landingPage)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={AUTHORIZATION} helpText={helpText} helpTitle={AUTHORIZATION} pageTitle={AUTHORIZATION}
          roles={roles}>
      <AuthorizationUserTable/>
      <NavigateFab
        text={'neu'}
        path={`${url}${NEW_PATH}`}
      >
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default UserAuthorizationPage