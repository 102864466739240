/**
 * authorization Roles used in the application
 *
 */
export const ROLES = {
  /**
   * Flotteladen support employee (admin access to all clients)
   */
  FL_SUPPORT: 'FL_support',

  /**
   * CPO for some clients
   */
  FL_CPO: 'FL_cpo',

  /**
   * technical admin for some clients
   */
  FL_SERVICE: 'FL_service',

  /**
   * owner of a client
   */
  FL_LMS_OWNER: 'FL_lms_owner',

  /**
   * FL developer - sees some dev features
   */
  FL_DEV: 'FL_dev',

}

export const ALL_ROLES = [ROLES.FL_SUPPORT, ROLES.FL_CPO, ROLES.FL_SERVICE, ROLES.FL_LMS_OWNER, ROLES.FL_DEV]
export const SERVICE_ROLES = [ROLES.FL_SUPPORT, ROLES.FL_CPO, ROLES.FL_SERVICE]
export const CPO_ROLES = [ROLES.FL_SUPPORT, ROLES.FL_CPO]
export const SUPPORT_ROLE = [ROLES.FL_SUPPORT]

/**
 * get array of roles of the current user
 * @param state
 * @returns {[string]|null}
 */
export function getRoles (state) {
  return state.auth?.roles
}

/**
 * checks if the Role Requirements are fulfilled or not
 *
 * page object with roles array
 * @param userRoles
 * @param requiredRoles
 * @returns {boolean}
 */
export function hasRequiredRole (requiredRoles, userRoles) {
  if (requiredRoles === undefined || requiredRoles === null || requiredRoles.length === 0) {
    return true
  } else if (userRoles?.some(x => requiredRoles.includes(x))) {
    return true
  }
  return false
}

export function isServicePerson (userRoles) {
  return hasRequiredRole(SERVICE_ROLES, userRoles)
}

export function isSupportPerson (userRoles) {
  return hasRequiredRole(SUPPORT_ROLE, userRoles)
}

export function isCPO (userRoles) {
  return hasRequiredRole([ROLES.FL_CPO], userRoles)
}

export function isLMSOwner (userRoles) {
  return hasRequiredRole([ROLES.FL_LMS_OWNER], userRoles)
}