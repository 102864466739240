import React, { useEffect, useState } from 'react'
import { NavigateFab, Page } from '../../components'
import AddIcon from '@mui/icons-material/Add'
import { useLocation } from 'react-router'
import ProcessContainer from './ProcessContainer'
import process from '../../help-texts/FL_Vorgänge.md'

const ProcessPage = () => {
  const url = useLocation().pathname

  const [helpText, setHelpText] = useState()
  const HELP_TITLE = 'In den Vorgängen werden Geschehnisse dokumentiert'

  useEffect(() => {
    fetch(process)
      .then(res => res.text())
      .then(text => setHelpText(text))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Vorgänge'} pageTitle={'Vorgänge'} helpText={helpText} helpTitle={HELP_TITLE}>
      <ProcessContainer/>
      <NavigateFab
        path={`${url}/new`}
        text={'neu'}
      >
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default ProcessPage