import * as Yup from 'yup'

Yup.addMethod(Yup.array, 'multipleRules', function () {
  return this.test('test-rule-value', function (value) {
    const { path, createError } = this
    let message = ''
    value.forEach((rule) => {
      switch (rule.threshold) {
        case 'powerLimitSwitchOn':
          const value = rule.values[0].thresholdValue
          if (isNaN(value)) {
            message += `Die Auslastung am Hausanschluss muss eine Zahl sein\n`
          } else if (value < 0) {
            message += `Ein negativer Wert ist nicht zulässig\n`
          } else if (value > 100) {
            message += `Die Auslastung am Hausanschluss darf nicht über 100 liegen\n`
          }
          break
        // no value so no validation needed
        case 'powerSurplus':
        default:
          break
      }
    })
    if (message !== '') {
      return createError({ path, message: message })
    } else {
      return true
    }

  })
})

Yup.addMethod(Yup.object, 'singleRuleValue', function () {
  return this.test('test-single-rule-value', function (value) {
    const { path, createError } = this
    let valuePath = path + '.thresholdValue'
    if (value.threshold === 'switchOnThreshold' && value.unit === 'kW') {
      const switchOffThreshold = this.parent.find(entry => entry.threshold === 'switchOffThreshold')
      if (isNaN(value.thresholdValue)) {
        return createError({ path: valuePath, message: 'Die Einschaltschwelle muss eine Zahl sein' })
      } else if (value.thresholdValue <= 0) {
        return createError({ path: valuePath, message: 'Der Wert muss über 0 liegen' })
      } else if (value.thresholdValue > 9999.9) {
        return createError({ path: valuePath, message: 'Die Einschaltschwelle darf nicht über 9999 liegen' })
      } else if (value.thresholdValue.toString().length > 5) {
        return createError({ path: valuePath, message: 'Die Einschaltschwelle darf nicht so lang sein' })
      } else if (value.thresholdValue <= switchOffThreshold.thresholdValue) {
        return createError({ path: valuePath, message: 'Die Einschaltschwelle muss größer als die Ausschaltschwelle sein' })
      }
    } else if (value.threshold === 'switchOffThreshold' && value.unit === 'kW') {
      if (isNaN(value.thresholdValue)) {
        return createError({ path: valuePath, message: 'Die Ausschaltschwelle muss eine Zahl sein' })
      } else if (value.thresholdValue <= 0) {
        return createError({ path: valuePath, message: 'Der Wert muss über 0 liegen' })
      } else if (value.thresholdValue > 9999.9) {
        return createError({ path: valuePath, message: 'Die Ausschaltschwelle darf nicht über 9999 liegen' })
      } else if (value.thresholdValue.toString().length > 5) {
        return createError({ path: valuePath, message: 'Die Ausschaltschwelle darf nicht so lang sein' })
      }
    } else if (value.threshold === 'switchOnThreshold' && value.unit === 'percentage') {
      if (isNaN(value.thresholdValue)) {
        return createError({ path: valuePath, message: 'Die Auslastung am Hausanschluss muss eine Zahl sein' })
      } else if (value.thresholdValue < 0) {
        return createError({ path: valuePath, message: 'Ein negativer Wert ist nicht zulässig' })
      } else if (value.thresholdValue > 100) {
        return createError({ path: valuePath, message: 'Die Auslastung am Hausanschluss darf nicht über 100 liegen' })
      }
    } else if (value.threshold === 'lessThanThreshold') {
      if (isNaN(value.thresholdValue)) {
        return createError({ path: valuePath, message: 'Die Eingabe muss eine Zahl sein' })
      } else if (value.thresholdValue < 0) {
        return createError({ path: valuePath, message: 'Ein negativer Wert ist nicht zulässig' })
      } else if (value.thresholdValue > 999.99) {
        return createError({ path: valuePath, message: 'Die Eingabe darf maximal 999,99 EURO / MWh betragen' })
      } else if (value.thresholdValue.toString()
        .includes('.') ? value.thresholdValue.toString()
        .split('.')[1].length > 2 : false) {
        return createError({ path: valuePath, message: 'Die Eingabe maximal 2 Nachkommastellen haben' })
      }
    }
    return true
  })
})

export const digitalOutputValidationSchema = Yup.object({
  name: Yup.string('Der Name darf nicht leer sein')
    .min(1, 'Der Name darf nicht leer sein')
    .required('Der Name darf nicht leer sein'),
  rules: Yup
    .array()
    .of(Yup.object()
      .shape({
        values: Yup.array()
          .of(Yup.object()
            .singleRuleValue(),
          ),

      }),
    ),
  minRunningTimeInMinutes: Yup
    .number('Die minimale Laufzeit muss eine Zahl sein')
    .min(0, 'Ein negativer Wert ist nicht zulässig')
    .max(999, 'Die minimale Laufzeit darf nicht über 999 Minuten liegen'),
  waitingTimeBeforeRestartInMinutes: Yup
    .number('Die Wartezeit vor Neustart muss eine Zahl sein')
    .min(0, 'Ein negativer Wert ist nicht zulässig')
    .max(999, 'Die Wartezeit vor Neustart darf nicht über 999 Minuten liegen'),
  loadLimit: Yup
    .object()
    .shape({
      values: Yup.array()
        .of(Yup.object()
          .shape({
            thresholdValue: Yup
              .number('Die Auslastung am Hausanschluss muss eine Zahl sein')
              .min(0, 'Ein negativer Wert ist nicht zulässig')
              .max(100, 'Die Auslastung am Hausanschluss darf nicht über 100% liegen')
              .required('Die Auslastung am Hausanschluss muss eine Zahl sein'),
          })),
    }),
})

export const chargingSuggestionValidationSchema = Yup.object({
  powerSurplus: Yup
    .object()
    .shape({
      values: Yup.array()
        .of(Yup.object()
          .singleRuleValue(),
        ),

    }),
  powerSpotPrice: Yup
    .object()
    .shape({
      values: Yup.array()
        .of(Yup.object()
          .singleRuleValue(),
        ),

    }),

})