import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Card, colors } from '@mui/material'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Grid from '@mui/material/Grid'
import { GradientCircularProgress } from 'react-circular-gradient-progress'

const SmallLabelBox = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))

const ValueLabel = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  color: theme.palette.primary.alternateContrastText,
}))

const PowerLabel = ({ total, uom, label, PowerLimit, PowerLimitUom, chargingStrategy, pvPossible }) => {
  const theme = useTheme()

  const getTotal = (val) => {
    try {
      return Math.abs(val)
        .toFixed(1)
        .toString()
        .replace('.', ',')
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <Card
      elevation={2}
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 2,
      }}
    >
      <Grid container>
        <Grid item xs={2}/>
        <Grid item xs={8}>
          <Typography
            align={'center'}
            sx={{
              height: '100%',
              fontWeight: '600',
              marginBottom: 2,
            }}
            variant={'h5'}>
            {'Aktuelle '}{label}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {pvPossible && <div>
            {(chargingStrategy === 'pvGuided') && total >= 0 &&
              <div align={'end'}><WbSunnyIcon style={{ fill: 'rgba(185, 185, 185,1)' }}/></div>}
            {(chargingStrategy === 'pvGuided') && total < 0 &&
              <div align={'end'}><WbSunnyIcon style={{ fill: 'rgba(249, 182, 16,1)' }}/></div>}
          </div>}
        </Grid>
      </Grid>

      {total !== undefined && PowerLimit !== undefined && (
        <Box position="relative" display="inline-flex" sx={{ marginBottom: 2 }}>
          {(total < 0.5 && total > -0.5) ? (
            <div>
                <GradientCircularProgress
                  startColor={colors.grey[200]}
                  middleColor={colors.grey[200]}
                  endColor={colors.grey[200]}
                  emptyColor={colors.grey[200]}
                  strokeWidth={2}
                  size={250}
                  progress={Number(total)}
                >
                <></>
              </GradientCircularProgress>
            </div>
          ) : (
            <div>
              {total < 0 ? (
                <GradientCircularProgress
                  progress={Number(Math.max(0, 100 - (Math.abs(total) / PowerLimit * 100)))}
                  strokeWidth={2}
                  size={250}
                  startColor={colors.grey[200]}
                  middleColor={colors.grey[200]}
                  endColor={colors.grey[200]}
                  emptyColor={colors.yellow[500]}
                >
                  <></>
                </GradientCircularProgress>

              ) : (
                <GradientCircularProgress
                  progress={total > 0 ? Number(total / PowerLimit * 100) : 0}
                  strokeWidth={2}
                  size={250}
                  startColor={theme.palette.circularGradient.start}
                  middleColor={theme.palette.circularGradient.middle}
                  endColor={theme.palette.circularGradient.end}
                  emptyColor={colors.grey[200]}
                >
                  <></>
                </GradientCircularProgress>
              )
              }</div>
          )}
          <Box
            top={10}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
              <Typography sx={{
                height: '100%',
                fontSize: '75px',
                paddingBottom: '20px',
                color: 'primary.alternateContrastText',
              }}>
                {getTotal(total)}
              </Typography>
              <ValueLabel>
                {uom}
              </ValueLabel>
            </div>
            <SmallLabelBox>
              <ValueLabel>
                /{Math.round(PowerLimit)}{' '}{PowerLimitUom}
              </ValueLabel>
            </SmallLabelBox>
          </Box>
        </Box>
      )}
      <Typography variant={'body2'}>
        {'Verfügbare '}{label}{': '}{Math.round(PowerLimit) - Math.round(total) > 0 ? Math.round(PowerLimit) - Math.round(total) : 0}{' '}{uom}
      </Typography>
    </Card>
  )
}

export default PowerLabel
