import * as yup from 'yup'
import { CONTROLLER_OVERLOAD, ERROR_NAME_EMPTY } from './ChargingPointVersionOneConstants'

const controllerOverloadChecker = (chargingPoints, controllerId) => {
  let count = 0
  if (chargingPoints) {
    chargingPoints.forEach((chargingPoint) => {
      if (chargingPoint.controllerId === controllerId) {
        count++
      }
    })
  }
  return count >= 12
}

export const chargingPointVersionOneValidation = (chargingPoints) => yup.object({
  name: yup.string()
    .required(ERROR_NAME_EMPTY),
  controllerId: yup.number()
    .required(ERROR_NAME_EMPTY)
    .test('CONTROLLER_OVERLOAD', CONTROLLER_OVERLOAD, function (controllerId) {
      return !controllerOverloadChecker(chargingPoints, controllerId)
    }),
})
