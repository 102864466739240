const newConfigSchemaBuilder = () => {
  return ({
      'type': 'object',
      'properties': {
        'numberOfControllers': {
          'type': 'number',
          'default': 1,
          'title': 'Anzahl Ladepunktkontroller',
          'enum': [
            0,
            1,
            2,
            3,
            4,
          ],
          'enumNames': [
            0,
            1,
            2,
            3,
            4,
          ],
        },
        'lmsMode': {
          'type': 'string',
          'title': 'Lademodus',
          'default': 'off',
          'enum': [
            'automatic',
            'off',
            'service',
          ],
          'enumNames': [
            'Ein',
            'Aus',
            'Service',
          ],
        },
      },
    }

  )
}

export default newConfigSchemaBuilder