import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useNavigate } from 'react-router'
import { ListItemButton } from '@mui/material'
import { DateTime } from 'luxon'

const ProcessList = ({ data, url }) => {
  const navigate = useNavigate()

  return (
    <List>
      {data && data.length > 0 && data.map((entry, index) => (
          <React.Fragment key={index}>
            <ListItemButton key={index}
                            button={!!entry.id}
                            onClick={() => navigate(`${url}/edit/${entry.id}`, { state: { process: entry } })}
            >
              <ListItemText key={index}
                            primary={
                              <Typography variant={'h6'} color="inherit">
                                {DateTime.fromISO(entry.creationDate)
                                  .toFormat('dd.MM.yyyy - HH:mm')}
                              </Typography>
                            }
                            secondary={
                              <Typography color="inherit" display={'inline'}
                                          style={{ whiteSpace: 'pre-line' }}>
                                Grund: {entry.reason}{'\n'}
                                Bemerkung: {entry.comments}{'\n'}
                                Erstellt von: {entry.responsible}
                              </Typography>
                            }/>
            </ListItemButton>
            <Divider component="li"/>
          </React.Fragment>
        ),
      )}
    </List>
  )
}

export default ProcessList