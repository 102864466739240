import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

const SystemStatus = ({ systemStatus, lmsMode }) => {
  try {
    switch (systemStatus?.status) {
      case 'STARTING':
        return <Typography component={'span'} sx={{ color: 'success.main' }} display={'inline'}
                           variant={'body2status'}>Startet {lmsModeString(lmsMode)}</Typography>
      case 'RUNNING' :
        return <Typography component={'span'} sx={{ color: 'success.main' }} display={'inline'}
                           variant={'body2status'}>Läuft {lmsModeString(lmsMode)}</Typography>
      case 'ERROR':
        return <Typography component={'span'} color={'error'} display={'inline'}
                           variant={'body2status'}>Störung</Typography>
      case 'PAUSED':
        return <Typography component={'span'} sx={{ color: 'warning.main' }} display={'inline'}
                           variant={'body2status'}>Angehalten</Typography>
      case 'UNAUTHORIZED':
        return <Typography component={'span'} sx={{ color: 'warning.main' }} display={'inline'}
                           variant={'body2status'}>Gesperrt</Typography>
      case 'UNKNOWN':
      default:
        return <Typography component={'span'} color={'secondary'} display={'inline'}
                           variant={'body2status'}>Unbekannt</Typography>
    }
  } catch (e) {
    throw new Error(e)
  }
}

const lmsModeString = (lmsMode) => {
  if (lmsMode === 'service') {
    return '(Service)'
  } else {
    return ''
  }
}

SystemStatus.propTypes = {
  className: PropTypes.string,
}

export default SystemStatus
