import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBackdrop, LoadMoreButton, Poller } from '../../components'
import MessagesList from './MessagesList'
import MessagesTable from './MessagesTable'
import { getRoles } from '../../security/roles'
import { Typography } from '@mui/material'
import { useParams } from 'react-router'
import TranslateMessage from './utils/TranslateMessage'
import { useTheme } from '@mui/material/styles'
import MessageDialog from './MessageDialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { fetchLastSystemMessages, fetchMoreSystemMessages } from '../../store'

const MessagesContainer = () => {

  const messages = useSelector(state => state.messages.systemMessageList)
  const payload = useSelector(state => state.messages.systemMessagePayload)
  const userRoles = useSelector(state => getRoles(state))
  const { clientId } = useParams()
  const theme = useTheme()
  const dispatch = useDispatch()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState(false)
  const [loadedCurrent, setLoadedCurrent] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [loadingMore, setLoadingMore] = useState(false)

  const SHOW_OLD_MESSAGES = 'Ältere Meldungen anzeigen'
  const SHOW_MORE_MESSAGES = 'Weitere Meldungen anzeigen'

  const onOpen = (item) => {
    try {
      setDialogOpen(true)
      setSelectedItem(item)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onClose = () => {
    try {
      setDialogOpen(false)
      setSelectedItem(null)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onLoadCurrentMore = (cursor) => {
    try {
      setLoadingMore(true)
      dispatch(fetchMoreSystemMessages({ clientId: clientId, cursor: cursor, latest: true }))
    } catch (e) {
      throw new Error(e)
    }
  }

  const onLoadMore = (cursor) => {
    try {
      if (!cursor) {
        dispatch(fetchMoreSystemMessages({ clientId: clientId, latest: false }))
      } else {
        dispatch(fetchMoreSystemMessages({ clientId: clientId, cursor: cursor, latest: false }))
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const onLoadOldFirst = () => {
    try {
      setLoadedCurrent(true)
      setLoadingMore(true)
      dispatch(fetchMoreSystemMessages({ clientId: clientId, latest: false }))

    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    try {
      setLoading(true)
      setLoadingMore(false)
      setDialogOpen(false)
      setSelectedItem(null)

      if (clientId) {
        dispatch(fetchLastSystemMessages({ clientId: clientId }))
      }
      setLoading(false)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Poller action={() => {
        if (loadingMore === false) {
          dispatch(fetchLastSystemMessages({ clientId: clientId }))
        }
      }}/>
      {(messages.length === 0) ? (
        <div>
          <Typography variant={'h4'} align={'center'} sx={{
            paddingTop: '50px',
            paddingBottom: '10px',
            fontWeight: '750',
          }}
          >
            Es gibt keine aktuellen Meldungen
          </Typography>
        </div>
      ) : (
        <div>
          {smallScreen ?
            <MessagesList messages={messages} translate={TranslateMessage} onOpen={onOpen}
                          userRoles={userRoles}/>
            :
            <MessagesTable messages={messages} translate={TranslateMessage} onOpen={onOpen}
                           userRoles={userRoles}/>
          }
        </div>
      )}
      <MessageDialog dialogOpen={dialogOpen} onClose={onClose} item={selectedItem}/>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '18px',
      }}>
        {payload?.last ?
          (!loadedCurrent &&
            <LoadMoreButton
              onClick={() => onLoadOldFirst()}
              label={SHOW_OLD_MESSAGES}
            />
          )
          :
          (loadedCurrent ? (
              <LoadMoreButton
                onClick={() => onLoadMore(payload.pageable?.cursor)}
                label={SHOW_MORE_MESSAGES}
              />
            ) : (
              <LoadMoreButton
                onClick={() => onLoadCurrentMore(payload.pageable.cursor)}
                label={SHOW_MORE_MESSAGES}
              />
            )
          )}
      </div>
    </React.Fragment>
  )
}

export default MessagesContainer