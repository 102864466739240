import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import { useNavigate } from 'react-router-dom'
import stationEditHelpText from '../../help-texts/FL_Ladestationen_bearbeiten.md'
import EditLms from './EditLms'
import backendApiClient from '../../apiClient/apiClient'

const EditLmsPage = ({ roles }) => {

  const navigate = useNavigate()
  const [editStationHelpText, setEditStationHelpText] = useState()
  const [groups, setGroups] = useState([])
  const COMPONENT_GROUP_URL = '/api/client/{client}/configuration/groups'

  useEffect(() => {
    fetch(stationEditHelpText)
      .then(res => res.text())
      .then(text => setEditStationHelpText(text))
  }, [])

  const fetchGroupData = (clientId, setLoading) => {
    setLoading(true)
    const url = COMPONENT_GROUP_URL.replace('{client}', clientId)
    backendApiClient.get(url)
      .then((response) => {
        setGroups(response.map((group) => group.groupName))
      })
      .catch(() => {
        setGroups([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Page viewName={'Ladestation'}
          pageTitle={'Ladestationen'}
          onBack={() => navigate(-1)}
          helpTitle={'Ladestation ändern'}
          helpText={editStationHelpText}
          requiredRoles={roles}>
      <EditLms fetchGroupData={fetchGroupData} groups={groups} onAfterSubmit={() => navigate(-1)}/>
    </Page>
  )
}

export default EditLmsPage
