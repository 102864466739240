import React, { useEffect, useState } from 'react'
import backendApiClient from '../../apiClient/apiClient'
import { LoadingBackdrop } from '../../components'
import { useTheme } from '@mui/material/styles'
import ProcessTable from './ProcessTable'
import ProcessList from './ProcessList'
import { useLocation, useParams } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'

const ProcessContainer = () => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { clientId } = useParams()
  const url = useLocation().pathname

  const apiUrl = '/api/client/' + clientId + '/events'

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(apiUrl)
      .then((response) => {
        setData(response.content)
        setLoading(false)
      })
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ marginLeft: -1 }}>
      <LoadingBackdrop open={loading}/>
      {smallScreen ?
        <ProcessList data={data} url={url}/>
        :
        <ProcessTable data={data} url={url}/>
      }
    </div>
  )
}

ProcessContainer.propTypes = {}

export default ProcessContainer