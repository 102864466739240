import React from 'react'
import { Page } from '../../components'
import FlotteladenImprint from '../../components/Imprints/FlotteladenImprint'
import AmperfiedImprint from '../../components/Imprints/AmperfiedImprint'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const AmperfiedDiv = styled('div')(({ theme }) => ({
  display: theme.displayAmperfied,
}))

const FlotteladenDiv = styled('div')(({ theme }) => ({
  display: theme.displayFlotteladen,
}))

const ImprintPage = () => {
  return (
    <RootDiv>
      <Page viewName={'Impressum'}>
        <FlotteladenDiv>
          <FlotteladenImprint/>
        </FlotteladenDiv>
        <AmperfiedDiv>
          <AmperfiedImprint/>
        </AmperfiedDiv>
      </Page>
    </RootDiv>
  )
}

export default ImprintPage
