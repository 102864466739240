/**
 * all known message types
 */
const messagesTypeTranslations = {
  SYSTEM_STARTUP: 'Ladestation wird gestartet',
  CONFIGURATION_CHANGE: 'Konfiguration wurde geändert',
  CHARGE_CONTROL_STRATEGY_PV_STARTING: 'Photovoltaik-Überschussladung aktiviert',
  CHARGE_CONTROL_STRATEGY_PV_STOPPED: 'Photovoltaik-Überschussladung deaktiviert',
  CHARGE_CONTROL_STRATEGY_STARTING: 'Automatik-Lademodus aktiviert',
  CHARGE_CONTROL_STRATEGY_STOPPED: 'Automatik-Lademodus gestoppt',
  CHARGING_POINT_POLICY_CHANGE: 'Ladepunkt Freigabe geändert',
  CHARGING_POINT_AUTHORIZATION_CHANGE: 'Ladepunkt Berechtigung geändert',
  CHARGING_POINT_ADDED: 'Neuer Ladepunkt wurde gefunden',
  CHARGING_POINT_IP_ALREADY_ASSIGNED: 'IP Adresse für Ladepunkt wird bereits von anderem Ladepunkt benutzt',
  CHARGING_POINT_IP_UPDATE: 'IP Adresse von Ladepunkt geändert',
  EMS_CONFIGURATION_CHANGE: 'EMS-Konfiguration wurde geändert',
}

const TranslateMessage = (message) => {

  return messagesTypeTranslations[message.messageType] || message.message || message.messageType

}

export default TranslateMessage