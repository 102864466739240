import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import frontendLicenses from '../../legal-texts/firstLicense.txt'
import backendLicenses from '../../legal-texts/secondLicense.txt'
import lmsLicenses from '../../legal-texts/thirdLicense.txt'
import modbusLicenses from '../../legal-texts/fourthLicense.txt'
import ocppLicenses from '../../legal-texts/fifthLicense.txt'
import revPiLicenses from '../../legal-texts/sixthLicense.txt'
import udpLicenses from '../../legal-texts/seventhLicense.txt'
import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const LicensesPage = ({}) => {
  const [frontendText, setFrontendText] = useState('')
  const [backendText, setBackendText] = useState('')
  const [lmsText, setLmsText] = useState('')
  const [modbusText, setModbusText] = useState('')
  const [ocppText, setOcppText] = useState('')
  const [revPiText, setRevPiText] = useState('')
  const [udpBridgeText, setUdpBridgeText] = useState('')

  const COCKPIT_HEADER = 'Cockpit'
  const LMS_HEADER = 'Lademanagementsystem'

  const outsideTextFirstPart = 'Die Lizenzbedingungen für den RevPi stehen unter '
  const outsideLink = 'https://revolutionpi.de/tutorials/downloads/#releasenotes'
  const outsideTextSecondPart = ' zur Verfügung'

  const copyright = `Copyright \u00A9 ${new Date().getFullYear()} Flotteladen GmbH.`
  const rightsReserved = 'Alle Rechte vorbehalten.'
  const copyrightHolderNotice = 'Dieses Produkt enthält Grafiken und Komponenten der folgenden Rechteinhaber:'

  useEffect(() => {
    fetch(frontendLicenses)
      .then(res => res.text())
      .then(text => setFrontendText(text))
      .catch(error => throw new Error(error))
    fetch(backendLicenses)
      .then(res => res.text())
      .then(text => setBackendText(text))
      .catch(error => throw new Error(error))
    fetch(lmsLicenses)
      .then(res => res.text())
      .then(text => setLmsText(text))
      .catch(error => throw new Error(error))
    fetch(modbusLicenses)
      .then(res => res.text())
      .then(text => setModbusText(text))
      .catch(error => throw new Error(error))
    fetch(ocppLicenses)
      .then(res => res.text())
      .then(text => setOcppText(text))
      .catch(error => throw new Error(error))
    fetch(revPiLicenses)
      .then(res => res.text())
      .then(text => setRevPiText(text))
      .catch(error => throw new Error(error))
    fetch(udpLicenses)
      .then(res => res.text())
      .then(text => setUdpBridgeText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div style={{
        padding: '8px',
      }}>
        <Page viewName={'Software-Lizenzen'}>

          <Typography variant="h3">
            {copyright}
          </Typography>
          <Typography variant="h4">
            {rightsReserved}
          </Typography>
          <br/>
          <Typography variant="body1">
            {copyrightHolderNotice}
          </Typography>
          <br/>

          <Accordion slotProps={{ transition: { timeout: 500 } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              <Typography>{COCKPIT_HEADER}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Markdown remarkPlugins={[remarkGfm]}>{frontendText}</Markdown>
              <Markdown remarkPlugins={[remarkGfm]}>{backendText}</Markdown>
            </AccordionDetails>
          </Accordion>

          <Accordion slotProps={{ transition: { timeout: 500 } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              <Typography>{LMS_HEADER}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                {outsideTextFirstPart}
                <Link
                  color={'#0000EE'}
                  component="a"
                  href={outsideLink}>
                  {outsideLink}
                </Link>
                {outsideTextSecondPart}
              </Typography>
              <Markdown remarkPlugins={[remarkGfm]}>{lmsText}</Markdown>
              <Markdown remarkPlugins={[remarkGfm]}>{modbusText}</Markdown>
              <Markdown remarkPlugins={[remarkGfm]}>{ocppText}</Markdown>
              <Markdown remarkPlugins={[remarkGfm]}>{revPiText}</Markdown>
              <Markdown remarkPlugins={[remarkGfm]}>{udpBridgeText}</Markdown>
            </AccordionDetails>
          </Accordion>
        </Page>
      </div>
    </React.Fragment>
  )
}

export default LicensesPage
