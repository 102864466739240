import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { backendApiClient } from '../../../../apiClient'
import { getRoles, isServicePerson } from '../../../../security/roles'
import { useSelector } from 'react-redux'
import CpTable from './CpTable'
import { LoadingBackdrop, NavigateFab } from '../../../../components'
import CpList from './CpList'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../lmsVersionTwo/ChargingPointVersionTwoConstants'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { sortPoints } from '../../../../utils'

const ChargingPointsScreen = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(false)
  const [chargingPoints, setChargingPoints] = useState([])

  const CHARGING_POINTS_URL = '/api/client/{client}/chargingpoints'
  const URL_CP_RESTART = '/api/client/{client}/chargingpoint/{chargingPointId}/restart'
  const URL_UPDATE = '/api/client/{client}/configuration/chargingpoints/{chargingpoints}'
  const URL_UPDATE_EXTENDER = '/api/client/{client}/configuration/chargingpoints/{chargingPointId}/extender/{chargingPointExtenderId}'
  const { clientId } = useParams()
  const { pathname } = useLocation()

  const { enqueueSnackbar } = useSnackbar()
  const chargingPointsUrl = CHARGING_POINTS_URL.replace(/{client}/g, clientId)

  const { customer } = useRouteLoaderData('clientRoot')
  const { settings } = useRouteLoaderData('settingsRoot')
  const userRoles = useSelector(state => getRoles(state))

  const fetchData = () => {
    setLoading(true)

    backendApiClient.get(chargingPointsUrl)
      .then((response) => {
        setChargingPoints(sortPoints(response))
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  const onActivate = (point, extender) => {
    if (point.areaId === null) {
      enqueueSnackbar('Um den Ladepunkt zu aktivieren, müssen sie dem Ladepunkt zuerst eine Zone zuordnen!', {
        variant: 'error',
      })
      return
    }
    if (extender) {
      extender.activated = !extender.activated
      let url = URL_UPDATE_EXTENDER
        .replace(/{client}/g, clientId)
        .replace(/{chargingPointId}/g, point.id)
        .replace(/{chargingPointExtenderId}/g, extender.id)
      putUrl(url, extender)
    } else {
      point.activated = !point.activated
      let url = URL_UPDATE
        .replace(/{client}/g, clientId)
        .replace(/{chargingpoints}/g, point.id)
      putUrl(url, point)
    }
  }

  const onRestart = (cp) => {
    let url = URL_CP_RESTART
      .replace(/{client}/g, clientId)
      .replace(/{chargingPointId}/g, cp.id)
    backendApiClient.put(url, {})
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
        fetchData()
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const putUrl = (url, data) => {
    backendApiClient.put(url, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
        fetchData()
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const editRedirect = (chargingPoint) => {
    navigate('./edit/' + chargingPoint.id, { state: { chargingPoint: chargingPoint } })
  }

  const editExtenderRedirect = (id, idExtender) => {
    navigate(pathname + '/edit/' + id + '/extender/' + idExtender)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      {smallScreen ?
        <CpList chargingPoints={chargingPoints}
                onEdit={editRedirect}
                onActivate={onActivate}
                onRestart={onRestart}
                pvSettings={settings.pvSettings}
                hasPv={customer?.hasPv}
                version={customer.lmsVersion}
        />
        :
        <CpTable chargingPoints={chargingPoints}
                 onEditExtender={editExtenderRedirect}
                 onEdit={editRedirect}
                 onActivate={onActivate}
                 onRestart={onRestart}
                 pvSettings={settings.pvSettings}
                 hasPv={customer?.hasPv}
                 version={customer.lmsVersion}
        />
      }
      {isServicePerson(userRoles) ?
        <NavigateFab
          path={`${pathname}/new`}
          text={'neu'}
        >
          <AddIcon/>
        </NavigateFab>
        :
        ''
      }
    </React.Fragment>
  )
}

export default ChargingPointsScreen