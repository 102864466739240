import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { LoadingBackdrop, Poller } from '../../components'
import Typography from '@mui/material/Typography'
import DashboardHeader from './components/DashboardHeader'
import MeterLabel from './components/MeterLabel'
import PowerLabel from './components/PowerLabel'
import ChargingPointLabel from './components/ChargingPointLabel'
import PowerHistoryChartCard from './components/PowerHistoryChartCard'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { getRoles } from '../../security/roles'
import { backendApiClient } from '../../apiClient'
import useMediaQuery from '@mui/material/useMediaQuery'
import { fetchMetrics, fetchMetricsHistoryForChargingPowerMeter, fetchMetricsHistoryForMainPowerMeter } from '../../store/metrics/metricsSlice'

const PointsHeader = styled(Typography)({
    fontWeight: '700',
    paddingBottom: '19px',
    paddingTop: '14px',
  },
)

const PointsGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(4),
  },
}))

const DashboardContainer = () => {

  const userRoles = useSelector(state => getRoles(state))
  const metrics = useSelector(state => state.metrics.metrics)
  const metricsHistory = useSelector(state => state.metrics.metricsHistory)

  const [hasChartData, setHastChartData] = useState(false)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { clientId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])
  const [lmsMode, setLmsMode] = useState('')

  const BASIC = 'basic'
  const GRID_POWER = 'Netzleistung'
  const GRID_COUNTER = 'Netzzähler'
  const CHARGING_POINT = 'Ladepunkte'
  const CHARGING_POWER = 'Ladeleistung'
  const CHARGING_COUNTER = 'Ladezähler'
  const url = '/api/client/' + clientId + '/configuration'

  const dispatch = useDispatch()

  const { customer } = useRouteLoaderData('clientRoot')

  const fetchData = () => {

    try {
      dispatch(fetchMetrics(clientId))
      dispatch(fetchMetricsHistoryForMainPowerMeter(clientId))
      dispatch(fetchMetricsHistoryForChargingPowerMeter(clientId))
    } catch (e) {
      throw new Error(e)
    }
  }

  const itemToPoint = (item) => {
    return { x: item.timestamp, y: item.total }
  }

  const fetchConfig = () => {
    backendApiClient.get(url)
      .then((response) => {
        setLmsMode(response.lmsMode)
      })
      .catch((error) => {
        if (error._response.statusCode === 404) {
          navigate('../settings', { relative: 'path' })
        }
      })
  }

  useEffect(() => {
    if (clientId) {
      setLoading(true)
      fetchData()
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  useEffect(() => {
    setLoading(true)
    fetchConfig()
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    try {

      let temp = []

      temp.push({
        'id': CHARGING_POWER,
        'data': metricsHistory.chargingPowerMeter.map(itemToPoint),
      })

      if (metricsHistory.mainPowerMeter.map((item) => itemToPoint(item)).length > 0) {
        temp.push({
          'id': GRID_POWER,
          'data': metricsHistory.mainPowerMeter.map(itemToPoint),
        })
      }
      setHastChartData(metricsHistory.chargingPowerMeter.length > 0 || metricsHistory.mainPowerMeter.length > 0)
      setChartData(temp)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsHistory])

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>

      {smallScreen ?
        <Grid container spacing={2} sx={{ marginTop: -3 }}>
          <Poller action={() => dispatch(fetchMetrics(clientId))} interval={30000}/>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <DashboardHeader connectionStatus={metrics?.connectionStatus}
                             systemStatus={metrics?.systemStatus}
                             chargingPoints={metrics?.chargingPoints}
                             lmsMode={lmsMode}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginRight: -3 }}>
            {(metrics?.chargingStrategy !== BASIC) &&
              <Grid item sm={6} xs={12}>
                <MeterLabel
                  label={GRID_COUNTER}
                  total={metrics.gridWork?.total}
                  uom={metrics.gridWork?.uom}
                />
              </Grid>}
            <PointsGrid item sm={6} xs={12}>
              <MeterLabel
                label={CHARGING_COUNTER}
                total={metrics.chargingWork?.total}
                uom={metrics.chargingWork?.uom}
              />
            </PointsGrid>
            <PointsGrid item xs={12}>
              <PointsHeader>
                {CHARGING_POINT}
              </PointsHeader>
              {metrics.chargingPoints && metrics.chargingPoints.map((point) => (
                <ChargingPointLabel chargingPoint={point} key={point.id} fetchData={fetchData} connectionStatus={metrics?.connectionStatus}
                                    clientId={clientId} hasEms={customer?.hasEms} userRoles={userRoles} systemStatus={metrics?.systemStatus?.status}
                                    versionTwoOrNewer={metrics?.systemStatus?.parameters?.lmsVersion > 1}/>
              ))}
            </PointsGrid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <PowerLabel
              label={CHARGING_POWER}
              total={metrics.chargingPower?.total}
              uom={metrics.chargingPower?.uom}
              PowerLimit={metrics.chargingPowerLimit?.value}
              PowerLimitUom={metrics.chargingPowerLimit?.uom}
              chargingStrategy={metrics.chargingStrategy}
            />
          </Grid>
          {(metrics?.chargingStrategy !== BASIC) && (
            <Grid item sm={12} xs={12}>
              <PowerLabel
                label={GRID_POWER}
                total={metrics.gridPower?.total}
                uom={metrics.gridPower?.uom}
                PowerLimit={metrics.gridPowerLimit?.value}
                PowerLimitUom={metrics.gridPowerLimit?.uom}
                chargingStrategy={metrics.chargingStrategy}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PowerHistoryChartCard
              hasData={hasChartData}
              data={chartData}
              gridPowerLimit={metrics.gridPowerLimit?.value}
              chargingPowerLimit={metrics.chargingPowerLimit?.value}
            />
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} sx={{ marginTop: -3 }}>
          <Poller action={() => dispatch(fetchMetrics(clientId))} interval={30000}/>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <DashboardHeader connectionStatus={metrics?.connectionStatus}
                             systemStatus={metrics?.systemStatus}
                             chargingPoints={metrics?.chargingPoints}
                             lmsMode={lmsMode}
            />
          </Grid>
          <Grid item sm={8} xs={12} sx={{ marginRight: -2 }}>
            {(metrics?.chargingStrategy !== BASIC) && (
              <Grid item container xs={12} spacing={4} justifyContent={'space-between'}
                    sx={{ paddingRight: '32px' }}>
                <Grid item sm={6} xs={12}>
                  <MeterLabel
                    label={GRID_COUNTER}
                    total={metrics.gridWork?.total}
                    uom={metrics.gridWork?.uom}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MeterLabel
                    label={CHARGING_COUNTER}
                    total={metrics.chargingWork?.total}
                    uom={metrics.chargingWork?.uom}
                  />
                </Grid>
              </Grid>
            )}
            {(metrics?.chargingStrategy === BASIC) && (
              <div style={{ marginBottom: '-30px' }}/>
            )}
            <PointsGrid item xs={12}>
              <PointsHeader variant={'h4'}>
                {CHARGING_POINT}
              </PointsHeader>
              {metrics.chargingPoints?.map((point) => (
                <ChargingPointLabel
                  connectionStatus={metrics?.connectionStatus}
                  systemStatus={metrics?.systemStatus?.status}
                  chargingPoint={point}
                  key={point.id}
                  fetchData={fetchData}
                  clientId={clientId}
                  hasEms={customer?.hasEms}
                  userRoles={userRoles}
                  versionTwoOrNewer={metrics?.systemStatus?.parameters?.lmsVersion > 1}/>
              ))}
            </PointsGrid>
          </Grid>
          <Grid item container sm={4} xs={12} alignContent={'flex-start'} spacing={3}>
            {(metrics?.chargingStrategy === 'basic') && (
              <Grid item xs={12} sx={{ marginTop: '42px' }}>
                <MeterLabel
                  label={CHARGING_COUNTER}
                  total={metrics.chargingWork?.total}
                  uom={metrics.chargingWork?.uom}
                />
              </Grid>
            )}
            <Grid item sm={12} xs={12}>
              <PowerLabel
                label={CHARGING_POWER}
                total={metrics.chargingPower?.total}
                uom={metrics.chargingPower?.uom}
                PowerLimit={metrics.chargingPowerLimit?.value}
                PowerLimitUom={metrics.chargingPowerLimit?.uom}
                chargingStrategy={metrics.chargingStrategy}
              />
            </Grid>
            {(metrics?.chargingStrategy !== BASIC) && (
              <Grid item sm={12} xs={12}>
                <PowerLabel
                  pvPossible={customer?.hasPv}
                  label={GRID_POWER}
                  total={metrics.gridPower?.total}
                  uom={metrics.gridPower?.uom}
                  PowerLimit={metrics.gridPowerLimit?.value}
                  PowerLimitUom={metrics.gridPowerLimit?.uom}
                  chargingStrategy={metrics.chargingStrategy}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <PowerHistoryChartCard
                hasData={hasChartData}
                data={chartData}
                gridPowerLimit={metrics.gridPowerLimit?.value}
                chargingPowerLimit={metrics.chargingPowerLimit?.value}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}

export default DashboardContainer