import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import TagWidget from './TagWidget'
import CardsNewHelpText from '../../../help-texts/FL_Authorization_Cards_New.md'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import Page from '../../../components/Page/Page'

const NewTagPage = () => {
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const [chargingPoints, setChargingPoints] = useState([])
  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState()

  const ID_TOKENS_URL = `/api/client/${clientId}/id-tokens`
  const CP_URL = `/api/client/${clientId}/chargingpoints`

  const TITLE = 'Ladekarte hinzufügen'

  useEffect(() => {
    fetchChargingPoints()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(CardsNewHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchChargingPoints = () => {
    setLoading(true)
    backendApiClient.get(CP_URL)
      .then((response) => {
        setChargingPoints(response)
      })
      .catch((e) => throw new Error(e))
      .finally(() => setLoading(false))
  }

  const save = (data, actions) => {
    backendApiClient.post(ID_TOKENS_URL, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, { variant: 'success' })
        navigate(-1)
      })
      .catch((error) => {
        console.log(error.response?.status)
        if (error._response.statusCode === 409) {
          actions.setErrors({ value: 'Karten-ID bereits vergeben' })
        } else {
          enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
        }
      })
  }

  return (
    <Page viewName={TITLE} pageTitle={TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={TITLE}>
      <TagWidget chargingPoints={chargingPoints} save={save} preSelectedId={location.state?.selectedCPId} loading={loading}/>
    </Page>
  )
}

export default NewTagPage
