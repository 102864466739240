import React from 'react'
import MessageIcon from '@mui/icons-material/Message'
import { Tooltip } from '@mui/material'

const TraceMessageStatusIcon = ({ severity, message }) => {
  if (message && severity) {
    switch (severity) {
      case 'WARNING':
        return (
          <Tooltip title={message}>
            <MessageIcon sx={{ color: 'warning.light' }}/>
          </Tooltip>
        )
      case 'ERROR':
        return (
          <Tooltip title={message}>
            <MessageIcon color={'error'}/>
          </Tooltip>
        )
      default:
        return <></>
    }
  } else {
    return <></>
  }
}

export default TraceMessageStatusIcon
