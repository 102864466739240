import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import { useNavigate, useParams } from 'react-router'
import editProcess from '../../help-texts/FL_Vorgang hinzufügen.md'
import ProcessCustomWidget from '../../components/crud/widgets/ProcessCustomWidget'
import { backendApiClient } from '../../apiClient'
import { useSnackbar } from 'notistack'

const NewProcessPage = () => {
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [helpText, setHelpText] = useState()
  const EDIT_HELP_TITLE = 'Halten Sie Vorgänge fest'

  const PROCESS_URL = `/api/client/${clientId}/events`

  useEffect(() => {
    fetch(editProcess)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data) => {
    backendApiClient.post(PROCESS_URL, data)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
      })
  }

  return (
    <Page viewName={'Vorgang hinzufügen'} onBack={() => navigate(-1)} pageTitle={'Vorgänge'} helpText={helpText}
          helpTitle={EDIT_HELP_TITLE}>
      <ProcessCustomWidget save={save}/>
    </Page>
  )
}

export default NewProcessPage