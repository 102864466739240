import React from 'react'
import Grid from '@mui/material/Grid'
import AggregationMetricGraph from '../AggregationMetricGraph/AggregationMetricGraph'

const AggregationMetricGraphBox = ({ rawData }) => {
  return (
    <Grid containerspacing={2}>
      <Grid
        item
        height={'300px'}
        width={'100%'}>
        <AggregationMetricGraph rawData={rawData}/>
      </Grid>
    </Grid>

  )
}

export default AggregationMetricGraphBox
