import React from 'react'
import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import { useLocation, useNavigate } from 'react-router'

const DigitalOutputsDesktopView = ({ data, toggleActivation }) => {
  const navigate = useNavigate()
  const url = useLocation().pathname

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '20%', borderBottom: 'none' }}>
            Ausgang
          </TableCell>
          <TableCell style={{ width: '65%', borderBottom: 'none' }}>
            Name
          </TableCell>
          <TableCell align={'right'} style={{ borderBottom: 'none' }}>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data && data.map((output) => (
            <TableRow key={output.id}>
              <TableCell>
                {output.id}
              </TableCell>
              <TableCell>
                {output.name}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => {
                  navigate(`${url}/edit`, {
                      replace: false,
                      state: output,
                    },
                  )
                }}>
                  <EditIcon/>
                </IconButton>
                <Checkbox
                  checked={output.activated}
                  onClick={() => toggleActivation(output)}
                />
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

DigitalOutputsDesktopView.propTypes = {
  data: PropTypes.array,
  toggleActivation: PropTypes.func,
}

export default DigitalOutputsDesktopView