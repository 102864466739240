import React from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import ReactMarkdown from 'react-markdown'

const QuickHelpButton = ({ helpText, helpTitle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function Image ({ alt, src, title }) {
    return (
      <img
        alt={alt}
        src={src}
        title={title}
        style={{ maxWidth: 750 }}/>
    )
  }

  const open = Boolean(anchorEl)

  return (
    <React.Fragment>
      <IconButton size={'small'} onClick={handleClick}>
        <HelpIcon sx={{ color: 'primary.alternateContrastText' }}/>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

      >
        <Grid size={12} container justifyContent={'space-between'} direction={'row'} alignItems={'center'} paddingBottom={0}>
          <Box pl={2} pr={2} fontSize={15} fontWeight={700}>{helpTitle}</Box>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon/>
          </IconButton>
        </Grid>
        <div style={{
          maxWidth: 800,
          minWidth: 300,
          paddingLeft: 20,
          paddingRight: 20,
        }}>
          <ReactMarkdown components={{ img: Image }}>
            {helpText}
          </ReactMarkdown>
        </div>
      </Popover>
    </React.Fragment>
  )
}

QuickHelpButton.propTypes = {
  className: PropTypes.string,
}

export default QuickHelpButton