import React, { useState } from 'react'
import { backendApiClient } from '../../apiClient'
import { LoadingBackdrop, SubmitFab } from '../../components'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'
import CustomDropzone from '../../components/Dropzone/CustomDropzone'

const NewDocument = ({ onAfterSubmit }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [name, setName] = useState()
  const [loading, setLoading] = useState(false)

  const { clientId } = useParams()

  const URL = '/api/client/' + clientId + '/documents/'
  const maxFileSize = 10485760 // 10MB

  const saveFile = (saveName) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('name', saveName)
    formData.append('file', file)
    backendApiClient.filePost(URL, formData)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
        setLoading(false)
        onAfterSubmit()
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const changeName = (event) => {
    try {
      setName(event.target.value)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleChange = (files) => {
    try {
      if (files.length > 0) {
        setFile(files[0])
        setFileName(files[0].name)
      } else if (files.length === 0) {
        setFile(undefined)
        setFileName(undefined)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const onSubmit = () => {
    try {
      if (name && name.length > 0 && file) {
        saveFile(name)
      } else if (file) {
        saveFile(fileName)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleReject = (error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  return (
    <React.Fragment>
      <LoadingBackdrop
        open={loading}/>
      <CustomDropzone
        dropzoneText={'Datei hierher ziehen und ablegen oder hier klicken'}
        maxFileSize={maxFileSize}
        onChange={(files) => handleChange(files)}
        onDropRejected={(e) => handleReject(e)}
      />
      <Grid container>
        <Grid
          item
          md={12}
          sx=
            {{
              display: 'flex',
              paddingTop: 2,
              paddingLeft: 1,
            }}
        >
          <Typography variant={'h6'}>
            Optional
          </Typography>
        </Grid>
        <Grid
          container
          item x
          s={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingRight: 2,
            paddingLeft: 1,
          }}
        >
          <Grid item xs={12} md={5}>
            <Typography
              sx={{ width: '540px' }}
            >
              Sie können der Datei einen neuen Namen geben:
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              sx={{ width: '100%' }}
              size={'medium'}
              onChange={changeName}
              label={'Name'}
              type={'text'}
              variant={'standard'}
            />
          </Grid>
        </Grid>
      </Grid>
      <SubmitFab disabled={!file} show onClick={() => onSubmit()}/>
    </React.Fragment>
  )
}

export default NewDocument
