import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import { useNavigate } from 'react-router-dom'
import stationNewHelpText from '../../help-texts/FL_Ladestationen_hinzufügen.md'
import NewLms from './NewLms'
import backendApiClient from '../../apiClient/apiClient'

const NewLmsPage = ({ roles }) => {

  const navigate = useNavigate()
  const [newStationHelpText, setNewStationHelpText] = useState()
  const [groups, setGroups] = useState([])
  const COMPONENT_GROUP_URL = '/api/client/{client}/configuration/groups'

  useEffect(() => {
    fetch(stationNewHelpText)
      .then(res => res.text())
      .then(text => setNewStationHelpText(text))
  }, [])

  const fetchGroupData = (clientId, setLoading) => {
    setLoading(true)
    const url = COMPONENT_GROUP_URL.replace('{client}', clientId)
    backendApiClient.get(url)
      .then((response) => {
        setGroups(response.map((group) => group.groupName))
      })
      .catch(() => {
        setGroups([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Page viewName={'Neue Ladestation'}
          pageTitle={'Ladestationen'}
          onBack={() => navigate(-1)}
          helpTitle={'Ladestation anlegen'}
          helpText={newStationHelpText}
          roles={roles}
    >
      <NewLms fetchGroupData={fetchGroupData} groups={groups} onAfterSubmit={() => navigate(-1)}/>
    </Page>
  )
}

export default NewLmsPage
