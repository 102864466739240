import React, { useState } from 'react'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { IconButton, ListItemSecondaryAction, Typography } from '@mui/material'
import TypDeclaration from '../TypDeclaration/TypDeclaration'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { isServicePerson } from '../../security/roles'
import { RestartAlt } from '@mui/icons-material'

const ChargingPointAccordionMobile = ({ item, userRoles, pvSettings, hasPv, onActivate, version, onEdit, onRestart }) => {
  const [extended, setExtended] = useState(false)
  let isDisconnected = item.controllerConnectionStatus === 'DISCONNECTED'
  const hasExtenders = item.extenders && item.extenders.length > 0

  const getManufacturerName = (manufacturer) => {
    switch (manufacturer) {
      case 'alfen':
        return 'Alfen'
      case 'abl' :
        return 'ABL'
      case 'amperfied':
        return 'AMP'
      default:
        return 'FL'
    }
  }

  const isRestartDisabled = (chargePoint) => {
    return chargePoint.interfaceType !== 'ocpp' || chargePoint.controllerConnectionStatus === 'DISCONNECTED'
  }

  return (
    <React.Fragment>
      <ListItem sx={[isDisconnected && { color: 'text.disabled' }, { marginBottom: 2 }]}>
        <ListItemText
          onClick={() => onEdit(item)}
          primary={
            <Typography variant={'h6'} color="inherit" sx={[isDisconnected && { color: 'text.disabled' }]}>
              {item.name}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography color="inherit" sx={[isDisconnected && { color: 'text.disabled' }]}>
                {version >= 2 ?
                  'Serien-Nr.:' + item.serialNumber
                  :
                  <TypDeclaration type={item.type}/>
                }
              </Typography>
              {version >= 2 &&
                <Typography color="inherit" sx={[isDisconnected && { color: 'text.disabled' }]}>
                  Hersteller: {getManufacturerName(item.manufacturer)}
                </Typography>
              }
              <Typography color="inherit" sx={[isDisconnected && { color: 'text.disabled' }]}>
                {version >= 2 ?
                  item.currentStatus?.info?.firmware === undefined ? 'Firmware:' : 'Firmware: ' + item.currentStatus?.info?.firmware
                  :
                  'Ladeleistungen in kW: ' + item.maxPower.toString()
                    .replace('.', ',') + ' kW'
                }
              </Typography>
              <Typography color="inherit" sx={[isDisconnected && { color: 'text.disabled' }]}>
                Zone: {item.areaName}
              </Typography>
            </React.Fragment>
          }/>

        <ListItemSecondaryAction style={{ top: 32 }}>
          <Checkbox
            disabled={isDisconnected || !isServicePerson(userRoles)}
            checked={item.activated}
            onClick={() => {
              onActivate(item)
            }}
          />
        </ListItemSecondaryAction>

        <ListItemSecondaryAction style={{ top: '100%' }}>
          {hasPv && pvSettings?.enabled &&
            (item.pvGuided ?
                <Grid item>
                  <WbSunnyIcon style={{ marginBottom: '-8px', fill: 'rgba(239,227,49,0.8)' }}/>
                </Grid>
                :
                <Grid item>
                  <WbSunnyIcon style={{ marginBottom: '-8px' }}/>
                </Grid>
            )}
          <IconButton id={`popover-restart-${item.id}`} disabled={isRestartDisabled(item)} onClick={() => onRestart(item)}>
            <RestartAlt/>
          </IconButton>
        </ListItemSecondaryAction>

        <ListItemSecondaryAction style={{ top: '100%' }}>
          {hasPv && pvSettings?.enabled &&
            (item.pvGuided ?
                <Grid item>
                  <WbSunnyIcon style={{ marginBottom: '-8px', fill: 'rgba(239,227,49,0.8)' }}/>
                </Grid>
                :
                <Grid item>
                  <WbSunnyIcon style={{ marginBottom: '-8px' }}/>
                </Grid>
            )}
          <IconButton id={`popover-restart-${item.id}`} disabled={isRestartDisabled(item)} onClick={() => onRestart(item)}>
            <RestartAlt/>
          </IconButton>
        </ListItemSecondaryAction>


      </ListItem>
      {
        hasExtenders &&
        <div style={{ marginTop: -33 }} align={'start'}>
          <IconButton style={{ paddingBottom: 0, marginBottom: 0 }} onClick={() => setExtended(!extended)}>
            <KeyboardArrowDownIcon
              sx={[
                extended ? { transition: 'transform .5s', transform: 'rotate(180deg)' } : { transition: 'transform .5s' },
              ]}
            />
          </IconButton>
        </div>
      }
      {
        hasExtenders && extended &&
        (item.extenders.map((extender) => (
          <div>
            <Divider component="li" sx={{ borderBottomWidth: 1, marginLeft: 2, paddingBottom: 1, marginRight: '30px' }}/>
            <Grid sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 2,
              paddingBottom: 1,
              paddingTop: 2,
            }}>
              <Grid
                item
                xs={12}
              >
                <Typography variant={'h6'} sx={[isDisconnected && { color: 'text.disabled' }]}>
                  {extender.name}
                </Typography>
                <Typography
                  color="inherit"
                  sx={[isDisconnected && { color: 'text.disabled' }]}
                >
                  {!extender.serialNumber.endsWith('*2') &&
                    'Serien-Nr.: ' + extender.serialNumber
                  }
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems={'center'}
                display="flex"
                justifyContent={'flex-end'}
                sx={[isDisconnected && { color: 'text.disabled' }]}
                align={'end'}
                spacing={1}>
                {hasPv && pvSettings?.enabled &&
                  (item.pvGuided ?
                      <Grid item>
                        <WbSunnyIcon style={{ marginBottom: '-8px', fill: 'rgba(239,227,49,0.8)' }}/>
                      </Grid>
                      :
                      <Grid item>
                        <WbSunnyIcon style={{ marginBottom: '-8px' }}/>
                      </Grid>
                  )}
                <Grid item>
                  <Checkbox
                    disabled={isDisconnected || !isServicePerson(userRoles)}
                    checked={item.activated}
                    onClick={() => {
                      onActivate(item)
                    }}
                    sx={{ marginTop: 0, paddingTop: 0, paddingRight: 3 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )))}
      <Divider component="li" sx={{ borderBottomWidth: 1.5 }}/>
    </React.Fragment>
  )
}

export default ChargingPointAccordionMobile