import palette from '../palette'

const MuiSvgIcon = {
  styleOverrides: {
    colorDisabled: {
      color: palette.divider,
    },
  },
}

export default MuiSvgIcon