import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import validate from 'validate.js'
import { TextField } from 'formik-mui'
import { VALIDATION_SCHEMA_EMAIL } from '../../constants'
import { Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'

const schema = {
  email: VALIDATION_SCHEMA_EMAIL,
}

const EmailForm = ({ onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Typography
        sx={{ marginBottom: 3 }}
        variant="h2">
        Passwort zurücksetzen
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1">
        Geben Sie Ihre E-Mail-Adresse an
      </Typography>
      <Formik
        validateOnMount={false}
        initialValues={{
          email: '',
        }}
        validate={values => {
          return validate(values, schema, { format: 'first' })
        }}
        onSubmit={values => {
          onSubmit(values)
          enqueueSnackbar('Verifizierungscode versendet', {
            variant: 'success',
          })
          navigate('/set-password')
        }
        }>
        {({ isSubmitting, isValid }) => (
          <Form>
            <Typography variant={'caption'} sx={{ marginTop: 2 }}>
              E-Mail
            </Typography>
            <Field
              fullWidth
              name="email"
              type="email"
              component={TextField}/>
            <Button
              sx={{ marginTop: 2 }}
              id={'submit'}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting || !isValid}
              variant="contained">
              <Typography sx={(theme) => ({
                transform: theme.skewText,
                color: theme.palette.white,
              })}>
                Verifizierungscode zusenden
              </Typography>
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

EmailForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default EmailForm
