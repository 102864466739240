import React from 'react'
import { Page } from '../../components'

const ReactMarkdown = require('react-markdown')

const HelpPagePage = ({}) => {
  const infoText = `# Infos
  
  Auf der linken Seite befindet sich eine Auswahl aller Themen, für die sie Zugriffsrechte besitzen. Weitere Informationen zu den Seiten finden sie, wenn sie auf der entsprechenden 
  Seite in der oberen rechten Ecke auf das Fragezeichensymbol klicken
  
  `
  const changeLog = `# Historie aller Änderungen
  
  + Zusätzliche Informationen sind direkt auf der Seite vorhanden.`

  return (
    <Page viewName={'Hilfe'} pageTitle={'Hilfe'}>
      <div>
        <ReactMarkdown source={infoText}/>
        <ReactMarkdown source={changeLog}/>
      </div>
    </Page>
  )
}

export default HelpPagePage
