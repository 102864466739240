import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import DashboardStatus from './DashboardStatus'
import SystemStatus from './SystemStatus'

const DashboardHeader = ({ connectionStatus, systemStatus, chargingPoints, lmsMode }) => {

  let activePoints = 0

  try {
    if (chargingPoints)
      activePoints = chargingPoints?.filter(point => point.currentStatus?.status === 'CHARGING').length
  } catch (e) {
    throw new Error(e)
  }

  return (
    <Grid container>
      <Typography display={'inline'} sx={{ paddingRight: 4 }} variant={'body2'}>
        Verbindung:&nbsp;
        <DashboardStatus status={connectionStatus}/>
      </Typography>
      <Typography sx={{ paddingRight: 4 }} variant={'body2'}>
        System Status:&nbsp;
        <SystemStatus systemStatus={systemStatus} lmsMode={lmsMode}/>
      </Typography>
      <Typography variant={'body2'}>
        {chargingPoints ? (activePoints + '/' + chargingPoints?.length + ' Ladepunkte aktiv') : 'Keine Ladepunkte registriert'}
      </Typography>
    </Grid>
  )
}

DashboardHeader.propTypes = {
  connectionStatus: PropTypes.string,
  systemStatus: PropTypes.object,
  chargingPoints: PropTypes.array,
}

export default DashboardHeader
