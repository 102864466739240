import React, { useEffect, useState } from 'react'
import { Autocomplete, Card, FormControlLabel, List, ListItem, Paper, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import EditIcon from '@mui/icons-material/Edit'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { isEmpty } from '../../utils'

const tableSx = {
  cursor: 'default',
  marginTop: '1px',
  overflow: 'auto',
  '&.MuiList-root': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eceef2',
      borderRadius: '180px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9b9b9b',
      borderRadius: '180px',
    },
  },
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

const CustomPaper = ({ children, selectAll, handleSelectAll, selectedChargingPoints, ...paperProps }) => (
  <Paper {...paperProps} style={{ border: '1px solid #B9B9B9', boxShadow: 'none' }}>
    <Box
      onMouseDown={(e) => e.preventDefault()} // prevent blur
      pl={1.5}
      py={0}
    >
      <FormControlLabel
        label="Alle auswählen"
        onClick={(e) => {
          e.preventDefault() // prevent blur
          handleSelectAll()
        }}
        control={
          <Checkbox
            id={`select-all-checkbox-for-autocomplete-1`}
            size="small"
            checked={selectAll}
          />
        }
      />
    </Box>
    <Divider/>
    {children}
  </Paper>)

const MultiSelector = ({ optionsData, selectedItems, setSelectedItems, displayValueWithId, inputPlaceHolderText, noSelectedItemsText, selectedBoxHeight = 100, maxSelectBoxHeight = 200 }) => {

  const [selectAll, setSelectAll] = useState(false)
  const [options, setOptions] = React.useState([])

  const getLabel = (key) => {
    const additionalText = displayValueWithId ? ' - ' + key : ''
    return optionsData[key] + additionalText
  }

  const handleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setSelectedItems(options)
      else setSelectedItems([])
      return !prev
    })
  }

  useEffect(() => {
    if (isEmpty(optionsData)) {
      return
    }
    const optionsArray = Array.from(Object.keys(optionsData))
    optionsArray.sort()
    setOptions(optionsArray)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData])

  return (
    <React.Fragment>
      <Card style={{ border: '1px solid #B9B9B9', boxShadow: 'none' }}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          disablePortal={true}
          limitTags={5}
          sx={{
            '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
          }}
          popupIcon={<EditIcon/>}
          defaultValue={selectedItems || []}
          value={selectedItems || []}
          options={options}
          getOptionLabel={(option) => getLabel(option)}
          onChange={(_e, value, reason) => {
            if (reason === 'clear' || reason === 'removeOption') {
              setSelectAll(false)
            }
            if (reason === 'selectOption' && value.length === options.length) {
              setSelectAll(true)
            }
            setSelectedItems(value)
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={props.key} style={{ padding: '8px 8px 8px 10px' }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ padding: 0, marginRight: 10 }}
                checked={selected}
              />
              {getLabel(option) || ''}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant={'standard'} placeholder={inputPlaceHolderText}/>
          )}
          renderTags={() => null}
          ListboxProps={{ style: { maxHeight: maxSelectBoxHeight } }}
          PaperComponent={CustomPaper}
          slotProps={{ paper: { selectedItems, selectAll, handleSelectAll } }}
        />
        <List
          divider={<Divider inset="..."/>}
          sx={{ ...tableSx, height: selectedBoxHeight }}
        >
          {selectedItems.length < 1 ?
            <ListItem key={`item-no-items`} sx={{ padding: '5px 2px 2px 5px' }}>
              <ListItemText primary={noSelectedItemsText}/>
            </ListItem>
            :
            selectedItems.map((value) => {
              const valueName = getLabel(value)
              return (!isEmpty(valueName) && <div>
                <ListItem key={`item-${value}`} sx={{ padding: '5px 0px 10px 5px', marginTop: -1 }}>
                  {valueName}
                </ListItem>
              </div>)
            })
          }
        </List>
      </Card>
    </React.Fragment>

  )
}

export default MultiSelector