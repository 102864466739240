import React from 'react'
import PowerHistoryDoEventChartContainer from './PowerHistoryDoEventChartContainer'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const PowerHistoryDoEventChartBox = ({ timeSpan, markerData, gridPowerLimit = 50, chargingPowerLimit = 30 }) => {

  const options = {
    gridLimit: gridPowerLimit,
    chargeLimit: chargingPowerLimit,
  }

  return (
    <RootDiv>
      <PowerHistoryDoEventChartContainer timeSpan={timeSpan} markerData={markerData} options={options}/>
    </RootDiv>
  )
}

export default PowerHistoryDoEventChartBox
