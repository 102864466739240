import { backendApiClient } from '../apiClient'

const CUSTOMER_API = '/api/client/$clientId/customer'
const CONFIGURATION_API = '/api/client/$clientId/configuration'
const SYSTEM_STATUS_API = '/api/systemstatus'

export const customerLoader = async ({ params }) => {
  const customer = await backendApiClient.get(CUSTOMER_API.replace('$clientId', params.clientId))
  const lmsVersion = parseInt(customer.lmsVersion)
  return { customer, lmsVersion }
}

export const settingsLoader = async ({ params }) => {
  const settings = await backendApiClient.get(CONFIGURATION_API.replace('$clientId', params.clientId))
    .catch(() => {return null})
  return { settings }
}

export const systemStatusLoader = async () => {
  const systemStatus = await backendApiClient.get(SYSTEM_STATUS_API, {pageSize: 200})
  const systemStatusList = systemStatus.content.sort((a, b) => (b.totalCharged || 0.0) - (a.totalCharged || 0.0))
  return { systemStatusList }
}