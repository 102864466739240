import { List, ListItemButton, Popover, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { RestartAlt } from '@mui/icons-material'
import React, { useState } from 'react'
import RestartDialog from '../../RestartDialog/RestartDialog'

const ChargingPointMoreSelect = ({ chargePoint, onEdit, onRestart, anchor, setAnchor }) => {
  const [openRestartDialog, setOpenRestartDialog] = useState(false)

  const openPopover = Boolean(anchor)

  const handleClose = () => {
    setAnchor(null)
    setOpenRestartDialog(false)
  }

  const onConfirmRestartDialog = () => {
    onRestart(chargePoint)
    handleClose()
  }

  const isRestartDisabled = (chargePoint) => {
    return chargePoint.interfaceType !== 'ocpp' || chargePoint.controllerConnectionStatus === 'DISCONNECTED'
  }

  return (
    <React.Fragment>
      <RestartDialog openDialog={openRestartDialog} handleClose={handleClose} onConfirm={onConfirmRestartDialog}/>
      <Popover
        open={openPopover}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItemButton id={`popover-edit-${chargePoint.id}`} onClick={() => onEdit(chargePoint)}>
            <Tooltip title={'Bearbeiten'} PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -80],
                  },
                },
              ],
            }}>
              <EditIcon/>
            </Tooltip>
          </ListItemButton>
          <ListItemButton id={`popover-restart-${chargePoint.id}`} disabled={isRestartDisabled(chargePoint)} onClick={() => setOpenRestartDialog(true)}>
            <Tooltip title={'Neustart'}>
              <RestartAlt/>
            </Tooltip>
          </ListItemButton>
        </List>
      </Popover>
    </React.Fragment>
  )
}

export default ChargingPointMoreSelect