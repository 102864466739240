import React from 'react'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { MoreOptionsPopover } from '../../components'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'

const StyledIconButton = styled(IconButton)({
  margin: 4,
})

const DocumentsTable = ({ data, isSupportPerson, showFile, downloadFile, handleDialogOpen }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '25%', borderBottom: 'none' }}>
            Name
          </TableCell>
          <TableCell style={{ width: '45%', borderBottom: 'none' }}>
            Datei
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            Erstellt am
          </TableCell>
          <TableCell align={'right'} style={{ borderBottom: 'none' }}>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data && data.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>
                {entry.name}
              </TableCell>
              <TableCell>
                {entry.url ? entry.url : ''}
              </TableCell>
              <TableCell>
                {entry.creationDate ?
                  DateTime.fromISO(entry.creationDate)
                    .toFormat('dd.MM.yyyy HH:mm')
                  : '--.--.---- --:--'
                }
              </TableCell>
              <TableCell align={'right'}>
                <MoreOptionsPopover
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                  id={entry.id}
                >
                  <StyledIconButton onClick={() => showFile(entry.id)}>
                    <VisibilityIcon/>
                  </StyledIconButton>
                  <StyledIconButton onClick={() => downloadFile(entry.id, entry.url)}>
                    <CloudDownloadIcon/>
                  </StyledIconButton>
                  {isSupportPerson && (
                    <StyledIconButton onClick={() => handleDialogOpen({ id: entry.id, name: entry.url })}>
                      <DeleteIcon/>
                    </StyledIconButton>
                  )}
                </MoreOptionsPopover>
              </TableCell>
            </TableRow>
          ))
        }

      </TableBody>
    </Table>
  )
}

export default DocumentsTable
