import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LmsStatusIcon from './LmsStatusIcon'
import TraceMessageStatusIcon from '../TraceMessageStatusIcon/TraceMessageStatusIcon'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'

const StatusBoxLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  height: '40px',
})

const RootDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  height: theme.spacing(31),
}))

const ValueDeclaration = ({ kpi }) => {

  if (kpi?.value === null || kpi?.value === undefined) {
    return ''
  }
  return kpi?.value
    .toFixed(0)
    .toString()
}

const LmsStatusBox = ({ status }) => {

  const kpi = {
    value: status.totalCharged,
    uom: status.totalChargedUom,
  }

  let versionText = ''

  if (status.lmsVersion) {
    versionText += 'LMS0' + status.lmsVersion
  }

  if (status.version) {
    versionText += ' - ' + status.version
  }

  let lastStatus

  if (status.systemHeartbeat) {
    lastStatus = <React.Fragment>{DateTime.fromISO(status.systemHeartbeat)
      .toFormat('dd.MM.yyyy HH:mm:ss')}</React.Fragment>
  } else {
    lastStatus = <React.Fragment/>
  }

  let notes
  if (!status.connectionStatus) {
    notes = 'Noch nicht konfiguriert'
  } else if (status.connectionStatus === 'OFFLINE') {
    notes = 'Offline'
  }

  return (
    <RootDiv>
      <Grid container spacing={2} height={'100%'}>
        <Grid item xs={2}>
          <StatusBoxLabel align={'left'}>
            <LmsStatusIcon status={status}/>
          </StatusBoxLabel>
        </Grid>
        <Grid item xs={8}>
          <StatusBoxLabel align={'center'}>
            {status.name && status.name.length > 0 ? status.name : status.clientId}
          </StatusBoxLabel>
        </Grid>
        <Grid item xs={2}>
          <StatusBoxLabel align={'right'}>
            <TraceMessageStatusIcon severity={status.severity} message={status.latestTraceMessage}/>
          </StatusBoxLabel>
        </Grid>

        {status.connectionStatus ? (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-end',
          }}>
            <Grid
              item
              xs={12}
              container
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              height={'100px'}
            >
              <Grid
                item
                justifyContent={'center'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'100%'}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  <Typography sx={{
                    color: 'primary.alternateContrastText',
                    fontSize: '40px',
                    fontWeight: '700',
                  }}>
                    <ValueDeclaration kpi={kpi}/>
                  </Typography>
                  <Typography sx={{
                    paddingTop: '14px',
                    marginLeft: '5px',
                    fontSize: '20px',
                    fontWeight: '400',
                    color: 'primary.alternateContrastText',
                  }}>
                    {kpi?.uom}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item
                  xs={12}
                  container
                  direction={'column'}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}>
              <Grid item>
                <Typography align={'center'}>
                  {notes} {lastStatus}
                </Typography>
              </Grid>
            </Grid>
            <Grid item
                  xs={12}
                  container
                  direction={'column'}
                  alignItems={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'flex-end'}>
              <Grid item>
                <Typography align={'center'}>
                  {versionText}
                </Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Typography>
                Noch keine Konfiguration
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </RootDiv>
  )
}

export default LmsStatusBox
