import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const PageReloadTimer = ({ seconds }) => {
  let timeout = null

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      // reload page
      window.location.reload(false)
    }, 1000 * seconds)
  }

  useEffect(() => {
    //reset timer everytime the page changes
    restartAutoReset()

    // cleanup timer
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div/>
}

PageReloadTimer.protTypes = {
  seconds: PropTypes.number,
}

export default PageReloadTimer