import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

const CustomTextField = (props) => {
  const [value, setValue] = useState(props.value)

  const onChange = (event) => {
    try {
      let val = event.target.value
      if (props.type === 'number' && val.length > 0) {
        val = parseInt(event.target.value)
      }
      setValue(val)
      props.onChange(val)
    } catch (e) {
      throw new Error(e)
    }
  }

  const getHelperText = () => {
    if (props?.error?.__errors?.length > 0) {
      return props.error.__errors[props.error.__errors.length - 1]
    } else if (props?.props?.description) {
      return props?.props?.description
    }
    return ' '
  }

  useEffect(() => {
    try {
      if (props.value === null || props.value === undefined) {
        setValue(props?.props.default)
      } else {
        setValue(props.value)
      }
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <TextField
      id={props.id}
      variant={props.variant ? props.variant : 'standard'}
      type={props.type}
      onBlur={props.onBlur}
      multiline={props.multiline}
      rows={props.rows}
      disabled={props.disabled}
      sx={{ width: '100%' }}
      value={value}
      label={props?.overrideTitle ? props.overrideTitle : props?.props?.title ? props.props.title : ''}
      onChange={onChange}
      helperText={getHelperText()}
      error={!!props?.error && props?.error.__errors.length > 0}
      InputProps={props.InputProps}
      InputLabelProps={props.InputLabelProps}
    />
  )
}

export default CustomTextField