import { store } from '../store'
import { doConfirmResetPassword, doConfirmSignIn, doPasswordReset, fetchCurrentSession, login, logout } from './authSlice'

export function loadCurrentAuthenticatedUser () {
  store.dispatch(fetchCurrentSession())
}

export function loginUser (username, password) {
  store.dispatch(login({ username, password }))
}

export function logoutUser () {
  store.dispatch(logout())
}

export function initPasswordReset (username) {
  store.dispatch(doPasswordReset({ username }))
}

export function completePasswordReset (username, code, newPassword) {
  store.dispatch(doConfirmResetPassword({ username: username, confirmationCode: code, newPassword: newPassword }))
}

export function completeNewPassword (user, newPassword) {
  store.dispatch(doConfirmSignIn({ challengeResponse: newPassword }))
}

export function isNewPasswordRequired (challenge) {
  return challenge === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
}

export function isChallengeDone (challenge) {
  return challenge === 'DONE'
}