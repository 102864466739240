import React from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { IconButton, List, ListItemSecondaryAction } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'

const UsersList = ({ content, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <List>
      {content && content.length > 0 && content.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItem onClick={() => handleEdit(entry)} sx={{ marginBottom: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h6'} color={'inherit'}>
                  {entry.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="inherit" display={'inline'} style={{ whiteSpace: 'pre-line' }}>
                  Kostenstelle: {entry.accountId}{'\n'}
                </Typography>
              </Grid>
            </Grid>
            {allowEdit &&
              <ListItemSecondaryAction style={{ top: '100%' }}>
                <IconButton
                  style={{ padding: 0 }}
                  size={'small'}
                  onClick={() => handleDelete(entry)}>
                  {CustomDeleteIcon ? <CustomDeleteIcon/> :
                    <DeleteIcon/>
                  }
                </IconButton>
              </ListItemSecondaryAction>
            }
          </ListItem>
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default UsersList