import React, { useEffect, useState } from 'react'
import AggregationMetricTable from '../../components/AggregationMetricTable/AggregationMetricTable'
import AggregationMetricGraphBox from '../../components/AggregationMetricGraphBox/AggregationMetricGraphBox'
import Paper from '@mui/material/Paper'
import { LoadingBackdrop, YearMonthPicker } from '../../components'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAggregation } from '../../store/metrics/metricsSlice'
import { DateTime } from 'luxon'

const AnnualConsumptionChartContainer = () => {
  const [selectedDate, setSelectedDate] = useState(DateTime.now())
  const { clientId } = useParams()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const aggregationMetrics = useSelector(state => state.metrics.metricsAggregation.content)

  useEffect(() => {
    doFetchAggregation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const doFetchAggregation = (date) => {
    setLoading(true)
    dispatch(fetchAggregation({
      clientId: clientId,
      payload: { periodPrefix: date ? date.year : DateTime.now().year, aggregation: 'month' },
    }))
    setLoading(false)
  }

  const calculateMainWorkSummary = (metrics) => {
    try {
      let x = 0.0
      metrics.forEach(entry => {
        x += getValueOrDefault(entry.mainWork, 0.0)
      })
      return { value: x, uom: 'kWh' }
    } catch (e) {
      throw new Error(e)
    }
  }

  const getValueOrDefault = (metric, defaultValue) => {
    try {
      if (!metric) return defaultValue
      return metric.value
    } catch (e) {
      throw new Error(e)
    }
  }

  const calculateChargedWorkSummary = (metrics) => {
    try {
      let x = 0.0
      metrics.forEach(entry => {
        x += getValueOrDefault(entry.chargedWork, 0.0)
      })
      return { value: x, uom: 'kWh' }
    } catch (e) {
      throw new Error(e)
    }
  }

  const onYearChange = (date) => {
    try {
      setSelectedDate(date)
      doFetchAggregation(date)
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <YearMonthPicker onChange={(date) => onYearChange(date)}
                       disableFuture={true}
                       selectedDate={selectedDate}
                       label={'Wähle ein Jahr'}
      />
      {aggregationMetrics && aggregationMetrics.length > 0 && (
        <Paper
          sx={{
            padding: 2,
            marginBottom: '15px',
            marginTop: 2,
          }}
        >
          <AggregationMetricGraphBox rawData={aggregationMetrics}/>
        </Paper>
      )}
      <Paper sx={{ marginTop: 2 }}>
        {aggregationMetrics ? <AggregationMetricTable metrics={aggregationMetrics}
                                                      summaryMainWork={calculateMainWorkSummary(aggregationMetrics)}
                                                      summaryChargedWork={calculateChargedWorkSummary(aggregationMetrics)}
                                                      sx={{ marginTop: 2 }}/>
          : <AggregationMetricTable/>}
      </Paper>
    </div>
  )
}

export default AnnualConsumptionChartContainer