import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { FormControl, MenuItem, Select } from '@mui/material'
import TabPanel, { tabIdProps } from '../../components/TabPanel/TabPanel'
import { Outlet, useLocation, useNavigate, useRouteLoaderData } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CustomTab, CustomTabs } from '../../components/TabMenu/TabMenu'

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const EvaluationTabContainer = () => {

  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const url = useLocation().pathname
  const navigate = useNavigate()

  const mapURLtoTab = (url) => {
    if (url.includes('consumption')) {
      return 1
    } else if (url.includes('sessions')) {
      return 2
    } else if (url.includes('history')) {
      return 0
    } else {
      navigate('history', { relative: 'path' })
      return 0
    }
  }

  const [currentIndex, setCurrentIndex] = useState(url ? mapURLtoTab(url) : 0)

  const handleChange = (event, newIndex) => {
    setCurrentIndex(newIndex)
    navigateTo(newIndex)
  }

  const handleSelectChange = (event) => {
    const newIndex  = event.target.value
    setCurrentIndex(newIndex)
    navigateTo(newIndex)
  }

  const navigateTo = (newIndex) => {
    if (newIndex === 1) {
      navigate('consumption', { relative: 'path' })
    } else if (newIndex === 2) {
      navigate('sessions', { relative: 'path' })
    } else {
      navigate('history', { relative: 'path' })
    }
  }

  return (
    <RootDiv>
      {smallScreen ?
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={currentIndex}
            onChange={handleSelectChange}
          >
            <MenuItem value={0}>Leistungsverlauf</MenuItem>
            <MenuItem value={1}>Jahresverbrauch</MenuItem>
            {lmsVersion !== '0' &&
              <MenuItem value={2}>Ladevorgänge</MenuItem>
            }
          </Select>
        </FormControl>
        :
        <CustomTabs
          value={currentIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="configuration-tabs"
        >
          <CustomTab label="Leistungsverlauf" {...tabIdProps(0)}/>
          <CustomTab label="Jahresverbrauch" {...tabIdProps(1)}/>
          {lmsVersion !== '0' &&
            <CustomTab label="Ladevorgänge" {...tabIdProps(2)}/>
          }
        </CustomTabs>
      }
      <TabPanel value={currentIndex} index={currentIndex}>
        <Outlet/>
      </TabPanel>
    </RootDiv>
  )
}

export default EvaluationTabContainer