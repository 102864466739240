import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import React from 'react'
import { styled } from '@mui/material/styles'

const CustomTableCell = styled(TableCell)({
  paddingTop: '4px',
})

const TagsTableRow = ({ entry, index, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <TableRow key={index}>
      <CustomTableCell>
        {entry.name}
      </CustomTableCell>
      <CustomTableCell>
        {entry.id}
      </CustomTableCell>
      <CustomTableCell>
        {entry.accountId}
      </CustomTableCell>
      <CustomTableCell align={'right'}>
        {allowEdit && (
          <div>
            <IconButton size={'small'} onClick={() => handleEdit(entry)}>
              <Tooltip title={'Karte bearbeiten'}>
                <EditIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size={'small'} onClick={() => handleDelete(entry)}>
              {CustomDeleteIcon ? <CustomDeleteIcon/> :
                <Tooltip title={'Karte löschen'}>
                  <DeleteIcon/>
                </Tooltip>
              }
            </IconButton>
          </div>
        )}
      </CustomTableCell>
    </TableRow>
  )
}

const TagsTable = ({ content, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell style={{ borderBottom: 'none' }}>
            Kartenname
          </CustomTableCell>
          <CustomTableCell style={{ borderBottom: 'none' }}>
            Karten-ID
          </CustomTableCell>
          <CustomTableCell style={{ borderBottom: 'none' }}>
            Kostenstelle
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {content && content.length > 0 && content.map((row, index) => (
          <TagsTableRow entry={row} key={index} index={index} handleEdit={handleEdit} handleDelete={handleDelete} allowEdit={allowEdit} CustomDeleteIcon={CustomDeleteIcon}/>
        ))}
      </TableBody>
    </Table>
  )
}

export default TagsTable