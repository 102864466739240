import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import backendApiClient from '../../apiClient/apiClient'
import contactSchema from './contactSchema.json'
import EditForm from '../../components/crud/EditForm'
import ContactWidget from '../../components/crud/widgets/ContactWidget'
import { useSnackbar } from 'notistack'
import { LoadingBackdrop } from '../../components'
import { useParams } from 'react-router'

const EditContact = ({ onAfterSubmit }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [allData, setAllData] = useState({})

  const { clientId, contactId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const url = '/api/client/' + clientId + '/customer'

  const save = (formData) => {
    setLoading(true)
    const payload = { ...allData }
    payload.contacts[payload.contacts.indexOf(data.contact)] = formData.contact
    backendApiClient.put(url, payload)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
        setLoading(false)
        onAfterSubmit()
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(url)
      .then((response) => {
        setData({ contact: response?.contacts?.find((entry) => entry.id === contactId) })
        setAllData(response)
      })
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uiSchema = {
    'ui:field': 'ContactWidget',
  }

  const fields = {
    ContactWidget: ContactWidget,
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <EditForm
        liveValidate={true}
        schema={contactSchema}
        formData={data}
        onSubmit={save}
        uiSchema={uiSchema}
        fields={fields}
      />

    </div>
  )
}

EditContact.propTypes = {
  className: PropTypes.string,
}

export default EditContact
