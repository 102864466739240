import palette from '../palette'
import typography from '../typography'

const muiTableCell = {
  styleOverrides: {
    root: {
      ...typography.body1,
      borderBottom: `1px solid ${palette.divider}`,
      padding: '10px',
    },
  },
}

export default muiTableCell
