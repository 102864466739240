import React from 'react'
import { Card, Tooltip } from '@mui/material'
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined'

const SustainableIcon = () => {
  return (
    <Tooltip title={'Nachhaltiges Laden'}>
      <Card sx={{
        display: 'flex',
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        backgroundColor: '#B2DF8A',
      }}>
        <SpaOutlinedIcon sx={{
          color: '#ffffff',
          margin: 0.5,
          fontSize: 22,
        }}/>
      </Card>
    </Tooltip>
  )
}

export default SustainableIcon