import * as yup from 'yup'

export const validationLmsContactSchema = yup.object({
  salutation: yup.string('Es muss eine Anrede ausgewählt werden')
    .min(1, 'Es muss eine Anrede ausgewählt werden')
    .required('Es muss eine Anrede ausgewählt werden'),
  firstName: yup.string('Der Vorname darf nicht leer sein')
    .min(1, 'Der Vorname darf nicht leer sein')
    .required('Der Vorname darf nicht leer sein'),
  lastName: yup.string('Der Nachname darf nicht leer sein')
    .min(1, 'Der Nachname darf nicht leer sein')
    .required('Der Nachname darf nicht leer sein'),
  locationStreet: yup.string()
    .min(2, 'Die Straßen Bezeichnung darf nicht kürzer als 2 Zeichen sein')
    .required('Die Straßen Bezeichnung darf nicht kürzer als 2 Zeichen sein'),
  locationStreetNumber: yup.string()
    .min(1, 'Die Hausnummer darf nicht kürzer als 1 Zeichen sein')
    .required('Die Hausnummer darf nicht kürzer als 1 Zeichen sein'),
  locationPostcode: yup.string()
    .min(4, 'Die Postleitzahl darf nicht weniger als 4 Zeichen enthalten')
    .required('Die Postleitzahl darf nicht weniger als 4 Zeichen enthalten'),
  locationCity: yup.string()
    .min(2, 'Die Ort Beschreibung darf nicht weniger als 2 Zeichen beinhalten')
    .required('Die Ort Beschreibung darf nicht weniger als 2 Zeichen beinhalten'),
  email: yup.string('E-Mail darf nicht leer sein')
    .min(1, 'Ungültige E-Mail-Adresse')
    .required('Ungültige E-Mail-Adresse')
    .email('Ungültige E-Mail-Adresse'),
  phone: yup.string()
    .min(6, 'Die Telefonnummer darf nicht weniger als 6 Zeichen enthalten')
    .required('Die Telefonnummer darf nicht weniger als 6 Zeichen enthalten'),
})