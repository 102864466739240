import Page from '../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import EnergyManagementTabContainer from './EnergyManagementTabContainer'
import digitalOutputsHelpTextRaw from '../../help-texts/FL_Energiemanagement_Digitale_Ausgaenge.md'
import chargingRecommendationHelpTextRaw from '../../help-texts/FL_Energiemanagement_Ladeempfehlung.md'
import digitalOutputGraphHelpTextRaw from '../../help-texts/FL_Energiemanagement_Graph_Auswertung.md'

const EnergyManagementPage = () => {
  const currentUrl = useLocation().pathname

  const PAGE_TITLE = 'Energiemanagement'

  const [helpTitle, setHelpTitle] = useState('')
  const [helpText, setHelpText] = useState('')

  const DIGITAL_OUTPUT_HELP_TITLE = 'Digitale Ausgänge'
  const CHARGING_RECOMMENDATION_HELP_TITLE = 'Ladeempfehlung bearbeiten'
  const GRAPH_HELP_TITLE = 'Auswertung'

  const fetchHelpText = (helpTextRaw) => {
    fetch(helpTextRaw)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    if (currentUrl.includes('optimalCharging')) {
      setHelpTitle(CHARGING_RECOMMENDATION_HELP_TITLE)
      fetchHelpText(chargingRecommendationHelpTextRaw)
    } else if (currentUrl.includes('outputsGraph')) {
      setHelpTitle(GRAPH_HELP_TITLE)
      fetchHelpText(digitalOutputGraphHelpTextRaw)
    } else {
      setHelpTitle(DIGITAL_OUTPUT_HELP_TITLE)
      fetchHelpText(digitalOutputsHelpTextRaw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl])

  return (
    <Page viewName={PAGE_TITLE} pageTitle={PAGE_TITLE} helpText={helpText} helpTitle={helpTitle}>
      <EnergyManagementTabContainer/>
    </Page>
  )

}

export default EnergyManagementPage