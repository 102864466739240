import React, { useEffect, useState } from 'react'
import { useRouteLoaderData } from 'react-router'
import { useFormik } from 'formik'
import { Autocomplete, Grid, TextField } from '@mui/material'
import CustomFormikSwitch from '../../../../components/crud/widgets/components/CustomFormikSwitch'
import CustomFormikSelectField from '../../../../components/crud/widgets/components/CustomFormikSelectField'
import CustomFormikRadioButtons from '../../../../components/crud/widgets/components/CustomFormikRadioButtons'
import CustomFormikTextField from '../../../../components/crud/widgets/components/CustomFormikTextField'
import { LoadingBackdrop, SubmitFab } from '../../../../components'
import { chargingPointVersionOneValidation } from './ChargingPointValidation'
import CustomRadioButtons from '../../../../components/crud/widgets/CustomRadioButtons'

const ChargingPointVersionOneWidget = ({ save, chargingPoint, lmsVersion, loading }) => {
  const { settings } = useRouteLoaderData('settingsRoot')
  const { customer } = useRouteLoaderData('clientRoot')
  const [type, setType] = useState('TYP2')
  const [typePower, setTypePower] = useState(3.7)
  const [disabledPhases, setDisabledPhases] = useState(false)

  const TYP2 = 'TYP2'
  const SCHUKO = 'SCHUKO'

  const typeOptions = [
    {
      name: SCHUKO,
      label: 'Schuko',
    },
    {
      name: TYP2,
      label: 'Typ2',
    },
  ]

  const phaseTyp2 = [
    {
      name: 'noPhase',
      label: 'Keine Phase',
    },
  ]

  const phases = [
    {
      name: 1,
      label: 'Phase 1',
    },
    {
      name: 2,
      label: 'Phase 2',
    },
    {
      name: 3,
      label: 'Phase 3',
    },
  ]

  const powerChoices = type !== SCHUKO ? [
    { title: 3.7 },
    { title: 11 },
  ] : [
    { title: 2.3 },
    { title: 3.7 },
    { title: 11 },
  ]

  const getControllerId = () => {
    const numberOfChargingPoints = settings.chargingPoints?.length || 1
    return (numberOfChargingPoints + 1) % 12 === 0 ? Math.floor((numberOfChargingPoints + 1) / 12) : (Math.floor((numberOfChargingPoints + 1) / 12) + 1)
  }

  const getConnectorId = () => {
    const numberOfChargingPoints = settings.chargingPoints?.length || 0
    return (numberOfChargingPoints + 1) % 12 === 0 ? 12 : (numberOfChargingPoints + 1) % 12
  }

  const getAreaId = () => {
    return Math.max.apply(Math, settings.areas.map(function (o) {return o.id}))
  }

  const formik = useFormik({
    initialValues: {
      activated: false,
      connectorId: getConnectorId(),
      controllerId: getControllerId(),
      id: 1,
      maxPower: 3.7,
      name: 'LP ' + (settings.chargingPoints ? settings.chargingPoints?.length + 1 : 1),
      ordinalNumber: 1,
      phase: 1,
      pvGuided: false,
      type: 'TYP2_SINGLE_PHASE',
      serialNumber: '',
      areaId: getAreaId() || 1,
    },
    validationSchema: chargingPointVersionOneValidation(settings.chargingPoints),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  const handleZeroControllerId = () => {
    if (settings.numberOfControllers >= formik.values?.controllerId) {
      return settings.numberOfControllers === 0 && formik.values?.controllerId === 0
    } else {
      return false
    }
  }

  const changeType = (type) => {
    setType(type)
    if (type === TYP2) {
      if (!(typePower === 3.7 || typePower === 11)) {
        setTypePower(3.7)
        onTypeChange(type, 3.7)
      } else {
        onTypeChange(type, typePower)
      }
    } else if (type === SCHUKO) {
      setTypePower(2.3)
      onTypeChange(type, 2.3)
    }
  }

  const onTypeChange = (type, powerValue) => {
    formik.setFieldValue('maxPower', powerValue ? powerValue : typePower)
    switch (type) {
      case TYP2:
        if (powerValue ? powerValue === 11 : typePower === 11) {
          setDisabledPhases(true)
          formik.setFieldValue('type', 'TYP2_THREE_PHASE')
          formik.setFieldValue('maxPower', powerValue ? powerValue : typePower)
        } else if (powerValue ? powerValue === 3.7 : typePower === 3.7) {
          setDisabledPhases(false)
          formik.setFieldValue('type', 'TYP2_SINGLE_PHASE')
        }
        break
      case SCHUKO:
      default:
        setDisabledPhases(false)
        formik.setFieldValue('type', 'SCHUKO')
    }
  }

  const changePower = (value) => {
    onTypeChange(type, value)
    if (value > 0) {
      if (type === 'TYP2' && !(value === 3.7 || value === 11)) {
        setTypePower(3.7)
      } else {
        setTypePower(value)
      }
    }
  }

  useEffect(() => {
    if (chargingPoint && Object.values(chargingPoint).length > 0) {
      formik.setValues(chargingPoint)

      switch (chargingPoint.type) {
        case 'TYP2_THREE_PHASE':
          setTypePower(11)
          setType(TYP2)
          setDisabledPhases(true)
          break
        case 'TYP2_SINGLE_PHASE':
          setTypePower(3.7)
          setType(TYP2)
          setDisabledPhases(false)
          break
        case 'SCHUKO':
        default:
          setTypePower(chargingPoint.maxPower)
          setType(SCHUKO)
          setDisabledPhases(false)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoint])

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        sx={{
          flexGrow: 1,
          marginTop: -2,
        }}
        spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
        >
          <CustomFormikTextField
            value={formik.values.name}
            onChange={formik.handleChange}
            id={'name'}
            label={'Name'}
            error={Boolean(formik.errors?.name)}
            helperText={formik.errors?.name || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikSelectField
            value={formik.values.areaId}
            values={settings.areas.map((area) => ({ value: area.id, label: area.name }))}
            onChange={formik.handleChange}
            id={'areaId'}
            disabled={true}
            label={'Zone'}
            error={Boolean(formik.errors?.areaId)}
          />
        </Grid>
        <Grid
          container
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          item
          spacing={5}
          xs={12}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            {lmsVersion === 1 ?
              <CustomFormikSelectField
                value={formik.values.controllerId}
                values={[
                  ...Array(getControllerId())
                    .keys()].map(x => x + 1)}
                onChange={formik.handleChange}
                id={'controllerId'}
                label={'LPC Nr.'}
                disabled={handleZeroControllerId()}
                error={Boolean(formik.errors?.controllerId)}
                helperText={formik.errors?.controllerId || ''}
              />
              :
              <CustomFormikTextField
                value={formik.values.connectorId}
                onChange={formik.handleChange}
                type={'number'}
                id={'connectorId'}
                label={'LPC Anschluss-Nr'}
                error={Boolean(formik.errors?.connectorId)}
                helperText={formik.errors?.connectorId || ''}
              />
            }
          </Grid>
          <Grid
            item
            md={4}
            xs={12}>
            <CustomFormikRadioButtons
              input={typeOptions}
              row={true}
              paddingBetween={3}
              handleChange={(event, value) => changeType(value)}
              id={'type'}
              value={type}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={12}
          >
            <Autocomplete
              id="free-solo-type-power"
              onChange={(event, newValue) => changePower(newValue)}
              freeSolo={type === 'SCHUKO'}
              value={typePower}
              getOptionLabel={(x) => x.toString()}
              options={powerChoices.map((option) => option.title)}
              renderInput={(params) =>
                <TextField
                  variant={'standard'}
                  type={'number'}
                  label="kW"
                  {...params}
                />}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          {lmsVersion === 1 &&
            <CustomFormikTextField
              value={formik.values.connectorId}
              onChange={formik.handleChange}
              type={'number'}
              id={'connectorId'}
              label={'LPC Anschluss-Nr'}
              error={Boolean(formik.errors?.connectorId)}
              helperText={formik.errors?.connectorId || ''}
            />
          }
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <div style={{ paddingLeft: 8 }}>
            {disabledPhases ?
              <CustomRadioButtons
                grid
                itemsCount={3}
                input={phaseTyp2}
                disabled={true}
                row={true}
                value={'noPhase'}
              /> :
              <CustomRadioButtons
                grid
                itemsCount={3}
                input={phases}
                row={true}
                onChange={(value) => formik.setFieldValue('phase', parseInt(value))}
                value={formik.values.phase}
              />}
          </div>
        </Grid>
        {customer.hasPv &&
          <Grid item>
            <CustomFormikSwitch
              value={formik.values.pvGuided}
              onChange={formik.handleChange}
              label={'PV-Überschussladung'}
              id={'pvGuided'}
            />
          </Grid>
        }
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default ChargingPointVersionOneWidget