const globalStyles = {
  '@global': {
    html: {
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
      backgroundColor: '#FFFFFF !important',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    '.App': {
      height: '100%',
      width: '100%',
    },
  },
}

export default globalStyles