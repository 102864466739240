import React from 'react'
import ConfigurationEditForm from './ConfigurationEditForm'
import NoConfigurationScreen from './NoConfigurationScreen'
import { useRouteLoaderData } from 'react-router'

const ConfigurationTabContainer = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const settingsRouteData = useRouteLoaderData('settingsRoot')

  return (
    !settingsRouteData?.settings ?
      <NoConfigurationScreen version={lmsVersion}/>
      :
      <ConfigurationEditForm settings={settingsRouteData?.settings}/>
  )
}

export default ConfigurationTabContainer