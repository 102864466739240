import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import EditContact from './EditContact'
import { useNavigate } from 'react-router'
import baseDataNewContactHelpText from '../../help-texts/FL_Ansprechperson_anlegen.md'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const EditBaseDataPage = () => {

  const navigate = useNavigate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const EDIT_CONTACT = 'Ansprechperson bearbeiten'
  const [helpText, setHelpText] = useState()

  useEffect(() => {
    fetch(baseDataNewContactHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Page viewName={smallScreen ? 'Kontakt' : 'Ansprechperson bearbeiten'} onBack={() => navigate(-1)}
            pageTitle={'Stammdaten'} helpTitle={EDIT_CONTACT} helpText={helpText}>
        <EditContact onAfterSubmit={() => navigate(-1)}/>
      </Page>
    </div>
  )
}

export default EditBaseDataPage