import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import PeriodTranslation from '../PeriodTranslation/PeriodTranslation'

const AggregationMetricTable = ({ metrics, summaryMainWork, summaryChargedWork }) => {
  let intFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 })

  function int (kpi) {
    if (!kpi) return ''
    return intFormat.format(kpi?.value) + ' ' + kpi?.uom
  }

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Monat</TableCell>
            <TableCell>Lademenge</TableCell>
            <TableCell>Netzbezug</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!metrics ? <TableRow/> : metrics.map((item) => (
            <TableRow key={item.period}>
              <TableCell><PeriodTranslation period={item.period}/></TableCell>
              <TableCell>{int(item?.chargedWork)}</TableCell>
              <TableCell>{int(item?.mainWork)}</TableCell>
            </TableRow>
          ))}
          {!(summaryChargedWork || summaryMainWork) ? <TableRow/> :
            <TableRow key={'summary'}>
              <TableCell><b>Gesamt</b></TableCell>
              <TableCell><b>{int(summaryChargedWork)}</b></TableCell>
              <TableCell><b>{int(summaryMainWork)}</b></TableCell>
            </TableRow>
          }

        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AggregationMetricTable
