import { colors } from '@mui/material'

const white = '#FFFFFF'
const black = '#000000'

const palette = {
  black,
  white,
  primary: {
    light: '#4f5c6b',
    main: '#233447',
    dark: '#182431',
    contrastText: white,
    alternateContrastText: '#1EA2A7',
    alternateContrastTextHover: 'rgba(30,162,167,0.44)',
    unselectedIcon: '#1EA2A7',
    hoveredSidebarItem: 'rgba(30,162,167,0.35)',
    selectedSidebarItem: '#1EA2A7',
    zoneListItem: '#1EA2A7',
    gradient: 'linear-gradient(180deg, #233447 10%, #233447 100%)',
    unselectedText: '#86BFA3',
    alternateTableHeader: white,
    errorPageColor: white,
    checkBoxContrast: white,
    childBackdrop: 'rgba(30,162,167,0.35)',
  },
  secondary: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#b2b2b2',
    contrastText: white,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: white,
    secondary: white,
    disabled: '#8b959a',
    link: '#1EA2A7',
  },
  background: {
    default: '#233447',
    paper: '#233447',
  },
  circularGradient: {
    start: '#43718B',
    middle: '#1EA2A7',
    end: '#45AC34',
  },
  graph: {
    mainColor1: '#1EA2A7',
    mainColor2: '#45AC34',
    text: white,
  },
  icon: '#1EA2A7',
  divider: colors.grey[200],
  disabled: '#8b959a',
}

export default palette
