import React from 'react'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&$checked': {
    color: theme.palette.primary.main,
  },
}))

const CustomFormikRadioButtons = ({ title, value, handleChange, row, grid, input, itemsCount, disabled, id, paddingBetween }) => {
  return (
    <React.Fragment>
      <FormControl component="fieldset" sx={{ width: '100%' }} name={id} id={id}>
        <FormLabel component="legend" sx={{ fontSize: '11px' }}>{title ? title : ''}</FormLabel>
        <RadioGroup aria-label={title ? title : ''} name={id} id={id} value={value} onChange={handleChange} row={row} color={'primary'} sx={{ fontSize: '11px' }}>
          {grid ? (
            <Grid container sx={{ width: '100%' }}>
              {input.map((entry) => (
                <Grid key={entry.name} item xs={12 / itemsCount}>
                  <CustomFormControlLabel value={entry.name} control={<Radio disabled={disabled}/>} label={entry.label}/>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div>
              {input.map((entry) => (
                <CustomFormControlLabel key={entry.name} value={entry.name} control={<Radio disabled={disabled}/>} label={entry.label} sx={{ paddingRight: paddingBetween }}/>
              ))}
            </div>
          )}
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  )
}

CustomFormikRadioButtons.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  row: PropTypes.bool,
  grid: PropTypes.bool,
  input: PropTypes.array,
  itemsCount: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

export default CustomFormikRadioButtons