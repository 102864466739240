import { FormControl, Grid, MenuItem, Select } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import YearMonthPicker from '../DatePicker/YearMonthPicker'

const TimeWindowPicker = ({ timeWindows, timeWindow, selectedDate, onDateChange, handleTimeChange }) => {
  return (
    <Grid container item sm={8} xs={12} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
      <Grid item xs={12} sm={8}>
        <YearMonthPicker
          selectedDate={selectedDate}
          onChange={onDateChange}
          views={timeWindow === 'month' ? ['month', 'year'] : ['year']}
          pickable={false}
          disableFuture={true}
        />
      </Grid>
      <Grid item>
        <FormControl
          sx={{
            padding: '4px',
            minWidth: 100,
            maxWidth: 150,
          }}
          variant={'outlined'}>
          <Select
            sx={{
              '&.MuiSelect-outlined': { padding: '12px' },
            }}
            labelId="time-window-choose"
            id="time-window-choose"
            value={timeWindow}
            onChange={handleTimeChange}
          >
            {timeWindows.map((window) => (
              <MenuItem key={window.key} value={window.key}>
                {window.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

TimeWindowPicker.propTypes = {
  timeWindows: PropTypes.array,
  timeWindow: PropTypes.string,
  selectedDate: PropTypes.any,
  onDateChange: PropTypes.func,
  handleTimeChange: PropTypes.func,
}

export default TimeWindowPicker