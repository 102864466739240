import React, { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'

const CustomChangeableSelect = (props) => {

  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    setValue(event.target.value)
    props.onChange(event.target.value)
  }

  useEffect(() => {
    try {
      setValue(props.value)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <TextField
      id={props.id}
      onBlur={props.onBlur}
      disabled={props?.disabled}
      sx={{
        color: 'text.primary',
        width: '100%',
      }}
      defaultValue={props.value ? props.value : props?.props.default}
      select
      label={props?.props.title}
      value={value}
      variant={'standard'}
      onChange={handleChange}
      helperText={props?.error?.__errors.length > 0 ? props.error.__errors[0] : ' '}
      error={!!props?.error && props.error.__errors.length > 0}
    >
      {props?.useFirst ? (
        props.setOne.map((item) => (
          <MenuItem key={props?.setOne.indexOf(item)} value={item}>
            {props.setOneNames ? props.setOneNames[props.setOne.indexOf(item)] : item}
          </MenuItem>
        ))
      ) : (
        props.setTwo.map((item) => (
          <MenuItem key={props?.setTwo.indexOf(item)} value={item}>
            {props.setTwoNames ? props.setTwoNames[props.setTwo.indexOf(item)] : item}
          </MenuItem>
        )))}
    </TextField>
  )
}

export default CustomChangeableSelect