import EditForm from '../../../components/crud/EditForm'
import schema from './AreaSchemaBuilder.js'
import React, { useEffect, useState } from 'react'
import AreaWidget from '../../../components/crud/widgets/AreaWidget'
import { useParams } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import Grid from '@mui/material/Grid'
import { IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Typography } from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import DeleteIcon from '@mui/icons-material/Delete'
import listToTree from '../Chargepoints/utils/listToTree'

const AreaRow = ({ area, selectedArea, setSelectedArea, isChild = 1, onDelete, version, areaCount, canSave }) => {
  return (
    <div key={area.id}>
      <ListItemButton selected={area.id === selectedArea.id} button onClick={() => setSelectedArea(area)}
                      sx={{ paddingLeft: isChild * 16 + 'px' }}>
        {isChild > 1 &&
          <ListItemIcon sx={{
            marginRight: -3,
            color: 'primary.zoneListItem',
          }}>
            <SubdirectoryArrowRightIcon/>
          </ListItemIcon>
        }
        <ListItemText sx={{ marginRight: 4 }} primary={area.name ? area.name : (area.energyMeter?.name ? area.energyMeter?.name : '')}/>
        {area.children.length === 0 && version >= 2 && areaCount > 1 && canSave && (
          <ListItemSecondaryAction>
            <IconButton size={'small'} onClick={() => onDelete(area)}>
              <DeleteIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
      {area.children.map((child) => (
        <AreaRow area={child} selectedArea={selectedArea} setSelectedArea={setSelectedArea}
                 isChild={isChild + 1} key={child.id} onDelete={onDelete} version={version}
                 areaCount={areaCount} canSave={canSave}/>
      ))}
    </div>
  )
}

const AreaEditForm = ({ canSave, version }) => {

  const URL_AREA_CONFIG = '/api/client/{client}/configuration/areas'
  const NEW_ZONE_BUTTON = 'Neue Zone hinzufügen'

  const { enqueueSnackbar } = useSnackbar()

  const { clientId } = useParams()

  const [areaData, setAreaData] = useState()
  const [data, setData] = useState({})
  const [tempSelectedArea, setTempSelectedArea] = useState({})
  const [areaChanged, setAreaChanged] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [toDeleteArea, setToDeleteArea] = useState({})
  const [areaSchema, setAreaSchema] = useState(schema(data.areas))
  const [firstArea, setFirstArea] = useState()

  const fetchData = (currentArea) => {
    const url = URL_AREA_CONFIG.replace('{client}', clientId)
    backendApiClient.get(url)
      .then((response) => {
        const areasData = []
        let activeArea
        response.forEach((area) => {
          const newArea = adjustManufacturer(area)
          areasData.push({
            ...newArea,
            powerLimit: Math.round(area.powerLimit / 0.69),
            meterConfiguration: area.energyMeter !== null,
            energyMeter: area.energyMeter !== null ? area.energyMeter : getTemplateEnergyMeter(area.id),
          })
        })
        if (!areaData && areasData.length > 0) {
          if (!firstArea) {
            setFirstArea(areasData[0].id)
          }
          activeArea = areasData[0]
          changeSchema(areasData[0], areasData)
        } else if (currentArea) {
          activeArea = currentArea
          changeSchema(currentArea, areasData)
        } else {
          const newArea = areasData.find(area => area.id === areaData.id)
          activeArea = newArea
          changeSchema(newArea, areasData)
        }
        activeArea.hasChild = hasChildAreas(activeArea, areasData)
        setAreaData(activeArea)
        setData({
          areas: areasData,
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasChildAreas = (currentArea, areas) => {
    let hasChild = false
    areas.forEach((areaToCheck) => {
      if (areaToCheck.parentAreaId === currentArea.id) {
        hasChild = true
      }
    })
    return hasChild
  }

  const changeSchema = (newArea, areas) => {
    if (areaSchema?.properties.areas.items.properties.parentAreaId) {
      let deviceIdArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      let parentIdArray = []
      let parentIdNameArray = []
      if (areas) {
        areas.forEach((area) => {
          const idx = deviceIdArray.findIndex((id) => id === area.energyMeter?.deviceId && id !== newArea.energyMeter?.deviceId)
          if (idx >= 0) {
            deviceIdArray.splice(idx, 1)
          }
          if (area.id !== newArea.id) {
            parentIdArray.push(area.id)
            parentIdNameArray.push(area.name)
          }
        })
      }
      //ensures that parentIdArray is never empty, so LMS0 can be saved without errors
      if (parentIdArray.length < 1) {
        parentIdArray.push(1)
        parentIdNameArray.push('1')
      }
      if (newArea.energyMeter?.comType === 'tcp') {
        areaSchema.properties.areas.items.properties.energyMeter.manufacturer.enum = ['celsa', 'gossen', 'janitza']
        areaSchema.properties.areas.items.properties.energyMeter.manufacturer.enumNames = ['Celsa', 'Gossen', 'Janitza']
      } else {
        areaSchema.properties.areas.items.properties.energyMeter.manufacturer.enum = ['schneider']
        areaSchema.properties.areas.items.properties.energyMeter.manufacturer.enumNames = ['Schneider']
      }
      areaSchema.properties.areas.items.properties.energyMeter.deviceId.enum = deviceIdArray

      areaSchema.properties.areas.items.properties.parentAreaId.enum = parentIdArray
      areaSchema.properties.areas.items.properties.parentAreaId.enumNames = parentIdNameArray
      setAreaSchema(areaSchema)

    }
  }

  const onNewArea = () => {
    const areaTemplate = getTemplateArea(data.areas)
    const newAreas = data.areas
    newAreas.push(areaTemplate)
    const newData = {
      ...data,
      areas: newAreas,
    }
    setData(newData)
    setAreaData(areaTemplate)
    setTempSelectedArea(areaTemplate)
    setAreaChanged(true)
    changeSchema(areaTemplate, newAreas)
  }

  const getTemplateArea = (areas) => {
    const maxIdPlus = Math.max.apply(Math, areas.map(function (o) {
      return o.id
    })) + 1
    return {
      id: maxIdPlus,
      powerLimit: 0,
      fuseCurrentLimit: 0,
      energyMeter: getTemplateEnergyMeter(maxIdPlus),
      meterConfiguration: true,
      name: `Zone ${maxIdPlus}`,
      parentAreaId: data.areas.find((area) => area.parentAreaId === 0 || area.parentAreaId === null).id,
      new: true,
    }
  }

  const getTemplateEnergyMeter = (id) => {
    return {
      name: '',
      comType: 'tcp',
      ipAddress: '192.168.43.' + (id),
      //set for LMS01 and older, gets deleted for newer versions
      deviceId: id,
      manufacturer: 'gossen',
    }
  }

  const afterSave = () => {
    setAreaChanged(false)
    setOpen(false)
    fetchData()
    enqueueSnackbar('Erfolgreich gespeichert', {
      variant: 'success',
    })
  }

  const adjustManufacturer = (data) => {
    if (data.energyMeter?.manufacturer === null) {
      if (version < 2 || data.energyMeter?.comType === 'serial') {
        data.energyMeter.manufacturer = 'schneider'
      } else {
        data.energyMeter.manufacturer = 'celsa'
      }
    }
    return data
  }

  const save = (formData) => {
    if (formData.parentAreaId === 0) {
      delete formData.parentAreaId
    }
    if (formData.new === true) {
      const url = URL_AREA_CONFIG.replace(/{client}/g, clientId)
      const areaToSave = {
        ...formData,
        energyMeter: formData.meterConfiguration ? formData.energyMeter : null,
        powerLimit: Math.round(formData.powerLimit * 0.69),
      }
      // new and children aren't used in backend. ID is assigned in backend but needed for frontend sorting
      delete areaToSave.id
      delete areaToSave.children
      delete areaToSave.new
      // version 2 uses areaId as deviceId
      if (version >= 2 && formData.meterConfiguration) {
        delete areaToSave.energyMeter?.deviceId
      }
      backendApiClient.post(url, areaToSave)
        .then(() => {
          afterSave()
        })
        .catch(() => {
          setOpen(false)
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
            variant: 'error',
          })
        })
    } else {
      const url = URL_AREA_CONFIG.replace(/{client}/g, clientId)
        .concat('/', formData.id)
      const areaToSave = {
        ...formData,
        energyMeter: formData.meterConfiguration ? formData.energyMeter : null,
        powerLimit: Math.round(formData.powerLimit * 0.69),
      }
      // children aren't used in the backend
      delete areaToSave.children
      // version 2 uses areaId as deviceId
      if (version >= 2 && formData.meterConfiguration) {
        delete areaToSave.energyMeter?.deviceId
      }
      backendApiClient.put(url, areaToSave)
        .then(() => {
          afterSave()
        })
        .catch(() => {
          setOpen(false)
          enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
            variant: 'error',
          })
        })
    }
  }

  const handleDeleteOpen = (area) => {
    setOpenDelete(true)
    setToDeleteArea(area)
  }

  const handleDeleteDataReset = () => {
    if (toDeleteArea.id === areaData.id) {
      setAreaChanged(false)
      newSelectedArea(data.areas.find((area) => area.id === 1), true)
    }
    const idx = data.areas.findIndex((area) => area.id === toDeleteArea.id)
    const newAreas = data.areas
    newAreas.splice(idx, 1)
    setData({
      ...data,
      areas: newAreas,
    })
  }

  const onDelete = () => {
    setOpenDelete(false)
    if (toDeleteArea.new) {
      handleDeleteDataReset()
    } else {
      if (toDeleteArea.id === areaData.id) {
        setAreaChanged(false)
        setAreaData(data.areas[0])
      }
      const url = URL_AREA_CONFIG.replace(/{client}/g, clientId)
        .concat('/', toDeleteArea.id)
      backendApiClient.delete(url)
        .then(() => {
          fetchData(data.areas[0])
        })

    }
  }

  const newSelectedArea = (area, suppressSave = false) => {
    if (area.id !== areaData.id) {
      setTempSelectedArea(area)
      if (areaChanged && !suppressSave && canSave) {
        setOpen(true)
      } else {
        if (!canSave && areaChanged) {
          enqueueSnackbar('Sie haben keine Berechtigung zum ändern!', {
            variant: 'warning',
          })
        }
        area.hasChild = hasChildAreas(area, data.areas)
        setAreaChanged(false)
        setAreaData({
          ...area,
        })
        changeSchema(area, data.areas)
      }
    }
  }

  const handleNoSave = () => {
    setOpen(!open)
    setAreaData({
      ...tempSelectedArea,
    })
    fetchData(tempSelectedArea)
    changeSchema(tempSelectedArea, data.areas)
    setAreaChanged(false)
  }

  const areaValidate = (formData, errors) => {
    try {
      if (formData) {
        const parentArea = data.areas?.find((parent) => parent.id === formData.parentAreaId)
        if (formData.name === '') {
          errors.name.addError('Der Eintrag darf nicht leer sein')
        }
        if (formData.powerLimit < 1 || formData.powerLimit === null) {
          errors.powerLimit.addError('Die Zahl darf nicht kleiner als 1 sein')
        }
        if (formData.fuseCurrentLimit < 1 || formData.fuseCurrentLimit === null) {
          errors.fuseCurrentLimit.addError('Die Zahl darf nicht kleiner als 1 sein')
        }
        if (formData.fuseCurrentLimit < formData.powerLimit) {
          errors.powerLimit.addError('Muss kleiner als die Absicherung sein')
        }
        if (parentArea) {
          if (formData.fuseCurrentLimit > parentArea.fuseCurrentLimit) {
            errors.fuseCurrentLimit.addError('Darf nicht größer als die Absicherung der übergeordneten Zone sein')
          }
          if (formData.powerLimit > parentArea.powerLimit) {
            errors.powerLimit.addError('Darf nicht größer als die Leistungsbegrenzung der übergeordneten Zone sein')
          }
        }
        if (formData.meterConfiguration || version < 2) {
          if (formData.energyMeter?.name === null || formData.energyMeter?.name === '') {
            errors.energyMeter.name.addError('Der Eintrag darf nicht leer sein')
          }
          // eslint-disable-next-line
          if (formData.energyMeter?.comType === 'tcp' && !formData?.energyMeter?.ipAddress?.match('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$')) {
            errors.energyMeter.ipAddress.addError('Der Eintrag ist keine valide IP-Adresse.')
          }
          if (formData.energyMeter?.comType === 'serial' && formData.energyMeter?.manufacturer !== 'schneider') {
            errors.energyMeter.manufacturer.addError('Hersteller ist nicht für Typ Serial verfügbar')
          }
          if (formData.energyMeter?.comType === 'tcp' && formData.energyMeter?.manufacturer === 'schneider') {
            errors.energyMeter.manufacturer.addError('Hersteller ist nicht für Typ TCP verfügbar')
          }
          data.areas?.filter((filterArea) => filterArea.id !== formData.id)
            .forEach((innerArea) => {
              if (formData.energyMeter?.comType === 'tcp' && innerArea.energyMeter?.ipAddress === formData.energyMeter?.ipAddress) {
                errors.energyMeter.ipAddress.addError('IP-Adresse wird bereits verwendet')
              }
              if (version < 2 && innerArea.energyMeter?.deviceId === formData.energyMeter?.deviceId) {
                errors.energyMeter.deviceId.addError('Zählerkennung wird bereits verwendet')
              }
            })
        } else {
          //disables errors when virtual meter is active
          errors.energyMeter.__errors = []
          errors.energyMeter.ipAddress.__errors = []
          errors.energyMeter.manufacturer.__errors = []
          errors.energyMeter.deviceId.__errors = []
        }
      }
      return errors
    } catch (e) {
      throw new Error(e)
    }
  }

  const areaUiSchema = {
    'ui:field': 'AreaWidgetWithProps',
  }

  const areaFields = {
    AreaWidgetWithProps: (props) => <AreaWidget {...props} version={version} setAreaChanged={setAreaChanged}
                                                setAreaData={setAreaData} isServicePerson={canSave}
                                                areas={data.areas} firstArea={firstArea}/>,
  }

  return (

    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <DialogDelete
        open={open}
        closeText={'Änderungen verwerfen'}
        submitText={'Zurückkehren'}
        handleClose={() => handleNoSave()}
        handleConfirm={() => setOpen(false)}
        dialogText={'Die Zone ist nicht gespeichert, sollen die Änderungen verworfen werden?'}
      />
      <DialogDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleConfirm={() => onDelete()}
        dialogText={'Soll die Zone gelöscht werden?'}
      />
      <Grid
        item
        xs={12}
        md={3}>
        <Paper
          sx={(theme) => ({
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: '7px',
          })}
          elevation={2}>
          <List disablePadding>
            {data?.areas && data.areas.length > 0 && listToTree(data.areas)
              .map((currentArea) => (
                <AreaRow area={currentArea} selectedArea={areaData} setSelectedArea={newSelectedArea}
                         key={currentArea.id} onDelete={handleDeleteOpen} version={version}
                         areaCount={data.areas.length} canSave={canSave}/>
              ))}
            {version >= 2 && canSave && (
              <ListItemButton
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.secondary.light}`,
                  display: 'flex',
                  justifyContent: 'center',
                })}
                onClick={() => onNewArea()}>
                <Typography align={'center'}>
                  {NEW_ZONE_BUTTON}
                </Typography>
              </ListItemButton>
            )}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <EditForm
          schema={areaSchema ? areaSchema : schema(data.areas)}
          uiSchema={areaUiSchema}
          customValidate={areaValidate}
          onSubmit={save}
          formData={areaData}
          fields={areaFields}
          liveValidate
          disabledSubmit={canSave}
        />
      </Grid>
    </Grid>
  )
}

export default AreaEditForm