import Fab from '@mui/material/Fab'
import { Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const IconBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transform: theme.skewText,
}))

const GeneralFab = ({ onClick, text, children, id, disabled = false }) => {
  return (
    <Tooltip title={text} arrow>
      <Fab
        onClick={onClick}
        color={'primary'}
        sx={{
          position: 'fixed',
          bottom: 40,
          right: 40,
        }}
        id={id}
        disabled={disabled}
      >
        <IconBox>
          {children}
        </IconBox>
      </Fab>
    </Tooltip>
  )
}

GeneralFab.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default GeneralFab