import React from 'react'
import Typography from '@mui/material/Typography'

const LoadingPointRequirementInfoText = () => {
  return (
    <Typography
      sx={{
        margin: 1,
        padding: 1,
        fontSize: '20px',
      }}
      color={'primary'}
      align={'center'}
    >
      Bitte Fahrzeug mit Ladepunkt verbinden!
    </Typography>
  )
}

export default LoadingPointRequirementInfoText
