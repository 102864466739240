import React, { useEffect, useState } from 'react'
import { NavigateFab, Page } from '../../components'
import DocumentsContainer from './DocumentsContainer'
import AddIcon from '@mui/icons-material/Add'
import { getRoles, isSupportPerson } from '../../security/roles'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import documents from '../../help-texts/FL_Dokumente.md'

const DocumentsPage = () => {

  const userRoles = useSelector(state => getRoles(state))
  const url = useLocation().pathname

  const HELP_TITLE = 'Hier finden Sie Dokumente und können selbst welche hinzufügen'
  const DOCUMENTS = 'Dokumente'
  const NEW = 'neu'

  const [documentsHelpText, setDocumentsHelpText] = useState()

  useEffect(() => {
    fetch(documents)
      .then(res => res.text())
      .then(text => setDocumentsHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={DOCUMENTS} helpTitle={HELP_TITLE} helpText={documentsHelpText}>
      <DocumentsContainer isSupportPerson={isSupportPerson(userRoles)}/>
      <NavigateFab
        path={`${url}/new`}
        text={NEW}
      >
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default DocumentsPage