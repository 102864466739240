import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
}))

const Quote = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/assets/images/Anmeldung_Edit211021.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left',
}))

const ContentBody = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  },
}))

const FormDiv = styled('div')(({ theme }) => ({
  paddingLeft: 100,
  paddingRight: 100,
  flexBasis: 700,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const UserActionLayout = () => {
  return (
    <Root>
      <Grid
        container
        sx={{ height: '100%' }}
      >
        <Grid
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
          item
          lg={5}>
          <Quote>
            <div style={{
              textAlign: 'center',
              flexBasis: '600px',
            }}
            >
              <Typography
                sx={{
                  marginTop: 3,
                  color: 'white',
                }}
                variant="body1">
                Smart. Intelligent. Kostenoptimiert.
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  fontWeight: 300,
                }}
                variant="h1">
                Die Ladelösung für<br/>Elektroauto-Flotten.
              </Typography>
            </div>
          </Quote>
        </Grid>
        <Grid
          item
          lg={7}
          xs={12}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <ContentBody>
            <FormDiv>
              <Outlet/>
            </FormDiv>
          </ContentBody>
        </Grid>
      </Grid>
    </Root>
  )
}

export default UserActionLayout