const listToTree = (list) => {
  let map = {}, node, roots = [], i

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.parentAreaId) {
      // if you have dangling branches check that map[node.parentAreaId] exists
      list[map[node.parentAreaId]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export default listToTree