import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import FirmwareHelpTextEdit from '../../../help-texts/administration/FL_Firmware_Update_Status.md'
import Page from '../../../components/Page/Page'
import { Typography } from '@mui/material'
import { updateChargingPointFirmwareStatus } from '../../../store/firmwarestatus/firmwareStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Poller } from '../../../components'
import StatusInstallFirmwareTable from './StatusInstallFirmwareTable'
import Grid from '@mui/material/Grid'

const StatusInstallFirmwarePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { manufacturer, version } = useParams()

  const [helpTextFirmwareEdit, setHelpTextFirmwareEdit] = useState()

  const FIRMWARE_INSTALL_CARD_TITLE = 'Firmware Update Status'

  const { status } = useSelector(state => state.firmwareStatus)
  const TITLE = `${manufacturer} - Version: ${version}`
  const [chargingPoints, setChargingPoints] = useState({})

  useEffect(() => {
    try {
      setChargingPoints(status[manufacturer][version])
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    fetchHelpText()
    retrieveFirmwareStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(FirmwareHelpTextEdit)
      .then(res => res.text())
      .then(text => setHelpTextFirmwareEdit(text))
      .catch(error => throw new Error(error))
  }

  const retrieveFirmwareStatus = () => {
    try {
      const clientMap = status[manufacturer][version]
      for (const client in clientMap) {
        if (!clientMap.hasOwnProperty(client)) continue
        for (const cp in clientMap[client]) {
          dispatch(updateChargingPointFirmwareStatus({ clientId: client, chargingPointId: cp, manufacturer: manufacturer, version: version }))
        }
      }
    } catch (e) {}
  }

  return (
    <Page viewName={FIRMWARE_INSTALL_CARD_TITLE} helpText={helpTextFirmwareEdit} helpTitle={FIRMWARE_INSTALL_CARD_TITLE} pageTitle={FIRMWARE_INSTALL_CARD_TITLE} onBack={() => navigate(-1)}>
      <Poller interval={30000} action={() => retrieveFirmwareStatus()}/>
      <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>
            {TITLE}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StatusInstallFirmwareTable chargingPoints={chargingPoints}/>
        </Grid>
      </Grid>
    </Page>
  )
}

export default StatusInstallFirmwarePage
