import React, { useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem, Select } from '@mui/material'
import { useParams } from 'react-router'
import { backendApiClient } from '../../apiClient'
import ChargingProcessList from '../../components/ChargingProcessList/ChargingProcessList'
import { DateTime, Duration } from 'luxon'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { GeneralFab, LoadingBackdrop, LoadMoreButton, TimeWindowPicker } from '../../components'
import { downloadBlob } from '../../utils'

const ChargingProcessesContainer = () => {

  const { clientId } = useParams()
  const URL = '/api/client/' + clientId + '/charging/sessions'
  const pointsURL = `/api/client/${clientId}/chargingpoints`
  const [chargingPoints, setChargingPoints] = useState()
  const [selectedCps, setSelectedCps] = useState('all')
  const [timeWindow, setTimeWindow] = useState('month')
  const [selectedDate, setSelectedDate] = useState(DateTime.now())
  const [data, setData] = useState([])
  const [last, setLast] = useState(false)
  const [pageable, setPageable] = useState({})
  const [totalTime, setTotalTime] = useState('')
  const [totalAmount, setTotalAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  const EXPORT_LABEL = 'exportieren'
  const SHOW_MORE_DATA_LABEL = 'Mehr Daten laden'

  const timeWindows = [
    { key: 'month', name: 'Monat' },
    { key: 'year', name: 'Jahr' },
  ]

  const handleChange = (event) => {
    setSelectedCps(event.target.value)
  }

  const handleTimeChange = (event) => {
    const currentDate = DateTime.now()
    if (selectedDate > currentDate) {
      setSelectedDate(currentDate)
    }
    setTimeWindow(event.target.value)
  }

  const onDateChange = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    try {
      if (clientId) {
        fetchChargingPointsData()
      }
    } catch (error) {
      throw new Error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  useEffect(() => {
    setSelectedCps('all')
  }, [chargingPoints])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeWindow, selectedDate, selectedCps])

  useEffect(() => {
    fetchData()
    fetchChargingPointsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchChargingPointsData = () => {
    backendApiClient.get(pointsURL)
      .then((response) => {
        const sortedPoints = response.sort((a, b) => {
          if (a.ordinalNumber > b.ordinalNumber) {
            return 1
          } else if (a.ordinalNumber < b.ordinalNumber) {
            return -1
          }
          return 0
        })
        setChargingPoints(sortedPoints)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const fetchData = (date) => {
    try {
      setLoading(true)
      let usedDate = selectedDate
      if (date) {
        usedDate = date
      }
      let params = {
        year: usedDate.year,
      }
      if (timeWindow === 'month') {
        params = {
          ...params,
          month: usedDate.month,
        }
      }
      if (selectedCps !== 'all') {
        params = {
          ...params,
          cpId: selectedCps.id,
        }
      }
      backendApiClient.get(URL, params)
        .then((response) => {
          const sortedData = response.content.sort((a, b) => {
            if (a.evse.id > b.evse.id) {
              return 1
            } else if (a.evse.id < b.evse.id) {
              return -1
            }
            return 0
          })
          setData(sortedData)
          setLast(response.last)
          setTotalAmount(response.quantity)
          setTotalTime(response.duration)
          setPageable(response.pageable)
          setLoading(false)
        })
        .catch((error) => {
          throw new Error(error)
        })
    } catch (error) {
      throw new Error(error)
    }
  }

  const fetchMoreData = () => {
    setLoading(true)
    let params = { year: selectedDate.year, cursor: pageable.cursor }
    if (timeWindow === 'month') {
      params = {
        ...params,
        month: selectedDate.month,
      }
    }
    if (selectedCps !== 'all') {
      params = {
        ...params,
        cpId: selectedCps.id,
      }
    }
    backendApiClient.get(URL, params)
      .then((response) => {
        const sortedData = response.content.sort((a, b) => {
          if (a.evse.id > b.evse.id) {
            return 1
          } else if (a.evse.id < b.evse.id) {
            return -1
          }
          return 0
        })
        if (selectedCps === 'all') {
          let mergedData = [...data]
          sortedData.forEach((dataSet) => {
            let mergedEntry = mergedData.find(dataEntry => dataEntry.evse.id === dataSet.evse.id)
            if (mergedEntry) {
              mergedEntry.chargingSessions = mergedEntry.chargingSessions.concat(dataSet.chargingSessions)
              mergedEntry.quantity = mergedEntry.quantity + dataSet.quantity
              // transform hh:mm:ss to luxon duration to add then and transform back to hh:mm:ss
              const dataSetDurationArray = dataSet.duration.split(':')
              const dataSetDuration = {
                hours: dataSetDurationArray[0],
                minutes: dataSetDurationArray[1],
                seconds: dataSetDurationArray[2],
              }
              const mergedDataDurationArray = mergedEntry.duration.split(':')
              const mergedDataDuration = {
                hours: mergedDataDurationArray[0],
                minutes: mergedDataDurationArray[1],
                seconds: mergedDataDurationArray[2],
              }
              mergedEntry.duration = Duration.fromObject(mergedDataDuration)
                .plus(Duration.fromObject(dataSetDuration))
                .toFormat('hh:mm:ss')
            } else {
              mergedData.push(dataSet)
            }
          })
          setData(mergedData)
        } else {
          let mergedData = [...data]
          sortedData.filter(cp => selectedCps.id === cp.evse.id)
            .forEach((dataSet) => {
              let mergedEntry = mergedData.find(dataEntry => dataEntry.evse.id === dataSet.evse.id)
              if (mergedEntry) {
                mergedEntry.chargingSessions = mergedEntry.chargingSessions.concat(dataSet.chargingSessions)
                mergedEntry.quantity = mergedEntry.quantity + dataSet.quantity
                // transform hh:mm:ss to luxon duration to add then and transform back to hh:mm:ss
                const dataSetDurationArray = dataSet.duration.split(':')
                const dataSetDuration = {
                  hours: dataSetDurationArray[0],
                  minutes: dataSetDurationArray[1],
                  seconds: dataSetDurationArray[2],
                }
                const mergedDataDurationArray = mergedEntry.duration.split(':')
                const mergedDataDuration = {
                  hours: mergedDataDurationArray[0],
                  minutes: mergedDataDurationArray[1],
                  seconds: mergedDataDurationArray[2],
                }
                mergedEntry.duration = Duration.fromObject(mergedDataDuration)
                  .plus(Duration.fromObject(dataSetDuration))
                  .toFormat('hh:mm:ss')
              } else {
                mergedData.push(dataSet)
              }
            })
          setData(mergedData)
        }
        setLast(response.last)
        setPageable(response.pageable)
        setLoading(false)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const handleExport = () => {
    setLoading(true)
    let fileName
    let params = { year: selectedDate.year }
    if (timeWindow === 'month') {
      params = {
        ...params,
        month: selectedDate.month,
      }
    }
    if (selectedCps !== 'all') {
      params = {
        ...params,
        cpId: selectedCps.id,
      }
    }
    backendApiClient.fileGet(URL + '/export', params)
      .then((response) => {
        fileName = response.headers['content-disposition'].replace('attachment; filename=', '')
        return new Blob([response.data], { type: response.data.type })
      })
      .then((blob) => {
        downloadBlob(blob, fileName)
        setLoading(false)
      })
      .catch((error) => {
        throw new Error(error)
      })

  }

  return (
    <Grid container
          spacing={0}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0px',
          }}
    >
      <LoadingBackdrop open={loading}/>
      <Grid item sm={4} xs={12}>
        <FormControl variant={'outlined'}
                     sx={{
                       padding: '4px',
                       minWidth: 200,
                       maxWidth: 200,
                     }}

        >
          <Select
            value={selectedCps}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value={'all'}>
              Alle Ladepunkte
            </MenuItem>
            {chargingPoints?.map((cp) => (
              <MenuItem key={cp.id}
                        value={cp}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
              >
                {cp.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <TimeWindowPicker
        timeWindow={timeWindow}
        timeWindows={timeWindows}
        selectedDate={selectedDate}
        handleTimeChange={handleTimeChange}
        onDateChange={onDateChange}
      />

      <ChargingProcessList allChargingPoints={selectedCps === 'all' ? chargingPoints : selectedCps}
                           processesByPoints={data} totalTime={totalTime} totalAmount={totalAmount}
                           filtered={selectedCps !== 'all'}/>
      <Grid container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 2,
            }}
      >
        <Grid item>
          {!last &&
            <LoadMoreButton
              onClick={() => {
                fetchMoreData()
              }}
              label={SHOW_MORE_DATA_LABEL}
            />
          }
        </Grid>
      </Grid>
      <GeneralFab
        text={EXPORT_LABEL}
        onClick={handleExport}
        id={'exportFab'}
      >
        <SaveAltIcon/>
      </GeneralFab>
    </Grid>
  )
}

export default ChargingProcessesContainer