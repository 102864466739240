import * as React from 'react'
import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { IconButton, ListItemIcon } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ListItemText from '@mui/material/ListItemText'
import InputIcon from '@mui/icons-material/Input'
import { logoutUser } from '../../../../store'
import { useNavigate } from 'react-router'
import { hasRequiredRole } from '../../../../security/roles'
import { userElements } from './TopbarRoutes'

const UserMenu = ({ userRoles, clientId }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const pages = clientId ? userElements.general : userElements.global

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)

  }
  const handleSignOut = () => {
    logoutUser()
  }

  const navigateToSettings = () => {
    if (clientId) {
      navigate(`/client/${clientId}/user/settings`)
    } else {
      navigate('/user/settings')
    }
  }

  const userPages = pages
    .filter(page => hasRequiredRole(page.roles, userRoles))

  return (
    <React.Fragment>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => handleClick(e)}
        sx={{
          color: 'primary.contrastText',
        }}
      >
        <PersonIcon/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userPages.map((page, i) => (
          <MenuItem key={i} onClick={() => navigateToSettings()}>
            <ListItemIcon>
              {React.createElement(page.icon)}
            </ListItemIcon>
            <ListItemText>{page.title}</ListItemText>
          </MenuItem>
        ))}
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <InputIcon/>
          </ListItemIcon>
          <ListItemText>Ausloggen</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default UserMenu