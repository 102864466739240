import React from 'react'
import { Page } from '../../components'
import InstallationContainer from './InstallationContainer'
import { useNavigate } from 'react-router'

const helpText = `
## Inbetriebnahme der Ladepunkte. 
Bevor die Ladestation in ihren regulären Betriebsmodus übergehen kann, muss die Phasenzuordnung der Ladepunkte geprüft werden. Dazu werden folgende Schritte durchgeführt:

1. Ladepunkt aktivieren
2. Testadapter oder Fahrzeug am Ladepunkt anschliessen.
3. Mit dem Fahrzeug oder Testadapter verbunden Ladepunkt erscheint auf der Anzeige der verbundenen Ladepunkte.
4. Die aktuelle Ladeleistung am Ladepunkt muss nun mit der Phasenzuordnung übereinstimmen:
  - ist das nicht der Fall, ist entweder die Verdrahtung fehlerhaft oder die Konfiguration stimmt nicht mit der Verdrahtung überein 
  - die Konfiguration des Ladepunktes mit einem Klick auf das  **Editier-Icon** (Stift) geändert werden
  - je nach LMS-Version können Phase, die Zuordnung zum Ladepunkt Controller und die Anschluss Nr. am Ladepunkt Controller eingestellt werden
  - jede Änderung muss mit dem Speichern-Button bestätigt werden
  - die Aktualisierung der LMS Software kann einige Sekunden in Anspruch nehmen, unter dem Menüpunkt ‚Meldungen‘, kann geprüft werden, ob das LMS nach einer Änderung neu gestartet wurde.
5. Diese Schritte für alle Ladepunkte wiederholen.
`

const InstallationPage = ({ roles }) => {
  const navigate = useNavigate()

  return (
    <Page viewName={'Inbetriebnahme'} onBack={() => navigate(-1)} helpText={helpText} helpTitle={'Inbetriebnahme'} roles={roles}>
      <InstallationContainer/>
    </Page>
  )
}

export default InstallationPage
