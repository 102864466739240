import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop, LoadMoreButton, NavigateFab } from '../../../components'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import FirmwareTable from './FirmwareTable'
import FirmwareList from './FirmwareList'
import AddIcon from '@mui/icons-material/Add'
import { downloadBlob, isEmpty } from '../../../utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import FirmwareMoreSelect from './FirmwareMoreSelect'
import { checkExpired } from '../../../store/firmwarestatus/firmwareStatusSlice'

const ButtonRoot = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '18px',
})

const FirmwareTab = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userRoles = useSelector(state => getRoles(state))
  const { status } = useSelector(state => state.firmwareStatus)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState()
  const [data, setData] = useState({})
  const [content, setContent] = useState([])
  const [selectedFirmware, setSelectedFirmware] = useState({})
  const [moreAnchore, setMoreAnchor] = useState()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const FIRMWARES_URL = '/api/administration/charging-point-firmwares'
  const FIRMWARE_URL = '/api/administration/charging-point-firmwares/{manufacturer}/{version}'

  const fetchData = (cursor) => {
    setLoading(true)
    let queryParams = cursor ? { cursor: cursor } : {}
    backendApiClient.get(FIRMWARES_URL, queryParams)
      .then((response) => {
        setData(response)
        if (cursor) {
          setContent(content.concat(response.content))
        } else {
          setContent(response.content)
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => setLoading(false))

  }

  const deleteFirmware = () => {
    setLoading(true)
    setDeleteOpen(false)
    const url = FIRMWARE_URL.replace(/{manufacturer}/g, itemToDelete.manufacturer)
      .replace(/{version}/g, itemToDelete.version)
    backendApiClient.delete(url)
      .then(() => {
        fetchData()
      })
      .catch(() => {
        enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten, bitte versuchen Sie es erneut', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  const downloadFirmware = (entry) => {
    setLoading(true)
    const url = FIRMWARE_URL.replace(/{manufacturer}/g, entry.manufacturer)
      .replace(/{version}/g, entry.version)
    const fileName = entry.filePath.split('/')
      .pop()
    backendApiClient.fileGet(url + '/download')
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers.contentType })
        downloadBlob(blob, fileName)
      })
      .catch(() => {
        enqueueSnackbar('Es gab ein Fehler beim Herunterladen, bitte versuchen Sie es erneut!', {
          variant: 'error',
        })
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }

  const handleDialogOpen = (entry) => {
    try {
      setItemToDelete(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
      setMoreAnchor(undefined)
    } catch (e) {
      throw new Error(e)
    }
  }

  const showFirmwareUpdateIcon = (firmware) => {
    return !(isEmpty(status[firmware.manufacturer]) || isEmpty(status[firmware.manufacturer][firmware.version]))
  }

  useEffect(() => {
    fetchData()
    dispatch(checkExpired())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleEdit (entry) {
    navigate(`${entry.manufacturer}/${entry.version}/edit`, { state: { firmware: entry } })
  }

  function handleInstall (entry) {
    navigate(`${entry.manufacturer}/${entry.version}/install`, { state: { firmware: entry } })
  }

  function handleUpdateStatus (entry) {
    navigate(`${entry.manufacturer}/${entry.version}/install/status`, { state: { firmware: entry } })
  }

  const selectMore = (firmware, anchor) => {
    setSelectedFirmware(firmware)
    setMoreAnchor(anchor)
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={deleteFirmware}
        dialogText={'Soll dieser Eintrag wirklich gelöscht werden?'}
      />
      {smallScreen ?
        <FirmwareList entry={content} handleEdit={handleEdit} handleInstall={handleInstall} handleDelete={handleDialogOpen} handleUpdateStatus={handleUpdateStatus} handleDownload={downloadFirmware}
                      allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>
        :
        <FirmwareTable content={content} selectMore={selectMore} handleUpdateStatus={handleUpdateStatus} showFirmwareUpdateIcon={showFirmwareUpdateIcon}
                       allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>
      }
      <ButtonRoot>
        {data?.last === false ?
          <LoadMoreButton
            onClick={() => {fetchData(data.pageable.cursor)}}
            label={'Weitere Versionen anzeigen'}
          />
          :
          <></>
        }
      </ButtonRoot>
      <NavigateFab
        path={`new`}
        text={'neu'}
      >
        <AddIcon/>
      </NavigateFab>
      <FirmwareMoreSelect firmware={selectedFirmware} anchor={moreAnchore} setAnchor={setMoreAnchor} handleEdit={handleEdit} handleInstall={handleInstall} handleDelete={handleDialogOpen}
                          handleDialogClose={handleDialogClose}/>
    </React.Fragment>
  )
}

export default FirmwareTab