import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import backendApiClient from '../../apiClient/apiClient'
import { LoadingBackdrop } from '../../components'
import { useParams } from 'react-router'
import LmsWidget from '../../components/crud/widgets/LmsWidget'

const EditLms = (props) => {
  const { clientId } = useParams()
  const { onAfterSubmit, groups, fetchGroupData } = props
  const CUSTOMER_URL = '/api/client/' + clientId + '/customer'
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    backendApiClient.get(CUSTOMER_URL)
      .then((response) => {
        setData({
          ...response,
          lmsVersion: response.lmsVersion != null ? response.lmsVersion : '1',
          organisationId: response.organisationId ? response.organisationId : '',
        })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  useEffect(() => {
    fetchGroupData(clientId, setLoading)
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <LmsWidget
        data={data}
        groups={groups}
        onAfterSubmit={onAfterSubmit}
        url={CUSTOMER_URL}
      />
    </div>
  )
}

EditLms.propTypes = {
  onAfterSubmit: PropTypes.func,
}

export default EditLms
