import React from 'react'
import Typography from '@mui/material/Typography'

const DashboardStatus = ({ status }) => {
  try {
    switch (status) {
      case 'ONLINE' :
        return <Typography component={'span'} sx={{ color: 'success.main' }} display={'inline'}
                           variant={'body2status'}>Online</Typography>
      case 'OFFLINE':
        return <Typography component={'span'} color={'error'} display={'inline'}
                           variant={'body2status'}>Offline</Typography>
      case 'UNKNOWN':
      default:
        return <Typography component={'span'} color={'secondary'} display={'inline'} variant={'body2status'}>Noch
          nicht konfiguriert</Typography>
    }
  } catch (e) {
    throw new Error(e)
  }
}

export default DashboardStatus
