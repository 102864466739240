import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'

const LogoFL = styled('img')(({ theme }) => ({
  width: 220,
  display: theme.displayFlotteladen,
}))

const Topbar = props => {
  const { className, ...rest } = props

  return (
    <AppBar {...rest}
            elevation={0}
            className={className}
            position="fixed"
            color={'primary'}
            sx={{ boxShadow: '0px 8px 8px rgba(0,0,0,0.25) !important' }}
    >
      <Toolbar>
        <RouterLink to="/">
          <LogoFL alt="Flotteladen" src="/assets/images/FL_Logo_solo_210525.svg"/>
        </RouterLink>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
