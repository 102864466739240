import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import PowerHistoryMarkerChart from '../../../../components/PowerHistoryChart/PowerHistoryMarkerChart'
import { fetchMetrics, fetchMetricsHistoryForChargingPowerMeter, fetchMetricsHistoryForMainPowerMeter } from '../../../../store/metrics/metricsSlice'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const PowerHistoryDoEventChartContainer = ({ timeSpan, markerData }) => {
  const { clientId } = useParams()
  const [dataConsumption, setDataConsumption] = useState([])
  const [dataSurplus, setDataSurplus] = useState([])

  const dispatch = useDispatch()
  const metrics = useSelector(state => state.metrics)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const fetchData = () => {
    try {
      dispatch(fetchMetrics(clientId))
      dispatch(fetchMetricsHistoryForMainPowerMeter(clientId))
      dispatch(fetchMetricsHistoryForChargingPowerMeter(clientId))
    } catch (e) {
      throw new Error(e)
    }
  }

  function fillData (data) {
    let dimDataConsumption = []
    let dimDataSurplus = []
    data.forEach((item) => {
      dimDataConsumption.push([item.timestamp, item.total])
      dimDataSurplus.push([item.timestamp, item.total * (-1)])
    })
    setDataConsumption(dimDataConsumption)
    setDataSurplus(dimDataSurplus)
  }

  useEffect(() => {
    try {
      if (metrics.chargingPowerMeter.length > 0) {
        fillData(metrics.mainPowerMeter)
      }
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics.gridPowerLimit, metrics.chargingPowerLimit])

  return (
    <RootDiv>
      <PowerHistoryMarkerChart
        dataConsumption={dataConsumption}
        dataSurplus={dataSurplus}
        timeSpan={timeSpan}
        gridPowerLimit={metrics.gridPowerLimit?.value}
        chargingPowerLimit={metrics.chargingPowerLimit?.value}
        markerData={markerData}
      />
    </RootDiv>
  )
}

export default PowerHistoryDoEventChartContainer