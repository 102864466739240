import * as yup from 'yup'
import {
  ERROR_CHARGEPOINT_ID_DUPLICATE,
  ERROR_CHARGEPOINT_ID_EMPTY,
  ERROR_CHARGEPOINT_ID_INVALID,
  ERROR_IP_DUPLICATE,
  ERROR_IP_INVALID,
  ERROR_MANUFACTURER_IP_MISSING,
  ERROR_NAME_EMPTY,
  ERROR_SERIALNUMBER_DUPLICATE,
  ERROR_SERIALNUMBER_LENGTH,
  ERROR_SERIALNUMBER_NOT_A_NUMBER,
} from './ChargingPointVersionTwoConstants'

export const chargingPointVersionTwoValidation = (chargingPoints) => yup.object({
  name: yup.string()
    .required(ERROR_NAME_EMPTY),
  serialNumber: yup.string()
    .required(ERROR_SERIALNUMBER_LENGTH)
    .max(30, ERROR_SERIALNUMBER_LENGTH)
    .test('ERROR_SERIALNUMBER_DUPLICATE', ERROR_SERIALNUMBER_DUPLICATE, function (serialNumber) {
      const parent = this.parent
      if(chargingPoints == null || !chargingPoints) {
        return true
      }
      return chargingPoints.find((point) => point.serialNumber.toString() === serialNumber.toString() && point.id !== parent?.id) === undefined
    })
    .test('ERROR_SERIALNUMBER_NOT_A_NUMBER', ERROR_SERIALNUMBER_NOT_A_NUMBER, function (serialNumber) {
      const { manufacturer } = this.parent
      return !((manufacturer === 'flotteladen' || manufacturer === 'amperfied') && isNaN(serialNumber))
    }),
  chargingBoxType: yup.string(),
  manufacturer: yup.string()
    .required(),
  ipAddress: yup.string()
    .test('ERROR_IP_INVALID', ERROR_IP_INVALID, function (ip) {
      return ip === undefined || ip.trim() === '' ? true : ip.split('.')
        .find(i => parseInt(i, 10) > 255) === undefined
    })
    .test('ERROR_MANUFACTURER_IP_MISSING', ERROR_MANUFACTURER_IP_MISSING, function (ip) {
      const { manufacturer } = this.parent
      return !(ip === '0.0.0.0' && (manufacturer === 'alfen' || manufacturer === 'abl' || manufacturer === 'amperfied'))
    })
    .test('ERROR_IP_DUPLICATE', ERROR_IP_DUPLICATE, function (ipAddress) {
      const parent = this.parent
      if (chargingPoints && ipAddress !== '0.0.0.0') {
        return chargingPoints.find(point => point.ipAddress === ipAddress && point.id !== parent?.id) === undefined
      }
      return true
    }),
  twinName: yup.string()
    .nullable()
    .when(['chargingBoxType', 'manufacturer'], {
      is: (chargingBoxType, manufacturer) => chargingBoxType === 'twin' && (manufacturer === 'abl' || manufacturer === 'alfen'),
      then: () => yup.string()
        .required(ERROR_NAME_EMPTY),
    }),
  chargePointId: yup.string()
    .nullable()
    .when(['manufacturer'], {
      is: (manufacturer) => manufacturer === 'abl' || manufacturer === 'alfen',
      then: () => yup.string()
        .required(ERROR_CHARGEPOINT_ID_EMPTY)
        .test('ERROR_CHARGEPOINT_ID_INVALID', ERROR_CHARGEPOINT_ID_INVALID, function (chargePointId) {
          return !(chargePointId === null || (chargePointId.length < 1 || chargePointId.length > 30))
        })
        .test('ERROR_CHARGEPOINT_ID_DUPLICATE', ERROR_CHARGEPOINT_ID_DUPLICATE, function (chargePointId) {
          if(chargingPoints == null || !chargingPoints) {
            return true
          }
          const parent = this.parent
          if(parent.id) {
            return chargingPoints.find((point) => point.chargePointId === chargePointId && point.id !== parent?.id) === undefined
          } else {
            return chargingPoints.find((point) => point.chargePointId === chargePointId) === undefined
          }
        }),
    }),
})
