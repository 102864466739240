import { alpha, colors } from '@mui/material'

const white = '#FFFFFF'
const black = '#000000'

const palette = {
  black,
  white,
  primary: {
    light: '#53be70',
    main: '#3eb660',
    dark: '#30ad51',
    contrastText: white,
    alternateContrastText: '#3eb660',
    unselectedIcon: alpha(white, 0.5),
    selectedSidebarItem: alpha(white, 0.9),
    hoveredSidebarItem: alpha(white, 0.4),
    zoneListItem: '#8b959a',
    gradient: 'linear-gradient(180deg, #3eb660 10%, #2A3332 100%)',
    unselectedText: '#86BFA3',
    alternateTableHeader: black,
    errorPageColor: '#213D2D',
    checkBoxContrast: '#3eb660',
    childBackdrop: colors.grey[100],
  },
  secondary: {
    light: '#8b959a',
    main: '#5e676c',
    dark: '#343d41',
    contrastText: white,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: '#3eb660',
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#5e676c',
    secondary: '#5e676c',
    link: '#3eb660',
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  border: {
    light: colors.grey[200],
  },
  circularGradient: {
    start: '#30ad51',
    middle: '#B2DF8A',
    end: colors.red[700],
  },
  graph: {
    mainColor1: '#6DBA78',
    mainColor2: '#253D2C',
    text: black,
  },
  icon: '#546e7a',
  divider: colors.grey[200],
}

export default palette
