import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { Divider, Drawer, Link } from '@mui/material'
import { SidebarNav } from './components'
import Typography from '@mui/material/Typography'
import { useParams, useRouteLoaderData } from 'react-router'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

const CopyrightBox = styled('span')(({ theme }) => ({
  display: theme.displayFlotteladen,
}))

const RootDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2),
}))

const Sidebar = ({ open, variant, onClose }) => {
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const { clientId } = useParams()
  const { pathname } = useLocation()

  const clientRootData = useRouteLoaderData('clientRoot')
  const rootData = useRouteLoaderData('root')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Drawer
      PaperProps={{ elevation: 0 }}
      onClose={onClose}
      open={open}
      variant={variant}
      sx={(theme) => ({
        '.MuiDrawer-paper': {
          width: 240,
          background: theme.palette.primary.gradient,
          border: 'none',
          [theme.breakpoints.up('lg')]: {
            marginTop: 8,
            height: 'calc(100% - 64px)',
          },
        },
      })}
    >
      <RootDiv>
        {!largeScreen &&
          <div>
            <Typography
              sx={{ color: 'primary.contrastText' }}
              variant="h5">
              {clientRootData?.customer?.name}
            </Typography>
            <Divider sx={(theme) => ({ margin: theme.spacing(2, 0) })}/>
          </div>
        }
        <SidebarNav
          clientId={clientId}
          lmsNumber={rootData?.systemStatusList.length}
          hasEms={clientRootData?.customer?.hasEms}
        />
        <Box sx={{ flexGrow: 1 }}/>
        <Typography
          variant="body1"
          sx={{
            paddingBottom: 1,
            color: 'white',
          }}
        >
          <Link
            component="a"
            href="/imprint"
            sx={{ color: 'text.link' }}
          >
            Impressum
          </Link>
          {' '}
          <Link
            component="a"
            href="/licenses"
            sx={{ color: 'text.link' }}
          >
            Lizenzen
          </Link>
          <CopyrightBox>
            {' '}&copy;{' '}
            <Link
              component="a"
              href="https://www.flotteladen.de/"
              target="_blank"
              sx={(theme) => ({
                display: theme.displayFlotteladen === 'block' ? 'inline' : 'none',
                color: theme.palette.text.link,
              })}
            >
              Flotteladen
            </Link>
            . {new Date().getFullYear()}
          </CopyrightBox>
        </Typography>
      </RootDiv>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
