import React from 'react'
import { IconButton, TableBody, TableHead, TableRow } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import LinesEllipsis from 'react-lines-ellipsis'
import EditIcon from '@mui/icons-material/Edit'
import Table from '@mui/material/Table'
import { useNavigate } from 'react-router'
import { DateTime } from 'luxon'

const ProcessTable = ({ data, url }) => {
  const navigate = useNavigate()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ borderBottom: 'none' }}>
            Datum
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            Grund
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            Bemerkung
          </TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            Erstellt von
          </TableCell>
          <TableCell align={'right'} style={{ width: '5%', borderBottom: 'none' }}/>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.length > 0 && data.map((entry) => (
          <TableRow key={entry.id}>
            <TableCell style={{ width: '19%' }}>
              {DateTime.fromISO(entry.creationDate)
                .toFormat('dd.MM.yyyy - HH:mm')}
            </TableCell>
            <TableCell style={{ width: '26%' }}>
              <LinesEllipsis
                text={entry.reason}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"/>
            </TableCell>
            <TableCell style={{ width: '37%' }}>
              <LinesEllipsis
                text={entry.comments}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"/>
            </TableCell>
            <TableCell style={{ width: '13%' }}>
              {entry.responsible}
            </TableCell>
            <TableCell align={'right'} style={{ width: '5%' }}>
              <IconButton size={'small'}
                          onClick={() => navigate(`${url}/edit/${entry.id}`, { state: { process: entry } })}
              >
                <EditIcon/>
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ProcessTable