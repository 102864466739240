import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import PhaseBox from '../../components/PhaseBox/PhaseBox'
import ChargingPointInfoBox from '../../components/ChargingPointInfoBox/ChargingPointInfoBox'
import LoadingPointRequirementInfoText from '../../components/LoadingPointRequirementInfoText/LoadingPointRequirementInfoText'
import { LoadingBackdrop, Poller } from '../../components'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { fetchMetrics } from '../../store/metrics/metricsSlice'
import backendApiClient from '../../apiClient/apiClient'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import { useSnackbar } from 'notistack'

const InstallationContainer = () => {

  const navigate = useNavigate()
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [cpSelection, setCpSelection] = React.useState(1)
  const [selectedChargingPoints, setSelectedChargingPoints] = useState([])
  const { lmsVersion } = useRouteLoaderData('clientRoot')

  const [loading, setLoading] = useState(false)

  const URL_EDIT_CHARGINGPOINT = '/api/client/{client}/configuration/chargingpoints/{chargingPointId}'
  const URL_EDIT_EXTENDER = '/api/client/{client}/configuration/chargingpoints/{chargingPointId}/extender/{chargingPointExtenderId}'
  const GRID_POWER = 'Netzleistung'
  const LOADING_PERFORMANCE = 'Ladeleistung'

  const dispatch = useDispatch()
  const metrics = useSelector(state => state.metrics.metrics)

  useEffect(() => {
    getMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const updateChargingPointExtenderConfig = (clientId, parentId, chargingPointExtender) => {
    const url = URL_EDIT_EXTENDER
      .replace(/{client}/g, clientId)
      .replace(/{chargingPointId}/g, parentId)
      .replace(/{chargingPointExtenderId}/g, chargingPointExtender.id)

    backendApiClient.put(url, chargingPointExtender)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const updateChargingPointConfig = (clientId, updatedChargingPoint) => {
    const url = URL_EDIT_CHARGINGPOINT
      .replace(/{client}/g, clientId)
      .replace(/{chargingPointId}/g, updatedChargingPoint.id)

    backendApiClient.put(url, updatedChargingPoint)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const getMetrics = () => {
    try {
      if (clientId) {
        dispatch(fetchMetrics(clientId))
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    findSelectedChargingPoints()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics])

  const handleActivation = (chargingPoint) => {
    let parent = getParent(chargingPoint)
    try {
      const updatedChargingPoint = { ...chargingPoint, activated: !chargingPoint.activated }
      if (parent) {
        updateChargingPointExtenderConfig(clientId, parent.id, updatedChargingPoint)
      } else {
        updateChargingPointConfig(clientId, updatedChargingPoint)
      }
      setLoading(true)
      getMetrics()
    } catch (e) {
      throw new Error(e)
    }
  }

  const editChargingPoint = (chargingPoint) => {
    let parent = getParent(chargingPoint)
    if (parent) {
      navigate(`../settings/chargingpoints/edit/${parent.id}/extender/${chargingPoint.id}`)
    } else {
      navigate(`../settings/chargingpoints/edit/${chargingPoint.id}`)
    }
  }

  const getParent = (chargingPoint) => {
    return metrics?.chargingPoints.find((cp) => cp.extenders && cp.extenders.find((e) => e.id === chargingPoint?.id))
  }

  const handleChange = (event) => {
    setCpSelection(event.target.value)
    findSelectedChargingPoints(event.target.value)
  }

  const findSelectedChargingPoints = (selectionValue = cpSelection) => {
    if (selectionValue === 1) {
      setSelectedChargingPoints(metrics?.chargingPoints || [])
    } else {
      setSelectedChargingPoints(getConnectedChargingPoints() || [])
    }
  }

  function getConnectedChargingPoints () {
    return metrics?.chargingPoints?.filter((chargingPoint) => {
        const status = chargingPoint.currentStatus?.status
        return status !== undefined && status !== 'AVAILABLE' && status !== 'FAULTY_LOCKED' && status !== 'UNDEFINED'
      },
    )
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Poller action={() => {
        getMetrics()
        setLoading(false)
      }} interval={5000}/>
      <LoadingBackdrop open={loading}/>

      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <PhaseBox title={GRID_POWER} metric={metrics.gridPower}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <PhaseBox title={LOADING_PERFORMANCE} metric={metrics.chargingPower}/>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12} md={6} paddingTop={2} container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="lp-select-label">Auswahl Ladepunkte</InputLabel>
              <Select
                labelId="lp-select-label"
                id="lp-select"
                value={cpSelection}
                label="Auswahl Ladepunkte"
                onChange={handleChange}
              >
                <MenuItem value={1}>Alle</MenuItem>
                <MenuItem value={2}>Verbundene</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {selectedChargingPoints && selectedChargingPoints.length === 0 ?
            <Grid item xs={12}><LoadingPointRequirementInfoText/></Grid>
            :
            <Grid item xs={12}>
              {selectedChargingPoints
                .map((chargingPoint) => {
                  return (<div style={{ paddingTop: 15 }} key={chargingPoint.id}>
                    <ChargingPointInfoBox chargingPoint={chargingPoint}
                                          lmsVersion={lmsVersion}
                                          onEdit={() => editChargingPoint(chargingPoint)}
                                          onActivate={() => handleActivation(chargingPoint)}
                    />
                  </div>)
                })}
            </Grid>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InstallationContainer