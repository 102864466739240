import { Badge, Checkbox, ListItemButton, ListItemText, Popover } from '@mui/material'
import React from 'react'
import ReplayIcon from '@mui/icons-material/Replay'
import ListItem from '@mui/material/ListItem'
import FilterListIcon from '@mui/icons-material/FilterList'
import IconButton from '@mui/material/IconButton'

const FilterPopover = ({ selectOptions, filter, getFilterLabel, handleToggle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {
          selectOptions.map((option) => (
            option.startsWith('SEPARATOR') ?
              <ListItem key={option}
                        value={option}
                        sx={{
                          opacity: '1 !important',
                          color: 'primary.main',
                          paddingTop: '12px',
                          paddingBottom: 0,
                          paddingLeft: '16px',
                          width: '270px',
                        }}
                        disablePadding>
                <ListItemText primary={getFilterLabel(option)} sx={{ color: 'primary.main' }}
                              primaryTypographyProps={{
                                color: 'primary',
                              }}/>
              </ListItem>
              :
              <ListItem
                key={option}
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: '32px',
                }}
                disablePadding>
                <ListItemButton
                  onClick={() => handleToggle(option)}>
                  <Checkbox color={'secondary'} checked={filter.indexOf(option) > -1}/>
                  <ListItemText primary={getFilterLabel(option)}/>
                </ListItemButton>
              </ListItem>
          ))
        }
        <ListItem key={'CLEAR'} sx={{ height: '54px' }} disablePadding>
          <ListItemButton onClick={() => handleToggle('CLEAR')}>
            <ReplayIcon sx={{ marginRight: '5px' }}/>
            <ListItemText primary={'Filter zurücksetzen'}/>
          </ListItemButton>
        </ListItem>
      </Popover>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={filter.length} color="primary">
          <FilterListIcon/>
        </Badge>
      </IconButton>
    </div>
  )
}

export default FilterPopover