import React from 'react'
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'

const ConnectionStatusIcon = ({ status }) => {
  switch (status) {
    case 'ONLINE' :
      return <OfflineBoltIcon color={'success'}/>
    case 'OFFLINE':
      return <OfflineBoltIcon sx={{ color: 'warning.light' }}/>
    case 'ERROR':
      return <OfflineBoltIcon color={'error'}/>
    case 'UNKNOWN':
    default:
      return <OfflineBoltIcon color={'secondary'}/>
  }
}

export default ConnectionStatusIcon
