import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useState } from 'react'
import { Popover } from '@mui/material'

const MoreOptionsPopover = ({ children, id, className }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (

    <div>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon/>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id={id ? 'moreOptions-' + id : 'moreOptions'}
        key={id ? 'moreOptions-' + id : 'moreOptions'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={className}>
          {children}
        </div>
      </Popover>
    </div>
  )

}

export default MoreOptionsPopover