import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Page } from '../../components'
import UserManagementWidget from '../../components/crud/widgets/UserManagementWidget'
import { backendApiClient } from '../../apiClient'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'

const EditUserPage = ({ roles }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const user = location.state?.user || {}
  const [loading, setLoading] = useState(false)

  const EDIT_USER_TITLE = 'Nutzer editieren'
  const USER_MANAGEMENT = 'Nutzerverwaltung'

  const USER_PROFILES_API = '/api/user/profiles'

  const save = (data) => {
    setLoading(true)
    backendApiClient.put(USER_PROFILES_API, data, { userProfileId: user.userId })
      .then(() => {
        enqueueSnackbar('Nutzer erfolgreich gespeichert!', {
          variant: 'success',
        })
        navigate('..')
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={EDIT_USER_TITLE} onBack={() => navigate(-1)} pageTitle={USER_MANAGEMENT} roles={roles}>
      <UserManagementWidget save={save} user={user} disabled={true} loading={loading}/>
    </Page>
  )
}

export default EditUserPage