import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

const CustomMultilineTextField = (props) => {
  const [value, setValue] = useState(props.value)

  const onChange = (event) => {
    let val = event.target.value
    if (props.type === 'number' && val.length > 0) {
      val = parseInt(event.target.value)
    }
    setValue(val)
    props.onChange(val)
  }

  useEffect(() => {
    if (props.value === null || props.value === undefined) {
      setValue(props?.props.default)
    } else {
      setValue(props.value)
    }
  }, [props])

  return (
    <div>
      <TextField
        multiline
        rows={props.rows}
        disabled={props.disabled}
        sx={{
          color: 'text.primary',
          width: '100%',
        }}
        variant={'outlined'}
        value={value}
        onChange={onChange}
        helperText={props?.error?.__errors?.length > 0 ? props.error.__errors[0] : props?.props.description}
        error={!!props?.error && props?.error.__errors.length > 0}
        InputProps={props.InputProps}
      />
    </div>
  )
}

export default CustomMultilineTextField