import { FormControl, MenuItem, Select, useTheme } from '@mui/material'
import React, { useState } from 'react'
import TabPanel, { tabIdProps } from '../../components/TabPanel/TabPanel'
import { Outlet, useLocation, useNavigate } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CustomTab, CustomTabs } from '../../components/TabMenu/TabMenu'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const AdministrationTabContainer = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const url = useLocation().pathname
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const FIRST_PAGE = 'Firmware'

  const mapURLtoTab = (url) => {
    switch (true) {
      case url.includes('firmware'):
      default:
        return 0
    }
  }
  const [currentIndex, setCurrentIndex] = useState(url ? mapURLtoTab(url) : 0)

  const handleSelectChange = (newIndex) => {
    setCurrentIndex(newIndex)
    switch (newIndex) {
      case 0:
      default:
        navigate('../firmware')
        break
    }
  }

  return (
    <RootDiv>
      {smallScreen ?
        <FormControl sx={{ width: '100%' }}>
          <Select
            labelId="user-tab-label"
            id="user-tab-select"
            value={currentIndex}
            onChange={event => handleSelectChange(event.target.value)}
          >
            <MenuItem value={0}>{FIRST_PAGE}</MenuItem>

          </Select>
        </FormControl>
        :
        <CustomTabs
          value={currentIndex}
          onChange={(event, idx) => handleSelectChange(idx)}
          indicatorColor="primary"
          textColor="primary"
          aria-label="release-tabs"
        >
          <CustomTab label={FIRST_PAGE} {...tabIdProps(0)} value={0}/>
        </CustomTabs>
      }
      <TabPanel value={currentIndex} index={currentIndex}>
        <Outlet/>
      </TabPanel>
    </RootDiv>
  )
}

export default AdministrationTabContainer