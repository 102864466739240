import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { TextField } from 'formik-mui'
import { Field, Form, Formik } from 'formik'
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD } from '../../constants'
import { Button, IconButton, InputAdornment, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useSelector } from 'react-redux'
import { CONFIRM_RESET_PASSWORD_FULFILLED } from '../../store/auth/authSlice'
import { styled } from '@mui/material/styles'

const schema = {
  email: VALIDATION_SCHEMA_EMAIL,
  code: {
    presence: { allowEmpty: false, message: '^Verifizierungscode erforderlich' },
    format: {
      pattern: /[0-9]{6}/,
      message: '^Der Code besteht aus 6 Zahlen',
    },
  },
  password: VALIDATION_SCHEMA_PASSWORD,
  confirm: {
    presence: { allowEmpty: false, message: '^Bitte Passwort wiederholen' },
    equality: {
      attribute: 'password',
      message: '^Passwörter stimmen nicht überein',
    },
  },
}

const TextFieldLabelBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const ResetPasswordForm = ({ onSubmit, code, email, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const error = useSelector(state => state.auth.error)
  const challenge = useSelector(state => state.auth.challenge)

  const navigate = useNavigate()

  useEffect(() => {
    if (challenge === CONFIRM_RESET_PASSWORD_FULFILLED) {
      navigate('sign-in')
      return
    }
    if (error?.name === 'CodeMismatchException') {
      enqueueSnackbar('Ungültiger Verifizierungscode', {
        variant: 'error',
      })
    } else if (error?.name === 'LimitExceededException') {
      enqueueSnackbar('Versuchslimit überschritten, bitte versuchen Sie es nach einiger Zeit erneut.', {
        variant: 'error',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, challenge])

  return (
    <div {...rest}>
      <Typography
        sx={{ marginBottom: 2 }}
        variant="h2">
        Neues Passwort vergeben
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1">
        Geben Sie Ihre E-Mail-Adresse und den Verifizierungscode an, den wir Ihnen per Email zugesendet haben um ein neues Passwort vergeben zu können.
      </Typography>

      <Formik
        validateOnMount={false}
        initialValues={{
          email: email || '',
          code: code || '',
          password: '',
          confirm: '',
        }}
        validate={values => {
          return validate(values, schema, { format: 'first' })
        }}
        onSubmit={values => {
          onSubmit(values)
        }}>
        {({ isValid }) => (
          <Form>
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                E-Mail
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="email"
              type="email"
              component={TextField}
            />
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                Verifizierungscode
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="code"
              type="text"
              component={TextField}
            />
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                Passwort
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={TextField}
            />
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                Passwort wiederholen
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="confirm"
              type={showRepeatPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={TextField}
            />
            <Button
              sx={{ marginTop: 2 }}
              id={'submit'}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={!isValid}
              variant="contained">
              <Typography
                sx={{
                  transform: 'skewText',
                  color: 'white',
                }}
              >
                Passwort speichern
              </Typography>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default ResetPasswordForm
