import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { alpha, AppBar, Badge, ClickAwayListener, IconButton, Popper, Toolbar } from '@mui/material'
import { Poller } from '../../../../components'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { useParams, useRouteLoaderData } from 'react-router'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationPopperWithMessage from './components/NotificationPopperWithMessage'
import TranslateMessage from '../../../../pages/Messages/utils/TranslateMessage'
import { backendApiClient } from '../../../../apiClient'
import NotificationPopperWithoutMessage from './components/NotificationPopperWithoutMessage'
import UserMenu from './UserMenu'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getRoles } from '../../../../security/roles'

const RoundingBox = styled('div')(({ theme }) => ({
  display: theme.roundingDisplay,
}))

const RoundingBackground = styled('div')(({ theme }) => ({
  height: theme.spacing(6),
  width: theme.spacing(6),
  marginBottom: '-48px',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('lg')]: {
    marginLeft: 240,
  },
}))

const Rounding = styled('div')(({ theme }) => ({
  borderTopLeftRadius: theme.spacing(6),
  height: theme.spacing(6),
  width: theme.spacing(6),
  marginBottom: '-48px',
  backgroundColor: theme.palette.white,
  [theme.breakpoints.up('lg')]: {
    marginLeft: 240,
  },
}))

const LogoFL = styled('img')(({ theme }) => ({
  width: 220,
  display: theme.displayFlotteladen,
  marginRight: 23,
}))

const SmallLogoFL = styled('img')(({ theme }) => ({
  width: 20,
  marginLeft: 1,
  display: theme.displayFlotteladen,
}))

const Topbar = ({ onSidebarOpen }) => {
  const { clientId } = useParams()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const [count, setCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState()
  const [messages, setMessages] = useState([])
  const [error, setError] = useState(false)

  const open = Boolean(anchorEl)

  const userRoles = useSelector(state => getRoles(state))
  const clientRouteData = useRouteLoaderData('clientRoot')

  const MESSAGE_API = `/api/client/${clientId}/system-messages`

  const handleChange = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    try {
      if (clientId) {
        fetchData()
      }
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const fetchData = () => {
    backendApiClient.get(MESSAGE_API)
      .then((response) => {
        setMessages(response.content)
        setCount(response.content.length)
        setError(false)
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <AppBar
      elevation={0}
      color={'primary'}
      // places AppBar beneath Drawer for proper display mostly in mobile
      sx={{ zIndex: (theme) => theme.zIndex.appBar }}
    >
      <Toolbar style={{
        paddingLeft: 12,
        maxHeight: '64px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Poller
          interval={60000}
          action={() => {
            if (clientId) {
              fetchData()
            }
          }}/>
        <div>
          <RouterLink to="/">
            {smallScreen ?
              <div>
                <SmallLogoFL
                  alt="Flotteladen"
                  src="/assets/images/Logo_small.png"/>
              </div>
              :
              <div>
                <LogoFL
                  alt="Flotteladen"
                  src="/assets/images/FL_Logo_solo_210525.svg"/>
              </div>
            }
          </RouterLink>
        </div>
        {largeScreen &&
          <Typography
            sx={(theme) => ({
              marginLeft: 4,
              color: alpha(theme.palette.primary.contrastText, 0.5),
            })}
            variant={'h3'}>
            {clientRouteData?.customer?.name}
          </Typography>
        }
        <div style={{ flexGrow: 1 }}></div>
        <Box
          display="block"
          displayPrint="none"
          sx={{
            width: 220,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {clientId ?
            <ClickAwayListener onClickAway={handleClose}>
              <IconButton sx={{ color: 'primary.contrastText' }} size="large">
                <Badge color={'error'} badgeContent={count}>
                  <NotificationsIcon onClick={handleChange} messages={messages}/>
                </Badge>
                {count > 0 ?
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                  >

                    <Box paddingTop={5}>
                      {error ? <NotificationPopperWithoutMessage error={error}/> :
                        <NotificationPopperWithMessage
                          clientId={clientId}
                          translate={TranslateMessage}
                          messages={messages.slice(0, 3)}
                          userRoles={userRoles}
                          onOpen={() => {
                          }}
                        />}
                    </Box>
                  </Popper>
                  :
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                  >
                    <Box paddingTop={4}>
                      <NotificationPopperWithoutMessage error={error}/>
                    </Box>
                  </Popper>
                }
              </IconButton>
            </ClickAwayListener> : ''}
          <UserMenu userRoles={userRoles} clientId={clientId}/>
          {!largeScreen &&
            <Box display="block" displayPrint="none">
              <IconButton sx={{
                color: 'primary.contrastText',
              }}
                          onClick={onSidebarOpen} size="large">
                <MenuIcon/>
              </IconButton>
            </Box>
          }
        </Box>
      </Toolbar>
      <RoundingBox>
        <RoundingBackground/>
        <Rounding/>
      </RoundingBox>
    </AppBar>
  )
}

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
}

export default Topbar
