import React from 'react'
import NoChargingPointsScreen from './content/NoChargingPointsScreen'
import ChargingPointsScreen from './content/ChargingPointsScreen'
import { useRouteLoaderData } from 'react-router'

const ChargingPointsTabContainer = () => {

  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const { settings } = useRouteLoaderData('settingsRoot')

  const hasChargingPoints = () => {
    return settings.chargingPoints && settings.chargingPoints.length > 0
  }

  return (
    hasChargingPoints() ?
      <ChargingPointsScreen version={lmsVersion}/>
      :
      <NoChargingPointsScreen version={lmsVersion}/>
  )
}

export default ChargingPointsTabContainer