import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { IconButton, List, ListItemButton, ListItemSecondaryAction, Tooltip } from '@mui/material'
import { Delete, SystemUpdateAlt } from '@mui/icons-material'
import ListItem from '@mui/material/ListItem'

const FirmwareList = ({ entry, handleEdit, handleDelete, handleInstall, handleUpdateStatus, handleDownload, allowEdit }) => {
  return (
    <List sx={{ padding: 0 }}>
      {entry && entry.length > 0 && entry.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItem sx={{ marginBottom: 2 }}>
            <ListItemButton key={index}
                            onClick={() => handleEdit(entry)}
            >
              <ListItemText key={index}
                            sx={{ margin: 0 }}
                            primary={
                              <Typography color={'inherit'}>
                                {entry.manufacturer}
                              </Typography>
                            }
                            secondary={
                              <Typography color="inherit" display={'inline'}
                                          style={{ whiteSpace: 'pre-line' }}>
                                Version: {entry.version}{'\n'}
                                Modell: {entry.model}{'\n'}
                                Kommentar: {entry.comment || ''}
                              </Typography>
                            }/>
            </ListItemButton>
            {allowEdit && (
              <ListItemSecondaryAction style={{ top: '100%' }}>
                <IconButton size="small" onClick={() => handleInstall(entry)}>
                  <Tooltip title={'Version installieren'}>
                    <SystemUpdateAlt/>
                  </Tooltip>
                </IconButton>
                <IconButton size="small" onClick={() => handleDelete(entry)}>
                  <Tooltip title={'Version löschen'}>
                    <Delete/>
                  </Tooltip>
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default FirmwareList