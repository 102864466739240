import React, { useEffect, useState } from 'react'
import { LoadingBackdrop } from '../../../components'
import DigitalOutputsDesktopView from './DigitalOutputsDesktopView'
import DigitalOutputsMobileView from './DigitalOutputsMobileView'
import { useParams } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const DigitalOutputsContainer = ({}) => {
  const { clientId } = useParams()
  const URL = '/api/client/' + clientId + '/ems/configuration/digitaloutputs'
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(URL)
      .then((response) => {
        setData(response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  const toggleOutput = (output) => {
    setLoading(true)
    output.activated = !output.activated
    backendApiClient.put(URL, output)
      .then(() => {
        fetchData()
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      {smallScreen ?
        <DigitalOutputsMobileView data={data} toggleActivation={toggleOutput}/>
        :
        <DigitalOutputsDesktopView data={data} toggleActivation={toggleOutput}/>
      }
    </div>
  )
}

export default DigitalOutputsContainer