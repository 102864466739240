import React from 'react'
import { useLocation } from 'react-router'
import { NavigateFab, Page } from '../../components'
import AddIcon from '@mui/icons-material/Add'
import UserManagementContainer from './UserManagementContainer'

const helpText = `
## Benutzer anlegen und löschen.
`

const UserManagementPage = ({ roles }) => {

  const url = useLocation().pathname
  const NEW_PATH = '/new'
  const USER_MANAGEMENT = 'Nutzerverwaltung'

  return (
    <Page viewName={USER_MANAGEMENT} helpText={helpText} helpTitle={USER_MANAGEMENT} pageTitle={USER_MANAGEMENT} roles={roles}>
      <UserManagementContainer/>
      <NavigateFab path={`${url}${NEW_PATH}`}>
        <AddIcon/>
      </NavigateFab>
    </Page>
  )
}

export default UserManagementPage