import { Accordion, AccordionSummary, Checkbox, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import TypDeclaration from '../TypDeclaration/TypDeclaration'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import { isServicePerson } from '../../security/roles'
import EditIcon from '@mui/icons-material/Edit'
import React from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ChargingPointInfo = ({ item, version, pvSettings, hasPv, userRoles, onActivate, onEditExtender, parent, toggleAccordion, selectMore }) => {
  const isDisconnected = item.controllerConnectionStatus === 'DISCONNECTED'

  const getManufacturerName = (manufacturer) => {
    switch (manufacturer) {
      case 'alfen':
        return 'Alfen'
      case 'abl' :
        return 'ABL'
      case 'amperfied':
        return 'AMP'
      default:
        return 'FL'
    }
  }

  return (
    <React.Fragment>
      <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
      >
        <Grid item
              sx={[
                { paddingLeft: parent && 2 },
                isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
              ]}
              xs={item?.extenders ? 2.8 : 3}>
          <Tooltip title={isDisconnected ? 'Verbindung zum LMS ist getrennt.' : ''}>
            <Typography sx={[
              isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
            ]}
                        onClick={toggleAccordion}>{item.name}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1.5}>
          <Typography sx={[
            isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
          ]}>
            {version >= 2 &&
              (item.connectorId >= 2 ? '' : getManufacturerName(item.manufacturer))}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={[
            isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
          ]}>
            {version >= 2 ?
              item.serialNumber.endsWith('*2') ? '' : item.serialNumber
              :
              <TypDeclaration type={item.type}/>
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={[
            isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
          ]}>
            {version >= 2 ?
              item.currentStatus?.info?.firmware
              :
              item.maxPower && item.maxPower.toString()
                .replace('.', ',') + ' kW'
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {
            item.areaId &&
            <Typography sx={[
              isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
            ]}>
              {item.areaName}
            </Typography>
          }
        </Grid>
        <Grid item xs={0.5} align={'left'}>
          {hasPv && pvSettings?.enabled &&
            (item.pvGuided ?
              <WbSunnyIcon sx={[
                isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
              ]}
                           style={{ marginBottom: '-8px', fill: 'rgba(239,227,49,0.8)' }}/>
              :
              <WbSunnyIcon sx={[
                isDisconnected ? { color: 'text.disabled' } : { color: 'text.primary' },
              ]}
                           style={{ marginBottom: '-8px' }}/>)
          }
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          {onActivate &&
            <Checkbox
              id={`activate-chargingpoint-${item.id}`}
              disabled={isDisconnected || !isServicePerson(userRoles)}
              color="primary"
              checked={item.activated}
              onClick={() => parent ? onActivate(parent, item) : onActivate(item)}
            />
          }
          {!parent ?
            <IconButton id={`more-chargingpoint-${item.id}`} disabled={!isServicePerson(userRoles)}
                        onClick={(event) => selectMore(item, event.currentTarget)}>
              <MoreVertIcon/>
            </IconButton>
            :
            <IconButton id={`edit-chargingpoint-${item.id}`} disabled={!isServicePerson(userRoles)}
                        onClick={() => onEditExtender(parent.id, item.id)}>
              <EditIcon/>
            </IconButton>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const ChargingPointAccordion = ({ item, version, pvSettings, hasPv, userRoles, onActivate, onEditExtender, selectMore }) => {
  const [accordionOpen, setAccordionOpen] = React.useState(false)
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen)
  }
  return (
    <Accordion sx={{
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        marginTop: '0px !important',
        marginBottom: '0px !important',
      },
    }}
               expanded={accordionOpen}
    >
      <AccordionSummary
        expandIcon={item.extenders && <ExpandMoreIcon id={`expand-less-${item.id}`} sx={{ paddingTop: 0 }} onClick={toggleAccordion}/>}
        sx={(theme) => ({
          height: '64px',
          flexDirection: 'row-reverse',
          borderBottom: `1pt solid ${theme.palette.border.light}`,
          paddingLeft: 0,
          paddingRight: 0,
          '& .MuiAccordionSummary-expandIconWrapper': { marginLeft: -0.5 },
        })}
      >
        <ChargingPointInfo item={item}
                           onActivate={onActivate}
                           pvSettings={pvSettings}
                           hasPv={hasPv}
                           userRoles={userRoles}
                           version={version}
                           toggleAccordion={toggleAccordion}
                           selectMore={selectMore}/>

      </AccordionSummary>
      {
        item.extenders && item.extenders.length > 0 &&
        <AccordionDetails
          sx={{
            backgroundColor: 'primary.childBackdrop',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingBottom: '0px',
            paddingTop: '0px',
          }}
        >
          {
            item.extenders.map((extender) => (
              <div
                style={{
                  paddingLeft: '5px',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}
                key={extender.id}>
                <ChargingPointInfo item={extender}
                                   onActivate={onActivate}
                                   onEditExtender={onEditExtender}
                                   pvSettings={pvSettings}
                                   hasPv={hasPv}
                                   userRoles={userRoles}
                                   version={version}
                                   parent={item}
                                   selectMore={selectMore}/>
              </div>
            ))
          }
        </AccordionDetails>
      }
    </Accordion>
  )
}

export default ChargingPointAccordion