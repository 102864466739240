import React from 'react'
import { PowerHistoryChart } from '../../../components'
import { Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const PowerHistoryChartBox = ({ gridPowerLimit, chargingPowerLimit, data }) => {

  const options = {
    gridLimit: gridPowerLimit,
    chargeLimit: chargingPowerLimit,
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid containerspacing={2}>
        <Grid item>
          <Typography
            align={'left'}
            sx={{
              paddingTop: 1,
              fontSize: '20px',
              fontWeight: '600',
            }}>
            Leistungsverlauf
          </Typography>
        </Grid>
        <Grid item height={'300px'}>
          <PowerHistoryChart data={data} options={options}/>
        </Grid>
      </Grid>
    </Paper>

  )
}

export default PowerHistoryChartBox
