import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import muiTypography from './MuiTypography'
import GlobalStyles from './GlobalStyles'
import MuiDropzoneArea from './MuiDropzoneArea'
import MuiCard from './MuiCard'
import MuiChip from './MuiChip'
import MuiSvgIcon from './MuiSvgIcon'

const index = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography: muiTypography,
  MuiCard,
  MuiDropzoneArea,
  MuiChip,
  MuiSvgIcon,
  // apply global styles as Css Baseline override
  MuiCssBaseline: GlobalStyles,
}

export default index
