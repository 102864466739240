import { Button, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const LoadMoreButton = ({ onClick, label }) => {

  return (
    <Button
      onClick={onClick}
      variant={'outlined'}
      color={'secondary'}
    >
      <Typography>
        {label}
      </Typography>
    </Button>
  )
}

LoadMoreButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
}

export default LoadMoreButton