import * as yup from 'yup'

export const validationSchema = yup.object({
  firstName: yup.string()
    .required('Der Vorname darf nicht leer sein'),
  lastName: yup.string()
    .required('Der Nachname darf nicht leer sein'),
  email: yup.string()
    .required('E-Mail darf nicht leer sein')
    .email('Ungültige E-Mail-Adresse'),
  groupName: yup.string()
    .required('Die Berechtigungsart darf nicht leer sein')
})