import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import MessagesContainer from './MessagesContainer'
import meldungen from '../../help-texts/FL_Meldungen.md'

const Messages = ({}) => {
  const [helpText, setHelpText] = useState()
  const HELP_TITLE = 'Hier werden Ihnen die tagesaktuellen Meldungen angezeigt'

  useEffect(() => {
    fetch(meldungen)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Meldungen'} helpTitle={HELP_TITLE} helpText={helpText} pageTitle={'Meldungen'}>
      <MessagesContainer/>
    </Page>
  )
}

export default Messages
