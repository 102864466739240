import React from 'react'
import { Grid, Typography } from '@mui/material'
import ChargingPointSelectionAccordion from './ChargingPointSelectionAccordion'

const InstallFirmwareTable = ({ lmsList, changeSelectedState, manufacturer }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant={'h6'}>
            Ladestationen
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>
            Hersteller
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>
            Firmware Version
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={'h6'} align="center">
            Auswahl
          </Typography>
        </Grid>
      </Grid>
      {
        lmsList && lmsList.length > 0 &&
        lmsList.map((item, index) => <ChargingPointSelectionAccordion key={index} station={item} changeSelectedState={changeSelectedState} manufacturer={manufacturer}/>)
      }
    </div>
  )
}

export default InstallFirmwareTable