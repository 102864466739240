import React from 'react'
import { Checkbox, Grid, Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router'

const DigitalOutputsMobileView = ({ data, toggleActivation }) => {
  const navigate = useNavigate()
  const url = useLocation().pathname

  return (
    <div>
      {
        data && data.map((output) => (
          <Paper sx={{ borderBottom: `1px solid #B9B9B9` }} elevation={0} square onClick={() => {
            navigate(`${url}/edit`, {
                replace: false,
                state: output,
              },
            )
          }}>
            <Grid container>
              <Grid item container justifyContent={'space-between'}>
                <Typography variant={'h2'} sx={{ paddingTop: '3px' }}>
                  {output.id}
                </Typography>
                <Checkbox
                  checked={output.activated}
                  onClick={() => toggleActivation(output)}
                />
              </Grid>
              <Grid item sx={{ paddingBottom: '3px' }}>
                <Typography>
                  {output.name}
                </Typography>
              </Grid>
            </Grid>

          </Paper>
        ))
      }
    </div>
  )
}

DigitalOutputsMobileView.propTypes = {
  data: PropTypes.array,
  toggleActivation: PropTypes.func,
}

export default DigitalOutputsMobileView