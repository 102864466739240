import React, { useEffect, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomTextField = styled(TextField)({
  width: '2.5rem',
  alignItems: 'center',

})

const CustomTypography = styled(Typography)({
  marginBottom: '0.25rem',
})

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '11px',
  width: 190,
})

const IpAddressWidgetV2 = ({ value, id, title, onBlur, error, helperText, disabled, onChange }) => {
  const [stringOne, setStringOne] = useState('')
  const [stringTwo, setStringTwo] = useState('')
  const [stringThree, setStringThree] = useState('')
  const [stringFour, setStringFour] = useState('')

  const constructAddress = (newString, index) => {
    let addressArray = [stringOne, stringTwo, stringThree, stringFour]
    addressArray[index - 1] = newString
    const addressString = `${addressArray[0]}.${addressArray[1]}.${addressArray[2]}.${addressArray[3]}`
    onChange(addressString)
  }

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target
    // eslint-disable-next-line
    const [fieldName, fieldIndex] = name.split('-')

    let fieldIntIndex = parseInt(fieldIndex, 10)

    switch (fieldIntIndex) {
      case 1:
      default:
        setStringOne(value)
        constructAddress(value, fieldIntIndex)
        break
      case 2:
        setStringTwo(value)
        constructAddress(value, fieldIntIndex)
        break
      case 3:
        setStringThree(value)
        constructAddress(value, fieldIntIndex)
        break
      case 4:
        setStringFour(value)
        constructAddress(value, fieldIntIndex)
        break
    }

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {

      // It should not be last input field
      if (fieldIntIndex < 6) {

        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=ipfield-${fieldIntIndex + 1}]`,
        )

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus()
        }
      }
    }
  }

  useEffect(() => {
    if (value) {
      const addressArray = value.split('.')

      setStringOne(addressArray[0])
      setStringTwo(addressArray[1])
      setStringThree(addressArray[2])
      setStringFour(addressArray[3])

    }
  }, [value])

  const inputProps = {
    min: 0,
    max: 255,
    maxLength: 3,
    style: { textAlign: 'center' },
  }

  return (
    <RootDiv>
      <Typography sx={{
        fontSize: '14px',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
      }}>
        {title}
      </Typography>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}>

        <CustomTextField name="ipfield-1"
                         id={id + 'ipfield-1'}
                         inputProps={inputProps}
                         variant={'standard'}
                         value={stringOne}
                         type={'tel'}
                         disabled={disabled}
                         onBlur={onBlur}
                         onChange={(event) => {
                           setStringOne(event.target.value)
                           handleChange(event)
                         }}
        />
        <CustomTypography>
          .
        </CustomTypography>
        <CustomTextField name="ipfield-2"
                         id={id + 'ipfield-2'}
                         inputProps={inputProps}
                         variant={'standard'}
                         value={stringTwo}
                         type={'tel'}
                         disabled={disabled}
                         onBlur={onBlur}
                         onChange={(event) => {
                           setStringTwo(event.target.value)
                           handleChange(event)
                         }}/>
        <CustomTypography>
          .
        </CustomTypography>
        <CustomTextField name="ipfield-3"
                         id={id + 'ipfield-3'}
                         inputProps={inputProps}
                         variant={'standard'}
                         value={stringThree}
                         type={'tel'}
                         disabled={disabled}
                         onBlur={onBlur}
                         onChange={(event) => {
                           setStringThree(event.target.value)
                           handleChange(event)
                         }}/>
        <CustomTypography>
          .
        </CustomTypography>
        <CustomTextField name="ipfield-4"
                         id={id + 'ipfield-4'}
                         inputProps={inputProps}
                         variant={'standard'}
                         value={stringFour}
                         type={'tel'}
                         disabled={disabled}
                         onBlur={onBlur}
                         onChange={(event) => {
                           setStringFour(event.target.value)
                           handleChange(event)
                         }}/>
      </div>

      {error &&
        <Typography
          variant={'body2'}
          color={'error'}
          sx={{
            fontSize: '15px',
            transformOrigin: 'top left',
            transform: 'translate(0, 1.5px) scale(0.75)',
            width: 220,
          }}
        >
          {helperText}
        </Typography>
      }
    </RootDiv>
  )
}

export default IpAddressWidgetV2