import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingBackdrop = ({ open }) => {
  return (
    <Backdrop
      open={open}
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 100,
        color: '#fff',
      })}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
}

LoadingBackdrop.propTypes = {
  open: PropTypes.bool,
}

export default LoadingBackdrop
