import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import CardsAddHelpText from '../../../help-texts/FL_Authorization_ChargingPoints_Cards_Add.md'
import { backendApiClient } from '../../../apiClient'
import Page from '../../../components/Page/Page'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import MultiSelector from '../../../components/MultiSelector/MultiSelector'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'

const AddChargingPointToTagsPage = () => {
  const { clientId, pointId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState()
  const [selectableTags, setSelectableTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [name, setName] = useState(location.state?.name)

  const CP_ID_TOKENS_URL = `/api/client/${clientId}/chargingpoints/${pointId}/id-tokens`
  const CP_ID_TOKENS_ADDABLE_URL = `/api/client/${clientId}/chargingpoints/${pointId}/id-tokens/addable`
  const CP_URL = `/api/client/${clientId}/configuration/chargingpoints/${pointId}`

  const SUB_TITLE = `Für Ladepunkt ${name}`
  const TITLE = `Ladekarten hinzufügen`

  useEffect(() => {
    fetchHelpText()
    if (!name) {
      fetchChargingPoint()
    }
    fetchInactiveTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(CardsAddHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchChargingPoint = () => {
    const pointUrl = CP_URL
      .replace(/{chargingPoint}/g, pointId)
    backendApiClient.get(pointUrl)
      .then((response) => {
        setName(response.name)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const fetchInactiveTags = () => {
    setLoading(true)
    backendApiClient.get(CP_ID_TOKENS_ADDABLE_URL)
      .then((response) => {
        setSelectableTags(response)
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => setLoading(false))
  }

  const handleSetSelectedTags = (value) => {
    setSelectedTags(value)
  }

  const onSubmit = () => {
    setLoading(true)
    backendApiClient.post(CP_ID_TOKENS_URL, selectedTags)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, { variant: 'success' })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={TITLE} pageTitle={TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={TITLE}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h4'}>
            {SUB_TITLE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={3}
        >
          <Box paddingBottom={0.5}>
            <Typography>Karten hinzufügen</Typography>
          </Box>
          <MultiSelector optionsData={selectableTags.reduce((prev, item) => ({ ...prev, [item.id]: String(item.name) }), {})}
                         selectedItems={selectedTags}
                         setSelectedItems={handleSetSelectedTags}
                         inputPlaceHolderText={' Ladekarten auswählen...'}
                         noSelectedItemsText={' Keine Karten ausgewählt'}
                         displayValueWithId={true}
                         selectedBoxHeight={250}
          />
        </Grid>
      </Grid>
      <SubmitFab show={true} onClick={() => onSubmit()}/>
    </Page>
  )
}

export default AddChargingPointToTagsPage