import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'
import { styled } from '@mui/material/styles'

const CustomSpan = styled('span')(({ theme }) => ({
  transform: theme.skewText,
}))

function NoChargingPointsScreen ({}) {

  const navigate = useNavigate()

  return (
    <div>
      <Grid
        container
        spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2,
            color: 'text.secondary',
          }}>
            <Grid
              container
              direction={'column'}
              justifyContent={'space-evenly'}
              alignItems={'center'}
              spacing={3}>
              <Grid
                item
                xs={12}>
                <Typography
                  align={'center'}
                  variant={'h2'}>
                  Es sind noch keine Ladepunkte vorhanden.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}>
                <Typography
                  align={'center'}
                  variant={'h4'}>
                  Bitte gemäß Schaltplan alle Ladepunkte hinzufügen
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}>
                <Button
                  onClick={() => { navigate('./new')}}
                  variant={'contained'}
                  color={'primary'}>
                  <CustomSpan>
                    Ladepunkt hinzufügen
                  </CustomSpan>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default NoChargingPointsScreen