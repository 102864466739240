import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import { useLocation } from 'react-router'
import FirmwareHelpText from '../../help-texts/administration/FL_Firmware_Versions.md'
import AdministrationTabContainer from './AdministrationTabContainer'

const AdministrationPage = ({ roles }) => {
  const url = useLocation().pathname

  const PAGE_TITLE = 'Administration'
  const FIRMWARE_HELP_TITLE = 'Firmware'

  const [helpTitle, setHelpTitle] = useState()
  const [helpText, setHelpText] = useState()

  const getHelpText = (url) => {
    if (url.includes('firmware')) {
      return { title: FIRMWARE_HELP_TITLE, text: FirmwareHelpText }
    }
  }

  useEffect(() => {
    const helpText = getHelpText(url)
    if (!helpText) {
      return
    }
    setHelpTitle(helpText.title)
    fetch(helpText.text)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <Page viewName={PAGE_TITLE} helpText={helpText} helpTitle={helpTitle} pageTitle={PAGE_TITLE} roles={roles}>
      <AdministrationTabContainer/>
    </Page>
  )
}

export default AdministrationPage