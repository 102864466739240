import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { backendApiClient } from '../../../apiClient'
import { LoadingBackdrop, LoadMoreButton, NavigateFab } from '../../../components'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import TagsTable from './TagsTable'
import TagsList from './TagsList'
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonRoot = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '18px',
})

const TagsTabContainer = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const userRoles = useSelector(state => getRoles(state))
  const theme = useTheme()
  const { clientId, pointId } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState()
  const [data, setData] = useState({})
  const [content, setContent] = useState([])
  const ID_TOKENS_URL = '/api/client/{client}/id-tokens'
  const ID_TOKEN_URL = '/api/client/{client}/id-tokens/{token-id}'
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const fetchData = (cursor) => {
    setLoading(true)
    const idTokensUrl = ID_TOKENS_URL.replace(/{client}/g, clientId)
    let queryParams = cursor ? { cursor: cursor } : {}
    backendApiClient.get(idTokensUrl, queryParams)
      .then((response) => {
        setData(response)
        if (cursor) {
          setContent(content.concat(response.content))
        } else {
          setContent(response.content)
        }
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
      .finally(() => setLoading(false))
  }

  const deleteTag = () => {
    setLoading(true)
    setDeleteOpen(false)
    const url = ID_TOKEN_URL.replace(/{client}/g, clientId)
      .replace(/{chargingPoint}/g, pointId)
      .replace(/{token-id}/g, itemToDelete.id)
    backendApiClient.delete(url)
      .then(() => {
        fetchData()
      })
      .catch(() => {
        enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten, bitte versuchen Sie es erneut', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  const handleDialogOpen = (entry) => {
    try {
      setItemToDelete(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleEdit (tag) {
    navigate(`${tag.value}/edit`, { state: { tag: tag } })
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      {lmsVersion < 2 && <Navigate to={-1}/>}
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={deleteTag}
        dialogText={'Soll dieser Eintrag wirklich gelöscht werden?'}
      />
      {
        smallScreen ?
          <TagsList entry={content} handleEdit={handleEdit} handleDelete={handleDialogOpen} allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>
          :
          <TagsTable content={content} handleEdit={handleEdit} handleDelete={handleDialogOpen}
                     allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}/>

      }
      <ButtonRoot>
        {data?.last === false &&
          <LoadMoreButton
            onClick={() => {fetchData(data.pageable.cursor)}}
            label={'Weitere Karten anzeigen'}
          />
        }
      </ButtonRoot>
      <NavigateFab
        path={`new`}
        text={'neu'}
      >
        <AddIcon/>
      </NavigateFab>
    </React.Fragment>
  )
}

export default TagsTabContainer