import { Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import { styled } from '@mui/material/styles'

const TypographyAlignment = styled(Typography)({
  paddingTop: '4px',
})

const DigitalOutputRuleValue = ({ formik, rule, ruleIndex, value, valueIndex }) => {

  const SWITCH_ON_HELP = 'Der Digitale Ausgang wird ab dieser Leistung aktiviert'
  const SWITCH_ON_LABEL = 'Einschaltschwelle'
  const SWITCH_OFF_HELP = 'Der Digitale Ausgang wird unterhalb dieser Leistung deaktiviert'
  const SWITCH_OFF_LABEL = 'Ausschaltschwelle'

  const ruleError = (helpText) => {
    if (formik.errors.rules === undefined || formik.errors.rules[ruleIndex]?.values === undefined || formik.errors.rules[ruleIndex].values[valueIndex]?.thresholdValue === undefined) {
      return helpText
    } else {
      return formik.errors?.rules[ruleIndex]?.values[valueIndex]?.thresholdValue
    }
  }

  const getRuleValueUnitInText = (unit) => {
    switch (unit) {
      case 'percentage':
        return '%'
      case 'kWh':
        return 'kWh'
      case 'euroPerMWh':
        return 'EUR / MWh'
      default:
        return ''
    }
  }

  const getRuleValueOperatorAsText = (valueType) => {
    switch (valueType) {
      case 'lessThanThreshold':
        return '  < '
      case 'greaterThanThreshold':
      case 'switchOnThreshold':
        return '  > '
      default:
        return ''
    }
  }

  return (
    <Grid item container xs={12} sm={4}>
      {(rule.type === 'powerLimitSwitchOn' || rule.type === 'powerSpotPrice') &&
        <React.Fragment>
          <TypographyAlignment
            sx={[
              { pl: 1 },
              !rule.activated && { color: 'text.disabled' },
            ]}>
            {getRuleValueOperatorAsText(value.threshold)}
          </TypographyAlignment>
          <TextField
            sx={{
              width: '60px',
              paddingLeft: 1,
              paddingRight: 1,
              align: 'right',
            }}
            inputProps={{ style: { textAlign: 'right' } }}
            type={'number'}
            variant={'standard'}
            id={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
            name={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
            value={value?.thresholdValue ? value.thresholdValue : 0}
            onChange={formik.handleChange}
            disabled={!rule.activated}
            error={!(formik.errors.rules === undefined || formik.errors.rules[ruleIndex]?.values === undefined || formik.errors.rules[ruleIndex].values[valueIndex]?.thresholdValue === undefined)}
          />
          <TypographyAlignment sx={[!rule.activated && { color: 'text.disabled' }]}>
            {getRuleValueUnitInText(value.unit)}
          </TypographyAlignment>
          <TypographyAlignment
            color={'error'}
            sx={[
              { whiteSpace: 'pre-line', marginLeft: '8px' },
              !rule.activated && { color: 'text.disabled' },
            ]}
          >
            {ruleError()}
          </TypographyAlignment>
        </React.Fragment>
      }
      {rule.type === 'powerSurplus' && value.threshold === 'switchOnThreshold' &&
        <TextField
          sx={{ marginRight: '8px', marginTop: '5px', width: '250px' }}
          type={'number'}
          variant={'standard'}
          id={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
          name={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
          disabled={!rule.activated}
          value={value?.thresholdValue ? value.thresholdValue : 0}
          onChange={formik.handleChange}
          label={SWITCH_ON_LABEL}
          helperText={ruleError(SWITCH_ON_HELP)}
          error={!(formik.errors.rules === undefined || formik.errors.rules[ruleIndex]?.values === undefined || formik.errors.rules[ruleIndex].values[valueIndex]?.thresholdValue === undefined)}
          inputProps={{ step: 0.01 }}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <PlayArrowIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!rule.activated && { color: 'secondary.light' }]}>
                    kW
                  </Typography>
                </InputAdornment>
              ),
            }
          }
        />
      }
      {rule.type === 'powerSurplus' && value.threshold === 'switchOffThreshold' &&
        <TextField
          sx={{ marginTop: '5px', width: '250px' }}
          type={'number'}
          variant={'standard'}
          id={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
          name={`rules[${ruleIndex}].values[${valueIndex}].thresholdValue`}
          disabled={!rule.activated}
          value={value?.thresholdValue ? value.thresholdValue : 0}
          onChange={formik.handleChange}
          label={SWITCH_OFF_LABEL}
          helperText={ruleError(SWITCH_OFF_HELP)}
          error={!(formik.errors.rules === undefined || formik.errors.rules[ruleIndex]?.values === undefined || formik.errors.rules[ruleIndex].values[valueIndex]?.thresholdValue === undefined)}
          inputProps={{ step: 0.01 }}
          InputProps={
            {
              startAdornment: (
                <InputAdornment position={'start'}>
                  <StopIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Typography sx={[!rule.activated && { color: 'secondary.light' }]}>
                    kW
                  </Typography>
                </InputAdornment>
              ),
            }
          }
        />
      }
    </Grid>
  )
}

export default DigitalOutputRuleValue