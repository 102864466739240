import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { backendApiClient } from '../../apiClient'
import { isEmpty } from '../../utils'
import { DateTime } from 'luxon'

/**
 * Example state:
 *
 * {
 *   ABL: { <--manufacturer
 *     1.23:{ <--version
 *       clientId1: { <-- client id
 *         1: {status: 'Installed', timestamp: '..', currentVersion: '1.2', expirationTimestamp:'..', startTimestamp:'..'},
 *         2: {status: 'Downloaded', timestamp: '..', currentVersion: '1.3', expirationTimestamp:'..', startTimestamp:'..'},
 *         }
 *     }
 *   }
 */
let initialState = {
  status: {},
}

const DURATION_KEEP_TRACK_OF_STATUS = { hours: 6 }

const CHARGING_POINTS_URL = '/api/client/{clientId}/chargingpoint/{chargingPointId}/firmware/status'

export const updateChargingPointFirmwareStatus = createAsyncThunk('firmwareStatus/updateChargingPointFirmwareStatus', async (data) => {
  const url = CHARGING_POINTS_URL
    .replace(/{clientId}/g, data.clientId)
    .replace(/{chargingPointId}/g, data.chargingPointId)
  return backendApiClient.get(url)
})

export const firmwareStatusSlice = createSlice({
  name: 'firmwareStatus',
  initialState,
  reducers: {
    addChargingPointForStatusUpdates (state, action) {
      const payload = action.payload
      const targets = payload.targets
      for (const [key, value] of Object.entries(targets)) {
        value.forEach(function (item) {
          if (typeof state.status[payload.manufacturer] === 'undefined') {
            state.status[payload.manufacturer] = {}
          }
          if (typeof state.status[payload.manufacturer][payload.version] === 'undefined') {
            state.status[payload.manufacturer][payload.version] = {}
          }
          if (typeof state.status[payload.manufacturer][payload.version][key] === 'undefined') {
            state.status[payload.manufacturer][payload.version][key] = {}
          }
          state.status[payload.manufacturer][payload.version][key][item.id] = {
            name: item.name,
            expirationTimestamp: DateTime.now()
              .plus(DURATION_KEEP_TRACK_OF_STATUS)
              .toISO(),
            startTimestamp: DateTime.now().toISO(),
          }
        })
      }
    },
    checkExpired (state, action) {
      const tmpStatus = { ...state.status }
      iterateAndCleanStatus(tmpStatus)
      state.status = tmpStatus || {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateChargingPointFirmwareStatus.fulfilled, (state, action) => {
        const payload = action.payload
        const arg = action.meta.arg
        try {
          const currentFirmwareStatus = state.status[arg.manufacturer][arg.version][arg.clientId][arg.chargingPointId]
          state.status[arg.manufacturer][arg.version][arg.clientId][arg.chargingPointId] = {
            ...currentFirmwareStatus,
            status: payload.status,
            timestamp: payload.timestamp,
            currentVersion: payload.currentVersion,
          }
        } catch (e) {}
      })
  },
})

const iterateAndCleanStatus = (obj) => {
  Object.keys(obj)
    .forEach(key => {
      if (typeof obj[key] === 'object' && !isEmpty(obj[key])) {
        iterateAndCleanStatus(obj[key])
      }
      if (isEmpty(obj[key]) || (obj[key].hasOwnProperty('expirationTimestamp') && DateTime.now() > DateTime.fromISO(obj[key].expirationTimestamp))) {
        delete obj[key]
      }
    })
}

export const { addChargingPointForStatusUpdates, checkExpired } = firmwareStatusSlice.actions
export default firmwareStatusSlice.reducer