import { configureStore } from '@reduxjs/toolkit'
import authSliceReducer from './auth/authSlice'
import messageSliceReducer from './messages/messagesSlice'
import metricsSliceReducer from './metrics/metricsSlice'
import customerSliceReducer from './customer/customerSlice'
import firmwareStatusReducer from './firmwarestatus/firmwareStatusSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'firmwareStatus',
  storage,
}

const persistedFirmwareStatus = persistReducer(persistConfig, firmwareStatusReducer)

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    messages: messageSliceReducer,
    metrics: metricsSliceReducer,
    customers: customerSliceReducer,
    firmwareStatus: persistedFirmwareStatus,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistentStore = persistStore(store)