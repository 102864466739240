import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ExpandableContainer from '../../components/User/ExpandableContainer'
import { backendApiClient } from '../../apiClient'
import { LoadingBackdrop, LoadMoreButton } from '../../components'
import { useSnackbar } from 'notistack'
import DialogDelete from '../../components/ConfirmDialog/ConfirmDialog'
import SearchBarHeader from '../../components/User/SearchBarHeader'
import { Typography } from '@mui/material'

const UserManagementContainer = () => {

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState()
  const [data, setData] = useState()
  const [searchList, setSearchList] = useState()
  const [clientIdList, setClientIdList] = useState()
  const [selectedClientId, setSelectedClientId] = useState()
  const [searchText, setSearchText] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const URL = `/api/user/customers`
  const URL_LIST_USERS = '/api/user/profiles'
  const API_EDIT_ENABLED = '/api/user/profiles/enable'
  const API_REACTIVATE = '/api/user/profiles/reactivate'
  const NAME = 'Name'
  const STATE = 'Status'
  const CREATED_AT = 'Erstellt am'
  const ACTIVE = 'Aktiviert'
  const DEACTIVATE = 'Deaktiviert'
  const DELETE_TEXT = `Soll der Nutzer: ${user?.firstName} ${user?.lastName} wirklich gelöscht werden?`
  const SHOW_MORE_USER = 'Weitere Nutzer Anzeigen'
  const SEARCHBAR_LABEL = 'Suche'

  useEffect(() => {
    try {
      fetchData()
      fetchClientIdList()
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try {
      fetchData()
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId])

  useEffect(() => {
    try {
      if (searchText.trim().length > 2 || searchText.trim().length === 0) {
        fetchData()
      }
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    try {
      setSearchList(data?.content)
    } catch (e) {
      throw new Error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const fetchData = (forceEmpty = false) => {
    try {
      setLoading(true)

      let queryParams = {}

      if (selectedClientId) {
        queryParams.clientId = selectedClientId
      }
      if (!forceEmpty && searchText.length > 2) {
        queryParams.searchText = searchText
      }

      backendApiClient.get(URL_LIST_USERS, queryParams)
        .then((response) => {
          setData(response)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })

    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchMoreData = (page) => {
    try {
      let queryParams = { page: page }

      if (selectedClientId) {
        queryParams.clientId = selectedClientId
      }
      if (searchText.trim().length > 2) {
        queryParams.searchText = searchText.trim()
      }
      setLoading(true)
      backendApiClient.get(URL_LIST_USERS, queryParams)
        .then((response) => {
          setData(
            {
              content: [...data.content.concat(response.content)],
              empty: response.empty,
              last: response.last,
              pageable: response.pageable,
            },
          )
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })

    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchClientIdList = () => {
    try {
      backendApiClient.get(URL)
        .then((response) => {
          setClientIdList(response.content)
        })
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDelete = () => {
    setLoading(true)
    backendApiClient.delete(URL_LIST_USERS, {
      userProfileId: user?.userId,
    })
      .then(() => {
        enqueueSnackbar('Der Nutzer wurde erfolgreich gelöscht!', {
          variant: 'success',
        })
        handleClear()
      })
      .catch(() => {
        enqueueSnackbar('Der Nutzer konnte nicht gelöscht werden!', {
          variant: 'error',
        })
      })
      .finally(() => {
        handleDialogClose()
        setLoading(false)
      })
  }

  const handleCheckBox = (user) => {
    user.enabled = !user.enabled
    setLoading(true)
    backendApiClient.put(API_EDIT_ENABLED, user)
      .then(() => {
        enqueueSnackbar(`Der Nutzer ${user.lastName} wurde erfolgreich ${activationChecker(user.enabled)}!`, {
          variant: 'success',
        })
        setLoading(false)
      })
      .catch(() => {
        enqueueSnackbar('Der Nutzer konnte nicht editiert werden!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const handleResendInvitation = (user) => {
    setLoading(true)
    backendApiClient.put(API_REACTIVATE, user)
      .then(() => {
        setLoading(false)
        enqueueSnackbar('Die Einladung wurde erfolgreich versendet', {
          variant: 'success',
        })
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar('Das Senden der erneute Einladung war fehlerhaft', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  const activationChecker = (enable) => {
    switch (enable) {
      case true :
        return ACTIVE.toLowerCase()
      default:
        return DEACTIVATE.toLowerCase()
    }
  }

  const handleDialogOpen = (user) => {
    try {
      setOpen(true)
      setUser(user)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setOpen(false)
      setUser(null)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleSelectChange = (event, value) => {
    try {
      if (value?.clientId) {
        setSelectedClientId(value.clientId)
      } else {
        setSelectedClientId(null)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const keyUpHandler = (event) => {
    setSearchText(event.target.value)
  }

  const handleClear = () => {
    setSearchText('')
    fetchData(true)
  }

  return <>
    <LoadingBackdrop open={loading}/>
    <DialogDelete
      open={open}
      handleClose={handleDialogClose}
      handleConfirm={handleDelete}
      dialogText={DELETE_TEXT}
    />
    <Box paddingBottom={8}>
      <SearchBarHeader
        data={searchList}
        label={SEARCHBAR_LABEL}
        clientList={clientIdList}
        handleSelectChange={handleSelectChange}
        keyUpHandler={keyUpHandler}
        searchValue={searchText}
        handleClear={handleClear}
      />
    </Box>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        marginLeft: '-9px',
        paddingBottom: '5px',
      }}
    >
      <Grid
        sx={{ paddingLeft: 1 }}
        item
        xs={4}
      >
        <Typography variant={'h6'}>{NAME}</Typography>
      </Grid>
      <Grid
        sx={{ paddingLeft: '15px' }}
        item
        xs={3}
      >
        <Typography variant={'h6'}>{STATE}</Typography>
      </Grid>
      <Grid
        sx={{ paddingLeft: '7px' }}
        item
        xs={3}
      >
        <Typography variant={'h6'}>{CREATED_AT}</Typography>
      </Grid>
      <Grid
        sx={{ paddingRight: 1 }}
        item
        xs={2}
      >
        <Typography variant={'h6'} align={'right'}>{ACTIVE}</Typography>
      </Grid>
    </Grid>
    {data?.content?.map((row, index) =>
      <div style={{
        marginLeft: '-9px',
      }}
           key={row.userId}>
        <ExpandableContainer
          key={row.userId}
          index={index}
          row={row}
          handleDelete={handleDialogOpen}
          handleCheckBox={handleCheckBox}
          handleResendInvitation={handleResendInvitation}
        />
      </div>,
    )}
    <div style={{
      borderTop: `1px solid #B9B9B9`,
      width: '100% + 9px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '18px',
      marginLeft: '-9px',
    }}>
      {data?.last ? <></> :
        <LoadMoreButton
          onClick={() => fetchMoreData(data?.pageable?.pageNumber + 1)}
          label={SHOW_MORE_USER}
        />
      }
    </div>
  </>
}

export default UserManagementContainer



