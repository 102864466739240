import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { SubmitFab } from '../../../components'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import { useParams } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles'

const RadioLabel = styled(FormControlLabel)({
  paddingTop: '11px',
  splay: 'flex',
  alignItems: 'flex-start',
})

const GridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

const RadioButton = styled(Radio)({
    marginTop: '-10px',
  },
)

const NoConfigurationScreen = ({ version }) => {
  const { clientId } = useParams()

  const HEADER = 'Wählen Sie die Art des Lademanagements aus:'
  const STATIC_TITLE = 'Statisches Lademanagement'
  const STATIC_DESCRIPTION = 'Die Ladeleistung wird auf einen von Ihnen festgelegten Wert begrenzt'
  const DYNAMIC_TITLE = 'Dynamisches Lademanagement'
  const DYNAMIC_DESCRIPTION = 'Die Ladeleistung wird durch die am Hausanschluss maximal zur Verfügung stehende Leistung begrenzt'
  const CUSTOM_TITLE = 'Individuelle Eingabe'
  const CUSTOM_DESCRIPTION = 'Alle Parameter werden von Ihnen angelegt'
  const [value, setValue] = useState('STATIC')
  const { enqueueSnackbar } = useSnackbar()

  const URL_TEMPLATE = '/api/client/{client}/configuration'

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleContinue = () => {
    const url = URL_TEMPLATE.replace('{client}', clientId)
    backendApiClient.post(url, { type: value })
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
      })
  }

  return (
    <Grid
      container
      spacing={3}>
      <Grid item xs={12}>
        <Grid
          container
          direction={'column'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          sx={{ paddingLeft: 5, paddingRight: 5 }}
          spacing={3}>
          <Grid
            item
            xs={12}>
            <Typography
              align={'center'}
              variant={'h4'}>
              {HEADER}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl component="fieldset">
              <RadioGroup value={value} row color={'primary'} onChange={handleChange}>
                <Grid container justifyContent={'center'}>
                  <GridItem key={1} item xs={12} sm={4}>
                    <RadioLabel value="STATIC" control={<RadioButton/>}
                                labelPlacement={'end'} label={
                      <div>
                        <Typography>
                          {STATIC_TITLE}
                        </Typography>
                        <FormHelperText>
                          {STATIC_DESCRIPTION}
                        </FormHelperText>
                      </div>
                    }
                    />
                  </GridItem>
                  <GridItem key={2} item xs={12} sm={4}>
                    <RadioLabel value="DYNAMIC" control={<RadioButton/>}
                                labelPlacement={'end'} label={
                      <div>
                        <Typography>
                          {DYNAMIC_TITLE}
                        </Typography>
                        <FormHelperText>
                          {DYNAMIC_DESCRIPTION}
                        </FormHelperText>
                      </div>
                    }
                    />
                  </GridItem>
                  <GridItem key={3} item xs={12} sm={4}>
                    <RadioLabel value="CUSTOM" control={<RadioButton disabled={version < 2}/>}
                                labelPlacement={'end'} label={
                      <div>
                        <Typography>
                          {CUSTOM_TITLE}
                        </Typography>
                        <FormHelperText>
                          {CUSTOM_DESCRIPTION}
                        </FormHelperText>
                      </div>
                    }
                    />
                  </GridItem>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <SubmitFab show onClick={handleContinue} text={'weiter'}>
          <ArrowRightIcon/>
        </SubmitFab>
      </Grid>
    </Grid>
  )
}

export default NoConfigurationScreen