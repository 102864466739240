import React from 'react'
import { Autocomplete, Chip, TextField, useTheme } from '@mui/material'

const MultipleSelect = ({ value, onChange, options, title, label, error, helperText }) => {
  const theme = useTheme()
  return (
    <div>
      <Autocomplete
        multiple
        options={options}
        defaultValue={value}
        value={value}
        getOptionLabel={(option) => option}
        disableCloseOnSelect
        onChange={(event, values) => onChange(values)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip style={{ backgroundColor: theme.palette.background.default }} variant="outlined"
                  label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            style={label ? {} : { marginTop: 18 }}
            label={label}
            error={error}
            helperText={helperText}
            variant={'standard'}
            placeholder={title}
          />
        )}
      />
    </div>
  )
}

export default MultipleSelect