import React from 'react'

const Footer = () => {
  return (
    <React.Fragment>
    </React.Fragment>
  )
}

export default Footer
