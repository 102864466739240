import React from 'react'
import { ResetPasswordForm } from '../../components'
import { useSelector } from 'react-redux'
import { completePasswordReset } from '../../store'
import { useSearchParams } from 'react-router-dom'
import base64 from 'react-native-base64'

const ResetPasswordConfirm = () => {
  const error = useSelector(state => state.auth.error?.message)
  const [searchParams] = useSearchParams();

  const handleSubmit = (values) => {
    completePasswordReset(values.email, values.code, values.password)
  }

  return (
    <ResetPasswordForm
      code={searchParams.get('code')}
      email={base64.decode(searchParams.get('email') || '')}
      onSubmit={handleSubmit}
      error={error}
    />
  )
}

export default ResetPasswordConfirm