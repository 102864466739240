import React, { useEffect, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomTextField = styled(TextField)({
  width: '2.5rem',
})

const CustomTypography = styled(Typography)({
  marginBottom: '0.25rem',
})

const RootDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginBottom: '11px',
})

const IpAddressWidget = (props) => {
  const [stringOne, setStringOne] = useState('')
  const [stringTwo, setStringTwo] = useState('')
  const [stringThree, setStringThree] = useState('')
  const [stringFour, setStringFour] = useState('')

  const constructAddress = (newString, index) => {
    let addressArray = [stringOne, stringTwo, stringThree, stringFour]
    addressArray[index - 1] = newString
    const addressString = `${addressArray[0]}.${addressArray[1]}.${addressArray[2]}.${addressArray[3]}`
    props.onChange(addressString)
  }

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target
    // eslint-disable-next-line
    const [fieldName, fieldIndex] = name.split('-')

    let fieldIntIndex = parseInt(fieldIndex, 10)

    switch (fieldIntIndex) {
      case 1:
      default:
        setStringOne(value)
        constructAddress(value, fieldIntIndex)
        break
      case 2:
        setStringTwo(value)
        constructAddress(value, fieldIntIndex)
        break
      case 3:
        setStringThree(value)
        constructAddress(value, fieldIntIndex)
        break
      case 4:
        setStringFour(value)
        constructAddress(value, fieldIntIndex)
        break
    }

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {

      // It should not be last input field
      if (fieldIntIndex < 6) {

        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=ipfield-${fieldIntIndex + 1}]`,
        )

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus()
        }
      }
    }
  }

  useEffect(() => {
    if (props.value) {
      const addressArray = props.value.split('.')

      setStringOne(addressArray[0])
      setStringTwo(addressArray[1])
      setStringThree(addressArray[2])
      setStringFour(addressArray[3])

    }
  }, [props])

  return (
    <RootDiv>
      <Typography sx={{
        fontSize: '14px',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        marginBottom: '25px',
        marginRight: '-73px',
      }}>
        {props?.props?.title}
      </Typography>
      <CustomTextField name="ipfield-1"
                       id={props.id + 'ipfield-1'}
                       inputProps={{
                         maxLength: 3,
                         style: { textAlign: 'center' },
                       }}
                       variant={'standard'}
                       value={stringOne}
                       type={'tel'}
                       disabled={props?.disabled}
                       onBlur={props.onBlur}
                       onChange={(event) => {
                         setStringOne(event.target.value)
                         handleChange(event)
                       }}
      />
      <CustomTypography>
        .
      </CustomTypography>
      <CustomTextField name="ipfield-2"
                       id={props.id + 'ipfield-2'}
                       inputProps={{
                         maxLength: 3,
                         style: { textAlign: 'center' },
                         min: 0,
                       }}
                       variant={'standard'}
                       value={stringTwo}
                       type={'tel'}
                       disabled={props?.disabled}
                       onBlur={props.onBlur}
                       onChange={(event) => {
                         setStringTwo(event.target.value)
                         handleChange(event)
                       }}/>
      <CustomTypography>
        .
      </CustomTypography>
      <CustomTextField name="ipfield-3"
                       id={props.id + 'ipfield-3'}
                       inputProps={{
                         maxLength: 3,
                         style: { textAlign: 'center' },
                         min: 0,
                       }}
                       variant={'standard'}
                       value={stringThree}
                       type={'tel'}
                       disabled={props?.disabled}
                       onBlur={props.onBlur}
                       onChange={(event) => {
                         setStringThree(event.target.value)
                         handleChange(event)
                       }}/>
      <CustomTypography>
        .
      </CustomTypography>
      <CustomTextField name="ipfield-4"
                       id={props.id + 'ipfield-4'}
                       inputProps={{
                         maxLength: 3,
                         style: { textAlign: 'center' },
                       }}
                       variant={'standard'}
                       value={stringFour}
                       type={'tel'}
                       disabled={props?.disabled}
                       onBlur={props.onBlur}
                       onChange={(event) => {
                         setStringFour(event.target.value)
                         handleChange(event)
                       }}/>

      {props.error && props.error.__errors.length > 0 &&
        <Typography
          variant={'body2'}
          color={'error'}
          sx={{
            fontSize: '14px',
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'bottom left',
            marginBottom: '-17px',
            marginRight: '-65px',
            marginLeft: '-171px',
          }}
        >
          {props.error.__errors[0]}
        </Typography>
      }
    </RootDiv>
  )
}

export default IpAddressWidget