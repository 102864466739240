import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import CustomFormikSwitch from '../../../components/crud/widgets/components/CustomFormikSwitch'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

const MailNotificationScreen = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [settings, setSettings] = useState({})
  const URL_USER_SETTINGS = '/api/user/profiles/settings'

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(URL_USER_SETTINGS)
      .then((response) => {
        formikValidationEmailNotifications.setValues(response.mailNotifications)
        setSettings(response)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const formikValidationEmailNotifications = useFormik({
    initialValues: {
      infoNotifications: false,
      warningNotifications: false,
      errorNotifications: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setLoading(true)
      let finalValues = settings
      finalValues.mailNotifications = values
      backendApiClient.put(URL_USER_SETTINGS, finalValues)
        .then(() => {
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen.', { variant: 'success' })
          fetchData()
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          throw new Error(error)
        })
    },
  })

  const notificationLabel = (active) => {
    if (active) {
      return 'Aktiviert'
    } else {
      return 'Deaktiviert'
    }
  }

  return (
    <form onSubmit={formikValidationEmailNotifications.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid item container spacing={2} alignItems={'center'}>
        <Grid
          item
          sm={6}
          xs={12}>
          <Typography>
            Benachrichtigung über Infos
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
        >
          <CustomFormikSwitch
            value={formikValidationEmailNotifications.values.infoNotifications
              ? formikValidationEmailNotifications.values.infoNotifications
              : formikValidationEmailNotifications.initialValues.infoNotifications}
            onChange={formikValidationEmailNotifications.handleChange}
            label={notificationLabel(formikValidationEmailNotifications.values.infoNotifications)}
            id={'infoNotifications'}
            name={'infoNotifications'}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}>
          <Typography>
            Benachrichtigung über Warnungen
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
        >
          <CustomFormikSwitch
            value={formikValidationEmailNotifications.values.warningNotifications
              ? formikValidationEmailNotifications.values.warningNotifications
              : formikValidationEmailNotifications.initialValues.warningNotifications}
            onChange={formikValidationEmailNotifications.handleChange}
            label={notificationLabel(formikValidationEmailNotifications.values.warningNotifications)}
            id={'warningNotifications'}
            name={'warningNotifications'}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}>
          <Typography>
            Benachrichtigung über Fehler
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}>
          <CustomFormikSwitch
            value={formikValidationEmailNotifications.values.errorNotifications
              ? formikValidationEmailNotifications.values.errorNotifications
              : formikValidationEmailNotifications.initialValues.errorNotifications}
            onChange={formikValidationEmailNotifications.handleChange}
            label={notificationLabel(formikValidationEmailNotifications.values.errorNotifications)}
            id={'errorNotifications'}
            name={'errorNotifications'}
          />
        </Grid>
      </Grid>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '10%',
      }}>
        <PriorityHighIcon sx={{ marginRight: 1 }}/>
        <Typography variant={'h6'}>Die Nachrichten werden an {user?.email} gesendet!</Typography>
      </div>

      <SubmitFab show={true}/>
    </form>
  )
}

MailNotificationScreen.propTypes = {
  validationEmailNotifications: PropTypes.object,
}

export default MailNotificationScreen