import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import dashboard from '../../help-texts/FL_DashboardV2.md'
import DashboardContainer from './DashboardContainer'
import PageReloadTimer from '../../components/PageReloadTimer/PageReloadTimer'

export default function DashboardPage ({ roles }) {

  const [helpText, setHelpText] = useState('')
  const HELP_TITLE = 'Im Dashboard werden Ihnen alle wichtigen Informationen einer Ladestation auf einen Blick angezeigt'

  useEffect(() => {
    fetch(dashboard)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={'Dashboard'} helpText={helpText} helpTitle={HELP_TITLE} pageTitle={'Dashboard'} roles={roles}>
      <DashboardContainer/>
      {/* reload page every 5 minutes to avoid a page crash */}
      <PageReloadTimer seconds={300}/>
    </Page>
  )
}