import React, { useState } from 'react'
import PropTypes from 'prop-types'
import backendApiClient from '../../apiClient/apiClient'
import contactSchema from './contactSchema.json'
import EditForm from '../../components/crud/EditForm'
import { useSnackbar } from 'notistack'
import { LoadingBackdrop } from '../../components'
import ContactWidget from '../../components/crud/widgets/ContactWidget'
import { useParams } from 'react-router'

const NewContact = ({ onAfterSubmit }) => {
  const data = {}
  const { clientId } = useParams()
  const url = '/api/client/' + clientId + '/customer'
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const save = (data) => {
    setLoading(true)
    backendApiClient.get(url)
      .then((response) => {
        if (!response.contacts) {
          response.contacts = []
        }
        response.contacts.push(data.contact)
        backendApiClient.put(url, response)
          .then(() => {
            enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
              variant: 'success',
            })
            setLoading(false)
            onAfterSubmit()
          })
          .catch(() => {
            enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
              variant: 'error',
            })
            setLoading(false)
          })
      })
  }

  const uiSchema = {
    'ui:field': 'ContactWidget',
  }

  const fields = {
    ContactWidget: ContactWidget,
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <EditForm
        schema={contactSchema}
        formData={data}
        onSubmit={save}
        uiSchema={uiSchema}
        fields={fields}
      />

    </div>
  )
}

NewContact.propTypes = {
  className: PropTypes.string,
}

export default NewContact
