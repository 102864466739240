import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useTheme } from '@mui/material/styles'

const AggregationMetricGraph = ({ rawData }) => {
  const theme = useTheme()

  function getOrZero (metric) {
    return metric ? Math.round(metric.value) : 0.0
  }

  let data = rawData.map(entry => {return { period: entry.period.slice(5), Lademenge: getOrZero(entry.chargedWork), Netzbezug: getOrZero(entry.mainWork) }})

  return (
    <ResponsiveBar
      data={data}
      keys={['Lademenge', 'Netzbezug']}
      indexBy="period"
      margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      colors={[theme.palette.graph.mainColor1, theme.palette.graph.mainColor2]}
      axisTop={null}
      axisRight={null}

      theme={{
        textColor: theme.palette.graph.text,
      }}

      tooltip={(input) => {
        return (
          <div
            style={{
              padding: 6,
              color: theme.palette.black,
              background: theme.palette.white,
              display: 'flex',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
            }}
          >
            <div
              style={{
                marginTop: '4px',
                marginRight: '5px',
                width: '12px',
                height: '12px',
                backgroundColor: input.color,
              }}
            />
            <span
              style={{
                marginRight: '5px',
              }}
            >
              {input.label + ':'}
            </span>
            <strong>
              {input.formattedValue}
            </strong>
          </div>
        )
      }}

      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Monat',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Verbrauch in kWh',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={theme.palette.graph.text}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 65,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  )
}
export default AggregationMetricGraph