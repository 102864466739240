import React from 'react'
import { Accordion, AccordionDetails, Checkbox, List, ListItemText, Tooltip, Typography } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { DateTime } from 'luxon'
import { useLocation, useNavigate } from 'react-router'
import { ROLES } from '../../security/roles'

const ExpandableContainer = ({ index, row, handleDelete, handleCheckBox, handleResendInvitation }) => {

  const navigate = useNavigate()
  const url = useLocation().pathname

  const CONFIRMED = 'Bestätigt'
  const UNCONFIRMED = 'Nicht Bestätigt'
  const UNKNOWN = 'Unbekannt'

  const stateTranslator = (state) => {
    switch (state) {
      case 'CONFIRMED':
        return CONFIRMED
      case 'FORCE_CHANGE_PASSWORD':
        return UNCONFIRMED
      default :
        return UNKNOWN
    }
  }

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          marginTop: '0px !important',
          marginBottom: '0px !important',
        },

      }}
      square
    >
      <AccordionSummary
        key={index}
        expandIcon={<KeyboardArrowDownIcon/>}
        sx={{
          height: '64px',
          paddingLeft: 0,
          borderTop: `1px solid #B9B9B9`,
          flexDirection: 'row-reverse',
          '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(-90deg)',
            marginLeft: -12,
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={4}
          >
            {row.firstName === ' ' ?
              <Typography>{row.lastName}</Typography>
              :
              <Typography>{row.lastName}, {row.firstName}</Typography>}
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography>{stateTranslator(row.accountStatus)}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography>{DateTime
              .fromISO(row.created)
              .toFormat('dd.MM.yyyy, HH:mm')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent={'flex-end'}
            style={{ paddingRight: 7 }}
          >
            <Checkbox onClick={(event) => {
              event.stopPropagation()
              handleCheckBox(row)
            }} color={'primary'} checked={row.enabled}/>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{
        paddingLeft: 3,
      }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={4}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            height={'100%'}
          >
            <Typography>{row.groupName}</Typography>
            <Typography>{row.email}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            height={'100%'}
          >
            <List style={{ paddingTop: 0 }}>
              {row.clientIds.map((item, key) =>
                <ListItemText key={key} style={{ margin: 0 }}>
                  {item}
                </ListItemText>,
              )}
            </List>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <Typography>{}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent={'flex-end'}
            paddingRight={'12px'}
          >
            <List style={{ paddingTop: 0 }}>
              {row.groupName === ROLES.FL_CP_USER &&
                <Tooltip title={'Einladung erneut senden'}>
                  <IconButton disabled={row.accountStatus === 'CONFIRMED'}
                              onClick={() => handleResendInvitation(row)} size={'small'}>
                    <ForwardToInboxIcon/>
                  </IconButton>
                </Tooltip>
              }
              <IconButton onClick={() => {
                navigate(`${url}/edit`, {
                    replace: false,
                    state: { user: row },
                  },
                )
              }} size={'small'}>
                <EditIcon/>
              </IconButton>
              <IconButton disabled={row.enabled} onClick={() => handleDelete(row)} size={'small'}>
                <DeleteIcon/>
              </IconButton>
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExpandableContainer