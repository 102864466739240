import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CustomRadioButtons from './CustomRadioButtons'
import SwitchCheckbox from './SwitchCheckBox'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFormik } from 'formik'
import { validationSchema } from '../../../pages/UserAuthorization/UserAuthorizationValidation'
import CustomFormikTextField from './components/CustomFormikTextField'
import { LoadingBackdrop, SubmitFab } from '../../index'
import { useParams, useRouteLoaderData } from 'react-router'

const AuthorizationWidget = ({ save, disabled, searchedUserInUse, user, loading }) => {
  const { clientId } = useParams()
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const { customer } = useRouteLoaderData('clientRoot')

  const DATA_LABEL = 'Daten'
  const AUTHORIZATION = 'Berechtigungsart'
  const NOTIFICATION = 'E-Mail-Benachrichtigungen'

  const salutation = [
    {
      name: 'Frau',
      label: 'Frau',
    },
    {
      name: 'Herr',
      label: 'Herr',
    },
    {
      name: 'Divers',
      label: 'Neutrale Anrede',
    },
  ]

  const groupName = [
    {
      name: 'FL_cpo',
      label: 'Betreiber*in',
    },
    {
      name: 'FL_service',
      label: 'Servicemitarbeiter*in',
    },
    {
      name: 'FL_lms_owner',
      label: 'Verwalter*in',
    },
  ]

  const formik = useFormik({
    initialValues: {
      settings: {
        mailNotifications: {
          infoNotifications: true,
          warningNotifications: true,
          errorNotifications: true,
        },
      },
      salutation: 'Divers',
      firstName: '',
      lastName: '',
      email: '',
      groupName: '',
      organisationId: customer.organisationId,
      clientIds: [clientId],
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  const notificationLabel = (active) => {
    if (active) {
      return 'Aktiviert'
    } else {
      return 'Deaktiviert'
    }
  }

  const onChangeNotifications = (name, value) => {
    try {
      const settings = {
        ...formik.values.settings,
        mailNotifications: {
          ...formik.values.settings?.mailNotifications,
          [name]: value,
        },
      }
      formik.setFieldValue('settings', settings)
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    if (user && Object.values(user).length > 0) {
      formik.setValues(user)
      formik.setErrors({})
    } else {
      formik.setValues(formik.initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        sx={{ flexGrow: 1 }}
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={3.5}
          md={2}
        >
          <Typography variant={'h5'} sx={{ marginTop: 1 }}>
            {DATA_LABEL}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6.5}
          spacing={1}
          container
        >
          <Grid
            item
            xs={12}
            sm={12}
          >
            <CustomRadioButtons
              disabled={searchedUserInUse}
              input={salutation}
              row={true}
              onChange={(value) => formik.setFieldValue('salutation', value)}
              value={formik.values.salutation}
            />
          </Grid>
          <Grid item>
            <Typography sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              color: 'error.main',
            }} align={'center'}>
              {formik.errors?.salutation || ''}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <CustomFormikTextField
                disabled={searchedUserInUse}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                id={'firstName'}
                label={'Vorname'}
                error={Boolean(formik.errors?.firstName)}
                helperText={formik.errors?.firstName || ''}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <CustomFormikTextField
                disabled={searchedUserInUse}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                id={'lastName'}
                label={'Nachname'}
                error={Boolean(formik.errors?.lastName)}
                helperText={formik.errors?.lastName || ''}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
          >
            <CustomFormikTextField
              disabled={!!disabled || !!searchedUserInUse}
              value={formik.values.email}
              onChange={formik.handleChange}
              id={'email'}
              label={'E-Mail'}
              error={Boolean(formik.errors?.email)}
              helperText={formik.errors?.email || ''}
            />
          </Grid>
        </Grid>
        {largeScreen &&
          <Grid item xs={false} sm={2}/>
        }
        <Grid
          item
          xs={12}
          sm={3.5}
          md={2}
        >
          <Typography variant={'h5'} sx={{ marginTop: 1 }}>
            {AUTHORIZATION}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8.5}
        >
          <CustomRadioButtons
            disabled={!!disabled || !!searchedUserInUse}
            input={groupName}
            row={true}
            onChange={(value) => formik.setFieldValue('groupName', value)}
            value={formik.values.groupName}
          />
          <Typography
            variant={'body2'}
            color={'error'}
            sx={{
              fontSize: '16px',
              transformOrigin: 'top left',
              transform: 'translate(0, 1.5px) scale(0.75)',
            }}
          >
            {formik.errors?.groupName || ''}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.5}
          md={2}
        >
          <Typography variant={'h5'} sx={{ marginTop: 1 }}>
            {NOTIFICATION}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8.5}
          container
          spacing={2}
          alignItems={'center'}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography>
              Benachrichtigung über Infos
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <SwitchCheckbox
              value={formik.values.settings?.mailNotifications?.infoNotifications}
              onChange={(value) => onChangeNotifications('infoNotifications', value)}
              id={'infoNotificationSwitch'}
              label={notificationLabel(formik.values.settings?.mailNotifications?.infoNotifications)}
              labelPlacement={'end'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography>
              Benachrichtigung über Warnungen
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <SwitchCheckbox
              value={formik.values.settings?.mailNotifications?.warningNotifications}
              onChange={(value) => onChangeNotifications('warningNotifications', value)}
              id={'warningNotifications'}
              label={notificationLabel(formik.values.settings?.mailNotifications?.warningNotifications)}
              labelPlacement={'end'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography>
              Benachrichtigung über Fehler
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <SwitchCheckbox
              value={formik.values.settings?.mailNotifications?.errorNotifications}
              onChange={(value) => onChangeNotifications('errorNotifications', value)}
              id={'errorNotifications'}
              label={notificationLabel(formik.values.settings?.mailNotifications?.errorNotifications)}
              labelPlacement={'end'}
            />
          </Grid>
        </Grid>
        <Grid item xs={false} sm={2}/>
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default AuthorizationWidget