import React from 'react'
import DigitalOutputEventChartTimeZoom from '../../../../components/DigitalOutputEventsChart/DigitalOutputEventChartTimeZoom'
import { styled } from '@mui/material/styles'

const ChartBoxContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const DigitalOutputEventChartBoxTimeLine = ({ dimData, timeSpan }) => {
  return (
    <ChartBoxContainer>
      <DigitalOutputEventChartTimeZoom dimData={dimData} timeSpan={timeSpan}/>
    </ChartBoxContainer>
  )
}

export default DigitalOutputEventChartBoxTimeLine