import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { getRoles, isServicePerson } from '../../../security/roles'
import { useSelector } from 'react-redux'
import ConfigurationTabLayout from './ConfigurationTabLayout'
import { useLocation } from 'react-router-dom'
import PvHelpText from '../../../help-texts/FL_Konfiguration_Photovoltaik.md'
import ChargingPointHelpText from '../../../help-texts/FL_Konfiguration_Ladepunkte.md'
import ConfigurationHelpText from '../../../help-texts/FL_Konfiguration_Lademanagement.md'

const ConfigurationTabPage = () => {
  const userRoles = useSelector(state => getRoles(state))
  const url = useLocation().pathname

  const [helpText, setHelpText] = useState()
  const [helpTitle, setHelpTitle] = useState()

  const CONFIG = 'Konfiguration'
  const LMS_HELP_TITLE = 'Lademanagement'
  const CP_HELP_TITLE = 'Ladepunkte'
  const PV_HELP_TITLE = 'Photovoltaik'

  const getHelpText = (url) => {
    if (url.includes('chargingpoints')) {
      return { title: CP_HELP_TITLE, text: ChargingPointHelpText }
    } else if (url.includes('pv')) {
      return { title: PV_HELP_TITLE, text: PvHelpText }
    } else {
      return { title: LMS_HELP_TITLE, text: ConfigurationHelpText }
    }
  }

  useEffect(() => {
    const helpText = getHelpText(url)
    setHelpTitle(helpText.title)
    fetch(helpText.text)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <Page viewName={'Konfiguration'} pageTitle={CONFIG} helpText={helpText} helpTitle={helpTitle}
          installation={isServicePerson(userRoles)}>
      <ConfigurationTabLayout/>
    </Page>
  )
}

export default ConfigurationTabPage