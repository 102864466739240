import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import CustomRadioButtons from './CustomRadioButtons'
import CustomTextField from './CustomTextField'
import CustomMultilineTextField from './CustomMultilineTextField'
import { styled } from '@mui/material/styles'

const TextFieldHeaderLeft = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  description: theme.typography.description,
}))

const ContactWidget = (props) => {
  const [data, setData] = useState(props?.formData)
  const schema = props?.schema.properties
  const [errorSchema, setErrorSchema] = useState(props?.errorSchema)

  const gender = [
    {
      name: 'Frau',
      label: 'Frau',
    },
    {
      name: 'Herr',
      label: 'Herr',
    },
    {
      name: 'Divers',
      label: 'Neutrale Anrede',
    },
  ]

  const roles = [
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'manager',
      label: 'Verwalter',
    },
  ]

  useEffect(() => {
    props.onChange(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setData(props.formData)
    setErrorSchema(props.errorSchema)
  }, [props])

  const onChange = (name, value, contact) => {
    if (contact) {
      setData(
        {
          ...data,
          contact: {
            ...data.contact,
            [name]: value,
          },
        },
      )
    } else {
      setData(
        {
          ...data,
          [name]: value,
        },
      )
    }
  }

  return (
    <Grid
      container
      sx={{ flexGrow: 1 }}
    >
      <Grid item container spacing={2} xs={12}>
        <Grid
          item
          container
          xs={12}
        >
          <Grid item sm={2}>
            <TextFieldHeaderLeft variant={'h5'}>
              {schema?.contact.properties.role.title ? schema?.contact.properties.role.title : ''}
            </TextFieldHeaderLeft>
          </Grid>
          <Grid item sm={10} xs={12}>
            <CustomRadioButtons
              input={roles}
              row={true}
              onChange={(value) => onChange('role', value, true)}
              value={data?.contact.role}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
        >
          <Grid item sm={2}>
            <TextFieldHeaderLeft variant={'h5'}>
              {schema?.contact.properties.salutation.title ? schema?.contact.properties.salutation.title : ''}
            </TextFieldHeaderLeft>
          </Grid>
          <Grid item xs={12} sm={10}>
            <CustomRadioButtons
              input={gender}
              row={true}
              onChange={(value) => onChange('salutation', value, true)}
              value={data?.contact.salutation}
            />
          </Grid>
          <Grid item sm={2}>
            <TextFieldHeaderLeft variant={'h5'}>
              Daten
            </TextFieldHeaderLeft>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <CustomTextField
                  props={schema?.contact.properties.firstName}
                  value={data?.contact.firstName}
                  error={errorSchema?.contact?.firstName}
                  onChange={(value) => onChange('firstName', value, true)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  props={schema?.contact.properties.lastName}
                  value={data?.contact.lastName}
                  error={errorSchema?.contact?.lastName}
                  onChange={(value) => onChange('lastName', value, true)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} sm={2}/>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <CustomTextField
                  props={schema?.contact.properties.locationStreet}
                  value={data?.contact.locationStreet}
                  error={errorSchema?.contact?.locationStreet}
                  onChange={(value) => onChange('locationStreet', value, true)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  props={schema?.contact.properties.locationStreetNumber}
                  value={data?.contact.locationStreetNumber}
                  error={errorSchema?.contact?.locationStreetNumber}
                  onChange={(value) => onChange('locationStreetNumber', value, true)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} sm={2}/>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <CustomTextField
                  props={schema?.contact.properties.locationPostcode}
                  value={data?.contact.locationPostcode}
                  error={errorSchema?.contact?.locationPostcode}
                  onChange={(value) => onChange('locationPostcode', value, true)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  props={schema?.contact.properties.locationCity}
                  value={data?.contact.locationCity}
                  error={errorSchema?.contact?.locationCity}
                  onChange={(value) => onChange('locationCity', value, true)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} sm={2}/>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <CustomTextField
              props={schema?.contact.properties.email}
              value={data?.contact.email}
              error={errorSchema?.contact?.email}
              onChange={(value) => onChange('email', value, true)}
            />
          </Grid>
          <Grid item xs={false} sm={2}/>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <CustomTextField
              props={schema?.contact.properties.phone}
              value={data?.contact.phone}
              error={errorSchema?.contact?.phone}
              onChange={(value) => onChange('phone', value, true)}
            />
          </Grid>

        </Grid>
        <Grid
          item
          container
          xs={12}
        >
          <Grid item sm={2}>
            <TextFieldHeaderLeft variant={'h5'}>
              {schema?.contact.properties.notes.title ? schema?.contact.properties.notes.title : ''}
            </TextFieldHeaderLeft>
          </Grid>
          <Grid item sm={10} xs={12}>
            <CustomMultilineTextField
              rows={5}
              props={schema?.contact.properties.notes}
              value={data?.contact.notes}
              error={errorSchema?.contact?.notes}
              onChange={(value) => onChange('notes', value, true)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactWidget