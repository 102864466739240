import React, { useState } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { TextField } from 'formik-mui'
import { Field, Form, Formik } from 'formik'
import { VALIDATION_SCHEMA_PASSWORD } from '../../constants'
import { Button, IconButton, InputAdornment, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { styled } from '@mui/material/styles'

const schema = {
  password: VALIDATION_SCHEMA_PASSWORD,
  confirm: {
    presence: { allowEmpty: false, message: '^Bitte Passwort wiederholen' },
    equality: {
      attribute: 'password',
      message: '^Passwörter müssen übereinstimmen',
    },
  },
}

const TextFieldLabelBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const NewPasswordForm = ({ onSubmit, error }) => {

  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  return (
    <div>
      <Typography
        sx={{ marginTop: 3 }}
        variant="h2">
        Neues Passwort vergeben
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1">
        Vergeben Sie ein neues Passwort für Ihr Nutzerkonto.
      </Typography>

      <Formik
        validateOnMount={false}
        initialValues={{
          password: '',
          confirm: '',
        }}
        validate={values => {
          return validate(values, schema, { format: 'first' })
        }}
        onSubmit={values => {
          onSubmit(values)
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
            variant: 'success',
          })
        }}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                Passwort
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              component={TextField}
            />
            <TextFieldLabelBox>
              <Typography variant={'caption'}>
                Passwort wiederholen
              </Typography>
            </TextFieldLabelBox>
            <Field
              fullWidth
              name="confirm"
              type={showRepeatPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              component={TextField}
            />
            <Button
              sx={{ marginTop: 2 }}
              id={'submit'}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isValid === false || isSubmitting}
              variant="contained">
              <Typography
                sx={{
                  transform: 'skewText',
                  color: 'white',
                }}>
                Passwort speichern
              </Typography>
            </Button>
          </Form>
        )}
      </Formik>
      {error && <Typography
        color="error"
        variant="subtitle2">
        {error}
      </Typography>}
    </div>
  )
}

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default NewPasswordForm
