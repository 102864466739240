import MultiSelector from './MultiSelector'
import React from 'react'

const ChargingPointMultiSelector = ({ chargingPoints, selectedChargingPoints, setSelectedChargingPoints }) => {

  const transformSelectedItems = (items) => {
    setSelectedChargingPoints(items.map(cpId => parseInt(cpId)))
  }

  return (
    <MultiSelector optionsData={chargingPoints.reduce((prev, item) => ({ ...prev, [item.id]: String(item.name) }), {})}
                   selectedItems={selectedChargingPoints.map(cpId => String(cpId))}
                   setSelectedItems={transformSelectedItems}
                   inputPlaceHolderText={' Ladepunkte auswählen...'}
                   noSelectedItemsText={'Keine Ladepunkte ausgewählt'}
    />
  )
}

export default ChargingPointMultiSelector