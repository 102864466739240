import React from 'react'
import { initPasswordReset } from '../../store'
import EmailForm from '../../components/EmailForm/EmailForm'

const ResetPassword = () => {

  const handleSubmit = (values) => {
    initPasswordReset(values.email)
  }

  return (
    <EmailForm
      onSubmit={handleSubmit}
    />
  )
}

export default ResetPassword
