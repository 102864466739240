import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import FirmwareHelpTextEdit from '../../../help-texts/administration/FL_Firmware_Install.md'
import Page from '../../../components/Page/Page'
import InstallFirmwareContainer from './InstallFirmwareContainer'

const InstallFirmwarePage = () => {
  const { manufacturer, version } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const FIRMWARE_URL = `/api/administration/charging-point-firmwares/${manufacturer}/${version}`

  const [firmware, setFirmware] = useState(location.state?.firmware || {})
  const [helpTextFirmwareEdit, setHelpTextFirmwareEdit] = useState()

  const FIRMWARE_INSTALL_CARD_TITLE = 'Firmware Version installieren'

  useEffect(() => {
    fetchFirmware()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(FirmwareHelpTextEdit)
      .then(res => res.text())
      .then(text => setHelpTextFirmwareEdit(text))
      .catch(error => throw new Error(error))
  }

  const fetchFirmware = () => {
    if (firmware) {
      return
    }
    backendApiClient.get(FIRMWARE_URL)
      .then((response) => {
        setFirmware(response)
      })
      .catch((e) => throw new Error(e))
  }

  return (
    <Page viewName={FIRMWARE_INSTALL_CARD_TITLE} helpText={helpTextFirmwareEdit} helpTitle={FIRMWARE_INSTALL_CARD_TITLE} pageTitle={FIRMWARE_INSTALL_CARD_TITLE} onBack={() => navigate(-1)}>
      <InstallFirmwareContainer firmware={firmware}/>
    </Page>
  )
}

export default InstallFirmwarePage
