import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import UsersAddHelpText from '../../../help-texts/FL_Authorization_ChargingPoints_Users_Add.md'
import { backendApiClient } from '../../../apiClient'
import Page from '../../../components/Page/Page'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import MultiSelector from '../../../components/MultiSelector/MultiSelector'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'

const AddChargingPointToUsersPage = () => {
  const { clientId, pointId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState()
  const [selectableUsers, setSelectableUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [name, setName] = useState(location.state?.name)

  const CP_USERS_ADDABLE = `/api/client/${clientId}/chargingpoints/${pointId}/users/addable`
  const CP_URL = `/api/client/${clientId}/configuration/chargingpoints/${pointId}`
  const CP_ID_TOKENS_URL = `/api/client/${clientId}/chargingpoints/${pointId}/id-tokens`

  const SUB_TITLE = `Für Ladepunkt ${name}`
  const TITLE = 'Nutzer hinzufügen'

  useEffect(() => {
    setLoading(true)
    fetchHelpText()
    if (!name) {
      fetchChargingPoint()
    }
    fetchInactiveChargingPointUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchChargingPoint = () => {
    backendApiClient.get(CP_URL)
      .then((response) => {
        setName(response.name)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const fetchHelpText = () => {
    fetch(UsersAddHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchInactiveChargingPointUsers = () => {
    setLoading(true)
    backendApiClient.get(CP_USERS_ADDABLE)
      .then((response) => {
        setSelectableUsers(response)
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => setLoading(false))
  }

  const handleSetSelectedUsers = (value) => {
    setSelectedUsers(value)
  }

  const onSubmit = () => {
    setLoading(true)
    backendApiClient.post(CP_ID_TOKENS_URL, selectedUsers)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, { variant: 'success' })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={TITLE} pageTitle={TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={TITLE}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h4'}>
            {SUB_TITLE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={3}
        >
          <Box paddingBottom={0.5}>
            <Typography>Nutzer hinzufügen</Typography>
          </Box>
          <MultiSelector optionsData={selectableUsers.reduce((prev, item) => ({ ...prev, [item.userId]: String(item.name + ' - ' + item.email) }), {})}
                         selectedItems={selectedUsers}
                         setSelectedItems={handleSetSelectedUsers}
                         inputPlaceHolderText={' Nutzer auswählen...'}
                         noSelectedItemsText={' Keine Nutzer ausgewählt'}
                         selectedBoxHeight={300}
          />
        </Grid>
      </Grid>
      <SubmitFab show={true} onClick={() => onSubmit()}/>
    </Page>
  )
}

export default AddChargingPointToUsersPage