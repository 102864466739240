import SettingsIcon from '@mui/icons-material/Settings'
import { ALL_ROLES } from '../../../../security/roles'

export const userElements = {
  global: [
    {
      title: 'Einstellungen',
      path: '/user',
      icon: SettingsIcon,
      roles: ALL_ROLES,
    },
  ],
  general: [
    {
      title: 'Einstellungen',
      path: '/client/:clientId/user',
      icon: SettingsIcon,
      roles: ALL_ROLES,
    },
  ],
}