import palette from '../palette'

const MuiChip = {
  styleOverrides: {
    root: {
      backgroundColor: palette.black,
    },
  },
}

export default MuiChip