import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'

const MeterLabel = ({ total, uom, label }) => {
  return (
    <Card
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 2,
      }}
    >
      <Typography
        variant={'h5'}
        sx={{
          height: '100%',
          fontWeight: '600',
        }}
      >
        {label}
      </Typography>
      {total !== undefined &&
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}>
          <Typography sx={{
            height: '100%',
            fontSize: '40px',
            fontWeight: '700',
            paddingTop: '15px',
            paddingBottom: '10px',
            color: 'primary.alternateContrastText',
          }}>
            {total.toFixed(2)
              .toString()
              .replace('.', ',')}
          </Typography>
          <Typography sx={{
            height: '100%',
            fontSize: '20px',
            fontWeight: '400',
            paddingTop: '15px',
            paddingBottom: '3px',
            color: 'primary.alternateContrastText',
          }}>
            {uom}
          </Typography>
        </div>
      }
    </Card>
  )
}

MeterLabel.propTypes = {
  total: PropTypes.number,
  CurrentLimit: PropTypes.number,
  PowerLimit: PropTypes.number,
}

export default MeterLabel
