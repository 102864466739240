import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import EditDigitalOutput from './EditDigitalOutput'
import editDigitalOutputsHelpTextRaw from '../../../help-texts/FL_Energiemanagement_Digitale_Ausgaenge_bearbeiten.md'

const EditDigitalOutputPage = () => {
  const navigate = useNavigate()

  const PAGE_TITLE = 'Energiemanagement'
  const DO_EDIT_PAGE_TITLE = 'Digitaler Ausgang bearbeiten'

  const [editDigitalOutputsHelpText, setEditDigitalOutputsHelpText] = useState()
  const EDIT_DIGITAL_OUTPUT_HELP_TITLE = 'Digitale Ausgänge bearbeiten'

  useEffect(() => {

    fetch(editDigitalOutputsHelpTextRaw)
      .then(res => res.text())
      .then(text => setEditDigitalOutputsHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={DO_EDIT_PAGE_TITLE} pageTitle={PAGE_TITLE} onBack={() => navigate(-1)}
          helpText={editDigitalOutputsHelpText} helpTitle={EDIT_DIGITAL_OUTPUT_HELP_TITLE}>
      <EditDigitalOutput onAfterSubmit={() => navigate(-1)}/>
    </Page>
  )

}

export default EditDigitalOutputPage