const region = process.env.REACT_APP_AWS_REGION || 'eu-central-1'
const userpoolId = process.env.REACT_APP_USER_POOL_ID
const userpoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID
const backendApi = process.env.REACT_APP_BACKEND_API

const awsmobile = {
  'aws_project_region': region,
  'aws_cognito_region': region,
  'aws_user_pools_id': userpoolId,
  'aws_user_pools_web_client_id': userpoolClientId,
  'oauth': {},
  'aws_dynamodb_all_tables_region': region,
  'aws_cloud_logic_custom': [
    {
      'name': 'backendApi',
      'endpoint': backendApi,
      'region': region,
    },
  ],
  'authenticationFlowType': 'USER_PASSWORD_AUTH',
}

export default awsmobile
