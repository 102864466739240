import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import FirmwareWidget from './FirmwareWidget'
import FirmwareHelpTextNew from '../../../help-texts/administration/FL_Firmware_Versions_New.md'
import { LoadingBackdrop } from '../../../components'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import Page from '../../../components/Page/Page'

const NewFirmwarePage = ({ roles }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const FIRMWARES_URL = '/api/administration/charging-point-firmwares'
  const maxFileSize = 262_144_000 // 250MB

  const [loading, setLoading] = useState(false)
  const [firmware] = useState({})
  const [helpTextFirmwareNew, setHelpTextFirmwareNew] = useState()

  const FIRMWARE_NEW_CARD_TITLE = 'Neue Firmware Version'

  useEffect(() => {
    fetch(FirmwareHelpTextNew)
      .then(res => res.text())
      .then(text => setHelpTextFirmwareNew(text))
      .catch(error => throw new Error(error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data, actions) => {
    setLoading(true)

    const { file, filePath, ...request } = data
    backendApiClient.post(FIRMWARES_URL, request)
      .then((response) => {
        return backendApiClient.s3Put(response.uploadUrl, data.file)
      })
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, { variant: 'success' })
        navigate(-1)
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          actions.setErrors({ value: 'Firmware Version bereits vorhanden' })
        } else {
          enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={FIRMWARE_NEW_CARD_TITLE} helpText={helpTextFirmwareNew} helpTitle={FIRMWARE_NEW_CARD_TITLE} pageTitle={FIRMWARE_NEW_CARD_TITLE} roles={roles} onBack={() => navigate(-1)}>
      <LoadingBackdrop open={loading}/>
      <FirmwareWidget firmware={firmware} save={save} loading={loading} maxFileSize={maxFileSize}/>
    </Page>
  )
}

export default NewFirmwarePage
