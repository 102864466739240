import React from 'react'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import MessageIcon from '../../components/MessageIcon/MessageIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { ListItemButton } from '@mui/material'
import { DateTime } from 'luxon'

const MessagesList = ({ messages, onOpen }) => {
  return (
    <List>
      {messages && messages.map((item) => (
        <React.Fragment key={item.id}>
          <ListItemButton
            button
            onClick={() => onOpen(item)}>
            <ListItemAvatar>
              <MessageIcon severity={item.severity}/>
            </ListItemAvatar>
            <ListItemText
              primary={item.messageType}
              secondary={
                <React.Fragment>
                  <Typography sx={{ fontWeight: 600 }}>
                    {item.id ? DateTime.fromISO(item.id)
                      .toFormat('dd.MM.yyyy HH:mm:ss') : '--'}
                  </Typography>
                  <Typography sx={{
                    timeStampOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}>
                    {item.message}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItemButton>
          <Divider variant="inset" component="li"/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default MessagesList
