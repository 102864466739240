import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import EvaluationTabContainer from './EvaluationTabContainer'
import chargingProcessesHelp from '../../help-texts/FL_Auswertung_Ladevorgänge.md'
import { useLocation } from 'react-router'

const annualConsumptionHelpText = `
## Jahresverbrauch
Die Diagramme auf dieser Seite stellen den Jahresverbrauch des ausgewählten Jahres dar.

### Auswahl Jahr
In der Auswahlbox kann das gewünschte Jahr ausgewählt werden. Durch eine Änderung werden die Diagramme automatisch aktualisiert. 

### Verbrauch in kWh
Im Balkendiagramm und in der Tabelle werden die monatlichen Lademengen und der Netzbezug, des gewählten Jahres dargestellt.
`

const powerHistoryHelpText = `
Dises Diagramm zeigt den Verlauf der Ladeleistung der letzten 24 Stunden.
`

const EvaluationPage = () => {
  const currentUrl = useLocation().pathname
  const [chargingProcessesHelpText, setChargingProcessesHelpText] = useState()
  const [helpText, setHelpText] = useState('')

  useEffect(() => {
    fetch(chargingProcessesHelp)
      .then(res => res.text())
      .then(text => setChargingProcessesHelpText(text))
  }, [])

  useEffect(() => {
    if (currentUrl.includes('consumption')) {
      setHelpText(annualConsumptionHelpText)
    } else if (currentUrl.includes('sessions')) {
      setHelpText(chargingProcessesHelpText)
    } else {
      setHelpText(powerHistoryHelpText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl])

  return (
    <Page viewName={'Auswertung'} helpText={helpText} helpTitle={'Auswertung'} pageTitle={'Auswertung'}>
      <EvaluationTabContainer/>
    </Page>
  )
}

export default EvaluationPage
