import { del, get, post, put } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios'

var Buffer = require('buffer/').Buffer

const apiName = 'backendApi'
const FILE_API_ADDRESS = process.env.REACT_APP_FILE_API_ADDRESS

async function getHeaders () {
  return ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${(await currentAccessToken()).toString()}`,
  })
}

async function getFileHeaders () {
  return ({
    'Content-Type': 'multipart/form-data; boundary=";"',
    Authorization: `Bearer ${(await currentAccessToken()).toString()}`,
  })
}

export async function currentAccessToken () {
  const tokens = (await fetchAuthSession()).tokens
  return tokens?.idToken || Promise.reject('No token')
}

class ApiClient {
  async get (path, queryParams = {}) {
    const options = {
      headers: await getHeaders(),
      queryParams: queryParams,
    }
    const response = await get({ apiName, path, options }).response
    return await response.body.json()

  }

  async post (path, data) {
    const options = {
      headers: await getHeaders(),
      body: data,
    }
    const restOperation = await post({ apiName, path, options }).response
    const responseBody = await restOperation.body
    try {
      return await responseBody.json()
    } catch (error) {
      return responseBody
    }
  }

  async put (path, body, queryParams = {}) {
    let options = {
      headers: await getHeaders(),
      body: body,
      queryParams: queryParams,
    }
    const restOperation = await put({ apiName, path, options }).response
    const responseBody = await restOperation.body
    try {
      return await responseBody.json()
    } catch (error) {
      return responseBody
    }
  }

  async delete (path, queryParams = {}) {
    let options = {
      headers: await getHeaders(),
      queryParams: queryParams,
    }
    const restOperation = await del({ apiName, path, options })
    return await restOperation.response
  }

  async filePost (url, data) {
    let init = {
      headers: await getFileHeaders(),
      responseType: 'blob',
    }
    return await axios.post(FILE_API_ADDRESS + url, data, init)
      .catch((error) => {
        if (error.response?.status === 403) {
          // navigate('/')
        }
        return Promise.reject(error)
      })
  }

  async fileGet (url, queryParams = {}) {
    let init = {
      headers: await getFileHeaders(),
      responseType: 'blob',
      params: queryParams,
    }
    return await axios.get(FILE_API_ADDRESS + url, init)
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  /**
   * Upload file to S3
   * @param presignedUrl the S3 presigned url to use
   * @param file the file to upload
   */
  async s3Put (presignedUrl, file) {
    const init = {
      headers: {
        'Content-Type': file.type,
      },
      responseType: 'json',
    }
    const fileBuffer = Buffer.from(await file.arrayBuffer())
    return await axios.put(presignedUrl, fileBuffer, init)
      .catch((error) => {
        return Promise.reject(error)
      })
  }

}

const client = new ApiClient()

export default client