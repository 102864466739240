import React, { useEffect, useState } from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useParams } from 'react-router'
import { backendApiClient } from '../../../../apiClient'
import { DateTime } from 'luxon'
import { LoadingBackdrop } from '../../../../components'
import DigitalOutputEventChartBoxTimeLine from './DigitalOutputEventChartBoxTimeLine'
import PowerHistoryDoEventChartBox from './PowerHistoryDoEventChartBox'

const DigitalOutputGraphContainer = ({}) => {
  const [loading, setLoading] = useState(false)
  const [chosenOutput, setChosenOutput] = useState(1)
  const [digitalOutputs, setDigitalOutputs] = useState([])
  const [digitalOutputEvents, setDigitalOutputEvents] = useState({})
  const [digitalOutputEventsDimensionData, setDigitalOutputEventsDimensionData] = useState({})
  const [markerData, setMarkerData] = useState([])
  const [timeSpan, setTimeSpan] = useState({})
  const { clientId } = useParams()

  const DIGITAL_OUTPUT_API = '/api/client/' + clientId + '/ems/configuration/digitaloutputs'
  const GRAPH_API = '/api/client/{client}/ems/digitaloutput/{output}/events'

  const handleSelectChange = (event) => {
    setChosenOutput(event.target.value)
  }

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(DIGITAL_OUTPUT_API)
      .then((response) => {
        setChosenOutput(response[0].id)
        setDigitalOutputs(response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  function getTemplateEntry () {
    return {
      digitalOutputId: '',
      from: '',
      to: '',
      reason_On: '',
      reason_Off: '',
      ruleValidationRecords: [],
    }
  }

  function getTemplateDimensions () {
    return [
      'Digitaler Ausgang',
      'Von',
      'Bis',
      'Anschaltgrund',
      'Ausschaltgrund',
      'Rule Records',
    ]
  }

  function fillDimensionData (data) {
    setDigitalOutputEventsDimensionData({})

    let arr = []
    let arrMarker = []
    let entry = getTemplateEntry()

    data.forEach((r) => {

      entry.digitalOutputId = r.digitalOutputId

      if (r.eventType === 'SWITCHED_ON' && entry.to === '') {
        entry.from = r.timestamp
        entry.ruleValidationRecords = r.ruleValidationRecords
        entry.reason_On = r.reason
      } else if (r.eventType === 'SWITCHED_OFF' && entry.from !== '') {
        entry.to = r.timestamp
        entry.reason_Off = r.reason

        addToArr(arr, arrMarker, entry)
        entry = getTemplateEntry()
      } else if (r.eventType === 'RULES_VALIDITY_CHANGED' && entry.from !== '') {
        entry.to = r.timestamp
        addToArr(arr, arrMarker, entry)
        entry = getTemplateEntry()
        entry.ruleValidationRecords = r.ruleValidationRecords
        entry.from = r.timestamp
      }
    })

    if (entry.to === '' && entry.from !== '') {
      entry.to = DateTime.now()
        .toUTC()
        .toISO()
      addToArr(arr, arrMarker, entry)
    }

    setDigitalOutputEventsDimensionData({ 'dimensions': getTemplateDimensions(), 'data': arr.slice() })
    setMarkerData(arrMarker)

  }

  function addToArr (arr, arr_marker, entry) {
    arr.push([entry.digitalOutputId, entry.from, entry.to, entry.reason_On, entry.reason_Off, entry.ruleValidationRecords])
    arr_marker.push([{ 'xAxis': new Date(entry.from) }, { 'xAxis': new Date(entry.to) }])
  }

  const fetchGraphData = () => {
    setLoading(true)
    const url = GRAPH_API.replace('{client}', clientId)
      .replace('{output}', chosenOutput.toString())
    const now = DateTime.now()

    let dataRequestParams = {
      from: now.minus({ hours: 24 })
        .toUTC()
        .toISO(),
      to: now.toUTC()
        .toISO(),
    }

    setTimeSpan(dataRequestParams)
    backendApiClient.get(url, dataRequestParams)
      .then((r) => {
        setDigitalOutputEvents(r.content)
        fillDimensionData(r.content)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        throw new Error(err)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchGraphData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenOutput])

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      {
        digitalOutputs.length > 0 &&
        <FormControl sx={{ width: '200px' }}>
          <Select
            id="DOSelect"
            value={chosenOutput}
            onChange={handleSelectChange}
          >
            {digitalOutputs.map((output) => (
              <MenuItem value={output.id} key={output.id}>{output.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }

      <div>
        {digitalOutputEvents &&
          <DigitalOutputEventChartBoxTimeLine dimData={digitalOutputEventsDimensionData} timeSpan={timeSpan}/>
        }
        <PowerHistoryDoEventChartBox timeSpan={timeSpan} markerData={markerData}/>
      </div>

    </div>
  )
}

export default DigitalOutputGraphContainer