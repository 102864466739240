import PropTypes from 'prop-types'
import { backendApiClient } from './apiClient'

export function isEmpty (value) {
  return (
    (value == null) || (value === 'undefined') ||
    (value.hasOwnProperty('length') && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}

export function truncateFileName (name, chars, subst) {
  return name.replace(
    new RegExp('(^.{' + chars + '}).+(\\.[^\\.]*$)'), '$1' + subst + '$2')
}

/**
 * convert a blob to a downloaded file
 * @param blob blob to download
 * @param fileName name the downloaded file is supposed to have
 */
export function downloadBlob (blob, fileName) {
  try {
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    // 3. Append to html page
    document.body.appendChild(link)
    // 4. Force download
    link.click()
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link)
  } catch (error) {
    throw new Error(error)
  }
}

export function sleep (milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

/**
 * determines if the app can be used or not
 * @param chargingPoint charging point in question
 * @param lmsVersion version of the system the charging point is in
 * @returns {boolean} true = can use app
 */
export function canUseApp (chargingPoint, lmsVersion) {
  return lmsVersion !== 0
}

export function canUseCard (chargingPoint, lmsVersion) {
  return lmsVersion >= 2
}

export function checkPointAppAccess (clientId, pointId, version, enqueueSnackbar) {
  const POINT_URL = `/api/client/${clientId}/configuration/chargingpoints/${pointId}`
  backendApiClient.get(POINT_URL)
    .then((r) => {
      if (!canUseApp(r, version)) {
        enqueueSnackbar('Unerlaubter Zugriff', {
          variant: 'warning',
        })
      }
    })
}

export const sortPoints = (points) => {
  return points.sort((a, b) => {
    if (a.ordinalNumber > b.ordinalNumber) {
      return 1
    } else if (a.ordinalNumber < b.ordinalNumber) {
      return -1
    }
    return 0
  })
}

canUseApp.propTypes = {
  chargingPoint: PropTypes.object,
  lmsVersion: PropTypes.number,
}