import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { truncateFileName } from '../../utils'

const DropzoneDiv = styled('div')({
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
  '&:hover': {
    cursor: 'pointer',
  },
})

const IconDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20,
})

function CustomDropzone ({ maxFiles = 1, dropzoneText, maxFileSize, onChange, onDropRejected }) {
  const mebibyte = 10485760 // 1MiB = 1048576

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    validator: maxFileSizeValidator,
    maxFiles: maxFiles,
  })

  function maxFileSizeValidator (file) {
    if (maxFileSize) {
      if (file.size > maxFileSize * 10) {
        const error = {
          code: 'file-too-large',
          message: `Das Dokument überschreitet die zulässige Dateigröße ${maxFileSize / mebibyte} MB`,
        }
        onDropRejected(error)
        return error
      }
    }
    return null
  }

  const isImage = (file) => {
    return file.type === 'image/jpeg' || file.type === 'image/png'
  }

  useEffect(() => {
    if (onChange) {
      onChange(acceptedFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const files = acceptedFiles.map(file => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {isImage(file) ?
          <img alt="Vorschau" src={URL.createObjectURL(file)} height="110"/>
          :
          <AttachFileIcon style={{ fontSize: 50 }}/>
        }
        <Typography variant={'h6'} align="center">
          {truncateFileName(file.path, 25, '...')}
        </Typography>
      </div>
    )
  })

  return (
    <Box
      my={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ border: '1px dashed lightgrey' }}>
      <DropzoneDiv
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <Typography variant={'h5'} align="center">
          {dropzoneText || ''}
        </Typography>
        <IconDiv>
          <CloudUploadIcon style={{ fontSize: 80 }} align="center"/>
        </IconDiv>
      </DropzoneDiv>
      <Box
        height={110}
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingBottom={3}
      >
        {files}
      </Box>
    </Box>
  )
}

export default CustomDropzone