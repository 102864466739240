import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import CustomFormikTextField from './components/CustomFormikTextField'
import { GeneralFab, LoadingBackdrop, SubmitFab } from '../../index'
import CustomFormikSwitch from './components/CustomFormikSwitch'
import CustomFormikRadioButtons from './components/CustomFormikRadioButtons'
import backendApiClient from '../../../apiClient/apiClient'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { sleep } from '../../../utils'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import TabPanel from '../../TabPanel/TabPanel'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { validationLmsStationSchema } from '../validation/LmsStationValidation'
import { validationLmsContactSchema } from '../validation/LmsContactValidation'
import useMediaQuery from '@mui/material/useMediaQuery'

const LmsWidget = ({ data, groups, onBlur, url, onAfterSubmit, clientId, isNew }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [editOrgId, setEditOrgId] = useState(false)
  const [tabState, setTabState] = useState(1)
  const [initialSetupNecessary, setInitialSetupNecessary] = useState(isNew)

  const versionEnum = ['0', '1', '2']

  const gender = [
    {
      name: 'Frau',
      label: 'Frau',
    },
    {
      name: 'Herr',
      label: 'Herr',
    },
    {
      name: 'Divers',
      label: 'Neutrale Anrede',
    },
  ]

  const formikValidationLmsContact = useFormik({
    initialValues: {
      salutation: 'Divers',
      role: 'manager',
      lastName: '',
      firstName: '',
      email: '',
      locationCity: '',
      locationPostcode: '',
      locationStreet: '',
      locationStreetNumber: '',
      phone: '',
    },
    validationSchema: validationLmsContactSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  })

  const formikValidationLmsStation = useFormik({
    initialValues: {
      name: '',
      lmsVersion: '2',
      organisationId: '',
      clientId: '',
      hasPv: false,
      hasEms: false,
      exportBilling: false,
    },
    validationSchema: validationLmsStationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  })

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
  }

  const handleTabChange = async (event, newValue) => {
    let errors = {}
    if (tabState === 1) {
      errors = await formikValidationLmsStation.validateForm()
    } else if (tabState === 2) {
      errors = await formikValidationLmsContact.validateForm()
    }

    if (isObjectEmpty(errors)) {
      setTabState(newValue)
    } else {
      enqueueSnackbar('Bitte prüfen Sie ihre Eingabe!', { variant: 'warning' })
    }
  }

  const handleSelectChange = async (event) => {
    await handleTabChange({}, event.target.value)
  }

  const onClickNew = async () => {
    let errors = await formikValidationLmsStation.validateForm()
    if (isObjectEmpty(errors)) {
      setInitialSetupNecessary(false)
      setTabState(2)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)

    let validLmsStation = await formikValidationLmsStation.validateForm()
    let validLmsContract = await formikValidationLmsContact.validateForm()

    if (!isObjectEmpty(validLmsStation) || !isObjectEmpty(validLmsContract)) {
      enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', { variant: 'error' })
      setLoading(false)
      return
    }

    let contactArray = []
    contactArray.push(formikValidationLmsContact.values)
    if (data?.contacts.length > 1) {
      contactArray = contactArray.concat(data.contacts.slice(1))
    }
    let contacts = { contacts: contactArray }
    const payload = Object.assign(contacts, formikValidationLmsStation.values)

    backendApiClient.put(url, payload)
      .then(() => {
        // quick fix so AWS has enough time to compile everything in opensearch etc. so its displayed in the overview immediately
        sleep(5000)
          .then(() => {
            enqueueSnackbar('Erfolgreich gespeichert!', {
              variant: 'success',
            })
            setLoading(false)
            onAfterSubmit()
          })
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    if (data && Object.values(data).length > 0) {
      formikValidationLmsContact.setValues(data.contacts[0])
      const { contacts, ...lmsStationData } = data
      formikValidationLmsStation.setValues(lmsStationData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    formikValidationLmsStation.setValues({
      ...formikValidationLmsStation.values,
      clientId: clientId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      handleSubmit()
        .then(() => {})
    }}>
      <LoadingBackdrop open={loading}/>
      {smallScreen ?
        <FormControl fullWidth>
          <Select
            labelId="mobileTabChanger-label"
            id="mobileTabChanger"
            value={tabState}
            onChange={handleSelectChange}
            fullWidth
            disabled={initialSetupNecessary}
          >
            <MenuItem value={1}>Ladestation</MenuItem>
            <MenuItem value={2}>Daten</MenuItem>
            <MenuItem value={3}>Eigenschaften</MenuItem>
          </Select>
        </FormControl>
        :
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabState}
                onChange={handleTabChange}
                aria-label="lms-tabs"
                indicatorColor="primary"
                textColor="primary"
                variant={'fullWidth'}
                disabled={initialSetupNecessary}
          >
            <Tab label="Ladestation" value={1} id={'tabs-1'} disabled={initialSetupNecessary}/>
            <Tab label="Daten" value={2} id={'tabs-2'} disabled={initialSetupNecessary}/>
            <Tab label="Eigenschaften" value={3} id={'tabs-3'} disabled={initialSetupNecessary}/>
          </Tabs>
        </Box>
      }
      <TabPanel value={tabState} index={1}>
        <Grid
          container
          sx={{ flexGrow: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={10}
          >
            <CustomFormikTextField
              onBlur={onBlur ? () => onBlur(formikValidationLmsStation.values.name, formikValidationLmsStation.values.lmsVersion) : () => {}}
              value={formikValidationLmsStation.values.name ? formikValidationLmsStation.values.name : ''}
              onChange={formikValidationLmsStation.handleChange}
              error={Boolean(formikValidationLmsStation.errors.name)}
              helperText={formikValidationLmsStation.errors.name}
              id={'name'}
              name={'name'}
              label={'Name'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <TextField
              onBlur={() => {
                if (onBlur) {
                  onBlur(formikValidationLmsStation.values.name, formikValidationLmsStation.values.lmsVersion)
                }
              }}
              sx={{ color: 'text.primary' }}
              select
              fullWidth
              id={'lmsVersion'}
              name={'lmsVersion'}
              label={'LMS Version'}
              variant={'standard'}
              onChange={formikValidationLmsStation.handleChange}
              value={formikValidationLmsStation.values.lmsVersion ? formikValidationLmsStation.values.lmsVersion : false}
              error={formikValidationLmsStation.touched.lmsVersion && Boolean(formikValidationLmsStation.errors.lmsVersion)}
            >
              {versionEnum.map((item) => (
                <MenuItem key={versionEnum.indexOf(item)} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            justify="flex-end"
          >
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <TextField
                  sx={{ width: '100%' }}
                  value={formikValidationLmsStation.values.organisationId}
                  onBlur={() => setEditOrgId(false)}
                  onChange={formikValidationLmsStation.handleChange}
                  disabled={!editOrgId}
                  label={'ORG-ID:'}
                  variant="standard"
                  size="small"
                  id={'organisationId'}
                  name={'organisationId'}
                  error={Boolean(formikValidationLmsStation.errors?.organisationId)}
                  helperText={formikValidationLmsStation.errors?.organisationId}
                />
                {!data ?
                  <IconButton
                    sx={{ marginBottom: -2 }}
                    onClick={() => setEditOrgId(true)}
                  >
                    <EditIcon fontSize="small"/>
                  </IconButton>
                  :
                  <></>
                }
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Grid item sm={10} xs={12}>
              <Typography
                display={'inline'}
                sx={{
                  width: '100px',
                  color: 'secondary.light',
                  paddingRight: 1,
                }}
              >
                {`LMS-ID:`}
              </Typography>
              <Typography
                display={'inline'}
                sx={{
                  color: 'secondary.light',
                }}>
                {`${formikValidationLmsStation.values.clientId ? formikValidationLmsStation.values.clientId : ''}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabState} index={2}>
        <Grid
          container
          sx={{ width: '100%' }}
          spacing={1}
        >
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sx={{
              marginTop: 1,
              marginBottom: '3px',
            }}
            >
              <Typography variant={'h5'}>
                Standort
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={10}
              spacing={1}
            >
              <Grid item xs={12} sm={9}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.locationStreet ? formikValidationLmsContact.values.locationStreet : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'locationStreet'}
                  name={'.locationStreet'}
                  label={'Straße'}
                  error={Boolean(formikValidationLmsContact.errors?.locationStreet)}
                  helperText={formikValidationLmsContact.errors?.locationStreet ? formikValidationLmsContact.errors.locationStreet : ''}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.locationStreetNumber ? formikValidationLmsContact.values.locationStreetNumber : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'locationStreetNumber'}
                  name={'locationStreetNumber'}
                  label={'Hausnummer'}
                  error={Boolean(formikValidationLmsContact.errors?.locationStreetNumber)}
                  helperText={formikValidationLmsContact.errors?.locationStreetNumber ? formikValidationLmsContact.errors.locationStreetNumber : ''}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={10}
              spacing={1}
              sx={{ marginBottom: '40px' }}
            >
              <Grid item xs={12} sm={9}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.locationCity ? formikValidationLmsContact.values.locationCity : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'locationCity'}
                  name={'locationCity'}
                  label={'Ort'}
                  error={Boolean(formikValidationLmsContact.errors?.locationCity)}
                  helperText={formikValidationLmsContact.errors?.locationCity ? formikValidationLmsContact.errors.locationCity : ''}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.locationPostcode ? formikValidationLmsContact.values.locationPostcode : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'locationPostcode'}
                  name={'locationPostcode'}
                  label={'PLZ'}
                  error={Boolean(formikValidationLmsContact.errors?.locationPostcode)}
                  helperText={formikValidationLmsContact.errors?.locationPostcode ? formikValidationLmsContact.errors.locationPostcode : ''}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{
              marginTop: 1,
              marginBottom: '3px',
            }}
            >
              <Typography variant={'h5'}>
                Ansprechperson
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={10}>
              <Grid item alignItems={'center'}>
                <CustomFormikRadioButtons
                  input={gender}
                  row={true}
                  handleChange={formikValidationLmsContact.handleChange}
                  id={'salutation'}
                  name={'salutation'}
                  value={formikValidationLmsContact.values.salutation ? formikValidationLmsContact.values.salutation : 'Divers'}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={10}
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.lastName ? formikValidationLmsContact.values.lastName : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'lastName'}
                  name={'lastName'}
                  label={'Nachname'}
                  error={Boolean(formikValidationLmsContact.errors?.lastName)}
                  helperText={formikValidationLmsContact.errors?.lastName ? formikValidationLmsContact.errors.lastName : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomFormikTextField
                  value={formikValidationLmsContact.values.firstName ? formikValidationLmsContact.values.firstName : ''}
                  onChange={formikValidationLmsContact.handleChange}
                  id={'firstName'}
                  name={'firstName'}
                  label={'Vorname'}
                  error={Boolean(formikValidationLmsContact.errors?.firstName)}
                  helperText={formikValidationLmsContact.errors?.firstName ? formikValidationLmsContact.errors.firstName : ''}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
            >
              <CustomFormikTextField
                value={formikValidationLmsContact.values.email ? formikValidationLmsContact.values.email : ''}
                onChange={formikValidationLmsContact.handleChange}
                id={'email'}
                name={'email'}
                label={'E-Mail'}
                error={Boolean(formikValidationLmsContact.errors?.email)}
                helperText={formikValidationLmsContact.errors?.email ? formikValidationLmsContact.errors.email : ''}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
            >
              <CustomFormikTextField
                value={formikValidationLmsContact.values.phone ? formikValidationLmsContact.values.phone : ''}
                onChange={formikValidationLmsContact.handleChange}
                id={'phone'}
                name={'phone'}
                label={'Telefonnummer'}
                error={Boolean(formikValidationLmsContact.errors?.phone)}
                helperText={formikValidationLmsContact.errors?.phone ? formikValidationLmsContact.errors.phone : ''}
              />
            </Grid>

          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabState} index={3}>
        <Grid item container xs={12} spacing={2}>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <CustomFormikSwitch
              value={formikValidationLmsStation.values.hasPv ? formikValidationLmsStation.values.hasPv : formikValidationLmsStation.initialValues.hasPv}
              onChange={formikValidationLmsStation.handleChange}
              label={'PV Anlage berücksichtigen?'}
              id={'hasPv'}
              name={'hasPv'}
            />
          </Grid>
          <Box
            component={Grid}
            item
            sm={2}
            display={{ xs: 'none', sm: 'block' }}
          />
          {formikValidationLmsStation.values.lmsVersion === '2' &&
            <Grid
              item
              xs={12}
              sm={10}
            >
              <CustomFormikSwitch
                value={formikValidationLmsStation.values.hasEms ? formikValidationLmsStation.values.hasEms : formikValidationLmsStation.initialValues.hasEms}
                onChange={formikValidationLmsStation.handleChange}
                label={'Energiemanagement wird unterstützt?'}
                id={'hasEms'}
                name={'hasEms'}
              />
            </Grid>
          }
          {formikValidationLmsStation.values.lmsVersion === '2' &&

            <Box
              component={Grid}
              item
              sm={2}
              display={{ xs: 'none', sm: 'block' }}
            />
          }
          {formikValidationLmsStation.values.lmsVersion === '2' &&

            <Grid
              item
              xs={12}
              sm={10}
            >
              <CustomFormikSwitch
                value={formikValidationLmsStation.values.exportBilling ? formikValidationLmsStation.values.exportBilling : formikValidationLmsStation.initialValues.exportBilling}
                onChange={formikValidationLmsStation.handleChange}
                label={'Automatischer Datenexport'}
                id={'exportBilling'}
                name={'exportBilling'}
              />
            </Grid>
          }
          {formikValidationLmsStation.values.lmsVersion === '2' && groups &&
            <Grid
              item
              container
              sx={{ marginTop: 2 }}
              spacing={1}
              sm={10}
              xs={12}>
              <Grid item sm={10} xs={12}>
                <Typography
                  display={'inline'}
                  variant={'h5'}
                >
                  Installierte Komponenten:
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12}>
                <FormGroup>
                  <FormControlLabel disabled control={<Checkbox checked={groups.includes('FL-LMS02-OCPP')}/>} label="OCPP"/>
                  <FormControlLabel disabled control={<Checkbox checked={groups.includes('FL-LMS02-Amperfied')}/>} label="Amperfied"/>
                </FormGroup>
              </Grid>
            </Grid>
          }
        </Grid>
      </TabPanel>
      {
        tabState === 1 && initialSetupNecessary ?
          <GeneralFab
            text={'Weiter'}
            onClick={onClickNew}
            id={'continueFab'}
          >
            <ArrowForwardIosIcon/>
          </GeneralFab>
          :
          <SubmitFab show={true}/>
      }
    </form>
  )
}

LmsWidget.propTypes = {
  data: PropTypes.object,
  onBlur: PropTypes.func,
  url: PropTypes.string,
  onAfterSubmit: PropTypes.func,
  validationLmsDataSchema: PropTypes.object,
  validationLmsStationSchema: PropTypes.object,
  clientId: PropTypes.string,
}

export default LmsWidget