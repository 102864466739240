import React from 'react'
import PropTypes from 'prop-types'

class Poller extends React.Component {

  timerId

  componentDidMount () {
    this.startPolling(this.props.action, this.props.interval)
  }

  componentWillUnmount () {
    this.stopPolling()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((this.props.action !== prevProps.action) ||
      (this.props.interval !== prevProps.interval)) {
      this.stopPolling()
      this.startPolling(this.props.action, this.props.interval)
    }
  }

  startPolling (action, interval) {
    if (this.props.action) {
      this.timerId = setInterval(() => {
        if (navigator.onLine) {
          action()
        }
      }, interval)
    }
  }

  stopPolling () {
    clearInterval(this.timerId)
  }

  render () {
    return null
  }

}

Poller.defaultProps = {
  interval: 5000,
}

Poller.propTypes = {
  action: PropTypes.func.isRequired,
  interval: PropTypes.number,
}

export default Poller