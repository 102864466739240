import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useTheme } from '@mui/material/styles'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

//to customize the chart, look here: https://nivo.rocks/line/
const PowerHistoryChart = ({ data, options /* see data tab */ }) => {
  const theme = useTheme()

  let chargeLimit = options.chargeLimit
  let gridLimit = options.gridLimit
  let markers
  let min

  data.forEach((entry) => {
    entry.data.forEach((point) => {
      if (min === undefined) {
        min = point.y
      }
      if (point.y < min) {
        min = point.y
      }
    })
  })

  if (gridLimit === 0) {
    markers = [
      {
        axis: 'y',
        value: chargeLimit,
        lineStyle: { stroke: theme.palette.graph.mainColor1, strokeWidth: 1 },
        textStyle: { fill: theme.palette.graph.text },
        legend: 'Ladelimit',
        legendOrientation: 'horizontal',
      },
    ]
  } else {
    if (chargeLimit === gridLimit) {
      markers = [
        {
          axis: 'y',
          value: chargeLimit,
          lineStyle: { stroke: theme.palette.graph.mainColor1, strokeWidth: 1 },
          textStyle: { fill: theme.palette.graph.text },
          legend: 'Ladelimit & Netzlimit',
          legendOrientation: 'horizontal',
        },
      ]
    } else {
      if (chargeLimit < gridLimit && chargeLimit > (gridLimit * 0.8)) {
        markers = [
          {
            axis: 'y',
            value: chargeLimit,
            lineStyle: { stroke: theme.palette.graph.mainColor1, strokeWidth: 1 },
            textStyle: { fill: theme.palette.graph.text },
            legend: 'Ladelimit',
            legendOrientation: 'horizontal',
            legendPosition: 'bottom-right',
          },
          {
            axis: 'y',
            value: gridLimit,
            lineStyle: { stroke: theme.palette.graph.mainColor2, strokeWidth: 1 },
            textStyle: { fill: theme.palette.graph.text },
            legend: 'Netzlimit',
            legendOrientation: 'horizontal',
          },
        ]
      } else {
        markers = [
          {
            axis: 'y',
            value: chargeLimit,
            lineStyle: { stroke: theme.palette.graph.mainColor1, strokeWidth: 1 },
            textStyle: { fill: theme.palette.graph.text },
            legend: 'Ladelimit',
            legendOrientation: 'horizontal',
          },
          {
            axis: 'y',
            value: gridLimit,
            lineStyle: { stroke: theme.palette.graph.mainColor2, strokeWidth: 1 },
            textStyle: { fill: theme.palette.graph.text },
            legend: 'Netzlimit',
            legendOrientation: 'horizontal',
          },
        ]
      }
    }
  }

  const yScaleMax = options.gridLimit > 0 ? options.gridLimit : options.chargeLimit

  //BUG with the time date format: https://github.com/plouc/nivo/issues/283
  return (

    // ResponsiveLineCanvas could be used to get better performance for lot of datapoints. However: canvas has some limitations:
    //  - isInteractive=true leads to a problem when moving mouse over chart.
    //  - markers not shown

    <ResponsiveLine
      data={data}
      isInteractive={true}
      curve="stepAfter"
      margin={{ top: 50, right: 10, bottom: 70, left: 60 }}

      xScale={{
        type: 'time',
        format: '%Y-%m-%dT%H:%M:%S.%LZ',
        useUTC: true,
      }}
      xFormat="time:%d.%m.%Y %H:%M:%S"
      yFormat={value =>
        Number(value)
          .toLocaleString('de', { minimumFractionDigits: 3 }) + ' kW'

      }

      yScale={{ type: 'linear', min: 'auto', max: yScaleMax, stacked: false, reverse: false }}

      markers={markers}
      theme={{
        textColor: theme.palette.graph.text,
      }}

      //see https://github.com/d3/d3-time-format
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -65,
        legendPosition: 'middle',

        format: '%H:%M',
        tickValues: 'every 2 hours',
        legendOffset: 36,
        legendOffsetX: 36,
        legendOffsetY: 36,
      }}
      tooltip={(input) => {
        return (
          <div
            style={{
              padding: 6,
              color: theme.palette.black,
              background: theme.palette.white,
              display: 'flex',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
            }}
          >
            <div
              style={{
                marginTop: '5px',
                marginRight: '5px',
                width: '12px',
                height: '12px',
                backgroundColor: input.point.serieColor,
              }}
            />
            <span
              style={{
                marginRight: '5px',
              }}
            >
              {'x:'}
            </span>
            <strong>
              {input.point.data.xFormatted}
            </strong>
            <span
              style={{
                marginRight: '5px',
              }}
            >
              {', y:'}
            </span>
            <strong>
              {input.point.data.yFormatted}
            </strong>
          </div>
        )
      }}

      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'kW',
        legendOffset: -40,
        legendOffsetX: -40,
        legendOffsetY: -40,
        legendPosition: 'middle',
      }}

      // concrete colors
      colors={[theme.palette.graph.mainColor1, theme.palette.graph.mainColor2]}

      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableArea={true}
      areaBaselineValue={min > 0 ? min : 0}
      useMesh={true}
      enableGridX={false}
      enableGridY={false}
      legends={[
        {
          legendOffsetY: 0,
          legendOffsetX: 0,
          anchor: 'bottom-right',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}
export default PowerHistoryChart
