import { Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GeneralFab, LoadingBackdrop, TimeWindowPicker } from '../../components'
import { styled, useTheme } from '@mui/material/styles'
import { backendApiClient } from '../../apiClient'
import { downloadBlob } from '../../utils'
import LinesEllipsis from 'react-lines-ellipsis'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DateTime } from 'luxon'

const CustomTableCell = styled(TableCell)({
  paddingTop: '2px',
  paddingBottom: '2px',
})

const CustomTableRow = styled(TableRow)({
  paddingTop: '2px',
  paddingBottom: '2px',
})

const HeaderTableHead = styled(TableHead)(({ theme }) => ({
  paddingBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.alternateTableHeader}`,
}))

const HeaderTableRow = styled(TableRow)(({ theme }) => ({
  paddingBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.alternateTableHeader}`,
}))

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  paddingBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.alternateTableHeader}`,
}))

const HeaderTableFont = styled(Typography)({
  fontWeight: 800,
  fontSize: '15px',
})

const HeaderTableFontTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: '15px',
  paddingRight: '6px',
})

const ExportTableRow = ({ station, activeStations, changeActive }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <CustomTableRow>
      <Tooltip title={station.name}>
        <TableCell style={{
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingRight: 0,
        }}>
          <LinesEllipsis
            text={
              station.name
            }
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="letters"/>
        </TableCell>
      </Tooltip>
      {smallScreen ?
        <CustomTableCell align={'right'} style={{ width: '50px' }}>
          {`${station.numberOfActiveChargingPoints}/${station.numberOfChargingPoints}`}
        </CustomTableCell>
        :
        <CustomTableCell style={{ width: '275px' }}>
          {`${station.numberOfActiveChargingPoints}/${station.numberOfChargingPoints} Ladepunkten aktiv`}
        </CustomTableCell>
      }
      <CustomTableCell align={'right'} style={{ width: '175px' }}>
        <Typography>
          {station.chargingDuration}
        </Typography>
      </CustomTableCell>
      <CustomTableCell align={'right'}>
        <Typography>
          {
            Math.round(((station.chargingQuantity / 1000) * 100) / 100)
              .toFixed(2)
              .toString()
              .replace('.', ',')
            + ' kWh'
          }
        </Typography>
      </CustomTableCell>
      <TableCell
        align={'right'}
        tyle={{ width: '100px' }}
        sx={{
          minWidth: '85px',
          paddingTop: '2px',
          paddingBottom: '2px',
        }}
      >
        <Checkbox checked={!!activeStations.find((item) => item === station.clientId)}
                  onChange={() => changeActive(station.clientId)} id={`checkBox-${station.clientId}`}/>
      </TableCell>
    </CustomTableRow>
  )
}

const ManualExport = () => {
  const EXPORT_URL = '/api/user/charging/sessions/export'
  const [chargingStations, setChargingStations] = useState([])
  const [activeStations, setActiveStations] = useState([])
  const [timeWindow, setTimeWindow] = useState('month')
  const [selectedDate, setSelectedDate] = useState(DateTime.now)
  const [totalTime, setTotalTime] = useState('')
  const [totalAmount, setTotalAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const EXPORT_TEXT = 'exportieren'

  const timeWindows = [
    { key: 'month', name: 'Monat' },
    { key: 'year', name: 'Jahr' },
  ]

  const handleTimeChange = (event) => {
    setTimeWindow(event.target.value)
  }

  const onDateChange = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeWindow, selectedDate])

  useEffect(() => {
    fetchData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const switchOnAllStations = (event) => {
    if (event.target.checked) {
      let newActive = [...activeStations]
      chargingStations.forEach((station) => {
        if (!activeStations.find(item => item === station.clientId)) {
          newActive.push(station.clientId)
        }
      })
      setActiveStations(newActive)
    } else {
      setActiveStations([])
    }
  }

  const fetchData = (firstLoad) => {
    setLoading(true)
    let usedDate = selectedDate
    let params = {
      year: usedDate.year,
    }
    if (timeWindow === 'month') {
      params = {
        ...params,
        month: usedDate.month,
      }
    }
    backendApiClient.get(EXPORT_URL, params)
      .then((res) => {
        setChargingStations(res.customerExportInformation)
        setTotalTime(res.totalDuration)
        setTotalAmount(res.totalQuantity)
        if (firstLoad) {
          let activeArray = []
          res.customerExportInformation.forEach((client) => {
            activeArray.push(client.clientId)
          })
          setActiveStations(activeArray)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        throw new Error(err)
      })
  }

  const changeActive = (id) => {
    const station = activeStations.find((item) => item === id)
    let array = [...activeStations]
    if (station) {
      array.splice(array.indexOf(id), 1)
      setActiveStations(array)
    } else {
      array.push(id)
      setActiveStations(array)
    }
  }

  const handleExport = () => {
    setLoading(true)
    const URL = EXPORT_URL + '/csv'
    let fileName
    let params = { year: selectedDate.year }
    if (timeWindow === 'month') {
      params = {
        ...params,
        month: selectedDate.month,
      }
    }
    params = {
      ...params,
      client: activeStations.toString(),
    }
    backendApiClient.fileGet(URL, params)
      .then((response) => {
        fileName = response.headers['content-disposition'].replace('attachment; filename=', '')
        return new Blob([response.data], { type: response.data.type })
      })
      .then((blob) => {
        downloadBlob(blob, fileName)
        setLoading(false)
      })
      .catch((error) => {
        throw new Error(error)
      })

  }

  return (
    <Grid container
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          padding={0}
    >
      <LoadingBackdrop open={loading}/>
      <TimeWindowPicker
        handleTimeChange={handleTimeChange}
        onDateChange={onDateChange}
        selectedDate={selectedDate}
        timeWindows={timeWindows}
        timeWindow={timeWindow}
      />
      <TableContainer>
        <Table>
          <HeaderTableHead>
            <HeaderTableRow>
              <HeaderTableCell/>
              <HeaderTableCell/>
              <HeaderTableCell align={'right'}>
                <HeaderTableFontTitle display={'inline'}>
                  {'Ladezeit '}
                </HeaderTableFontTitle>
                <HeaderTableFont color={'primary'} display={'inline'}>
                  {totalTime}
                </HeaderTableFont>
              </HeaderTableCell>
              <HeaderTableCell align={'right'} style={{ width: '255px' }}>
                <HeaderTableFontTitle display={'inline'}>
                  {'Lademenge '}
                </HeaderTableFontTitle>
                <HeaderTableFont color={'primary'} display={'inline'}>
                  {
                    Math.round(((totalAmount / 1000) * 100) / 100)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')
                    + ' kWh'
                  }
                </HeaderTableFont>
              </HeaderTableCell>
              <HeaderTableCell align={'right'}>
                <HeaderTableFont>
                  Alle
                  <Checkbox checked={chargingStations.length === activeStations.length}
                            onChange={switchOnAllStations} id={'checkBoxAll'}/>
                </HeaderTableFont>
              </HeaderTableCell>
            </HeaderTableRow>
          </HeaderTableHead>
          <TableBody>
            {
              chargingStations.map((station, index) =>
                <ExportTableRow station={station} activeStations={activeStations}
                                changeActive={changeActive} key={index}/>,
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <GeneralFab text={EXPORT_TEXT} onClick={handleExport} id={'exportFab'}>
        <SaveAltIcon/>
      </GeneralFab>
    </Grid>
  )
}

export default ManualExport