import { backendApiClient } from '../../apiClient'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from '../../utils'

const systemMessageApi = '/api/client/$clientId/system-messages'
const logsApi = '/api/client/$clientId/trace-messages'

let initialState = {
  systemMessageList: [],
  logMessageList: [],
}

export const fetchLastSystemMessages = createAsyncThunk('message/fetchLastSystemMessages', async (data) => {
  return fetchSystemMessages(data)
})

export const fetchMoreSystemMessages = createAsyncThunk('message/fetchMoreSystemMessages', async (data) => {
  return fetchSystemMessages(data)
})

export const fetchLastLogMessages = createAsyncThunk('message/fetchLastLogMessages', async (data) => {
  return fetchLogMessages(data)
})

export const fetchMoreLogMessages = createAsyncThunk('message/fetchMoreLogMessages', async (data) => {
  return fetchLogMessages(data)
})

const fetchLogMessages = (data) => {
  const clientId = data.clientId
  const queryParams = {}
  !isEmpty(data.from) && (queryParams.from = data.from)
  !isEmpty(data.to) && (queryParams.to = data.to)
  !isEmpty(data.cursor) && (queryParams.cursor = data.cursor)
  return backendApiClient.get(logsApi.replace('$clientId', clientId), queryParams)
}

const fetchSystemMessages = (data) => {
  const clientId = data.clientId
  const queryParams = {}
  !isEmpty(data.cursor) && (queryParams.cursor = data.cursor)
  !isEmpty(data.latest) && (queryParams.latest = data.latest)

  return backendApiClient.get(systemMessageApi.replace('$clientId', clientId), queryParams)
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLastSystemMessages.fulfilled, (state, action) => {
        const result = action.payload.content
        const payload = action.payload

        state.systemMessageList = result
        state.systemMessagePayload = payload
      })
      .addCase(fetchMoreSystemMessages.fulfilled, (state, action) => {
        const result = action.payload.content
        const payload = action.payload

        state.systemMessageList = state.systemMessageList.concat(result)
        state.systemMessagePayload = payload
      })
      .addCase(fetchLastLogMessages.fulfilled, (state, action) => {
        const result = action.payload.content
        const payload = action.payload

        state.logMessageList = result
        state.logMessagePayload = payload
      })
      .addCase(fetchMoreLogMessages.fulfilled, (state, action) => {
        const result = action.payload.content
        const payload = action.payload

        state.logMessageList = state.logMessageList.concat(result)
        state.logMessagePayload = payload
      })
  },
})

export default messageSlice.reducer