import React from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import MessagesList from '../../../../../pages/Messages/MessagesList'

const NotificationPopperWithMessage = props => {
  const navigate = useNavigate()
  const { clientId, messages, userRoles, translate, onOpen } = props

  const BUTTON_DESCRIPTION = 'Alle Meldungen Anzeigen'

  return (
    <Paper elevation={12}>
      <Box paddingRight={1} paddingBottom={2}>
        <Grid
          container
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <MessagesList
              messages={messages}
              translate={translate}
              userRoles={userRoles}
              onOpen={onOpen}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Box
              marginLeft={1}
              marginRight={1}
              marginBottom={1}
            >
              <Button
                size={'small'}
                variant={'contained'}
                onClick={() => navigate(
                  `/client/${clientId}/messages/`,
                )}
              >
                <Typography sx={{
                  color: 'primary.contrastText',
                  transform: 'theme.skewText',
                }}>
                  {BUTTON_DESCRIPTION}
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default NotificationPopperWithMessage