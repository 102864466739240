import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
}))

const TextFieldBox = ({ text, noNewLineBottom, noNewLineTop }) => {
  return (
    <RootDiv sx={[
      noNewLineBottom && noNewLineBottom === true && {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingTop: 0,
      },
      noNewLineTop && noNewLineTop === true && {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 2,
        paddingBottom: 0,
      },
    ]}>
      <Typography>{text}</Typography>
    </RootDiv>
  )
}

export default TextFieldBox
