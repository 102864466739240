import React from 'react'
import { Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import WarningIcon from '@mui/icons-material/Warning'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const ButtonSpan = styled('span')(({ theme }) => ({
  transform: theme.skewText,
}))

const RootDiv = styled('div')({
  margin: 4,
})

const NotFound = () => {
  const HEADLINE = 'Entschuldigung'
  const SUB_HEADLINE = 'Diese Seite existiert nicht'
  const BUTTON_TEXT = 'Zurück zur Startseite'

  return (
    <RootDiv>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <WarningIcon sx={{
            color: 'primary.errorPageColor',
            fontSize: '220px',
          }}/>
        </Grid>
        <Grid item>
          <Box
            sx={{ color: 'primary.errorPageColor' }}
            fontWeight={600}
            fontSize={48}>
            {HEADLINE}
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{ color: 'primary.errorPageColor' }}
            fontWeight={600}
            fontSize={15}
            pt={5}
            pb={2}>
            {SUB_HEADLINE}
          </Box>
        </Grid>
        <Grid item>
          <Button
            color={'primary'}
            variant={'contained'}
            component={Link}
            sx={{ margin: 1 }}
            to={`/`}
          >
            <ButtonSpan>
              {BUTTON_TEXT}
            </ButtonSpan>
          </Button>
        </Grid>
      </Grid>
    </RootDiv>
  )
}

export default NotFound
