import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import PowerIcon from '@mui/icons-material/Power'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'

const ConfigurationTextField = (props) => {

  const [value, setValue] = useState(0)

  const getKw = () => {
    return Math.round(value * 0.69)
  }

  useEffect(() => {
    if (!(props.value === null || props.value === undefined)) {
      if (!isNaN(parseInt(props.value))) {
        const val = parseInt(props.value)
        setValue(val)
      } else {
        setValue(props.value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onChange = (event) => {
    if (props.error) {
      props.error.__errors = []
    }
    let val = event.target.value
    props.onChange(isNaN(parseInt(val)) ? val : parseInt(val))
  }

  const chooseIcon = (title) => {
    switch (title) {
      case('Limit Netzanschluss'):
        return <PowerIcon/>
      case('Limit Ladesteuerung'):
        return <BatteryChargingFullIcon/>
      default:
        return ''
    }
  }

  return (
    <TextField
      id={props.id}
      onBlur={props.onBlur}
      variant={'standard'}
      type={'number'}
      value={value}
      label={props?.props.title}
      sx={{ width: '100%' }}
      disabled={props.readonly}
      helperText={props?.error?.__errors?.length > 0 ? props.error.__errors[0] : props?.props.description}
      error={!!props?.error && props?.error.__errors.length > 0}
      onChange={onChange}
      margin={'normal'}
      InputProps={
        {
          startAdornment: (
            <InputAdornment position={'start'}>
              {chooseIcon(props?.props.title)}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position={'end'}>
              A / {getKw()} kW
            </InputAdornment>
          ),
        }
      }
    />
  )
}

export default ConfigurationTextField

