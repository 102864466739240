import PropTypes from 'prop-types'

const TypDeclaration = ({ type }) => {

  switch (type) {
    case 'LEGACY':
    case 'SCHUKO' :
      return 'Schuko'
    case 'TYP2_SINGLE_PHASE' :
      return 'Typ2'
    case 'TYP2_THREE_PHASE' :
    default:
      return 'Typ2'
  }
}

TypDeclaration.propTypes = {
  className: PropTypes.string,
}

export default TypDeclaration

