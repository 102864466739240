import React from 'react'
import Grid from '@mui/material/Grid'
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'

const SearchBarHeader = (props) => {

  const { label, handleSelectChange, clientList, keyUpHandler, searchValue, handleClear, disableSelect = false } = props

  const handleSelectBarChange = (event, value) => {
    handleSelectChange(event, value)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        xs={disableSelect ? 12 : 9}
      >
        <TextField
          value={searchValue}
          fullWidth
          variant={'standard'}
          label={label ? label : 'Suche'}
          onChange={keyUpHandler}
          style={disableSelect ? {} : { marginTop: -16 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue.length > 0 &&
                  <IconButton onClick={handleClear} size="large">
                    <ClearIcon/>
                  </IconButton>
                }
                <Search/>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        {!disableSelect &&
          <Autocomplete
            id={'free-solo-demo'}
            onChange={handleSelectBarChange}
            options={clientList ? clientList : []}
            getOptionLabel={(options) => options.name || ''}
            noOptionsText={'Kein übereinstimmende Ladestation vorhanden'}
            renderInput={(params) =>
              <TextField
                variant={'outlined'}
                {...params}
                label="Ladestation"
              />}
          />
        }
      </Grid>
    </Grid>
  )
}

export default SearchBarHeader