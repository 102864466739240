import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const PhaseBoxValues = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
    lineHeight: '42px',
    fontWeight: '500',
  },
}))

function getPowerString (powerPhase, fractionDigits) {
  return powerPhase.toFixed(fractionDigits)
    .toString()
    .replace('.', ',')
}

const getPhasePower = (powerValue, uom, fractionDigits) => {
  if (uom === 'W') {
    return getPowerString(powerValue / 1000, fractionDigits)
  } else if (uom === 'kW') {
    return getPowerString(powerValue, fractionDigits)
  } else {
    return powerValue
  }
}

const PhaseBox = ({ title, metric, fractionDigits = 1 }) => {
  const l1 = getPhasePower(metric?.l1, metric?.uom, fractionDigits) || '0,0'
  const l2 = getPhasePower(metric?.l2, metric?.uom, fractionDigits) || '0,0'
  const l3 = getPhasePower(metric?.l3, metric?.uom, fractionDigits) || '0,0'

  return (
    <Paper sx={(theme) => ({
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(25),
      },
    })}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12}>
          <Typography align={'left'}
                      sx={{
                        fontSize: '20px',
                        fontWeight: '500',
                      }}
          >
            {title} (kW)
          </Typography>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}>
            <PhaseBoxValues align={'left'}>
              L1<br/>{l1}
            </PhaseBoxValues>
          </Grid>
          <Grid item xs={4}>
            <PhaseBoxValues align={'left'}>
              L2<br/>{l2}
            </PhaseBoxValues>
          </Grid>
          <Grid item xs={4}>
            <PhaseBoxValues align={'left'}>
              L3<br/>{l3}
            </PhaseBoxValues>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

PhaseBox.defaultProps = {
  chargingPower: 0,
}

PhaseBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  metric: PropTypes.object,
}

export default PhaseBox
