import React from 'react'
import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import { DateTime } from 'luxon'
import { useTheme } from '@mui/material/styles'

const HEIGHT_RATIO = 1
const DIM_CATEGORY_INDEX = 0
const DIM_TIME_FROM = 1
const DIM_TIME_TO = 2
let _cartesianXBounds = []
let _cartesianYBounds = []

const reasonToText = (reason) => {
  switch (reason) {
    case('POWER_LIMIT_REACHED'):
      return 'Definierter Limit am Hausanschluss erreicht'
    case('DEACTIVATED'):
      return 'Deaktivierung'
    case('ENABLED_BY_DEFAULT'):
      return 'Defaultmäßige Aktivierung'
    case('DISABLED_BY_DEFAULT'):
      return 'Defaultmäßige Deaktivierung'
    case('LMS_RESTART'):
      return 'Neustart des Lademanagement Systems'
    default:
      return ''
  }
}

const ruleToText = (rule) => {
  switch (rule) {
    case('powerSurplus'):
      return 'Überschuss am Hausanschluss'
    case('powerLimitSwitchOn'):
      return 'Auslastung am Hausanschluss'
    case('powerLimitSwitchOff'):
      return 'Auslastung am Hausanschluss (Ausschalten)'
    case('powerSpotPrice'):
      return 'Spotmarktpreis'
    default:
      return ''
  }
}

const threshHoldToText = (threshold) => {
  switch (threshold) {
    case('switchOnThreshold'):
      return 'Einschaltschwelle'
    case('switchOffThreshold'):
      return 'Ausschaltschwelle'
    case('lessThanThreshold'):
      return '<'
    case('greaterThanThreshold'):
      return '>'
    default:
      return ''
  }
}

const unitToText = (threshold) => {
  switch (threshold) {
    case('percentage'):
      return '%'
    case('kW'):
      return 'kW'
    case('kWh'):
      return 'kWh'
    case('euroPerMWh'):
      return 'EUR / MWh'
    default:
      return ''
  }
}

const tooltipOffReason = (paramData) => {
  // eslint-disable-next-line no-useless-concat
  return reasonToText(paramData) !== '' ? '<b>Aus</b>' + ': ' + reasonToText(paramData) : ''
}

const DigitalOutputEventChartTimeZoom = ({ dimData, timeSpan }) => {
  const theme = useTheme()

  function makeOption () {
    return {
      animation: false,
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (param) {
          // eslint-disable-next-line no-useless-concat
          return '<b>' + param.dimensionNames[1] + '</b>' + ': ' + formatDate(param.data[1]) + ' <br>' +
            // eslint-disable-next-line no-useless-concat
            '<b>' + param.dimensionNames[2] + '</b>' + ': ' + formatDate(param.data[2]) + ' <br>' +
            param.data[5].map((r) => (
                // eslint-disable-next-line no-useless-concat
                '<b>' + ruleToText(r.rule)) + '</b>' + ' <br>' + r.settings.map((s) => (
                threshHoldToText(s.threshold) + '  ' + s.thresholdValue + ' ' + unitToText(s.unit) + ' <br>'
              )) + 'Wert: ' + r.ruleValue + ' <br>',
            )
            + tooltipOffReason(param.data[4])
        },
      },
      toolbox: {
        iconStyle: {
          borderColor: theme.palette.graph.text,
        },
        feature: {
          restore: {},
        },
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          height: 25,
          bottom: 5,
          start: 0,
          end: 100,
        },
      ],
      grid: { top: 50, right: 15, bottom: 55, left: 50 },

      textStyle: {
        color: theme.palette.graph.text,
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        useUTC: true,
        min: timeSpan.from,
        max: timeSpan.to,
        position: 'bottom',
        axisLabel: {
          formatter: (function (value) {
            return DateTime.fromMillis(value)
              .toFormat('HH:mm')
          }),
        },

      },
      yAxis: {
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        min: getMinYAxis(),
        max: getMaxYAxis(),
      },
      series: [
        {
          id: 'eventData',
          type: 'custom',
          renderItem: renderGanttItem,
          dimensions: dimData.dimensions,
          encode: {
            x: [DIM_TIME_FROM, DIM_TIME_TO],
            y: DIM_CATEGORY_INDEX,
            tooltip: {
              formatter: value => value,
            },
          },
          data: dimData.data,
        },
      ],
    }

  }

  function formatDate (date) {
    return DateTime.fromISO(date)
      .toFormat('dd.LL.yyyy HH:mm')
  }

  function getMinYAxis () {
    return (dimData.data === undefined || dimData.data.length === 0) ? 0 : dimData.data[0][0]
  }

  function getMaxYAxis () {
    return (dimData.data === undefined || dimData.data.length === 0) ? 0 : dimData.data[0][0] + 1
  }

  function clipRectByRect (params, rect) {
    return echarts.graphic.clipRectByRect(rect, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    })
  }

  function renderGanttItem (params, api) {
    let categoryIndex = api.value(DIM_CATEGORY_INDEX)
    let timeStart = api.coord([api.value(DIM_TIME_FROM), categoryIndex])
    let timeStop = api.coord([api.value(DIM_TIME_TO), categoryIndex])
    let coordSys = params.coordSys
    _cartesianXBounds[0] = coordSys.x
    _cartesianXBounds[1] = coordSys.x + coordSys.width
    _cartesianYBounds[0] = coordSys.y
    _cartesianYBounds[1] = coordSys.y + coordSys.height
    let barLength = timeStop[0] - timeStart[0]
    let barHeight = api.size([0, 1])[1] * HEIGHT_RATIO
    let x = timeStart[0]
    let y = timeStart[1] - barHeight
    let rectNormal = clipRectByRect(params, {
      x: x,
      y: y,
      width: barLength,
      height: barHeight,
    })
    return {
      type: 'group',
      children: [
        {
          type: 'rect',
          ignore: !rectNormal,
          shape: rectNormal,
          style: api.style({ fill: theme.palette.graph.mainColor1 }),
        },
      ],
    }
  }

  return (
    <ReactEcharts
      option={makeOption()}
      notMerge={true}
      lazyUpdate={true}
      theme={'theme_name'}
      style={{ height: '150px' }}
    />
  )

}
export default DigitalOutputEventChartTimeZoom