import React from 'react'
import { Page } from '../../components'
import LogMessagesContainer from './LogMessagesContainer'

const helpText = `
### LMS Log Meldungen.
`

const LogMessagesPage = ({}) => {

  return (
    <Page viewName={'Log Meldungen'} helpText={helpText} helpTitle={'Log Meldungen'} pageTitle={'Logs'}>
      <LogMessagesContainer/>
    </Page>
  )
}

export default LogMessagesPage
