import { IconButton, ListItem, Typography } from '@mui/material'
import React from 'react'
import Divider from '@mui/material/Divider'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import { canUseApp, canUseCard } from '../../../utils'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import Grid from '@mui/material/Grid'

const UnlockingList = ({ chargingPoints, onPointAuthModeChange, handleTagClick, handleUserClick, version, canEdit }) => {
  return (
    <div>
      {chargingPoints && chargingPoints.length > 0 && chargingPoints.map((point, index) => {
        let isDisconnected = point.controllerConnectionStatus === 'DISCONNECTED'

        return (
          <React.Fragment key={index}>
            <ListItem key={index}>
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item sm={4} xs={12}>
                  <Typography
                    sx={[
                      isDisconnected && { color: 'text.disabled' },
                      version === 2 && { marginBottom: '-8px' },
                    ]}
                  >
                    {point.name}
                  </Typography>
                </Grid>
                <Grid item sm={8} xs={12} align={'right'}>
                  {point.interfaceType === 'ocpp' ?
                    <IconButton disabled={true} size={'small'} id={`lockButton${point.id}`}>
                      <LockIcon fontSize={'small'}/>
                    </IconButton>
                    :
                    point.currentPolicy?.authMode !== 'DEFAULT_UNAUTHORIZED' ?
                      <IconButton disabled={!canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'}>
                        <LockOpenIcon fontSize={'small'}/>
                      </IconButton>
                      :
                      <IconButton disabled={!canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'}>
                        <LockIcon fontSize={'small'}/>
                      </IconButton>

                  }
                  {version >= 1 &&
                    <React.Fragment>
                      <IconButton disabled={!canUseCard(point, version)} onClick={() => handleTagClick(point)}>
                        <CreditCardIcon fontSize={'small'}/>
                      </IconButton>
                      <IconButton disabled={!canUseApp(point, version)} onClick={() => handleUserClick(point)}>
                        <PhoneIphoneIcon fontSize={'small'}/>
                      </IconButton>
                    </React.Fragment>
                  }
                </Grid>
              </Grid>
            </ListItem>
            <Divider/>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default UnlockingList