const muiDropzoneArea = {
  styleOverrides: {
    textContainer: {
      paddingTop: '20px',
    },
    icon: {
      height: '150px',
      width: '150px',
      marginTop: '-20px',
    },
  },
}

export default muiDropzoneArea