import Page from '../../../components/Page/Page'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import chargingPointEditHelpText from '../../../help-texts/FL_Konfiguration_Ladepunkt_bearbeiten.md'
import { useParams, useRouteLoaderData } from 'react-router'
import { backendApiClient } from '../../../apiClient'
import ChargingPointExtenderWidget from './extender/ChargingPointExtenderWidget'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from './lmsVersionTwo/ChargingPointVersionTwoConstants'
import { enqueueSnackbar } from 'notistack'

const EditExtenderPage = () => {
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const [helpText, setHelpText] = useState()
  const navigate = useNavigate()
  const { clientId, pointId, pointIdExtender } = useParams()
  const [extender, setExtender] = useState({})
  const [loading, setLoading] = useState(false)

  const CONFIG = 'Konfiguration'
  const CP_EDIT_HELP_TITLE = 'Ladepunkt bearbeiten'

  const URL_CONFIG_CHARGINGPOINT = `/api/client/${clientId}/configuration/chargingpoints/${pointId}`
  const URL_EDIT_EXTENDER = `/api/client/${clientId}/configuration/chargingpoints/${pointId}/extender/${pointIdExtender}`

  const fetchHelpText = () => {
    fetch(chargingPointEditHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    fetch(chargingPointEditHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    if (extender && Object.values(extender).length > 0) {
      return
    }
    setLoading(true)
    backendApiClient.get(URL_CONFIG_CHARGINGPOINT)
      .then((r) => {
        setExtender(findExtender(r))
      })
      .catch((error) => {
        throw new Error(error)
      })
      .finally(() => setLoading(false))
  }

  const findExtender = (result) => {
    return result.extenders.find(extender => extender.id.toString() === pointIdExtender.toString())
  }

  const save = (data) => {
    delete data.twinName
    backendApiClient.put(URL_EDIT_EXTENDER, data)
      .then(() => {
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchHelpText()
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    lmsVersion > 1 ?
      <Page viewName={'Ladepunkt bearbeiten'} pageTitle={CONFIG} onBack={() => navigate(-1)}
            helpText={helpText} helpTitle={CP_EDIT_HELP_TITLE}>
        <ChargingPointExtenderWidget save={save} chargingPoint={extender} loading={loading}/>
      </Page>
      :
      <Navigate to="/not-found"/>
  )
}

export default EditExtenderPage