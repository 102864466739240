import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import PvOverchargingWidget from '../../../components/crud/widgets/PvOverchargingWidget'
import EditForm from '../../../components/crud/EditForm'
import schema from './schema.json'
import { useSnackbar } from 'notistack'
import { LoadingBackdrop } from '../../../components'
import { useParams } from 'react-router'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import { useSelector } from 'react-redux'

const PvOverchargingEditForm = () => {
  const URL_UPDATE = '/api/client/{client}/configuration/pv-settings'
  const [pvSettings, setPvSettings] = useState({})
  const [loading, setLoading] = useState(false)
  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const userRoles = useSelector(state => getRoles(state))
  const canEdit = isServicePerson(userRoles) || isLMSOwner(userRoles)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    setLoading(true)
    backendApiClient.get(URL_UPDATE.replace(/{client}/g, clientId))
      .then((res) => {
        setPvSettings(res)
        setLoading(false)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const uiSchema = {
    'ui:field': 'PvOverchargingForm',
  }

  const fields = {
    PvOverchargingForm: (props) => <PvOverchargingWidget {...props} canEdit={canEdit}/>,
  }

  const validate = (formData, errors) => {
    const regexTimeStamp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
    const regexNegativeNumber = new RegExp(/^\d+$/)

    try {

      if (formData.activationTime?.toString().length === 0 || (formData.activationTime && regexTimeStamp.test(formData?.activationTime) === false)) {
        errors.activationTime.addError('Der Eintrag muss dem Format HH:MM folgen')
      }

      if (formData.deactivationTime?.toString().length === 0 || (formData.deactivationTime && regexTimeStamp.test(formData?.deactivationTime) === false)) {
        errors.deactivationTime.addError('Der Eintrag muss dem Format HH:MM folgen')
      }

      if (formData.powerInfeedToStartCharging?.toString().length === 0 ||
        (formData.powerInfeedToStartCharging && regexNegativeNumber.test(formData?.powerInfeedToStartCharging?.toString()) === false)) {
        errors.powerInfeedToStartCharging.addError('Die Einschaltschwelle muss eine ganze positive Zahl sein')
      }

      if (formData.powerInfeedToStopCharging?.toString().length === 0 ||
        (formData?.powerInfeedToStopCharging && regexNegativeNumber.test(formData?.powerInfeedToStopCharging?.toString()) === false)) {
        errors.powerInfeedToStopCharging.addError('Die Ausschaltschwelle muss eine ganze positive Zahl sein')
      }

      if (errors.deactivationTime !== undefined && errors.deactivationTime?.__errors.length === 0 && formData?.deactivationTime <
        formData?.activationTime) {
        errors.deactivationTime.addError('Die Deaktivierungszeit muss nach der Aktivierungszeit festgelegt sein')
        errors.activationTime.addError('Die Aktivierungszeit muss vor der Deaktivierungszeit festgelegt sein')
      }

      if (errors.powerInfeedToStartCharging !== undefined && errors.powerInfeedToStartCharging.__errors.length === 0 && formData?.powerInfeedToStartCharging <
        formData?.powerInfeedToStopCharging) {
        errors.powerInfeedToStartCharging.addError('Die Einschaltschwelle darf nicht geringer sein als die Ausschaltschwelle')
        errors.powerInfeedToStopCharging.addError('Die Ausschaltschwelle darf nicht höher sein als die Einschaltschwelle')
      }

      return errors
    } catch (e) {
      throw new Error(e)
    }
  }

  const save = (data) => {
    const url = URL_UPDATE.replace(/{client}/g, clientId)

    backendApiClient.put(url, data)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe!', {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={pvSettings}
        liveValidate
        customValidate={validate}
        fields={fields}
        onSubmit={save}
        disabledSubmit={canEdit}
      />
    </div>
  )
}

export default PvOverchargingEditForm