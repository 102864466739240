import { Checkbox, Grid, Typography } from '@mui/material'
import React from 'react'
import DigitalOutputRuleValue from './DigitalOutputRuleValue'
import { styled } from '@mui/material/styles'

const TypographyAlignment = styled(Typography)({
  paddingTop: '4px',
})

const DigitalOutputRule = ({ formik, rule, index }) => {
  const getRuleTitleInText = (type) => {
    switch (type) {
      case 'powerSurplus':
        return 'Überschuss am Hausanschluss'
      case 'powerLimitSwitchOn':
        return 'Auslastung am Hausanschluss'
      case 'powerSpotPrice':
        return 'Spotmarktpreis'
      default:
        return ''
    }
  }

  return (
    <Grid key={index} item container xs={12} sx={{ paddingTop: 2 }} justifyContent={'space-between'}>
      <Grid item container xs={11}>
        <Grid item>
          <TypographyAlignment sx={[!rule.activated && { color: 'text.disabled' }]}>
            {getRuleTitleInText(rule.type)}
          </TypographyAlignment>
        </Grid>
        {rule.type === 'powerSurplus' ?
          <Grid item container xs={12}>
            {rule.values && rule.values?.length > 0 && rule.values.map((thresholdValue, valueIndex) =>
              <DigitalOutputRuleValue
                key={valueIndex}
                formik={formik}
                rule={rule}
                ruleIndex={index}
                value={thresholdValue}
                valueIndex={valueIndex}
              />,
            )}
          </Grid>
          :
          <Grid xs={8}>
            {rule.values && rule.values?.length > 0 && rule.values.map((thresholdValue, valueIndex) =>
              <DigitalOutputRuleValue
                key={valueIndex}
                formik={formik}
                rule={rule}
                ruleIndex={index}
                value={thresholdValue}
                valueIndex={valueIndex}
              />,
            )}
          </Grid>
        }
      </Grid>
      <Grid item container justifyContent={'flex-end'} xs={1}>
        <Checkbox
          sx={{ paddingTop: '3px' }}
          id={`rules[${index}].activated`}
          name={`rules[${index}].activated`}
          checked={rule.activated}
          onChange={formik.handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default DigitalOutputRule