import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import { useNavigate } from 'react-router'
import editPage from '../../help-texts/FL_Zugriffsberechtigung_bearbeiten.md'
import { useLocation } from 'react-router-dom'
import { backendApiClient } from '../../apiClient'
import AuthorizationWidget from '../../components/crud/widgets/AuthorizationWidget'
import { useSnackbar } from 'notistack'

const EditUserAuthorizationPage = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const user = location.state?.user || {}
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [editHelpText, setEditHelpText] = useState('')

  const AUTHORIZATION = 'Zugriffsberechtigung'
  const EDIT_AUTHORIZATION = 'Berechtigung bearbeiten'
  const API = '/api/user/profiles'

  const fetchHelpText = () => {
    fetch(editPage)
      .then(res => res.text())
      .then(text => setEditHelpText(text))
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data) => {
    setLoading(true)
    backendApiClient.put(API, data, { userProfileId: user.userId })
      .then(() => {
        enqueueSnackbar('Nutzer erfolgreich gespeichert!', {
          variant: 'success',
        })
        navigate('..')
      })
      .catch(() => {
        enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page viewName={EDIT_AUTHORIZATION} helpText={editHelpText} helpTitle={EDIT_AUTHORIZATION}
          onBack={() => navigate('..')} pageTitle={AUTHORIZATION}>
      <AuthorizationWidget save={save} user={user} disabled={true} loading={loading}/>
    </Page>
  )
}

export default EditUserAuthorizationPage