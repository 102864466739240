import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { backendApiClient } from '../../apiClient'
import { sortPoints } from '../../utils'

const API_METRICS = '/api/client/$clientId/metrics'
const API_METRICS_HISTORY = '/api/client/$clientId/metrics/history'
const API_AGGREGATION = '/api/client/$clientId/metrics/aggregation'

const MAIN_POWER_METER = 'mainPowerMeter'
const CHARGING_POWER_METER = 'chargingPowerMeter'

const maxNumDataPoints = 5000

let initialState = {
  metrics: {},
  metricsHistory: {
    chargingPowerMeter: [],
    mainPowerMeter: [],
  },
  metricsAggregation: [],
}

export const fetchMetrics = createAsyncThunk('metrics/fetchMetrics', async (clientId) => {
  return backendApiClient.get(API_METRICS.replace('$clientId', clientId))
})

export const fetchMetricsHistoryForMainPowerMeter = createAsyncThunk('metrics/fetchMetricsHistoryForMainPowerMeter', async (clientId) => {
  return fetchMetricsHistoryForSensor(clientId, MAIN_POWER_METER)
})

export const fetchMetricsHistoryForChargingPowerMeter = createAsyncThunk('metrics/fetchMetricsHistoryForChargingPowerMeter', async (clientId) => {
  return fetchMetricsHistoryForSensor(clientId, CHARGING_POWER_METER)
})

export const fetchAggregation = createAsyncThunk('aggregation/fetchAggregation', async (data, thunkAPI) => {
  return await backendApiClient.get(API_AGGREGATION.replace('$clientId', data.clientId), data.payload)
})

const fetchMetricsHistoryForSensor = (clientId, sensor) => {
  const queryParams = {
    sensor: sensor,
    fields: 'timestamp,total,l1,l2,l3',
    pageSize: 5000,
    days: 1,
  }
  return backendApiClient.get(API_METRICS_HISTORY.replace('$clientId', clientId), queryParams)
}

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.metrics = action.payload
        state.metrics.chargingPoints = sortPoints(action.payload.chargingPoints)
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.metrics = {}
      })
      .addCase(fetchMetricsHistoryForMainPowerMeter.fulfilled, (state, action) => {
        const result = action.payload
        state.metricsHistory.mainPowerMeter = limitSize(result.content, maxNumDataPoints) || []
      })
      .addCase(fetchMetricsHistoryForMainPowerMeter.rejected, (state, action) => {
        state.metricsHistory.mainPowerMeter = []
      })
      .addCase(fetchMetricsHistoryForChargingPowerMeter.fulfilled, (state, action) => {
        const result = action.payload
        state.metricsHistory.chargingPowerMeter = limitSize(result.content, maxNumDataPoints) || []
      })
      .addCase(fetchMetricsHistoryForChargingPowerMeter.rejected, (state, action) => {
        state.metricsHistory.chargingPowerMeter = []
      })
      .addCase(fetchAggregation.fulfilled, (state, action) => {
        state.metricsAggregation = action.payload
      })
      .addCase(fetchAggregation.rejected, (state, action) => {
        state.metricsAggregation = []
      })
  },
})

function limitSize (items, maxSize) {
  function selectSubsetFilter (item, index) {
    return index % divider === 0
  }

  function ignoreIllegalValuesFilter (item) {
    let isOk = null !== item.timestamp && item.timestamp.length === 24
    if (!isOk) {
      console.warn('item has illegal timestamp. ignored', item)
    }
    return isOk
  }

  if (!items) {
    return null
  }

  items = items.filter(ignoreIllegalValuesFilter)

  if (items.length <= maxSize) {
    return items
  }

  const divider = Math.floor(items.length / maxSize) + 1

  items = items.filter(selectSubsetFilter)
  return items
}

export default metricsSlice.reducer