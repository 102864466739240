import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useFormik } from 'formik'
import { tagValidationSchema } from './TagValidation'
import CustomFormikTextField from '../../../components/crud/widgets/components/CustomFormikTextField'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ChargingPointMultiSelector from '../../../components/MultiSelector/ChargingPointMultiSelector'
import { isEmpty } from '../../../utils'

const TagWidget = ({ chargingPoints, save, tag, preSelectedId, loading }) => {

  const formik = useFormik({
    initialValues: {
      name: '',
      value: '',
      accountId: '',
      description: '',
      validForChargingPoints: [],
    },
    validationSchema: tagValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values, actions) => {
      save(values, actions)
    },
  })

  useEffect(() => {
    if (preSelectedId && Object.values(preSelectedId).length > 0) {
      formik.setFieldValue('validForChargingPoints', [parseInt(preSelectedId)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEmpty(chargingPoints) || isEmpty(tag)) {
      return
    }
    const cpIds = chargingPoints.map(cp => cp.id)
    tag.validForChargingPoints = tag.validForChargingPoints ? tag.validForChargingPoints.filter(cpId => cpIds.includes(cpId)) : []
    formik.setValues(tag)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, chargingPoints])

  const handleSetSelectedChargingPoints = (value) => {
    formik.setFieldValue('validForChargingPoints', value)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        alignItems={'center'}
        spacing={3}
      >
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.name}
            onChange={formik.handleChange}
            id={'name'}
            name={'name'}
            label={'Kartenname'}
            error={Boolean(formik.errors?.name)}
            helperText={formik.errors?.name || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.value}
            onChange={formik.handleChange}
            id={'value'}
            name={'value'}
            label={'Karten-ID (RFID-UID)'}
            error={Boolean(formik.errors?.value)}
            helperText={formik.errors?.value || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.accountId}
            onChange={formik.handleChange}
            id={'accountId'}
            name={'accountId'}
            label={'Kostenstelle (Account-ID)'}
            error={Boolean(formik.errors?.accountId)}
            helperText={formik.errors?.accountId || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={1}
        >
          <Box paddingBottom={0.5}>
            <Typography>Freigegebene Ladepunkte</Typography>
          </Box>
          <ChargingPointMultiSelector chargingPoints={chargingPoints}
                                      selectedChargingPoints={formik.values.validForChargingPoints || []}
                                      setSelectedChargingPoints={handleSetSelectedChargingPoints}/>
        </Grid>
        <Grid
          item
          xs={12}>
          <Box paddingBottom={0.5}>
            <Typography>Kommentar</Typography>
          </Box>
          <CustomFormikTextField
            value={formik.values.description || ''}
            onChange={formik.handleChange}
            multiline
            rows={5}
            variant={'outlined'}
            id={'description'}
            name={'description'}
            error={Boolean(formik.errors?.description)}
            helperText={formik.errors?.description || ''}
          />
        </Grid>
      </Grid>

      <SubmitFab show={true}/>
    </form>
  )
}

export default TagWidget