import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, Grid, Typography } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DateTime, Duration } from 'luxon'
import CustomTableSummary from '../CustomTableSummary/CustomTableSummary'
import { styled } from '@mui/material/styles'

const CustomAccordion = styled(Accordion)({
  marginTop: '1px',
  boxShadow: 'none',
  width: '100%',
  '&:before': {
    display: 'none',
  },
})

const CustomAccordionSummary = styled(AccordionSummary)({
  borderBottom: `1px solid #B9B9B9`,
  flexDirection: 'row-reverse',
  '&.Mui-expanded & > .expandIconWrapper': {
    marginLeft: -2,
  },
})

const GridTypographySummary = styled(Typography)(({ theme }) => ({
  color: theme.alternateContrastText,
  fontFamily: 'Noto Sans',
}))

const GridTypography = styled(Typography)({
  fontFamily: 'Noto Sans',
})

const ChargingProcessPanel = ({ processesByPoints, point }) => {
  let [process, setProcess] = useState()

  // group by evse.id in case it's grouped by just evse (different name)
  useEffect(() => {
    const processes = processesByPoints.filter(process => process.evse.id === point.id)

    if (processes.length > 0) {
      const firstEntry = processes[0]
      let temp = { ...firstEntry }
      processes.forEach((entry) => {
        //serial number should only be a problem for dev data
        if (entry.evse.id === firstEntry.evse.id && (entry.evse.name !== firstEntry.evse.name || entry.evse.serialNumber !== firstEntry.evse.serialNumber)) {
          temp.chargingSessions = temp.chargingSessions.concat(entry.chargingSessions)
          temp.quantity += entry.quantity

          // transform hh:mm:ss to luxon duration to add then and transform back to hh:mm:ss
          const tempDurationArray = temp.duration.split(':')
          const tempDuration = {
            hours: tempDurationArray[0],
            minutes: tempDurationArray[1],
            seconds: tempDurationArray[2],
          }
          const entryDurationArray = entry.duration.split(':')
          const entryDuration = {
            hours: entryDurationArray[0],
            minutes: entryDurationArray[1],
            seconds: entryDurationArray[2],
          }
          temp.duration = Duration.fromObject(entryDuration)
            .plus(Duration.fromObject(tempDuration))
            .toFormat('hh:mm:ss')
        }
      })
      const sortedData = temp.chargingSessions.sort((a, b) => {
        if (DateTime.fromISO(a.startDateTime)
          .toMillis() < DateTime.fromISO(b.startDateTime)
          .toMillis()) {
          return 1
        } else if (DateTime.fromISO(a.startDateTime)
          .toMillis() > DateTime.fromISO(b.startDateTime)
          .toMillis()) {
          return -1
        }
        return 0
      })
      const data = {
        ...temp,
        chargingSessions: sortedData,
      }
      setProcess(data)
    } else {
      setProcess(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processesByPoints])

  return (
    <React.Fragment>
      {process !== undefined ? (
          <CustomAccordion key={point.id}>
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Grid container
                    direction={'row'}
                    justifycontent={'flex-start'}>
                <Grid item xs={4}>
                  <Typography variant={'h6'}>
                    {point.name}
                  </Typography>
                </Grid>
                <Grid item
                      container
                      xs={8}
                      direction={'row'}
                      justifycontent={'flex-end'}>
                  <Grid item
                        xs={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          paddingRight: '4px',
                          color: 'primary.main',
                        }}>
                    <GridTypographySummary color={'primary'}>
                      {process.duration}
                    </GridTypographySummary>
                  </Grid>
                  <Grid item
                        xs={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          color: 'primary.main',
                        }}>
                    <GridTypographySummary color={'primary'}>
                      {(Math.round((process.quantity / 1000) * 100) / 100).toFixed(2)
                        .toString()
                        .replace('.', ',') + ' kWh'}
                    </GridTypographySummary>
                  </Grid>
                </Grid>
              </Grid>
            </CustomAccordionSummary>
            <AccordionDetails>
              <Grid container
                    display={'flex'}
                    justifyContent={'flex-end'}
                    sx={(theme) => ({
                      [theme.breakpoints.up('sm')]: {
                        paddingLeft: '36px',
                      },
                    })}>
                {process.chargingSessions.map(session => (
                  <Grid item
                        container
                        xs={12}
                        key={session.endDateTime}>
                    <Grid item
                          md={4}
                          xs={false}/>
                    <Grid item
                          container
                          md={8}
                          xs={12}
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'flex-end'}
                          alignItems={'center'}
                          sx={(theme) => ({
                            minHeight: 40,
                            [theme.breakpoints.down('sm')]: {
                              borderBottom: `1px solid #B9B9B9`,
                            },
                          })}>
                      <Grid item
                            container
                            md={6}
                            sm={8}
                            xs={12}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'flex-end'}>
                        <Grid item
                              sm={4}
                              xs={4}
                              display={'flex'}
                              flexDirection={'row'}
                              justifyContent={'flex-end'}>
                          <GridTypography>
                            {DateTime.fromISO(session.startDateTime)
                              .toFormat('dd.MM.yy')}
                          </GridTypography>
                        </Grid>
                        <Grid item
                              sm={4}
                              xs={'auto'}
                              display={'flex'}
                              flexDirection={'row'}
                              justifyContent={'flex-end'}
                              sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                  paddingLeft: 1,
                                },
                              })}
                        >
                          <GridTypography>
                            {DateTime.fromISO(session.startDateTime)
                              .toFormat('HH:mm') + ' - ' + DateTime.fromISO(session.endDateTime)
                              .toFormat('HH:mm')}
                          </GridTypography>
                        </Grid>
                        <Grid item
                              sm={4}
                              xs={4}
                              display={'flex'}
                              flexDirection={'row'}
                              justifyContent={'flex-end'}
                              paddingRight={'10px'}
                              sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                  paddingRight: 0,
                                },
                              })}
                        >
                          <GridTypography>
                            {session.duration}
                          </GridTypography>
                        </Grid>
                      </Grid>
                      <Grid item
                            md={6}
                            sm={4}
                            xs={12}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                      >
                        <GridTypography>
                          {(Math.round((session.quantity.value / 1000) * 100) / 100).toFixed(2)
                            .toString()
                            .replace('.', ',') + ' kWh'}
                        </GridTypography>
                      </Grid>
                    </Grid>
                  </Grid>))}
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        ) :
        (
          <CustomAccordion key={point.id}>
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}
                                    disabled
            >
              <Typography variant={'h6'}>
                {point.name}
              </Typography>
            </CustomAccordionSummary> </CustomAccordion>
        )}
    </React.Fragment>
  )

}

const ChargingProcessList = ({ processesByPoints, allChargingPoints, totalAmount, totalTime, filtered }) => {

  return (
    <React.Fragment>
      <CustomTableSummary totalTime={totalTime}
                          totalAmount={totalAmount}
      />
      {!filtered && allChargingPoints && allChargingPoints.map(cp => (
        <ChargingProcessPanel processesByPoints={processesByPoints} point={cp} key={cp.id}/>
      ))}
      {filtered && allChargingPoints &&
        <ChargingProcessPanel processesByPoints={processesByPoints} point={allChargingPoints}
                              key={allChargingPoints.id}/>
      }
    </React.Fragment>
  )
}

ChargingProcessList.propTypes = {
  className: PropTypes.string,
  processesByPoints: PropTypes.array.isRequired,
}

export default ChargingProcessList