import { Page } from '../../components'
import manualHelp from './../../help-texts/FL_Datenexport_manuell.md'
import { useEffect, useState } from 'react'
import ManualExport from './ManualExport'

const DataExportPage = ({ roles }) => {
  const PAGE_TITLE = 'Datenexport'
  const [manualHelpText, setManualHelpText] = useState()

  useEffect(() => {
    fetch(manualHelp)
      .then(res => res.text())
      .then(text => setManualHelpText(text))
  }, [])

  return (
    <Page viewName={PAGE_TITLE} pageTitle={PAGE_TITLE} helpTitle={PAGE_TITLE} helpText={manualHelpText} roles={roles}>
      <ManualExport/>
    </Page>
  )

}

DataExportPage.propTypes = {}

export default DataExportPage