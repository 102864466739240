import React, { useEffect, useState } from 'react'
import { LoadingBackdrop, NavigateFab, Page, Poller } from '../../components'
import LmsListContainer from './LmsListContainer'
import AddIcon from '@mui/icons-material/Add'
import { getRoles, isCPO, isSupportPerson } from '../../security/roles'
import { useSelector } from 'react-redux'
import { useLocation, useRouteLoaderData } from 'react-router'
import stationAdminHelpText from '../../help-texts/FL_Ladestationen_Support_und_CPO.md'
import stationHelpText from '../../help-texts/FL_Ladestationen.md'
import { backendApiClient } from '../../apiClient'

const LmsListPage = ({ roles }) => {

  const url = useLocation().pathname
  const [adminStationHelpText, setAdminStationHelpText] = useState()
  const [stationsHelpText, setStationsHelpText] = useState()
  const userRoles = useSelector(state => getRoles(state))
  const rootData = useRouteLoaderData('root')
  const [lmsList, setLmsList] = useState(rootData?.systemStatusList)
  const [loading, setLoading] = useState(false)

  const SYSTEM_STATUS_API = '/api/systemstatus'

  useEffect(() => {
    fetch(stationAdminHelpText)
      .then(res => res.text())
      .then(text => setAdminStationHelpText(text))

    fetch(stationHelpText)
      .then(res => res.text())
      .then(text => setStationsHelpText(text))
  }, [])

  const fetchSystemStatus = () => {
    backendApiClient.get(SYSTEM_STATUS_API, { pageSize: 200 })
      .then((response) => {
        setLmsList(sortLmsList(response.content))
      })
      .catch(error => throw new Error(error))
      .finally(() => setLoading(false))
  }

  const sortLmsList = (lmsList) => {
    return lmsList.sort((a, b) => (b.totalCharged || 0.0) - (a.totalCharged || 0.0))
  }

  return (
    <Page roles={roles} viewName={'Ladestationen'} pageTitle={'Ladestationen'} helpTitle={'Ladestationen'}
          helpText={(isSupportPerson(userRoles) || isCPO(userRoles)) ? adminStationHelpText : stationsHelpText}>
      <LoadingBackdrop open={loading}/>
      <Poller interval={60000} action={() => fetchSystemStatus()}/>
      <LmsListContainer lmsList={lmsList} loading={loading}/>
      {(isSupportPerson(userRoles) || isCPO(userRoles)) &&
        <NavigateFab
          path={`${url}/new`}
          text={'neu'}
        >
          <AddIcon/>
        </NavigateFab>
      }
    </Page>
  )
}

export default LmsListPage
