import { List } from '@mui/material'
import NavItem from './NavItem'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import React from 'react'

function Section ({ pages, title, noDivider, hasEms }) {
  if (0 === pages.length) return <></>
  const finalPages = []

  pages.forEach((page) => {
    if (!finalPages.find((p) => p.title === page.title)) {
      if (page.title === 'Energiemanagement') {
        if (hasEms) {
          finalPages.push(page)
        }
      } else {
        finalPages.push(page)
      }
    }
  })

  return (
    <React.Fragment>
      <List subheader={title}
            sx={{ color: 'primary.contrastText' }}
      >
        {finalPages
          .map((page) => (
            <NavItem key={page.path} page={page}/>
          ))}

      </List>
      {!noDivider &&
        <Divider sx={{ margin: 8 }}/>
      }
    </React.Fragment>
  )
}

Section.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
}

export default Section