import React, { useEffect, useState } from 'react'
import { Page } from '../../components'
import NewDocument from './NewDocument'
import { useNavigate } from 'react-router'
import newDocuments from '../../help-texts/FL_Dokument_anlegen.md'

const DocumentsPage = () => {

  const navigate = useNavigate()
  const HELP_TITLE_EDIT = 'Fügen Sie ein neues Dokument hinzu'
  const NEW_DOCUMENTS = 'Dokument anlegen'
  const [newDocumentHelpText, setNewDocumentHelpText] = useState()

  useEffect(() => {
    fetch(newDocuments)
      .then(res => res.text())
      .then(text => setNewDocumentHelpText(text))
      .catch(error => throw new Error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page viewName={NEW_DOCUMENTS} onBack={() => navigate(-1)} helpTitle={HELP_TITLE_EDIT}
          helpText={newDocumentHelpText}>
      <NewDocument onAfterSubmit={() => navigate(-1)}/>
    </Page>
  )
}

export default DocumentsPage