import React from 'react'
import { NewPasswordForm } from '../../components'
import { useSelector } from 'react-redux'
import { completeNewPassword } from '../../store'

const NewPassword = ({ error }) => {
  const user = useSelector(state => state.auth.user)

  const handleSubmit = (values) => {
    completeNewPassword(user, values.password)
  }

  return (
    <NewPasswordForm onSubmit={handleSubmit} error={error}/>
  )
}

export default NewPassword
