import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

/**
 * showing switch for check box form fields
 */
const SwitchCheckbox = (props) => {
  // eslint-disable-next-line react/prop-types
  const { value, id, onChange, label, disabled, onBlur, labelPlacement = 'end' } = props

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={(
        <Switch
          id={id}
          disabled={disabled}
          checked={value}
          onChange={() => onChange(!value)}
          name={id}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
          onBlur={onBlur}
        />
      )}
      label={String(label)}
    />
  )
}

export default SwitchCheckbox
