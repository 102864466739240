import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { DateTime } from 'luxon'
import { useTheme } from '@mui/material/styles'

const displayTooltipValue = (arg) => {
  return arg.value[1] < 0 || arg.value[1] === null ? '<b>-</b>' : '<b>' + arg.value[1] + '</b>'
}

const tooltipLine = (arg) => {
  // eslint-disable-next-line no-useless-concat
  return '<p style="margin:0;padding:0; width:140px">' + arg.marker + ' ' + arg.seriesName + '<span style="float:right;">' + displayTooltipValue(arg) + '</span>' + '</p>'
}

function callbackFormatter (args) {
  if (!args[0] && !args[1]) {
    return ''
  } else if (!args[0] && args[1]) {
    return tooltipLine(args[1])
  } else if (args[0] && !args[1]) {
    return tooltipLine(args[0])
  } else {
    return tooltipLine(args[0]) + tooltipLine(args[1])
  }
}

const PowerHistoryMarkerChart = ({ dataConsumption, dataSurplus, timeSpan, gridPowerLimit, chargingPowerLimit, markerData }) => {
  const theme = useTheme()

  const yScaleMax = gridPowerLimit > 0 ? gridPowerLimit : chargingPowerLimit

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: callbackFormatter,
      axisPointer: {
        label: {
          formatter: (function (value) {
            return DateTime.fromMillis(value.value)
              .toFormat('dd.LL.yyyy HH:mm')
          }),
        },
      },
    },
    textStyle: {
      color: theme.palette.graph.text,
    },
    legend: {
      data: [{ name: 'Verbrauch', icon: 'circle' }, { name: 'Überschuss', icon: 'circle' }],
      top: 4,
      right: 40,
      textStyle: {
        color: theme.palette.graph.text,
      },
    },
    title: {
      left: 'left',
      text: 'Leistungsverlauf',
      textStyle: {
        color: theme.palette.graph.text,
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: theme.palette.graph.text,
      },
      feature: {
        restore: {},
      },
    },
    grid: { top: 50, right: 15, bottom: 70, left: 50 },
    xAxis: {
      type: 'time',
      min: timeSpan.from,
      max: timeSpan.to,
      axisLabel: {
        formatter: (function (value) {
          return DateTime.fromMillis(value)
            .toFormat('HH:mm')
        }),
      },
    },
    yAxis: {
      type: 'value',
      axisTick: { show: true },
      max: yScaleMax,
      min: 0,
      axisLabel: {
        formatter: '{value} kW',
      },
      boundaryGap: [0, '100%'],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: 'Verbrauch',
        data: dataConsumption,
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: theme.palette.graph.mainColor1,
        },
        markArea: {
          itemStyle: {
            color: theme.palette.graph.text,
          },
          data: markerData,
        },
      },
      {
        name: 'Überschuss',
        data: dataSurplus,
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: theme.palette.graph.mainColor2,
        },
        markArea: {
          itemStyle: {
            color: theme.palette.graph.text,
          },
          data: markerData,
        },
      },
    ],
  }

  return (
    <ReactEcharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={'theme_name'}
      style={{ height: '450px' }}
    />
  )
}

export default PowerHistoryMarkerChart