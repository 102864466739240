export const SNACKBAR_SUCCESS = 'Die Änderungen wurden erfolgreich übernommen'
export const SNACKBAR_ERROR = 'Die Änderungen können nicht gespeichert werden, bitte prüfen Sie Ihre Eingabe!'
export const ERROR_IP_INVALID = 'Der Eintrag ist keine gültige IP-Adresse'
export const ERROR_MANUFACTURER_IP_MISSING = 'IP-Adresse ist für diesen Hersteller nicht gültig'
export const ERROR_IP_DUPLICATE = 'IP-Adresse wird bereits verwendet'
export const ERROR_SERIALNUMBER_DUPLICATE = 'Seriennummer wird bereits verwendet'
export const ERROR_SERIALNUMBER_LENGTH = 'Die Seriennummer muss zwischen 1 und 30 Zeichen lang sein'
export const ERROR_SERIALNUMBER_NOT_A_NUMBER = 'Die Seriennummer muss eine ganze Zahl sein'
export const ERROR_CHARGEPOINT_ID_EMPTY = 'Die ChargingPointId darf nicht leer sein.'
export const ERROR_CHARGEPOINT_ID_INVALID = 'Die ChargePointId muss zwischen 1 und 30 Zeichen lang sein'
export const ERROR_CHARGEPOINT_ID_DUPLICATE = 'ChargePointId wird bereits verwendet'
export const ERROR_NAME_EMPTY = 'Der Name darf nicht leer sein.'