import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useFormik } from 'formik'
import { userValidationSchema } from './UserValidation'
import CustomFormikTextField from '../../../components/crud/widgets/components/CustomFormikTextField'
import { LoadingBackdrop, SubmitFab } from '../../../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ChargingPointMultiSelector from '../../../components/MultiSelector/ChargingPointMultiSelector'

const UserWidget = ({ chargingPoints, save, user, preSelectedId, loading }) => {

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      accountId: '',
      comment: '',
      chargingPointIds: [],
    },
    validationSchema: userValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values, actions) => {
      save(values, actions)
    },
  })

  useEffect(() => {
    if (preSelectedId && Object.values(preSelectedId).length > 0) {
      formik.setFieldValue('chargingPointIds', [parseInt(preSelectedId)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user && Object.values(user).length > 0) {
      formik.setValues(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleSetSelectedChargingPoints = (value) => {
    formik.setFieldValue('chargingPointIds', value)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Grid
        container
        alignItems={'center'}
        spacing={3}
      >
        <Grid
          item
          sm={6}
          xs={12}>
          <CustomFormikTextField
            value={formik.values.firstName}
            onChange={formik.handleChange}
            id={'firstName'}
            name={'firstName'}
            label={'Vorname'}
            error={Boolean(formik.errors?.firstName)}
            helperText={formik.errors?.firstName || ''}
            disabled={user}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}>
          <CustomFormikTextField
            value={formik.values.lastName}
            onChange={formik.handleChange}
            id={'lastName'}
            name={'lastName'}
            label={'Nachname'}
            error={Boolean(formik.errors?.lastName)}
            helperText={formik.errors?.lastName || ''}
            disabled={user}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.email}
            onChange={formik.handleChange}
            id={'email'}
            name={'email'}
            label={'E-Mail'}
            error={Boolean(formik.errors?.email)}
            helperText={formik.errors?.email || ''}
            disabled={user}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.accountId}
            onChange={formik.handleChange}
            id={'accountId'}
            name={'accountId'}
            label={'Kostenstelle (Account-ID)'}
            error={Boolean(formik.errors?.accountId)}
            helperText={formik.errors?.accountId || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={1}
        >
          <Box paddingBottom={0.5}>
            <Typography>Freigegebene Ladepunkte</Typography>
          </Box>
          <ChargingPointMultiSelector chargingPoints={chargingPoints}
                                      selectedChargingPoints={formik.values.chargingPointIds || []}
                                      setSelectedChargingPoints={handleSetSelectedChargingPoints}/>
        </Grid>
        <Grid
          item
          xs={12}>
          <Box paddingBottom={1}>
            <Typography>Kommentar</Typography>
          </Box>
          <CustomFormikTextField
            value={formik.values.comment || ''}
            onChange={formik.handleChange}
            multiline
            rows={5}
            variant={'outlined'}
            id={'comment'}
            name={'comment'}
            error={Boolean(formik.errors?.comment)}
            helperText={formik.errors?.comment || ''}
          />
        </Grid>
      </Grid>

      <SubmitFab show={true}/>
    </form>
  )
}

export default UserWidget